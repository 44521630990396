import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone } from '../../constants/loading_state';
import { useQueryString } from '../../hooks/use_query_string';
import {
  applyDiscount,
  getCheckoutId,
  getLoadingState,
  getLoadingStateAddToCart,
} from '../../redux/cart';
import { GlobalQueryParams } from '../../lib/query_string';

export const CartDiscountHandler = (): null => {
  const dispatch = useDispatch();
  const cartLoadingState = useSelector(getLoadingState);
  const addToCartActionLoadingState = useSelector(getLoadingStateAddToCart);
  const checkoutId = useSelector(getCheckoutId);
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const { add_to_cart, discount_code } = getCurrentQuery<{
    [GlobalQueryParams.add_to_cart]: string;
    [GlobalQueryParams.discount_code]: string;
  }>();

  useEffect(() => {
    if (checkoutId && isDone(cartLoadingState) && discount_code != null) {
      if ((add_to_cart && isDone(addToCartActionLoadingState)) || !add_to_cart) {
        dispatch(applyDiscount(checkoutId, discount_code));
        replaceQueryString({ [GlobalQueryParams.discount_code]: null });
      }
    }
  }, [checkoutId, cartLoadingState, discount_code, add_to_cart, addToCartActionLoadingState]);

  return null;
};
