import {
  FishingReport,
  Product,
  ProductFamily,
  ShopifyProduct,
  TechniqueFull,
} from '@omniafishing/core';
import { Col, Row } from 'antd';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OmniaButton } from '../../components/omnia_button/omnia_button';
import { tally } from '../../lib/tally';
import { getTechniques } from '../../redux/reference_data';
import styles from './product_description.less';

interface ProductDescriptionProps {
  shopifyProduct: ShopifyProduct;
  selectedProduct: Product;
  productFamily: ProductFamily;
  fishingReports: FishingReport[];
  onTechniqueClick: (techniqueName: string) => void;
}

export interface TechniqueWithCount extends TechniqueFull {
  count: number;
}

export const ProductDescription = React.memo((props: ProductDescriptionProps) => {
  const { shopifyProduct, selectedProduct, productFamily, fishingReports, onTechniqueClick } =
    props;
  const techniques = useSelector(getTechniques);
  const [isTechniqueListExpanded, setIsTechniqueListExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const productTechniqueNames = selectedProduct?.techniques || [];
  const reportTechniqueNamesInProductTechniques = fishingReports.reduce(
    (acc: string[], { technique }) => {
      if (productTechniqueNames.includes(technique.name)) {
        acc.push(technique.name);
      }
      return acc;
    },
    []
  );

  const reportTechniqueNamesTally = tally(reportTechniqueNamesInProductTechniques);
  const reportTechniquesWithCount: TechniqueWithCount[] = Object.entries(
    reportTechniqueNamesTally
  ).map((t) => {
    const techniqueName = t[0];
    const count = t[1];
    const techniqueModel = techniques.find((tech) => tech.name === techniqueName);
    return {
      ...techniqueModel,
      count,
    };
  });

  const reportTechniquesSorted = _.orderBy(
    reportTechniquesWithCount,
    ['count', 'display_name'],
    ['desc', 'asc']
  );

  const productTechniques = techniques.filter((t) => productTechniqueNames.includes(t.name));
  const combinedTechniqueList: (TechniqueFull | TechniqueWithCount)[] = [
    ...reportTechniquesSorted,
    ...productTechniques,
  ];
  const techniqueListToShow = _.uniqBy(combinedTechniqueList, 'name');

  return (
    <div>
      <div className={styles.productDetailsContainer}>
        <div ref={containerRef}>
          <Row gutter={24}>
            <Col xs={24} sm={14}>
              <section className={styles.productDetails}>
                <div dangerouslySetInnerHTML={{ __html: shopifyProduct.descriptionHtml }} />
              </section>
            </Col>
            <Col
              xs={24}
              sm={{
                span: 8,
                push: 2,
              }}
            >
              <section className={styles.productTags}>
                {techniqueListToShow.length > 0 && (
                  <div className={styles.sidebarList}>
                    <span className={styles.sidebarHeading}>Techniques:</span>{' '}
                    {(isTechniqueListExpanded
                      ? techniqueListToShow
                      : techniqueListToShow.slice(0, 4)
                    ).map((technique) => {
                      if ('count' in technique) {
                        return (
                          <div
                            onClick={() => onTechniqueClick(technique.name)}
                            key={technique.name}
                            className={styles.itemIcon__techniquesWithCount}
                          >
                            <img src={technique.image} alt={technique.img_alt} />
                            <span className={styles.itemName}>
                              {technique.display_name_alternate}
                            </span>
                            <span className={styles.itemCount}>({technique.count})</span>
                          </div>
                        );
                      }
                      return (
                        <div key={technique.id} className={styles.itemIcon__techniques}>
                          <img src={technique.image} alt={technique.img_alt} />
                          <span className={styles.itemName}>
                            {technique.display_name_alternate}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                {techniqueListToShow.length > 4 && (
                  <OmniaButton
                    onClick={() => setIsTechniqueListExpanded(!isTechniqueListExpanded)}
                    kind="link"
                    size="sm"
                    className={styles.showMoreItemsButton}
                  >
                    {isTechniqueListExpanded ? 'View Less' : 'View All'}
                  </OmniaButton>
                )}
              </section>
            </Col>
          </Row>
          {productFamily.description_data && (
            <div
              className={styles.descriptionData}
              dangerouslySetInnerHTML={{
                __html: productFamily.description_data,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});
