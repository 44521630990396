export const highLevelDataPoints = [
  {
    value: '11',
    item: 'Map Layers',
  },
  {
    value: '150k',
    item: 'Lakes Covered',
    plus: true,
  },
  {
    value: '30k',
    item: 'Fishing Reports',
    plus: true,
  },
  {
    value: '10%',
    item: 'Back on Gear',
  },
];

export const appReviews = [
  {
    rating: 5,
    content:
      'Omnia is far and away the best online fishing store I’ve used. The app is super helpful for planning trips, and the Pro features make it even better. I highly recommend giving it a try.',
    userName: 'jjalzen1',
  },
  {
    rating: 5,
    content:
      'This app is great. Not only do I use it to analyze lakes and plan my fishing trips, but the Pro features have taken my experience to the next level. Definitely worth a trial.',
    userName: 'WH1693',
  },
  {
    rating: 5,
    content:
      'I was first introduced to Omnia Fishing when I started looking for fishing gear. The app’s Pro features are amazing for trip planning and finding the best spots. It’s a must-try for any serious angler.',
    userName: 'Yak_Sota',
  },
  {
    rating: 5,
    content:
      'If you are really into bass fishing, Omnia is the app for you. The Pro features are incredibly detailed and have improved my success rate. Give the trial a shot; you won’t regret it.',
    userName: 'Hippe 13131313',
  },
  {
    rating: 5,
    content:
      'This is the best fishing app in the App Store, and not just for shopping. The Pro features provide invaluable information for planning my fishing trips. Highly recommended to start the trial.',
    userName: 'ATS200',
  },
  {
    rating: 5,
    content:
      'I just recently upgraded to Pro on the app. It has everything you need for fishing in one stop. The detailed maps and trip planning features are top-notch. Definitely try it out.',
    userName: 'Andy Clinton',
  },
  {
    rating: 5,
    content:
      'This is by far the best online tackle experience I’ve had. The Pro features on the app have significantly improved my fishing trips. You should definitely start a trial.',
    userName: 'The DadBod Angler',
  },
  {
    rating: 5,
    content:
      'Omnia has become my go-to one-stop shop for most of my fishing needs. The Pro features are a game-changer for planning and success. Try the trial; it’s worth it.',
    userName: 'Tyler Duncan',
  },
  {
    rating: 5,
    content:
      'I definitely enjoy shopping at Omnia Fishing. The Pro features on the app provide great rewards and useful planning tools. I recommend starting a trial to see the benefits.',
    userName: 'malimd',
  },
  {
    rating: 5,
    content:
      'Omnia is the best online store that I used in the last couple of years. The Pro features in the app are fantastic for trip planning and fishing success. Give the trial a try.',
    userName: 'B. I Fishing',
  },
];

export interface OmniaProgramComparisonItem {
  text: string;
  standard?: boolean;
  heading?: string;
  standardBlurb?: string;
  proBlurb?: string;
}

export const omniaProgramComparisons: OmniaProgramComparisonItem[] = [
  {
    text: 'Read thousands of fishing reports',
    standard: true,
  },
  {
    text: 'Create and store waypoints',
    heading: 'Mapping',
    standard: true,
  },
  {
    text: 'Average temperature summary by lake',
    standard: true,
  },
  {
    text: 'Solunar and barometric pressure',
    standard: true,
  },
  {
    text: 'Current water temps and clarity gradients',
  },
  {
    text: 'Depth contours from Navionics® and C-MAP®',
  },
  {
    text: 'Current wind speed and direction',
  },
  {
    text: 'Current and forecasted air temp, precip, storm cells',
  },
  {
    text: 'Gear suggestions organized by lake',
    standard: true,
    heading: 'Shopping',
  },
  {
    text: 'Same day shipping on 20K items',
    standard: true,
  },
  {
    text: 'Free shipping',
    standard: true,
    standardBlurb: 'Over $50',
    proBlurb: 'No minimums',
  },
  {
    text: '10% cash back',
  },
  {
    text: 'In stock alerts',
    standard: true,
    proBlurb: '4h head start',
  },
  {
    text: 'Exclusive private sales',
  },
  {
    text: 'Members only giveaways',
  },
];

export const premiumProFaqs = [
  {
    q: `What is Omnia PRO?`,
    a: `Omnia PRO is an optional membership that provides access to map layers with current conditions, plus ecommerce benefits including free shipping and 10% back on most purchases, and early access to products when they come back in stock. 
    The Omnia PRO Membership is available for $49/year.
    `,
  },
  {
    q: `Are there any limitations for earning credit?`,
    a: `Omnia PRO credit will be issued for product purchases where a discount of less than 20% is applied.  On occasion, we’ll run promotions with a discount of 20% or more and credit will not be earned in this case. You also don’t earn credit for an order that is paid with credit. In addition, we must follow our vendor supplier agreements and some brand restrictions can apply and change from time to time.`,
  },
  {
    q: 'Are the shipping benefits the same for US and Canadian addresses?',
    a: `PRO members shipping to US addresses can get free shipping, with no minimum order size requirements. Members that ship to Canada get a $10 flat rate for shipping, regardless of the size of their order.`,
  },
  {
    q: `Are all lakes in the United States covered by PRO maps?`,
    a: `Our map layers and weather data coverage varies, but is not comprehensive. Our goal is to cover most of the major recreational fisheries in the United States. Contours and Water Clarity data is available on over 30,000 lakes. Water Temperature data is available for around 8,000 lakes. Weather data is available for all waterbodies in the United States. Omnia is continuously adding more lakes and expanding our available dataset.`,
  },
  {
    q: 'Are all lakes in Canada covered by PRO maps?',
    a: `Waterbody data is less available in Canada than in the United States and coverage is less complete. We recommended starting a trial to see if your waterbodies are in an area that gets frequent updates`,
  },
  {
    q: `Can I check if my favorite lakes are available?`,
    a: `Omnia offers a FREE 7-day trial within the Omnia Fishing app. During this trial, you can use all of the available Omnia PRO features to test out the map layers and confirm coverage in your area.`,
  },
  {
    q: `How frequently is the data updated?`,
    a: `The map layers and weather data are collected from several different sources so the frequency varies. Water Temperature data is usually refreshed every 1-5 days depending on satellite passes and visual obstructions like cloud cover. Water Clarity data is currently updated on a monthly basis showing the average readings for the current month based upon a multi-year average of historical data. Contour data from Navionics is updated many times per day. C-MAP's community-collected readings are automatically added to PRO within 1 day.`,
  },
  {
    q: `How is this information collected?`,
    a: `Data collection sources vary. Water Temperature and Water Clarity data areis derived from satellite-based sources and compiled to present an accurate heatmap layer for each lake. Detailed nautical charts are provided through Garmin Navionics. C-MAP Genesis layers provide further detail on contours, bottom composition and vegetation based upon on-boat sonar/GPS readings made by a community of anglers on lakes across the United States.`,
  },
  {
    q: `How accurate is the data?`,
    a: `Observations show a close comparison between real-world data readings and the Omnia PRO map layer information in our heatmaps. But the data is most useful as a way to identify the relative values for a lake and should not be considered perfectly accurate or in real-time.`,
  },
  {
    q: `How can anglers use current lake conditions info?`,
    a: `There are many applications for using the Omnia PRO data to make decisions while on the water. For example, using Water Temperature to locate the warming areas of the lake during the spring spawning season, find cool underwater springs during summer months or selecting a lake for an outing based on the current water conditions being favorable. Other use cases include choosing the right bait colors based upon clarity estimates, monitoring weather conditions for a safe trip and using current wind readings to select the best launching points on a waterbody.`,
  },
  {
    q: `Is Omnia PRO available on the Omnia website?`,
    a: `PRO members can access fishing reports, and PRO map layers from the web as well as the Omnia Fishing app. Some map layers, such as Navionics depth contours, are currently only available from within the app.`,
  },
  {
    q: `How do I get a refund for my purchase?`,
    a: `Please contact support to learn how to request a refund for a purchased subscription. Merchandise returns are accepted within 30 days of receiving your order if the item[s] are returned in the original condition with tags.  Anything returned after 30 days of receipt will not be eligible for refund or store credit. A refund will be issued once we receive returned items.  Any items that are damaged when we receive them are not eligible for refund.  For our full refund policy visit omniafishing.com/delivery-return`,
  },
];
