import { AmbassadorMini, OmniaVideo, UserPrivate, UserRoles } from '@omniafishing/core';

export const NAME_FALLBACK = 'Omnia Community Member';

export enum AMBASSADOR_ROLES {
  BLUE_AMBASSADOR = 'ambassador_blue',
  GOLD_AMBASSADOR = 'ambassador_gold',
  SILVER_AMBASSADOR = 'ambassador_silver',
  BRONZE_AMBASSADOR = 'ambassador_bronze',
}

export function isAmbassador(user: AmbassadorMini | UserPrivate) {
  if (user == null) {
    return false;
  }
  return (
    ['ambassador_bronze', 'ambassador_silver', 'ambassador_gold', 'ambassador_blue'] as UserRoles[]
  ).includes(user.role);
}

export function isUserPublic(user: { role: UserRoles }) {
  if (user == null) {
    return false;
  }
  return (['ambassador_silver', 'ambassador_gold', 'ambassador_blue'] as UserRoles[]).includes(
    user.role
  );
}

export function getUserFullname(user: { first_name: string | null; last_name: string | null }) {
  if (!user) {
    return '';
  }

  const { first_name, last_name } = user;
  const first = first_name && first_name.trim();
  const last = last_name && last_name.trim();
  if (first_name && last_name) {
    return `${first} ${last}`;
  }
  if (first_name && !last_name) {
    return first;
  }
  if (!first_name && last_name) {
    return last;
  }

  return '';
}

export function roleAsText(role: UserRoles, defaultText = 'Standard') {
  switch (role) {
    case 'ambassador_blue':
      return 'Blue';
    case 'ambassador_bronze':
      return 'Bronze';
    case 'ambassador_silver':
      return 'Silver';
    case 'ambassador_gold':
      return 'Gold';
    default:
      return defaultText;
  }
}

export const userIsOmniaVideoAffiliateMatch = (user: UserPrivate, omniaVideo: OmniaVideo) => {
  return !!(
    omniaVideo?.affiliate?.campaign_source &&
    omniaVideo?.affiliate?.campaign_source === user?.affiliate?.campaign_source
  );
};
