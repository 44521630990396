import { ApolloQueryResult } from '@apollo/client';
import { LineItem } from '@omniafishing/core';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { shopifyClientInstance } from '../../apollo';
import { useCart } from '../../hooks/use_cart';
import { LineItemCustomAttribute, updateLineItems } from '../../redux/cart';

const checkoutFetchQuery = gql`
  query checkoutFetch($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        lineItems(first: 250) {
          nodes {
            id
            quantity
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
  }
`;

type LineItemWithCustomAttributes = LineItem & {
  customAttributes: LineItemCustomAttribute[];
};

interface CheckoutFetchResponse {
  node: {
    lineItems: {
      nodes: LineItemWithCustomAttributes[];
    };
  };
}

export const LineItemCustomAttributeFixer = (): null => {
  const { checkoutId } = useCart();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkoutId) {
      return;
    }

    shopifyClientInstance
      .query({
        query: checkoutFetchQuery,
        variables: {
          checkoutId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response: ApolloQueryResult<CheckoutFetchResponse>) => {
        const lineItems = response.data.node.lineItems.nodes;
        const hasCustomAttributesToFix = lineItems.some((lineItem) =>
          lineItem.customAttributes.some((customAttribute) => !customAttribute.key.startsWith('_'))
        );
        if (!hasCustomAttributesToFix) {
          return;
        }

        const lineItemsFixed = lineItems.map((lineItem) => {
          lineItem.customAttributes = lineItem.customAttributes.map((customAttribute) => {
            if (customAttribute.key.startsWith('_')) {
              return {
                key: customAttribute.key,
                value: customAttribute.value,
              };
            }
            return {
              key: `_${customAttribute.key}`,
              value: customAttribute.value,
            } as unknown as LineItemCustomAttribute;
          });

          return {
            id: lineItem.id,
            quantity: lineItem.quantity,
            customAttributes: lineItem.customAttributes,
          };
        });

        dispatch(updateLineItems(checkoutId, lineItemsFixed));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [checkoutId]);

  return null;
};
