import { Alert } from 'antd';
import React, { ReactNode } from 'react';

import styles from '../auth_form_wrapper/auth_form_wrapper.less';

export interface AuthFormErrorProps {
  errorMessage: ReactNode;
}

const AuthFormError: React.FC<AuthFormErrorProps> = (props) => {
  if (props.errorMessage == null) {
    return null;
  }

  return (
    <Alert message={props.errorMessage} type="error" showIcon closable className={styles.alert} />
  );
};

export default AuthFormError;
