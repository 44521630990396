import { Arrow, Pagination } from '@egjs/flicking-plugins';
import Flicking, { FlickingOptions, ViewportSlot, FlickingProps } from '@egjs/react-flicking';
import classNames from 'classnames';
import React from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import '../../styles/flicking.less';
import styles from './omnia_carousel.less';

interface OmniaCarouselProps extends Partial<FlickingProps & FlickingOptions> {
  adaptive?: boolean;
  arrows?: boolean;
  children: React.ReactNode;
  circular?: boolean;
  className?: string;
  dots?: boolean;
  fadedEdges?: boolean;
  key: string;
  mobileArrows?: boolean;
  newArrows?: boolean;
  onMoveStart?: () => void;
  renderOnlyVisible?: boolean;
  resizeDebounce?: number;
  resizeOnContentsReady?: boolean;
}

export const OmniaCarousel = (props: OmniaCarouselProps) => {
  const {
    adaptive = true,
    arrows,
    children,
    circular,
    className,
    dots,
    fadedEdges,
    mobileArrows,
    newArrows,
    renderOnlyVisible,
    resizeDebounce,
    resizeOnContentsReady,
    ...rest
  } = props;
  const { isDesktop } = useResponsive();
  const plugins = [];
  const showArrows = (arrows && isDesktop) || mobileArrows;

  if (showArrows) {
    plugins.push(new Arrow({ moveByViewportSize: true }));
  }
  if (dots) {
    plugins.push(new Pagination({ type: 'bullet' }));
  }

  return (
    <div
      className={classNames(styles.carousel, className, {
        [styles.carousel__fadedEdges]: fadedEdges,
        [styles.carousel__newArrows]: newArrows,
      })}
    >
      <Flicking
        adaptive={adaptive}
        align="prev"
        bound={circular ? false : true}
        circular={circular}
        duration={700}
        maxResizeDebounce={resizeDebounce ? 1500 : 100}
        plugins={plugins}
        renderOnlyVisible={renderOnlyVisible ?? true}
        resizeDebounce={resizeDebounce || 0}
        resizeOnContentsReady={resizeOnContentsReady}
        interruptable={false}
        {...rest}
      >
        {children}
        <ViewportSlot>
          {showArrows && (
            <>
              <span className="flicking-arrow-prev"></span>
              <span className="flicking-arrow-next"></span>
            </>
          )}
          {dots && <div className="flicking-pagination"></div>}
        </ViewportSlot>
      </Flicking>
    </div>
  );
};

export default OmniaCarousel;
