import { apiV1 } from '../../lib/api';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Waterbody } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import styles from './dashboard_file_report_card.less';

export const DashboardFileReportCard = ({
  waterbody,
  className,
}: {
  waterbody?: Waterbody;
  className: string;
}) => {
  const dispatch = useDispatch();

  const onFileFishingReport = async (w: Waterbody) => {
    if (!w) {
      dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN());
    }
    const res = await apiV1.waterbodyFetch(w.url_slug);
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(res.data.data));
  };
  return (
    <div
      className={classNames(styles.cardContainer, className)}
      onClick={() => onFileFishingReport(waterbody)}
    >
      <div className={styles.cardContent}>
        <OmniaButton
          size="lg"
          kind="tertiary"
          fontSize={14}
          shape={'circle'}
          onPress={() => onFileFishingReport(waterbody)}
          className={styles.fileReportButton}
        >
          <PlusOutlined />
        </OmniaButton>
        <p>
          {waterbody ? (
            <>
              File Your Report
              <br />
              <span>for {waterbody.primary_name}</span>
            </>
          ) : (
            'File a Report'
          )}
        </p>
      </div>
    </div>
  );
};
