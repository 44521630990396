import { Radio } from 'antd';
import React from 'react';
import styles from './all_filters_drawer.less';

interface SortByDistanceRadios {
  onChange: (val: boolean) => void;
  drawerSortByDistance: boolean;
}

export const SortingRadios = (props: SortByDistanceRadios) => {
  const { onChange, drawerSortByDistance } = props;

  return (
    <div className={styles.sortBySection}>
      <Radio.Group
        value={drawerSortByDistance}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className={styles.sortRadios}
      >
        <Radio value={true}>
          <span>Nearby</span>
        </Radio>
        <Radio value={false}>
          <span>Most recent</span>
        </Radio>
      </Radio.Group>
    </div>
  );
};
