import { SearchOutlined } from '@ant-design/icons';
import { FishingReport, Waterbody, WaterbodyDetail, Waypoint } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactMapGL, {
  GeolocateControl,
  MapLayerMouseEvent,
  MapRef,
  Marker,
  MarkerDragEvent,
  NavigationControl,
  ViewState,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { getEnv } from '../../env';
import { usePrevious } from '../../hooks/use_previous';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { fitBounds } from '../../lib/fit_bounds';
import { OmniaUrls } from '../../lib/urls';
import { waitFor } from '../../lib/wait_for';
import { WebAnalytics } from '../../lib/web_analytics';
import fishIcon from '../../_svg_src/icon/v3/fish_icon.png';
import fishIconImage from '../../_svg_src/icon/v3/fish_icon_image.png';
import greatSpotIcon from '../../_svg_src/icon/v3/great_spot_icon.png';
import greatSpotIconImage from '../../_svg_src/icon/v3/great_spot_icon_image.png';
import hazardIcon from '../../_svg_src/icon/v3/hazard_icon.png';
import hazardIconImage from '../../_svg_src/icon/v3/hazard_icon_image.png';
import landingPublic from '../../_svg_src/icon/v3/landingPublic.png';
import landingSemiPrivate from '../../_svg_src/icon/v3/landingSemiPrivate.png';
import markerIcon from '../../_svg_src/icon/v3/marker_icon.png';
import markerIconImage from '../../_svg_src/icon/v3/marker_icon_image.png';
import structureIcon from '../../_svg_src/icon/v3/structure_icon.png';
import structureIconImage from '../../_svg_src/icon/v3/structure_icon_image.png';
import SvgFullScreen from '../svg/full_screen';
import { FishingReportLink } from './fishing_report_link';
import {
  AerisController,
  AerisUnits,
  BoatLanding,
  LAYER_TYPES,
  MapLayerState,
  OVERLAY_LAYER,
} from './map_types';
import { deriveStyleUrl, determineWaterbodyActivity } from './map_utils';
import styles from './omnia_map.less';
import { OmniaMapSearchSelect, WaterbodySelectValue } from './omnia_map_search';
import {
  applyAerisLayerController,
  InteractiveLayerIds,
  OmniaMapSources,
} from './omnia_map_sources';
import { PinHighActivity } from './pin_high_activity';
import { PinLowActivity } from './pin_low_activity';
import { WaypointMarker } from './waypoint_marker';

const env = getEnv();

export interface MapBounds {
  n: number;
  s: number;
  e: number;
  w: number;
}

export const MAX_PIN_LIMIT = 70;

export interface MapProps {
  backToMapLat?: number;
  backToMapLink?: boolean;
  backToMapLng?: number;
  backToMapZoom?: number;
  disableScrollZoom?: boolean;
  draggableWaypoint?: Partial<Waypoint>;
  featuredMarkers?: Waterbody[];
  geoCoder?: boolean;
  geoCoderCentered?: boolean;
  geoCoderExpanded?: boolean;
  geoCoderFocusAndOpenOnMount?: boolean;
  getAerisController?: (aerisController: AerisController) => void;
  height: number;
  interactiveWaterbodyId?: string | null;
  loadInteractiveImages: boolean;
  mapLayerState: MapLayerState;
  markers: Waterbody[];
  maxBounds: mapboxgl.LngLatBoundsLike; //  [west, south, east, north]
  minZoom?: number;
  navControl: boolean;
  navControlPosition?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  onBackToMapClick?: () => void;
  onGeocoderResult?: (result: any) => void;
  onLoad?: (bounds: MapBounds, mapRef: MapRef) => void;
  onMapClick?: (e: MapLayerMouseEvent) => void;
  onPinClick?: (waterbody: Waterbody) => void;
  onStyleLoaded?: () => void;
  onViewportChange: (viewport: Partial<ViewState>, bounds: MapBounds) => void;
  onWaypointDragEnd?: (e: MarkerDragEvent) => void;
  placeHolderText?: string;
  popup?: React.ReactNode;
  restrictAllMovement?: boolean;
  selectedBoatLanding?: BoatLanding;
  selectedFishingReport?: FishingReport;
  selectedWaterbody?: WaterbodyDetail;
  selectedWaypoint?: Waypoint;
  usePreciseLocation?: boolean;
  userFavoriteWaterbodies?: WaterbodyDetail[];
  viewport: PartialViewState;
  waterbodyDetail?: WaterbodyDetail;
  waterClarityDailyTile?: string | undefined;
  waterClarityShowComposite?: boolean;
  waterTempDailyTile?: string | undefined;
  waterTempShowComposite?: boolean;
  waypoints?: Waypoint[];
  width: number;
}

export const FLYOVER_TRANSITION_DURATION = 3000;

const iconsToLoad: Record<string, string> = {
  marker_icon: markerIcon,
  marker_icon_image: markerIconImage,
  fish_icon: fishIcon,
  fish_icon_image: fishIconImage,
  structure_icon: structureIcon,
  structure_icon_image: structureIconImage,
  great_spot_icon: greatSpotIcon,
  great_spot_icon_image: greatSpotIconImage,
  hazard_icon: hazardIcon,
  hazard_icon_image: hazardIconImage,
  publicIcon: landingPublic,
  semiPrivateIcon: landingSemiPrivate,
};

type PartialViewState = Partial<ViewState>;

const OmniaMap = (props: MapProps) => {
  const {
    backToMapLat,
    backToMapLink,
    backToMapLng,
    backToMapZoom,
    disableScrollZoom,
    draggableWaypoint,
    featuredMarkers,
    geoCoder,
    geoCoderCentered,
    geoCoderExpanded,
    geoCoderFocusAndOpenOnMount,
    getAerisController,
    height,
    interactiveWaterbodyId,
    loadInteractiveImages,
    mapLayerState,
    markers,
    maxBounds,
    minZoom,
    navControl,
    navControlPosition = 'top-left',
    onBackToMapClick,
    onLoad,
    onMapClick,
    onPinClick,
    onWaypointDragEnd,
    placeHolderText,
    popup,
    restrictAllMovement,
    selectedBoatLanding,
    selectedFishingReport,
    selectedWaterbody,
    selectedWaypoint,
    usePreciseLocation,
    userFavoriteWaterbodies = [],
    waypoints,
    waterClarityDailyTile,
    waterClarityShowComposite = true,
    waterTempDailyTile,
    waterTempShowComposite = true,
    width,
  } = props;

  const { isDesktop } = useResponsive();
  const prevMapLayerState = usePrevious(mapLayerState);
  const [viewport, setViewport] = useState<PartialViewState>(props.viewport);
  const [mapRef, setMapRef] = useState<MapRef>();
  const [loaded, setLoaded] = useState(false);
  const { replaceQueryString } = useQueryString();
  const [aerisController, setAerisController] = useState<AerisController>();
  const [aerisTempUnits, setAerisTempUnits] = useState<AerisUnits>();

  const featuredPins = featuredMarkers || [];
  const featuredMarkerIds = featuredPins.map((featured) => featured.id);
  const userFavoriteWaterbodiesIds = userFavoriteWaterbodies.map((w) => w.id);
  const { waterbodiesSorted, highActivityWaterbodyIds } = determineWaterbodyActivity(markers);
  const allWaterbodies = _.uniqBy(
    [selectedWaterbody, ...waterbodiesSorted, ...featuredPins, ...userFavoriteWaterbodies].filter(
      Boolean
    ),
    'id'
  );

  // sorting for z-index / pin stacking
  const allWaterbodiesSortedByLat = _.orderBy(allWaterbodies, ['lat'], 'desc');

  const onMove = (e: ViewStateChangeEvent) => {
    setViewport({
      ...viewport,
      ...e.viewState,
    });
    props.onViewportChange(viewport, getBounds());
  };

  const onGeocoderResult = (result: WaterbodySelectValue) => {
    WebAnalytics.mapClick('[map_search].(results_list)', result?.value);
    if (props.onGeocoderResult) {
      props.onGeocoderResult(result);
    }
  };

  const onSearchResult = (bbox: number[][], longitude: number, latitude: number) => {
    let zoom = 12;

    if (bbox) {
      zoom = fitBounds(
        [
          [bbox[0][0], bbox[1][1]],
          [bbox[2][0], bbox[3][1]],
        ],
        {
          width,
          height,
        }
      ).zoom;
    }
    mapRef.flyTo({
      center: [longitude, latitude],
      zoom,
      duration: FLYOVER_TRANSITION_DURATION - 1000,
    });
  };

  const getBounds = useCallback(() => {
    if (!mapRef) {
      return null;
    }

    const bounds = mapRef.getMap()?.getBounds();
    if (!bounds) {
      return null;
    }

    const mapBounds = {
      n: bounds.getNorth(),
      s: bounds.getSouth(),
      e: bounds.getEast(),
      w: bounds.getWest(),
    };
    return mapBounds;
  }, [mapRef]);

  useEffect(() => {
    if (loaded && aerisController) {
      getAerisController?.(aerisController);
    }
  }, [aerisController, loaded]);

  useEffect(() => {
    if (aerisController && loaded) {
      const prevWeatherLayers = prevMapLayerState[LAYER_TYPES.WEATHER];
      const activeWeatherLayers = mapLayerState[LAYER_TYPES.WEATHER];
      const layersToRemove = _.difference(prevWeatherLayers, activeWeatherLayers);

      applyAerisLayerController({
        activeCoreLayer: mapLayerState[LAYER_TYPES.CORE],
        activeWeatherLayers,
        aerisController,
        aerisUnits: aerisTempUnits,
        mapHeight: height,
        mapWidth: width,
        prevWeatherLayers,
        weatherLayersToRemove: layersToRemove,
      });
    }
  }, [loaded, aerisTempUnits, aerisController, mapLayerState]);

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    const map = mapRef.getMap();

    const addImages = () => {
      Object.entries(iconsToLoad).forEach(([iconName, iconSrc]) => {
        if (!map.hasImage(iconName)) {
          const image = new Image();
          image.onload = () => {
            map.addImage(iconName, image);
          };
          image.onerror = () => {
            console.error(`Failed to load image for ${iconName}`);
          };
          image.src = iconSrc;
        }
      });
    };

    const handleImageMissing = (e: any) => {
      const { id } = e;
      if (!map.hasImage(id)) {
        const iconSrc = iconsToLoad[id];
        const retryImage = new Image();
        retryImage.onload = () => {
          map.addImage(id, retryImage);
        };
        retryImage.onerror = () => {
          console.error(`Retry failed for image ${id}`);
        };
        retryImage.src = iconSrc;
      }
    };

    if (map && loadInteractiveImages) {
      map.on('style.load', addImages);
      map.on('styleimagemissing', handleImageMissing);

      return () => {
        map.off('style.load', addImages);
        map.on('styleimagemissing', handleImageMissing);
      };
    }
  }, [mapRef, loadInteractiveImages]);

  const showPins = mapLayerState.OVERLAY.includes(OVERLAY_LAYER.PINS);

  const onWaterbodyClick = (e: React.MouseEvent<HTMLDivElement>, waterbody: Waterbody) => {
    e.stopPropagation();
    WebAnalytics.mapInteracted({
      point: {
        lat: waterbody.lat,
        lon: waterbody.lng,
      },
      type: 'pin',
      type_id: waterbody.id,
    });
    replaceQueryString({ waterbody_slug: waterbody.url_slug });
    onPinClick?.(waterbody);
  };

  // hide the editable / selected waypoint from list so it can be dragged & made larger etc
  const waypointsToRender = useMemo(
    () =>
      waypoints?.filter((w) => {
        return w.id !== draggableWaypoint?.id || w.id !== selectedWaypoint?.id;
      }),
    [waypoints, draggableWaypoint, selectedWaypoint]
  );

  const xWeatherVersion = '1.6.1';

  const handleRefCallback = useCallback(
    (node) => {
      if (!node) {
        return;
      }
      // this runs twice with the ref with the same ref value hence the checks
      if (!mapRef) {
        setMapRef(node);
      }
    },
    [mapRef, aerisController]
  );

  useEffect(() => {
    if (loaded) {
      onLoad?.(getBounds(), mapRef);
      if (!aerisController) {
        waitFor(() => window.hasOwnProperty('aerisweather')).then(() => {
          const account = new (window as any).aerisweather.mapsgl.Account(
            env.AERIS_ID,
            env.AERIS_SECRET_KEY
          );
          const startDate = dayjs().toDate();
          const endDate = dayjs().add(1, 'day').toDate();
          const aeris_controller = new (window as any).aerisweather.mapsgl.MapboxMapController(
            mapRef.getMap(),
            {
              account,
              animation: {
                duration: 5,
                endDelay: 1,
                repeat: true,
                start: startDate,
                end: endDate,
              },
            }
          );
          // play+pause appears to load the data at the start
          aeris_controller.timeline.play();
          aeris_controller.timeline.pause();
          const aerisMapsGLTempUnits: AerisUnits = (window as any).aerisweather.mapsgl.units;
          setAerisController(aeris_controller);
          setAerisTempUnits(aerisMapsGLTempUnits);
        });
      }
    }
  }, [loaded]);

  const handleLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <Helmet>
        <link href="https://api.mapbox.com/mapbox-gl-js/v3.4.0/mapbox-gl.css" rel="stylesheet" />
        {/* importing the aeris lib directly has SSR errors, so attaching the script as a workaround */}
        <script
          defer
          src={`https://cdn.aerisapi.com/sdk/js/mapsgl/${xWeatherVersion}/aerisweather.mapsgl.js`}
        ></script>
        <link
          href={`https://cdn.aerisapi.com/sdk/js/mapsgl/${xWeatherVersion}/aerisweather.mapsgl.css`}
          rel="stylesheet"
        />
      </Helmet>
      <ReactMapGL
        ref={handleRefCallback}
        mapboxAccessToken={env.MAPBOX_ACCESS_TOKEN}
        {...viewport}
        style={{
          width,
          height,
        }}
        transformRequest={(url, resourceType) => {
          const request: mapboxgl.RequestParameters = {
            url,
          };
          if (url.includes('socialmap.genesismaps.com')) {
            request.headers = {
              ...request.headers,
              // 'X-SocialMap-Key': env.SOCIALMAP_KEY,
              // Referer: 'https://www.omniafishing.com/',
              // Referrer: 'https://www.omniafishing.com/',
            };
          }
          return request;
        }}
        mapStyle={deriveStyleUrl(mapLayerState)} // mapStyle controls only satellite / standard
        onMove={onMove}
        onLoad={handleLoaded}
        styleDiffing={false}
        doubleClickZoom={!restrictAllMovement}
        dragPan={!restrictAllMovement}
        dragRotate={!restrictAllMovement}
        scrollZoom={restrictAllMovement ? false : !disableScrollZoom}
        touchPitch={!restrictAllMovement}
        touchZoomRotate={!restrictAllMovement}
        minZoom={minZoom}
        maxBounds={maxBounds}
        projection={{ name: 'mercator' }}
        onClick={(e: MapLayerMouseEvent) => {
          onMapClick?.(e);
        }}
        interactiveLayerIds={[
          InteractiveLayerIds.BOAT_LANDINGS,
          InteractiveLayerIds.WATERBODY_IDS,
          InteractiveLayerIds.OMNIA_WATER_TEMP,
          InteractiveLayerIds.CLARITY,
          InteractiveLayerIds.WAYPOINTS,
        ]}
      >
        <OmniaMapSources
          mapLayerState={mapLayerState}
          selectedWaterbodyId={interactiveWaterbodyId}
          selectedBoatLanding={selectedBoatLanding}
          waterTempDailyTile={waterTempDailyTile}
          waterTempShowComposite={waterTempShowComposite}
          waterClarityDailyTile={waterClarityDailyTile}
          waterClarityShowComposite={waterClarityShowComposite}
          waypoints={waypointsToRender}
        />
        {showPins &&
          allWaterbodiesSortedByLat.map((waterbody, i) => {
            const isSelected = selectedWaterbody?.id === waterbody.id;
            const isFavorite = userFavoriteWaterbodiesIds.includes(waterbody.id);
            const isFeatured = featuredMarkerIds.includes(waterbody.id);
            const showFishingReportLink = selectedFishingReport?.waterbody.id === waterbody.id;
            const showSelected = isSelected || showFishingReportLink;
            const isHighActivity = highActivityWaterbodyIds.includes(waterbody.id);
            const PinComponent =
              isFeatured || isHighActivity || isFavorite ? PinHighActivity : PinLowActivity;
            const fishingReportCount = waterbody.fishing_reports_count;

            return (
              <Marker
                key={waterbody.id}
                longitude={waterbody.lng}
                latitude={waterbody.lat}
                offset={[0, -PinComponent.height / 2]}
                style={{ zIndex: isSelected ? MAX_PIN_LIMIT + 1 : i }}
              >
                {showFishingReportLink && (
                  <FishingReportLink fishingReport={selectedFishingReport} />
                )}
                <PinComponent
                  onClick={(e) => {
                    onWaterbodyClick(e, waterbody);
                  }}
                  selected={showSelected}
                  favorite={isFavorite}
                  featured={isFeatured}
                  fishingReportCount={fishingReportCount}
                />
              </Marker>
            );
          })}
        {navControl && (
          <NavigationControl
            position={navControlPosition}
            data-test="test"
            style={{
              zIndex: 300,
              position: 'absolute',
              top: geoCoderCentered || !geoCoder ? '0' : '50px',
            }}
            showZoom={isDesktop}
          />
        )}
        {usePreciseLocation && (
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            position={navControlPosition}
            trackUserLocation
          />
        )}
        {backToMapLink && (
          <a
            href={OmniaUrls.map(backToMapLat, backToMapLng, backToMapZoom)}
            className={styles.backToMapLink}
            onClick={() => onBackToMapClick?.()}
          >
            <span role="img" aria-label="fullscreen-icon" className={styles.fullscreenIcon}>
              <SvgFullScreen />
            </span>
          </a>
        )}
        {geoCoder && (
          <OmniaMapSearchSelect
            allowClear
            showSearch
            suffixIcon={<SearchOutlined />}
            placeholder={placeHolderText ? placeHolderText : 'Search'}
            onSelect={(v: WaterbodySelectValue) => onGeocoderResult(v)}
            onChange={(newValue: WaterbodySelectValue) => {
              if (newValue) {
                const waterbody = newValue.label.props['data-result'];
                const { bbox, lng, lat } = waterbody;
                onSearchResult(bbox, lng, lat);
              }
            }}
            className={classNames({ [styles.geoCoderCentered]: geoCoderCentered })}
            geoCoderExpanded={geoCoderExpanded}
            dropdownStyle={{ minWidth: '240px' }}
            focusOnMount={geoCoderFocusAndOpenOnMount}
          />
        )}
        {draggableWaypoint && (
          <Marker
            longitude={draggableWaypoint.lng}
            latitude={draggableWaypoint.lat}
            anchor="bottom"
            draggable
            onDrag={onWaypointDragEnd}
            style={{ zIndex: MAX_PIN_LIMIT + 2 }}
          >
            <span className={styles.temporaryMarker__animated}>
              <WaypointMarker
                waypoint_type={draggableWaypoint.waypoint_type}
                img={draggableWaypoint.img}
              />
            </span>
          </Marker>
        )}
        {selectedWaypoint && !draggableWaypoint && (
          <Marker
            longitude={selectedWaypoint.lng}
            latitude={selectedWaypoint.lat}
            anchor="bottom"
            style={{ zIndex: MAX_PIN_LIMIT + 2 }}
          >
            <span className={styles.temporaryMarker__selected}>
              <WaypointMarker
                waypoint_type={selectedWaypoint.waypoint_type}
                img={selectedWaypoint.img}
              />
            </span>
          </Marker>
        )}
        {popup}
      </ReactMapGL>
    </>
  );
};
export default OmniaMap;
