import { useSelector } from 'react-redux';
import { getUtmTags } from '../redux/utm';

export const useUtm = () => {
  const utmTags = useSelector(getUtmTags);
  const { source, medium, name, term, content } = utmTags;

  return {
    utmTags,
    source,
    medium,
    name,
    term,
    content,
  };
};
