import type { SVGProps } from 'react';
import * as React from 'react';
const SvgLayerVegetation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 38 38" {...props}>
    <path
      d="M34.316 1.583A1.45 1.45 0 0 0 32.972.497c-.349-.013-8.393-.306-12.416 2.811-1.94 1.503-3.073 3.849-3.106 6.436-.035 2.596 1.036 5.102 2.794 6.539.059.048.13.065.193.102a56.16 56.16 0 0 0-.739 3.024l-.004-.016c-.731-2.482-2.455-4.435-4.729-5.356-4.712-1.912-12.388.541-12.713.646a1.449 1.449 0 0 0-1.001 1.408c.082 4.126 2.545 12.075 11.272 12.631l.093.003a1.45 1.45 0 0 0 .091-2.897c-6.605-.42-8.114-6.234-8.457-8.707 2.479-.652 6.984-1.467 9.625-.396 1.985.806 2.756 2.531 3.038 3.489.362 1.228.339 2.542-.031 3.602-1.85-2.138-4.51-4.315-8.35-6.218a1.45 1.45 0 0 0-1.287 2.597c8.342 4.135 10.319 9.524 10.777 12.173.006.078.018.153.036.228.118.786.099 1.288.094 1.373a1.45 1.45 0 0 0 2.892.203c.008-.107.049-.858-.128-2.017.096-1.739 1.125-17.851 6.234-23.412a1.45 1.45 0 0 0-2.135-1.963c-1.496 1.628-2.677 3.948-3.609 6.53-.674-.925-1.075-2.214-1.058-3.527.013-.999.288-2.869 1.982-4.182 2.247-1.74 6.81-2.174 9.375-2.215.337 2.477.452 8.479-5.792 10.666a1.45 1.45 0 0 0 .958 2.737c8.252-2.891 8.477-11.21 7.442-15.205Z"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default SvgLayerVegetation;
