import * as React from 'react';

const SvgCloseIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={11.5} fill="#fff" stroke="#D9D9D9" />
    <path
      d="m15.729 8-7.546 7.546M15.729 15.546 8.183 8"
      stroke="#737373"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCloseIcon;
