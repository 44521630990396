import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerBoatRamp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#layer_boat_ramp_svg__a)">
      <path d="M29.193 13.26a1.18 1.18 0 0 0-.735-.66L11.11 7.227l2.528-1.97a1.181 1.181 0 1 0-1.452-1.864L8.36 6.375 3.238 4.79a1.178 1.178 0 0 0-1.477.779 8.215 8.215 0 0 0 .584 6.305 8.223 8.223 0 0 0 4.871 4.045l17.388 5.385a1.18 1.18 0 0 0 1.412-.61l3.154-6.448c.151-.31.16-.67.023-.987zm-4.856 5.488L7.915 13.663a5.872 5.872 0 0 1-3.48-2.89 5.867 5.867 0 0 1-.654-3.343l22.66 7.018-2.103 4.3zM27.542 24.546 2.243 16.71A1.18 1.18 0 0 0 .712 17.84v4.418a1.181 1.181 0 1 0 2.363 0v-2.815l23.768 7.36a1.182 1.182 0 0 0 .7-2.257" />
    </g>
    <defs>
      <clipPath id="layer_boat_ramp_svg__a">
        <path fill="currentColor" d="M.334.333h29.333v29.333H.334z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLayerBoatRamp;
