import { AlgoliaBrand } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { getImgixPath } from '../../lib/imgix';
import { RoutePaths } from '../../routes';
import styles from './search_brand.less';

interface SearchBrandProps {
  brand: AlgoliaBrand;
  onClick: (target_url: string) => void;
}

export const SearchBrand = (props: SearchBrandProps) => {
  const { brand, onClick } = props;
  return (
    <Link
      to={`${RoutePaths.BRANDS}/${brand.url_slug}`}
      className={styles.link}
      onClick={() => onClick(`${RoutePaths.BRANDS}/${brand.url_slug}`)}
    >
      {!!brand.image ? (
        <img src={getImgixPath(brand.image, { w: 300 })} loading="lazy" />
      ) : (
        <span
          className={styles.name}
          dangerouslySetInnerHTML={{ __html: brand._highlightResult.display_name.value }}
        />
      )}
    </Link>
  );
};
