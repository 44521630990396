import { CheckOutlined, CloseOutlined, PauseOutlined, PlusOutlined } from '@ant-design/icons';
import { ProductFamily } from '@omniafishing/core';
import pluralize from 'pluralize';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useExperiment } from 'statsig-react';
import { SpanLink } from '../../components/span_link/span_link';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { GlobalQueryParams } from '../../lib/query_string';
import { WebAnalytics } from '../../lib/web_analytics';
import { summaries } from './fishing_report_summaries';

interface FishingReportSummaryProps {
  productFamily: ProductFamily;
}

export const FishingReportSummary = (props: FishingReportSummaryProps) => {
  const { productFamily } = props;
  const productSummary = summaries[productFamily.handle];

  const { isDesktop } = useResponsive();
  const { replaceQueryString, getCurrentQuery } = useQueryString();
  const [selectedAttributeName, setSelectedAttributeName] = React.useState<string | null>(null);
  const [height, setHeight] = React.useState<0 | 'auto'>(0);
  const [summaryExpanded, setSummaryExpanded] = React.useState<boolean>(isDesktop);

  const currentQuery = getCurrentQuery<{ show_summary: boolean }>();
  const defaultShowSummary = !!currentQuery.show_summary ?? false;
  const showSummary = useExperiment('pdp_llm_summary').config.get<boolean>(
    'show_summary',
    defaultShowSummary
  );

  if (!showSummary || !productSummary) {
    return null;
  }

  const { summary, attributes } = productSummary;
  const selectedAttribute = productSummary.attributes.find((a) => a.name === selectedAttributeName);
  const selectedAttributeMentionCount =
    selectedAttribute != null
      ? selectedAttribute.neutral_count + selectedAttribute.positive_count
      : 0;

  const summaryAttributes = summaryExpanded ? attributes : attributes.slice(0, 2);

  return (
    <div className="border rounded-md p-3">
      <p className="mb-2 font-bold">Anglers Say</p>
      <p className={`${!summaryExpanded ? 'line-clamp-2 text-ellipsis' : 'mb-2'}`}>{summary}</p>
      {!summaryExpanded && (
        <p className="mb-2">
          <SpanLink
            underline
            onClick={() => {
              setSummaryExpanded(true);
            }}
          >
            See more
          </SpanLink>
        </p>
      )}
      <p className="font-bold mb-1">Select to learn more</p>
      <ol className={`-ml-2 mb-3 ${isDesktop && 'flex flex-wrap'}`}>
        {summaryAttributes.map((attribute, index) => {
          return (
            <li
              key={attribute.name}
              className={`text-link-blue flex cursor-pointer ${
                isDesktop && index !== attributes.length - 1 ? 'border-r border-gray-300' : ''
              }
                `}
            >
              <span
                className={`px-2 flex gap-1 rounded-md border-2 select-none ${
                  attribute.name === selectedAttributeName && height === 'auto'
                    ? 'border-current'
                    : 'border-transparent'
                }`}
                onClick={() => {
                  if (selectedAttributeName === attribute.name && height === 'auto') {
                    setHeight(0);
                  } else {
                    setHeight('auto');
                  }
                  setSelectedAttributeName(attribute.name);
                }}
              >
                <CheckOutlined className="text-green-700" />
                <span>{attribute.name}</span>
              </span>
            </li>
          );
        })}
        {summaryAttributes.length !== attributes.length && (
          <li className="px-2">
            <SpanLink
              underline
              onClick={() => {
                setSummaryExpanded(true);
              }}
            >
              See more
            </SpanLink>
          </li>
        )}
      </ol>
      <AnimateHeight height={height} easing="ease-out" duration={200}>
        {selectedAttribute && (
          <div
            className={`px-3 py-2 relative rounded-md border ${
              height === 'auto' ? 'border-current' : 'border-transparent'
            }`}
          >
            <p className="flex gap-1 mb-2 mr-7">
              <span>
                {selectedAttributeMentionCount} fishing{' '}
                {pluralize('report', selectedAttributeMentionCount)} mention{' '}
                <strong>{selectedAttribute.name}</strong>
              </span>
              {selectedAttribute.positive_count > 0 && (
                <span className="text-green-700 flex-grow-0 flex-shrink-0">
                  {selectedAttribute.positive_count} {isDesktop ? 'positive' : <PlusOutlined />}
                </span>
              )}{' '}
              {selectedAttribute.neutral_count > 0 && (
                <span className="text-orange-700 flex-grow-0 flex-shrink-0">
                  {selectedAttribute.neutral_count}{' '}
                  {isDesktop ? 'neutral' : <PauseOutlined rotate={90} />}
                </span>
              )}
            </p>
            <p>{selectedAttribute.summary}</p>
            <ul>
              {selectedAttribute.evidence.map((e) => {
                return (
                  <li key={e.source_fishing_report_id} className="mt-2">
                    <q>{e.excerpt}</q>{' '}
                    <SpanLink
                      onClick={() => {
                        WebAnalytics.fishingReportReadMoreClicked(e.source_fishing_report_id);
                        replaceQueryString({
                          [GlobalQueryParams.report_id]: e.source_fishing_report_id,
                        });
                      }}
                      underline
                    >
                      Read&nbsp;report
                    </SpanLink>
                  </li>
                );
              })}
            </ul>

            <button
              className="absolute top-0 right-0 px-3 py-2"
              onClick={() => {
                setHeight(0);
              }}
              type="button"
            >
              <CloseOutlined />
            </button>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};
