import React from 'react';
import styles from './responsive_elements.less';
import classNames from 'classnames';

export const DesktopOnly = (props: {
  children: React.ReactNode;
  tag?: 'span' | 'section' | 'div';
  className?: string;
}) => {
  const { children, tag, className } = props;
  const ElementName = tag ?? 'div';
  return (
    <ElementName className={classNames(styles.desktopOnly, className)}>{children}</ElementName>
  );
};

export const MobileOnly = (props: {
  children: React.ReactNode;
  tag: 'span' | 'div';
  className?: string;
}) => {
  const { children, tag, className } = props;
  const ElementName = tag ?? 'div';
  return <ElementName className={classNames(styles.mobileOnly, className)}>{children}</ElementName>;
};
