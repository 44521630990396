import { Video } from '@omniafishing/core';
import React from 'react';
import { useResponsive } from '../../../hooks/use_responsive';
import { getImgixPath } from '../../../lib/imgix';
import SvgExpandIcon from '../../svg/expand_icon';
import styles from './fishing_report_card_media.less';
import iconPlay from '../../../assets/icon-play.svg';

interface FishingReportCardVideoProps {
  onVideoClick: (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => void;
  video: Video;
}
export const FishingReportCardVideo = (props: FishingReportCardVideoProps) => {
  const { onVideoClick, video } = props;
  const { isMobile } = useResponsive();

  const getbackgroundImageStyle = (formattedImage: string) => {
    return {
      backgroundImage: `url('${formattedImage}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      filter: 'blur(4px)',
    };
  };

  const formattedBackgroundVideoImage =
    video &&
    getImgixPath(video.image, {
      w: isMobile ? 300 : 170,
      ar: '1.5:1',
      fit: 'crop',
      crop: 'faces',
    });

  return (
    <>
      {isMobile && <div className={styles.overlayFade}></div>}
      <div
        style={getbackgroundImageStyle(formattedBackgroundVideoImage)}
        className={styles.imgBackground}
      />
      <img
        className={styles.image}
        src={getImgixPath(video.image, {
          w: isMobile ? 300 : 174,
          h: isMobile ? 210 : 117,
          crop: 'faces',
        })}
        onClick={onVideoClick}
      />
      {isMobile && (
        <button className={styles.expandIcon} onClick={onVideoClick}>
          <SvgExpandIcon />
        </button>
      )}
      {isMobile ? (
        <span className={styles.videoLabel}>
          <img src={iconPlay} className={styles.playIcon} /> Video
        </span>
      ) : (
        <img src={iconPlay} className={styles.desktopPlayIcon} onClick={onVideoClick} />
      )}
    </>
  );
};
