import { Product } from '@omniafishing/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../helpers/app_state';
import { eventNotificationSubscribe, getLoadingState } from '../../redux/event_notifications';
import { FlashMessageActions } from '../../redux/flash_message';
import { getUser } from '../../redux/user';
import { ProductCarryModal } from './product_carry_modal';

interface OwnProps {
  product: Product;
  open: boolean;
  onClose: () => void;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps) {
  return {
    loadingState: getLoadingState(state),
    product: ownProps.product,
    user: getUser(state),
    open: ownProps.open,
    onClose: ownProps.onClose,
  };
}

const dispatchProps = {
  setFlashMessage: FlashMessageActions.FLASH_MESSAGE_SET,
  eventNotificationSubscribe,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(dispatchProps, dispatch);

const ProductCarryModalContainer = connect(mapStateToProps, dispatchProps)(ProductCarryModal);

export type ProductCarryModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default ProductCarryModalContainer;
