import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { isDone, isPending } from '../../constants/loading_state';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { WebAnalytics } from '../../lib/web_analytics';
import { OmniaButton } from '../omnia_button/omnia_button';
import { InventoryForm } from './inventory_form';
import styles from './inventory_modal.less';
import { InventoryModalProps } from './inventory_modal_container';

export default class InventoryModal extends React.Component<InventoryModalProps, {}> {
  formRef = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: InventoryModalProps) {
    const { setFlashMessage, loadingState, product, area } = this.props;
    const wasSubmitting = isPending(prevProps.loadingState);
    const submitSuccess = isDone(loadingState);
    if (wasSubmitting && submitSuccess) {
      setFlashMessage({
        header: 'You’re on the list',
        subheader: `We will notify you when ${product.title} is back in stock`,
      });
      this.onCancel();
      WebAnalytics.outOfStockNotified(product, area);
    }
  }

  onOk = () => {
    const { product, outOfStockSubscribe } = this.props;
    const form = this.formRef.current;
    interface FormValues {
      email?: string;
    }
    form
      .validateFields()
      .then((values: FormValues) => {
        outOfStockSubscribe(product.id, values.email);
      })
      .catch((e) => null);
  };

  onCancel = () => {
    this.props.close();
  };

  get ProductContent() {
    const { product } = this.props;

    if (!product) {
      return null;
    }
    return (
      <div className={styles.product}>
        <div className={styles.img}>
          <img src={setShopifyImgWidth(product.img_url, 300)} alt="" />
        </div>
        <h2 className={styles.h2}>{`${product.title}`}</h2>
      </div>
    );
  }

  render() {
    const { loadingState, open, user } = this.props;
    return (
      <Modal
        title="Out of Stock"
        open={open}
        onOk={this.onOk}
        onCancel={this.onCancel}
        destroyOnClose
        okText="Subscribe"
        footer={null}
      >
        <h1 className={styles.h1}>Receive an email when we restock?</h1>
        {this.ProductContent}
        <InventoryForm formRef={this.formRef} user={user} />
        <div className={styles.modalFooter}>
          <OmniaButton
            kind="tertiary"
            size="lg"
            isDisabled={isPending(loadingState)}
            onPress={this.onCancel}
          >
            Cancel
          </OmniaButton>
          <OmniaButton
            kind="secondary"
            size="lg"
            onPress={this.onOk}
            loading={isPending(loadingState)}
          >
            Subscribe
          </OmniaButton>
        </div>
      </Modal>
    );
  }
}
