import { RequestThunk } from '../../types/generic';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { errorHandler } from '../lib/error_handler';
import { PrismicDocument, PrismicDocumentTypes, prismicOmniaInstance } from '../prismic';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'deals';

export enum StateKeys {
  deals = 'deals',
}

export const initialState = {
  [StateKeys.deals]: null as PrismicDocument,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getDeals = (state: ApplicationState) => state[reducerName][StateKeys.deals];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function DealsReducer(state = initialState, action: DealsActions) {
  switch (action.type) {
    case ReduxActions.DEALS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.deals]: action.payload,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const DealsActions = {
  DEALS_FETCH_PENDING: () => createAction(ReduxActions.DEALS_FETCH_PENDING),
  DEALS_FETCH_SUCCESS: (response: PrismicDocument) =>
    createAction(ReduxActions.DEALS_FETCH_SUCCESS, response),
  DEALS_FETCH_ERROR: (err: any) => createAction(ReduxActions.DEALS_FETCH_ERROR, err),
};
export type DealsActions = ActionsUnion<typeof DealsActions>;

export function fetchAllDeals(): RequestThunk {
  return (dispatch) => {
    dispatch(DealsActions.DEALS_FETCH_PENDING());

    return prismicOmniaInstance()
      .then((api) => {
        return api.getSingle(PrismicDocumentTypes.DEALS);
      })
      .then((response) => {
        return dispatch(DealsActions.DEALS_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`Error: fetchAllDeals`, error);
        return dispatch(DealsActions.DEALS_FETCH_ERROR(error));
      });
  };
}
