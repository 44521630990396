import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../../redux/auth';
import {
  cartContainsPremium,
  getActiveDiscountCode,
  getCartBanner,
  getCartPromos,
  getCartUpsellProducts,
  getCheckoutId,
  getDiscountErrorMessage,
  getDiscounts,
  getGiftCards,
  getIsOpen,
  getLineItems,
  getLineItemsSubtotal,
  getLoadingState,
  getRebateBalance,
  getSubtotalPrice,
  getWebUrl,
  hasDiscounts,
  hasGiftCards,
  hasRebateBalance,
} from '../../redux/cart';
import { getCategories } from '../../redux/reference_data';
import { getPath } from '../../redux/router';
import { isUserPremium } from '../../redux/user';
import { getAffiliateCampaign, getAffiliateSource } from '../../redux/utm';
import { getIsMobile, getIsWebview } from '../../redux/window';
import { Cart, CartProps } from './cart';
import { useUpsellProductsClosedSkus } from './use_upsell_products_closed_skus';
import { useUser } from '../../hooks/use_user';

export const CartContainer = () => {
  const { cartUpsellProductsClosedSkus } = useUpsellProductsClosedSkus();
  const { isPro } = useUser();
  const props: CartProps = {
    accessToken: useSelector(getAccessToken),
    activeDiscountCode: useSelector(getActiveDiscountCode),
    affiliateCampaign: useSelector(getAffiliateCampaign),
    affiliateSource: useSelector(getAffiliateSource),
    cartHeaderBanners: useSelector(getCartBanner),
    cartContainsPremium: useSelector(cartContainsPremium),
    cartUpsellProducts: useSelector(getCartUpsellProducts),
    cartUpsellProductsClosedSkus,
    categories: useSelector(getCategories),
    checkoutId: useSelector(getCheckoutId),
    discountErrorMessage: useSelector(getDiscountErrorMessage),
    discounts: useSelector(getDiscounts),
    dispatch: useDispatch(),
    giftCards: useSelector(getGiftCards),
    hasDiscounts: useSelector(hasDiscounts),
    hasGiftCards: useSelector(hasGiftCards),
    hasRebateBalance: useSelector(hasRebateBalance),
    isMobile: useSelector(getIsMobile),
    isOpen: useSelector(getIsOpen),
    isPro,
    isUserPremium: useSelector(isUserPremium),
    isWebview: useSelector(getIsWebview),
    lineItems: useSelector(getLineItems),
    lineItemsSubtotal: useSelector(getLineItemsSubtotal),
    loadingState: useSelector(getLoadingState),
    pathname: useSelector(getPath),
    promos: useSelector(getCartPromos),
    rebateBalance: useSelector(getRebateBalance),
    subtotalPrice: useSelector(getSubtotalPrice),
    webUrl: useSelector(getWebUrl),
  };

  return <Cart {...props} />;
};
