import {
  AmbassadorDetailExcludes,
  Brand,
  Category,
  Experiment,
  Forage,
  OmniaApiV1Connector,
  PromoBanner,
  Season,
  SeasonGroup,
  Species,
  State,
  Structure,
  TechniqueFull,
} from '@omniafishing/core';
import { axiosOmniaV1 } from '../axios_omnia';
import { ZoneCurrentSeasonGroups, ZoneCurrentSeasons } from '../redux/reference_data';

export interface ReferenceData {
  brands: Brand[];
  categories: Category[];
  experiments: Experiment[];
  forages: Forage[];
  promo_banners: PromoBanner[];
  season_groups: SeasonGroup[];
  seasons: Season[];
  species: Species[];
  states: State[];
  structures: Structure[];
  techniques: TechniqueFull[];
  zone_current_season_groups: ZoneCurrentSeasonGroups;
  zone_current_seasons: ZoneCurrentSeasons;
}

export enum RecommendationsFacets {
  brands = 'brands',
  categories = 'categories',
  pg = 'pg',
  season = 'season',
  sort = 'sort',
  species = 'species',
  styles = 'styles',
  subcategories = 'subcategories',
  subcat_types = 'subcat_types',
}

export const AMBASSADOR_EXCLUDE_ALL: AmbassadorDetailExcludes[] = [
  'brands',
  'lists',
  'organizations',
  'species',
  'waterbodies',
];

export const RECOMMENDATIONS_PAGE_SIZE_DEFAULT = 50;

export const apiV1 = new OmniaApiV1Connector(axiosOmniaV1);
