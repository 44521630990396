import { DownOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { FishingReport, Waterbody } from '@omniafishing/core';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { apiV1 } from '../../lib/api';
import { reportsWithinNumberOfDays } from '../../lib/fishing_reports';
import { OmniaUrls } from '../../lib/urls';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { FishingReportsCarousel } from '../fishing_reports_carousel/fishing_reports_carousel';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import SvgSearch from '../svg/search';
import {
  mediaWithinNumberOfDays,
  NEW_REPORTS_TIME_PERIOD,
  WaterbodyRecentMediaAndReports,
} from './dashboard';
import { DashBoardMediaCarousel } from './dashboard_media_carousel';
import styles from './dashboard_tab.less';

export const sortReportsByDateAndMedia = (fishingReports: FishingReport[]) => {
  return _.orderBy(
    fishingReports,
    [(r) => r.outing_date || dayjs(r.accepted_at).startOf('day'), (r) => r.image || r.video],
    ['desc', 'asc']
  );
};

interface DashboardWaterbodiesProps {
  waterbodiesRecentReportsAndMedia: WaterbodyRecentMediaAndReports[];
  onNewLakeClick: () => void;
}

export const DashboardWaterbodies = (props: DashboardWaterbodiesProps) => {
  const { waterbodiesRecentReportsAndMedia, onNewLakeClick } = props;
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const { isMobile } = useResponsive();
  const dispatch = useDispatch();

  useEffect(() => {
    if (waterbodiesRecentReportsAndMedia.length) {
      setActivePanels(waterbodiesRecentReportsAndMedia.map(({ waterbody }) => waterbody.url_slug));
    }
  }, [waterbodiesRecentReportsAndMedia]);

  const { Panel } = Collapse;

  const [waterbodiesWithRecentActivity, waterbodiesWithoutRecentActivity] = _.partition(
    waterbodiesRecentReportsAndMedia,
    ({ fishing_reports, media }) => fishing_reports.length || media.length
  );

  const onFileFishingReport = async (waterbody: Waterbody) => {
    const res = await apiV1.waterbodyFetch(waterbody.url_slug);
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(res.data.data));
  };

  const LakeStates = ({ locales }: Waterbody) => (
    <span className={styles.lakeStates}>
      (
      {locales.map((locale, i) => (
        <React.Fragment key={locale.state.abbr}>
          {i > 0 && ', '}
          <Link to={OmniaUrls.state(locale.state)} className={styles.state} target="_blank">
            {locale.state.abbr.toUpperCase()}
          </Link>
        </React.Fragment>
      ))}
      )
    </span>
  );

  return (
    <>
      <div className={styles.topActionButtons}>
        <OmniaButton onClick={onNewLakeClick} kind="primary" size={isMobile ? 'md' : 'lg'}>
          <SvgSearch
            className={styles.icon}
            width={isMobile ? 16 : 20}
            height={isMobile ? 16 : 20}
          />
          Find A New Lake
        </OmniaButton>
        {waterbodiesWithRecentActivity.length > 0 && isMobile && (
          <OmniaButton
            onPress={() => setActivePanels([])}
            size="md"
            kind="text"
            className={styles.collapseAllButton}
            style={{ fontSize: '14px', fontWeight: 400 }}
          >
            Collapse All
          </OmniaButton>
        )}
      </div>
      <div className={styles.collapseContainer__waterbody}>
        <Collapse
          expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
          ghost
          expandIconPosition="end"
          onChange={(panels) => {
            setActivePanels(panels as string[]);
          }}
          activeKey={activePanels}
        >
          {waterbodiesWithRecentActivity.map(({ waterbody, media, fishing_reports }) => {
            const sortedWaterbodyReports = sortReportsByDateAndMedia(fishing_reports);
            const recentReports = reportsWithinNumberOfDays(
              fishing_reports,
              NEW_REPORTS_TIME_PERIOD
            );
            const recentMedia = mediaWithinNumberOfDays(media, NEW_REPORTS_TIME_PERIOD);
            const recentReportsCount = recentReports.length;
            const recentMediaCount = recentMedia.length;
            return (
              <Panel
                header={
                  <>
                    <div className={styles.waterbodyHeader}>
                      <p className={styles.waterbodyHeaderLinks}>
                        <Link to={OmniaUrls.waterbody(waterbody)} className={styles.lakeName}>
                          {waterbody.primary_name}
                        </Link>
                        <span className={styles.lakeStates}>{LakeStates(waterbody)}</span>
                      </p>
                    </div>

                    <OmniaLinkButton
                      to={OmniaUrls.waterbody(waterbody)}
                      kind="text"
                      size="md"
                      className={styles.lakeLink}
                    >
                      View Lake Details <RightOutlined />
                    </OmniaLinkButton>
                  </>
                }
                key={waterbody.url_slug}
              >
                {sortedWaterbodyReports.length > 0 && (
                  <section className="overflow-hidden">
                    <div className={styles.miniHeader}>
                      <p>Recent Fishing Reports </p>
                      {recentReportsCount > 0 && (
                        <span className={styles.redCircle}>{recentReportsCount}</span>
                      )}
                    </div>
                    <ul className={styles.list}>
                      {activePanels.includes(waterbody.url_slug) && (
                        <FishingReportsCarousel
                          fishingReports={sortedWaterbodyReports}
                          key={`${activePanels.includes(waterbody.url_slug)}carousel`}
                          newReportIds={recentReports.map((r) => r.id)}
                          showWaterbodyName={false}
                          showFileReportCard
                          className={styles.carousel}
                          userImageSize={58}
                        />
                      )}
                    </ul>
                  </section>
                )}
                {media.length > 0 && (
                  <section className="overflow-hidden">
                    <p className={styles.miniHeader}>
                      Recent Videos & Articles{' '}
                      {recentMediaCount > 0 && (
                        <span>
                          <span className={styles.redCircle}>
                            <span>{recentMediaCount}</span>
                          </span>
                        </span>
                      )}
                    </p>
                    <ul className={styles.list}>
                      <DashBoardMediaCarousel
                        media={media}
                        key={`${activePanels.includes(waterbody.url_slug)}carousel`}
                        isPanelOpen={activePanels.includes(waterbody.url_slug)}
                        newMediaUids={recentMedia?.map((m) => m.uid)}
                      />
                    </ul>
                  </section>
                )}
              </Panel>
            );
          })}
        </Collapse>
      </div>
      {waterbodiesWithoutRecentActivity.map(({ waterbody }) => (
        <div className={styles.noReportsWaterbodyGrid} key={waterbody.url_slug}>
          <div className={styles.grid__1}>
            <p className={styles.waterbodyHeaderLinks__noLakes}>
              <span className={styles.lakeName}>{waterbody.primary_name} </span>
              <span className={styles.lakeStates}>{LakeStates(waterbody)}</span>
            </p>
            <OmniaLinkButton
              to={OmniaUrls.waterbody(waterbody)}
              kind="text"
              size="md"
              className={styles.lakeLink}
            >
              View Lake Details <RightOutlined />
            </OmniaLinkButton>
          </div>

          <p className={styles.grid__2}>No Recent Activity</p>

          <div className={styles.grid__3}>
            <OmniaButton
              size="md"
              kind="tertiary"
              fontSize={14}
              shape={isMobile ? 'circle' : 'rectangle'}
              onPress={() => {
                onFileFishingReport(waterbody);
              }}
              className={styles.fileReportButton__noWaterbodies}
            >
              {isMobile ? <PlusOutlined /> : 'File a Fishing Report'}
            </OmniaButton>
          </div>
        </div>
      ))}
    </>
  );
};
