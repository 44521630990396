import { CartUpsellProduct } from '@omniafishing/core';
import { isProductInStock } from './products';

export const isCartUpsellProductActive = (cartUpsellProduct: CartUpsellProduct, now: Date) => {
  if (!cartUpsellProduct) {
    return false;
  }
  const { start_date, end_date } = cartUpsellProduct;

  if (!isProductInStock(cartUpsellProduct.item_to_recommend)) {
    return false;
  }

  if (!start_date && !end_date) {
    return true;
  }

  const start = new Date(start_date);
  const end = new Date(end_date);

  if (start_date && now < start) {
    return false;
  }

  if (end_date && now > end) {
    return false;
  }

  return true;
};
