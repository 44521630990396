import { OmniaResponse } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import getNow from '../lib/time';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'shipping';

export enum StateKeys {
  shipping = 'shipping',
  expiresAtMs = 'expiresAtMs',
  loadingState = 'loadingState',
}

export interface Shipping {
  express: string;
  ground_start: string;
  ground_end: string;
  other_start: string;
  other_end: string;
  expires_at_epoch: number;
}

export const initialState = {
  [StateKeys.shipping]: null as Shipping,
  [StateKeys.expiresAtMs]: null as number,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getShipping = (state: ApplicationState) => state[reducerName][StateKeys.shipping];
export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getShippingExpired = (state: ApplicationState) => {
  return getNow() > state[reducerName][StateKeys.expiresAtMs];
};

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function ShippingReducer(state = initialState, action: ShippingActions) {
  switch (action.type) {
    case ReduxActions.SHIPPING_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.SHIPPING_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.shipping]: action.payload.data,
        [StateKeys.expiresAtMs]: action.payload.data.expires_at_epoch * 1000,
        [StateKeys.loadingState]: LoadingState.DONE,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const ShippingActions = {
  SHIPPING_FETCH_PENDING: () => createAction(ReduxActions.SHIPPING_FETCH_PENDING),
  SHIPPING_FETCH_SUCCESS: (response: OmniaResponse<Shipping>) =>
    createAction(ReduxActions.SHIPPING_FETCH_SUCCESS, response),
  SHIPPING_FETCH_ERROR: (err: any) => createAction(ReduxActions.SHIPPING_FETCH_ERROR, err),
};
export type ShippingActions = ActionsUnion<typeof ShippingActions>;

export function fetchShipping(): RequestThunk {
  return (dispatch) => {
    dispatch(ShippingActions.SHIPPING_FETCH_PENDING());

    return apiV1
      .shippingFetch()
      .then((response) => {
        return dispatch(ShippingActions.SHIPPING_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchShipping', error);
        return dispatch(ShippingActions.SHIPPING_FETCH_ERROR(error));
      });
  };
}
