import { AmbassadorMini, UserPrivate } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { roleAsText } from '../../lib/user';
import styles from './ambassador_color_dot.less';

interface AmbassadorColorDotProps {
  user: AmbassadorMini | UserPrivate;
  bordered?: boolean;
  dotSize?: number;
  className?: string;
}

export const AmbassadorColorDot = (props: AmbassadorColorDotProps) => {
  const { user, bordered, dotSize = '16px', className } = props;

  return (
    <span
      className={classNames(styles.dot, className, styles[roleAsText(user.role).toLowerCase()], {
        [styles.dot__bordered]: bordered,
      })}
      style={{ width: dotSize, height: dotSize }}
    ></span>
  );
};
