import { Col, Row } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategoryByName, SUBCATEGORY_GIFTCARDS } from '../../categories_subcategories';
import { ClickKey_Footer, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { getCategories } from '../../redux/reference_data';
import { getPath } from '../../redux/router';
import { RoutePaths } from '../../routes';
import utilities from '../../styles/utilities.less';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { NewsletterForm } from '../newsletter_form/newsletter_form';
import styles from './footer.less';
import { footerAbout, footerFishingResources, footerShop, footerSupport } from './footer_columns';
import { FooterIcons } from './footer_col_icons';
import { SubFooter } from './subfooter';

interface FooterProps {
  onlySubFooter?: boolean;
}

const Footer = ({ onlySubFooter }: FooterProps) => {
  const categories = useSelector(getCategories);
  const isLoggedIn = useSelector(hasAccessToken);
  const pathname = useSelector(getPath);
  const CategoryAccessories = getCategoryByName(categories, 'accessories');
  const SubcategoryGiftcards = CategoryAccessories
    ? _.find(CategoryAccessories.subcategories, {
        name: SUBCATEGORY_GIFTCARDS,
      })
    : null;
  const subCategoryGiftCardRoute = `${RoutePaths.CATEGORIES}/${CategoryAccessories?.url_path}/${SubcategoryGiftcards?.url_path}`;
  const webAnalyticsFooterHelper = (
    click_key: ClickKey_Footer,
    target_url?: string,
    isExternal_url?: boolean
  ) => {
    WebAnalytics.footerNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
      isExternal_url,
    });
  };

  const disallowedPathnames = () => {
    if (pathname) {
      return (
        !pathname.includes(RoutePaths.NEWSLETTER) &&
        !pathname.includes(RoutePaths.NEWSLETTER_SUCCESS) &&
        !pathname.startsWith(RoutePaths.GET_STARTED) &&
        !pathname.startsWith(RoutePaths.PREMIUM_PRO)
      );
    }
  };

  const SubFooterContent = <SubFooter webAnalyticsFooterHelper={webAnalyticsFooterHelper} />;

  if (onlySubFooter) {
    return SubFooterContent;
  }

  return (
    <>
      {!isLoggedIn && disallowedPathnames() && (
        <section className={styles.newsletterSection}>
          <ContentWrapper>
            <p>
              <strong>Join over 100k anglers who receive our newsletter.</strong>
              <br />
              Techniques, tips and tackle breakdowns personalized to your local area.
            </p>
            <div>
              <NewsletterForm context="footer" />
            </div>
          </ContentWrapper>
        </section>
      )}
      <section className={styles.footer}>
        <ContentWrapper>
          <Row justify="space-between" gutter={24}>
            <Col
              xs={24}
              sm={{ span: 18, offset: 3 }}
              lg={{ span: 6, offset: 0 }}
              className={utilities.marginBottom__gutter}
            >
              <FooterIcons webAnalyticsFooterHelper={webAnalyticsFooterHelper} />
            </Col>
            <Col xs={24} sm={7} lg={{ span: 4, offset: 1 }} className={styles.mobileGutter}>
              <p className={styles.subheading}> Shop at Omnia</p>
              <ul className={styles.links}>
                {footerShop.map((l) => {
                  return (
                    <li key={l.displayName}>
                      <Link
                        className={styles.link}
                        to={l.link}
                        onClick={() =>
                          webAnalyticsFooterHelper(
                            l.webAnalyticsKeyClick as ClickKey_Footer,
                            l.link
                          )
                        }
                      >
                        {l.displayName}
                      </Link>
                    </li>
                  );
                })}

                {CategoryAccessories && SubcategoryGiftcards && (
                  <li className={styles.listItem}>
                    <Link
                      to={subCategoryGiftCardRoute}
                      className={styles.link}
                      onClick={() =>
                        webAnalyticsFooterHelper('[footer].(shop)', subCategoryGiftCardRoute)
                      }
                    >
                      {SubcategoryGiftcards.display_name}
                    </Link>
                  </li>
                )}
              </ul>
            </Col>

            <Col xs={24} sm={7} lg={4} className={styles.mobileGutter}>
              <p className={styles.subheading}> Fishing Resources</p>
              <ul className={styles.links}>
                {footerFishingResources.map((l) => {
                  return (
                    <li key={l.displayName}>
                      <Link
                        className={styles.link}
                        to={l.link}
                        onClick={() =>
                          webAnalyticsFooterHelper(
                            l.webAnalyticsKeyClick as ClickKey_Footer,
                            l.link
                          )
                        }
                      >
                        {l.displayName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Col>

            <Col xs={24} sm={7} lg={4}>
              <p className={styles.subheading}> About Omnia</p>
              <ul className={styles.links}>
                {footerAbout.map((l) => {
                  return (
                    <li key={l.displayName}>
                      <Link
                        className={styles.link}
                        to={l.link}
                        onClick={() =>
                          webAnalyticsFooterHelper(
                            l.webAnalyticsKeyClick as ClickKey_Footer,
                            l.link
                          )
                        }
                      >
                        {l.displayName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Col>

            <Col xs={24} sm={{ span: 18, offset: 3 }} lg={{ span: 5, offset: 0 }}>
              <p className={styles.customerSupport}> Customer Support</p>
              <ul className={styles.links}>
                {footerSupport.map((l) => {
                  return (
                    <li key={l.displayName}>
                      <Link
                        className={styles.link}
                        to={l.link}
                        onClick={() =>
                          webAnalyticsFooterHelper(
                            l.webAnalyticsKeyClick as ClickKey_Footer,
                            l.link
                          )
                        }
                      >
                        {l.displayName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className={styles.contact}>
                <p>
                  6325 Sandburg Road <br />
                  Suite 100
                  <br />
                  Golden Valley, MN 55427
                </p>
                <p>
                  <a
                    className={styles.link}
                    href="tel:763-710-5581"
                    onClick={() =>
                      webAnalyticsFooterHelper('[footer].(contact)', 'tel:763-710-5581')
                    }
                  >
                    (763) 710-5581
                  </a>
                </p>
                <p>
                  <a
                    className={styles.link}
                    href="mailto:hello@omniafishing.com"
                    onClick={() =>
                      webAnalyticsFooterHelper(
                        '[footer].(contact)',
                        'mailto:hello@omniafishing.com'
                      )
                    }
                  >
                    hello@omniafishing.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </ContentWrapper>
      </section>
      {SubFooterContent}
    </>
  );
};

export default Footer;
