import { OmniaResponse, Product } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { WebAnalyticsEventAreas } from '../lib/web_analytics';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'inventoryModal';

export enum StateKeys {
  open = 'open',
  product = 'product',
  area = 'area',
  loadingState = 'loadingState',
}

export const initialState = {
  [StateKeys.open]: false,
  [StateKeys.product]: null as Product,
  [StateKeys.area]: null as WebAnalyticsEventAreas,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const isOpen = (state: ApplicationState) => state[reducerName][StateKeys.open];
export const getProduct = (state: ApplicationState) => state[reducerName][StateKeys.product];
export const getArea = (state: ApplicationState) => state[reducerName][StateKeys.area];
export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function InventoryModalReducer(state = initialState, action: InventoryModalActions) {
  switch (action.type) {
    case ReduxActions.INVENTORY_MODAL_OPEN:
      return {
        ...state,
        [StateKeys.open]: true,
        [StateKeys.product]: action.payload.product,
        [StateKeys.area]: action.payload.area,
      };

    case ReduxActions.INVENTORY_MODAL_CLOSE:
      return {
        ...state,
        [StateKeys.open]: false,
      };

    case ReduxActions.INVENTORY_MODAL_SUBSCRIBE_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.INVENTORY_MODAL_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
      };

    case ReduxActions.INVENTORY_MODAL_SUBSCRIBE_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const InventoryModalActions = {
  INVENTORY_MODAL_OPEN: (product: Product, area: WebAnalyticsEventAreas) =>
    createAction(ReduxActions.INVENTORY_MODAL_OPEN, { product, area }),
  INVENTORY_MODAL_CLOSE: () => createAction(ReduxActions.INVENTORY_MODAL_CLOSE),

  INVENTORY_MODAL_SUBSCRIBE_ERROR: (err: any) =>
    createAction(ReduxActions.INVENTORY_MODAL_SUBSCRIBE_ERROR, err),
  INVENTORY_MODAL_SUBSCRIBE_PENDING: () =>
    createAction(ReduxActions.INVENTORY_MODAL_SUBSCRIBE_PENDING),
  INVENTORY_MODAL_SUBSCRIBE_SUCCESS: (response: OmniaResponse<{}>) =>
    createAction(ReduxActions.INVENTORY_MODAL_SUBSCRIBE_SUCCESS, response),
};
export type InventoryModalActions = ActionsUnion<typeof InventoryModalActions>;

export function outOfStockSubscribe(productId: number, email?: string): RequestThunk {
  return (dispatch) => {
    dispatch(InventoryModalActions.INVENTORY_MODAL_SUBSCRIBE_PENDING());

    return apiV1
      .eventNotificationCreate({
        email,
        event: 'product_in_stock',
        subscribable_id: productId,
        subscribable_type: 'Product',
      })
      .then((response) => {
        return dispatch(InventoryModalActions.INVENTORY_MODAL_SUBSCRIBE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: outOfStockSubscribe: ${productId} ${email}`, error);
        return dispatch(InventoryModalActions.INVENTORY_MODAL_SUBSCRIBE_ERROR(error));
      });
  };
}
