import { FishingReport } from '@omniafishing/core';
import React, { useEffect, useState } from 'react';
import { useQueryString } from '../../hooks/use_query_string';
import { apiV1 } from '../../lib/api';
import { GlobalQueryParams } from '../../lib/query_string';
import { WebAnalytics } from '../../lib/web_analytics';
import { FishingReportDisplayModal } from './fishing_report_display_modal';

export const FishingReportDisplayModalShower = () => {
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.report_id]: string;
  }>();
  const [modalFishingReport, setModalFishingReport] = useState<FishingReport>();
  const [modalFishingReportVisible, setModalFishingReportVisible] = useState(false);

  useEffect(() => {
    if (currentQuery.report_id) {
      setModalFishingReport(null);
      apiV1
        .fishingReportsFetch({
          ids: [currentQuery.report_id],
        })
        .then((res) => {
          const fishingReport = res.data.data[0];
          setModalFishingReport(fishingReport);
          setModalFishingReportVisible(true);
          WebAnalytics.fishingReportListViewed('modal', [
            {
              report_id: fishingReport.id,
              position: 0,
            },
          ]);
        });
    }
  }, [currentQuery.report_id]);

  return (
    <FishingReportDisplayModal
      fishingReport={modalFishingReport}
      open={modalFishingReportVisible}
      onClose={() => {
        setModalFishingReportVisible(false);
        replaceQueryString({
          [GlobalQueryParams.report_id]: null,
        });
      }}
    />
  );
};
