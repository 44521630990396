import _ from 'lodash';
import { ColorOption } from './product_variant_selector';
import { isUnavailable } from './product_variant_selector_helpers';
import { SelectedOption, ShopifyProduct } from '@omniafishing/core';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import styles from './product_color_options.less';
interface ProductColorOptionsProps {
  colorOptions: ColorOption[];
  onColorChange: (colorName: string) => void;
  onColorHover: (hoveredColor: ColorOption) => void;
  selectedOptions: SelectedOption[];
  shopifyProduct: ShopifyProduct;
}

export const ProductColorOptions = (props: ProductColorOptionsProps) => {
  const { colorOptions, shopifyProduct, selectedOptions, onColorHover, onColorChange } = props;

  const selectedColorOption = selectedOptions.find(
    (selectedOption) => selectedOption.name.toLowerCase() === 'color'
  );

  const handleColorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onColorChange(e.target.value);
    },
    [onColorChange]
  );

  const colorOptionsWithAvailability = useMemo(() => {
    return colorOptions.map((colorOption) => {
      const available = !isUnavailable({
        shopifyProduct,
        selectedOptions,
        optionName: 'color',
        value: colorOption.colorName,
      });
      return { ...colorOption, available };
    });
  }, [
    shopifyProduct.id,
    selectedOptions.map((option) => option.value).join(','),
    colorOptions.map((option) => option.colorName).join(','),
  ]);

  return (
    <div className={styles.colorsWrapper}>
      {colorOptionsWithAvailability.map((colorOption) => {
        const isSelected = selectedColorOption.value === colorOption.colorName;
        return (
          <div
            onMouseEnter={() => {
              onColorHover({ colorName: colorOption.colorName, imgSrc: colorOption.imgSrc });
            }}
            onMouseLeave={() => {
              onColorHover(null);
            }}
            key={colorOption.colorName}
            className={styles.colorImageButtonContainer}
          >
            <label
              className={classNames(styles.colorLabel, {
                [styles.optionSelected]: isSelected,
                [styles.optionUnavailable]: !colorOption.available,
              })}
            >
              <img
                alt={colorOption.colorName}
                src={setShopifyImgWidth(colorOption.imgSrc, 100)}
                width="76"
                height="76"
                title={colorOption.colorName}
              />
              <input
                type="radio"
                name="color"
                value={colorOption.colorName}
                checked={isSelected}
                onChange={handleColorChange}
                className={styles.optionRadio}
              />
            </label>
          </div>
        );
      })}
    </div>
  );
};
