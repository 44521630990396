import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'signupLoginModal';

export enum StateKeys {
  open = 'open',
  forwardRoute = 'forwardRoute',
  heading = 'heading',
  headingBlurb = 'headingBlurb',
  initialForm = 'initialForm',
}

export type FormToDisplay = 'signup' | 'login';

export const initialState = {
  [StateKeys.open]: false,
  [StateKeys.forwardRoute]: null as string | null,
  [StateKeys.heading]: 'Create an Account or Log In',
  [StateKeys.headingBlurb]: null as string | null,
  [StateKeys.initialForm]: 'signup' as FormToDisplay,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const isOpen = (state: ApplicationState) => state[reducerName][StateKeys.open];
export const getForwardRoute = (state: ApplicationState) =>
  state[reducerName][StateKeys.forwardRoute];
export const getHeading = (state: ApplicationState) => state[reducerName][StateKeys.heading];
export const getHeadingBlurb = (state: ApplicationState) =>
  state[reducerName][StateKeys.headingBlurb];
export const getFormToDisplay = (state: ApplicationState) =>
  state[reducerName][StateKeys.initialForm];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function SignupLoginReducer(state = initialState, action: SignupLoginModalActions) {
  switch (action.type) {
    case ReduxActions.SIGNUP_LOGIN_MODAL_OPEN:
      return {
        ...state,
        [StateKeys.open]: true,
        [StateKeys.forwardRoute]: action.payload.forwardRoute || null,
        [StateKeys.heading]: action.payload.heading || initialState[StateKeys.heading],
        [StateKeys.headingBlurb]:
          action.payload.headingBlurb || initialState[StateKeys.headingBlurb],
        [StateKeys.initialForm]: action.payload.initialForm || initialState[StateKeys.initialForm],
      };

    case ReduxActions.SIGNUP_LOGIN_MODAL_CLOSE:
      return {
        ...state,
        [StateKeys.open]: false,
        [StateKeys.forwardRoute]: null,
        [StateKeys.heading]: initialState[StateKeys.heading],
        [StateKeys.headingBlurb]: initialState[StateKeys.headingBlurb],
        [StateKeys.initialForm]: initialState[StateKeys.initialForm],
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export interface SignupLoginModalOpenPayload {
  forwardRoute?: string;
  heading?: string;
  headingBlurb?: string;
  initialForm?: FormToDisplay;
}

export const SignupLoginModalActions = {
  SIGNUP_LOGIN_MODAL_OPEN: ({
    forwardRoute,
    heading,
    headingBlurb,
    initialForm,
  }: SignupLoginModalOpenPayload = {}) =>
    createAction(ReduxActions.SIGNUP_LOGIN_MODAL_OPEN, {
      forwardRoute,
      heading,
      headingBlurb,
      initialForm,
    }),
  SIGNUP_LOGIN_MODAL_CLOSE: () => createAction(ReduxActions.SIGNUP_LOGIN_MODAL_CLOSE),
};
export type SignupLoginModalActions = ActionsUnion<typeof SignupLoginModalActions>;
