import { OmniaResponse, Tournament } from '@omniafishing/core';
import { Omit, RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'tournaments';

export enum StateKeys {
  tournaments = 'tournaments',
  tournament = 'tournament',
  loadingState = 'loadingState',
  createLoadingState = 'createLoadingState',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.createLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.tournaments]: [] as Tournament[],
  [StateKeys.tournament]: null as Tournament,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getCreateLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.createLoadingState];
export const getTournaments = (state: ApplicationState) =>
  state[reducerName][StateKeys.tournaments];
export const getTournament = (state: ApplicationState) => state[reducerName][StateKeys.tournament];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function TournamentsReducer(state = initialState, action: TournamentsActions) {
  switch (action.type) {
    case ReduxActions.TOURNAMENTS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.tournaments]: [],
      };

    case ReduxActions.TOURNAMENTS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.tournaments]: action.payload.data,
      };

    case ReduxActions.TOURNAMENT_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.tournament]: null,
      };

    case ReduxActions.TOURNAMENT_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.tournament]: action.payload.data,
      };

    case ReduxActions.TOURNAMENTS_CREATE_PENDING:
      return {
        ...state,
        [StateKeys.createLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.TOURNAMENTS_CREATE_SUCCESS:
      return {
        ...state,
        [StateKeys.createLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.TOURNAMENTS_CREATE_ERROR:
      return {
        ...state,
        [StateKeys.createLoadingState]: LoadingState.ERROR,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const TournamentsActions = {
  TOURNAMENTS_FETCH_PENDING: () => createAction(ReduxActions.TOURNAMENTS_FETCH_PENDING),
  TOURNAMENTS_FETCH_SUCCESS: (response: OmniaResponse<Tournament[]>) =>
    createAction(ReduxActions.TOURNAMENTS_FETCH_SUCCESS, response),
  TOURNAMENTS_FETCH_ERROR: (err: any) => createAction(ReduxActions.TOURNAMENTS_FETCH_ERROR, err),

  TOURNAMENT_FETCH_PENDING: () => createAction(ReduxActions.TOURNAMENT_FETCH_PENDING),
  TOURNAMENT_FETCH_SUCCESS: (response: OmniaResponse<Tournament>) =>
    createAction(ReduxActions.TOURNAMENT_FETCH_SUCCESS, response),
  TOURNAMENT_FETCH_ERROR: (err: any) => createAction(ReduxActions.TOURNAMENT_FETCH_ERROR, err),

  TOURNAMENTS_CREATE_PENDING: () => createAction(ReduxActions.TOURNAMENTS_CREATE_PENDING),
  TOURNAMENTS_CREATE_SUCCESS: (response: OmniaResponse<Tournament>) =>
    createAction(ReduxActions.TOURNAMENTS_CREATE_SUCCESS, response),
  TOURNAMENTS_CREATE_ERROR: (err: any) => createAction(ReduxActions.TOURNAMENTS_CREATE_ERROR, err),
};
export type TournamentsActions = ActionsUnion<typeof TournamentsActions>;

export function fetchTournaments(params: {
  past?: boolean;
  species?: string;
  state?: string;
  waterbody?: string;
  date?: string;
}): RequestThunk {
  return (dispatch) => {
    dispatch(TournamentsActions.TOURNAMENTS_FETCH_PENDING());

    return apiV1
      .tournamentsFetch(params)
      .then((response) => {
        return dispatch(TournamentsActions.TOURNAMENTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchTournaments', error);
        return dispatch(TournamentsActions.TOURNAMENTS_FETCH_ERROR(error));
      });
  };
}

export function fetchTournament(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(TournamentsActions.TOURNAMENT_FETCH_PENDING());

    return apiV1
      .tournamentFetch(slug)
      .then((response) => {
        return dispatch(TournamentsActions.TOURNAMENT_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchTournaments', error);
        return dispatch(TournamentsActions.TOURNAMENT_FETCH_ERROR(error));
      });
  };
}

interface TournamentParams extends Omit<Tournament, 'species' | 'waterbodies'> {
  species: string[];
  waterbody: string;
  location: string;
}

export function createTournament(data: TournamentParams): RequestThunk {
  return (dispatch) => {
    dispatch(TournamentsActions.TOURNAMENTS_CREATE_PENDING());

    return apiV1
      .tournamentCreate(data)
      .then((response) => {
        return dispatch(TournamentsActions.TOURNAMENTS_CREATE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: createTournament', error);
        return dispatch(TournamentsActions.TOURNAMENTS_CREATE_ERROR(error));
      });
  };
}
