import React, { ReactChild } from 'react';
import styles from './new_badge.less';
import classNames from 'classnames';

export const NewBadge = ({
  className,
  children,
}: {
  className?: string;
  children?: ReactChild;
}) => {
  return <span className={classNames(styles.badge__new, className)}>{children || 'New'}</span>;
};
