import * as React from 'react';

const SvgFullScreen = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width={34}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 34 34"
  >
    <g clipPath="url(#full_screen_svg__a)">
      <path
        d="m25.038 21.934 2.097-2.096v6.486H20.65l2.096-2.097-4.193-4.193 2.293-2.293 4.194 4.193Zm-11.072-6.486 2.293-2.293-4.194-4.194 2.097-2.096H7.676v6.486l2.097-2.097 4.193 4.194Zm8.78-6.487-2.097-2.096h6.486v6.486l-2.097-2.097-4.193 4.194-2.293-2.293 4.194-4.194Zm-6.487 11.073-2.293-2.293-4.193 4.193-2.097-2.096v6.486h6.486l-2.097-2.097 4.194-4.193Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="full_screen_svg__a">
        <path fill="currentColor" transform="translate(7.676 6.865)" d="M0 0h19.459v19.459H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFullScreen;
