import { Waterbody } from '@omniafishing/core';
import { getServerEnv, ServerEnvs } from '../env';

interface ReactNativeWebView {
  postMessage: (message: string) => void;
}

const env = getServerEnv();

export const INTERNALS = {
  getReactNativeWebView: () => {
    const webview = (window as any).ReactNativeWebView as ReactNativeWebView;
    return (
      webview || {
        postMessage: (message: string) =>
          env !== ServerEnvs.PRODUCTION && console.log('WebViewEvents: ' + message),
      }
    );
  },
};

class WebViewEventsClass {
  urlChange(path: string) {
    const webview = INTERNALS.getReactNativeWebView();
    webview.postMessage(`url_change:${path}`);
  }

  cartOpen() {
    const webview = INTERNALS.getReactNativeWebView();
    webview.postMessage('open:cart');
  }

  fishingReportModalOpen(waterbody: Waterbody) {
    const webview = INTERNALS.getReactNativeWebView();
    let message = `open:fishing_report/new`;
    if (waterbody) {
      message += `?waterbody_id=${waterbody?.id}&waterbody_slug=${waterbody.url_slug}`;
    }
    webview.postMessage(message);
  }
}

export const WebViewEvents = new WebViewEventsClass();
