import classNames from 'classnames';
import React, { Ref, forwardRef } from 'react';
import base from '../../styles/base.less';

export interface ContentWrapperProps {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  mobileNoPadding?: boolean;
  desktopNoPadding?: boolean;
  noOverflow?: boolean;
  style?: React.CSSProperties;
  tag?: 'section' | 'div';
}

export const ContentWrapper = forwardRef((props: ContentWrapperProps, ref: Ref<HTMLDivElement>) => {
  const {
    mobileNoPadding,
    noPadding,
    noOverflow,
    tag,
    className,
    style,
    children,
    desktopNoPadding,
  } = props;
  const classnames = classNames(base.contentWrapper, className, {
    [base.contentWrapper__noPadding]: noPadding,
    [base.contentWrapper__mobileNoPadding]: mobileNoPadding,
    [base.contentWrapper__desktopNoPadding]: desktopNoPadding,
    [base.contentWrapper__noOverflow]: noOverflow,
  });
  const ElementName = tag ?? 'div';

  return (
    <>
      {tag ? (
        <ElementName className={classnames} style={style}>
          {children}
        </ElementName>
      ) : (
        <div className={classnames} style={style} ref={ref}>
          {children}
        </div>
      )}
    </>
  );
});
