import React from 'react';
import { LoadableVideoPlayer } from '../video_player/loadable_video_player';
import { PlyrOptions } from 'plyr-react';
import { SourceInfo } from 'plyr';
import styles from './muxPlayer.less';
import classNames from 'classnames';

interface MuxPlayerProps {
  thumbnail: string;
  playbackId: string;
  className?: string;
  playerOptions?: PlyrOptions;
}

export const standardPlayerOptions: PlyrOptions = {
  controls: [
    'play-large',
    'play',
    'fast-forward',
    'progress',
    'current-time',
    'mute',
    'volume',
    'fullscreen',
  ],
};

export const standardPlayerOptionsNoFullscreen: PlyrOptions = {
  controls: (standardPlayerOptions.controls as string[]).filter((c) => c !== 'fullscreen'),
};

const getVideoSource = (poster: string): SourceInfo => {
  return {
    type: 'video',
    poster,
    sources: [
      {
        src: 'currently the muxid creates the source internally',
      },
    ],
  };
};

export const MuxPlayer = ({ playbackId, thumbnail, className, playerOptions }: MuxPlayerProps) => {
  return (
    <div className={classNames(styles.videoPlayer, className)}>
      <LoadableVideoPlayer
        muxPlaybackId={playbackId}
        source={getVideoSource(thumbnail)}
        options={playerOptions || standardPlayerOptions}
      />
    </div>
  );
};
