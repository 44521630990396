import { EnvironmentFilled } from '@ant-design/icons';
import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { OmniaUrls } from '../../lib/urls';
import { isAmbassador, NAME_FALLBACK, roleAsText } from '../../lib/user';
import { WebAnalytics } from '../../lib/web_analytics';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import styles from './user_details.less';

interface UserDetailsProps {
  fishingReport: FishingReport;
  position: number;
  isLakePage?: boolean;
  onUserClick?: () => void;
  onLakeClick?: () => void;
  className?: string;
  showLakeName: boolean;
}

export const UserDetails = (props: UserDetailsProps) => {
  const { fishingReport, position, onUserClick, onLakeClick, isLakePage, className, showLakeName } =
    props;

  const { isMobile, isDesktop } = useResponsive();

  const { user, waterbody, image, video } = fishingReport;

  const isNotLakePage = !isLakePage;
  const hasMedia = !!image || !!video;
  const userFullName = user.full_name || NAME_FALLBACK;

  return (
    <div className={classNames(styles.userContainer, className)}>
      <div className={styles.userImageContainer}>
        <AmbassadorLink
          ambassador={user}
          onClick={() => {
            onUserClick?.();
            WebAnalytics.fishingReportEmbeddedClick(
              '[reports].(user_link)',
              fishingReport,
              'embedded',
              position
            );
          }}
          target="_blank"
        >
          <UserProfileImg image={user.image} imageSize={100} size={isDesktop ? 75 : 64} />
        </AmbassadorLink>
      </div>

      <div
        className={classNames(styles.userDetails, {
          [styles.userDetails__lakePage]: isLakePage,
        })}
      >
        <p
          className={classNames(styles.userName, {
            [styles.userName__lakePage]: isLakePage && !hasMedia,
          })}
        >
          <AmbassadorLink
            ambassador={user}
            onClick={() => {
              onUserClick?.();
              WebAnalytics.fishingReportEmbeddedClick(
                '[reports].(user_link)',
                fishingReport,
                'embedded',
                position
              );
            }}
            target="_blank"
          >
            {userFullName || 'Omnia Community'}
          </AmbassadorLink>
        </p>
        <p className={styles.role}>
          {isAmbassador(user) && <AmbassadorColorDot user={user} bordered />}
          {roleAsText(user.role)} {isAmbassador(user) ? 'Ambassador' : 'User'}
        </p>

        {isNotLakePage && showLakeName && (
          <div
            className={classNames(styles.lakeLink, {
              [styles.lakeLink__hasMedia]: isMobile,
            })}
          >
            <Link
              to={OmniaUrls.waterbody(waterbody)}
              onClick={() => {
                onLakeClick?.();
                WebAnalytics.fishingReportEmbeddedClick(
                  '[reports].(lake_link)',
                  fishingReport,
                  'embedded',
                  position
                );
              }}
              target="_blank"
              className={styles.waterbodyLink}
            >
              {/* <PinMidActivity favorite={false} width={16} height={20} fill={'#495f5f'} /> */}
              <EnvironmentFilled />
              <span className={styles.waterbodyName}>
                {waterbody.primary_name}
                {waterbody.locales.length > 0 && ', '}
                {waterbody.locales.map((locale) => `${locale.state.abbr}`).join(', ')}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
