import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { axiosOmniaV1 } from '../../axios_omnia';
import { isLocalhost } from '../../env';
import { usePrevious } from '../../hooks/use_previous';
import { OmniaButton } from '../omnia_button/omnia_button';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
export const VERSION_CHECK_INTERVAL = MINUTE;

export const VersionChecker = () => {
  const [manifestHash, setManifestHash] = useState(null as string);
  const prevManifestHash = usePrevious(manifestHash);
  const interval = useRef(null as number);
  const isLocal = isLocalhost();

  const manifestChanged =
    prevManifestHash && manifestHash && prevManifestHash !== manifestHash && !isLocal;

  const checkManifest = () => {
    axiosOmniaV1.get(`${window.location.origin}/app-version-hash`).then((response) => {
      setManifestHash(response.data.hash);
    });
  };

  useEffect(() => {
    checkManifest();
    interval.current = window.setInterval(checkManifest, VERSION_CHECK_INTERVAL);

    return () => {
      clearManifestInterval();
    };
  }, []);

  useEffect(() => {
    if (manifestChanged) {
      clearManifestInterval();
    }
  }, [manifestChanged]);

  const clearManifestInterval = () => {
    window.clearInterval(interval.current);
  };

  const reload = () => {
    window.location.reload();
  };

  if (!manifestChanged) {
    return null;
  }

  return (
    <Modal
      title={
        <span
          style={{
            color: 'orange',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ExclamationCircleOutlined style={{ fontSize: '1.5em', marginRight: '0.2em' }} />{' '}
          OmniaFishing has been updated
        </span>
      }
      centered
      closable={false}
      maskClosable={false}
      open={true}
      footer={null}
      bodyStyle={{ textAlign: 'center' }}
    >
      <p className="mb-3.5">This site has been updated, please reload to get the latest.</p>
      <OmniaButton kind="secondary" fontSize={14} size="lg" onClick={reload}>
        <ReloadOutlined /> Reload
      </OmniaButton>
    </Modal>
  );
};
