import { trackJs } from './trackjs';

export function errorHandler(message: string, error: any) {
  const isTest = process.env._NODE_ENV === 'test';
  if (isTest) {
    return;
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // 400 is missing params
    // 401 is unauthorized
    if (error.response.status > 401) {
      if (message) {
        console.error(message);
      }
      trackJs(error);
    } else {
      console.log(message);
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(error.message);
  }
}
