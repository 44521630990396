import { useSelector } from 'react-redux';
import { getPath } from '../redux/router';
import { getUtmTags } from '../redux/utm';
import { getPageTypeByPathname, PageTypes } from '../routes';

export const useUserCampaignInfo = (from: string | null = null) => {
  const utmTags = useSelector(getUtmTags);
  const routerPath = useSelector(getPath);
  const isDirect = !from;
  const source = isDirect ? 'direct' : 'omniafishing';
  const medium = isDirect ? null : 'omniafishing.com';
  const path = from || routerPath;
  const pageType = getPageTypeByPathname(path);
  const pathSplit = path.split('/');
  const pageSlug = pathSplit.slice(-1)[0];

  let campaignTerm = utmTags.term || pageSlug;
  let campaignContent = utmTags.content || null;

  if (pageType === PageTypes.PRODUCT_DETAIL) {
    // product variant pages like
    // /p/netbait-paca-slim/color*alabama~craw--length*3~1__2%22
    // will split into 4 parts, while product detail landing pages like
    // /p/netbait-paca-slim
    // will split into 3 parts
    const productHandle = pathSplit.length === 4 ? pathSplit.slice(-2)[0] : pathSplit.slice(-1)[0];
    const variantName = pathSplit.length === 4 ? pathSplit.slice(-1)[0] : null;

    campaignTerm = utmTags.term || productHandle;
    campaignContent = utmTags.content || variantName;
  }

  return {
    campaign_source: utmTags.source || source,
    campaign_medium: utmTags.medium || medium,
    campaign_name: utmTags.name || pageType,
    campaign_term: campaignTerm,
    campaign_content: campaignContent,
  };
};
