import { OmniaResponse } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'event_notifications';

export enum StateKeys {
  loadingState = 'loadingState',
  destroyLoadingState = 'destroyLoadingState',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.destroyLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getDestroyLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.destroyLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function EventNotificationsReducer(
  state = initialState,
  action: EventNotificationActions
) {
  switch (action.type) {
    case ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
      };

    case ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    case ReduxActions.EVENT_NOTIFICATION_DESTROY_PENDING:
      return {
        ...state,
        [StateKeys.destroyLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.EVENT_NOTIFICATION_DESTROY_SUCCESS:
      return {
        ...state,
        [StateKeys.destroyLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.EVENT_NOTIFICATION_DESTROY_ERROR:
      return {
        ...state,
        [StateKeys.destroyLoadingState]: LoadingState.ERROR,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const EventNotificationActions = {
  EVENT_NOTIFICATION_SUBSCRIBE_ERROR: (err: any) =>
    createAction(ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_ERROR, err),
  EVENT_NOTIFICATION_SUBSCRIBE_PENDING: () =>
    createAction(ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_PENDING),
  EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS: (response: OmniaResponse<{}>) =>
    createAction(ReduxActions.EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS, response),

  EVENT_NOTIFICATION_DESTROY_ERROR: (err: any) =>
    createAction(ReduxActions.EVENT_NOTIFICATION_DESTROY_ERROR, err),
  EVENT_NOTIFICATION_DESTROY_PENDING: () =>
    createAction(ReduxActions.EVENT_NOTIFICATION_DESTROY_PENDING),
  EVENT_NOTIFICATION_DESTROY_SUCCESS: (response: OmniaResponse<{}>) =>
    createAction(ReduxActions.EVENT_NOTIFICATION_DESTROY_SUCCESS, response),
};
export type EventNotificationActions = ActionsUnion<typeof EventNotificationActions>;

export function eventNotificationSubscribe(params: {
  subscribable_id: number;
  event: 'product_in_stock' | 'product_added';
  email?: string;
}): RequestThunk {
  return (dispatch) => {
    dispatch(EventNotificationActions.EVENT_NOTIFICATION_SUBSCRIBE_PENDING());

    const { email, event, subscribable_id } = params;

    return apiV1
      .eventNotificationCreate({
        email,
        event,
        subscribable_id,
        subscribable_type: 'Product',
      })
      .then((response) => {
        return dispatch(
          EventNotificationActions.EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: eventNotificationSubscribe: ${event} ${email}`, error);
        return dispatch(EventNotificationActions.EVENT_NOTIFICATION_SUBSCRIBE_ERROR(error));
      });
  };
}

export function eventNotificationDestroy(id: number): RequestThunk {
  return (dispatch) => {
    dispatch(EventNotificationActions.EVENT_NOTIFICATION_DESTROY_PENDING());

    return apiV1
      .eventNotificationDelete(id)
      .then((response) => {
        return dispatch(EventNotificationActions.EVENT_NOTIFICATION_DESTROY_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: eventNotificationDestroy: ${id}`, error);
        return dispatch(EventNotificationActions.EVENT_NOTIFICATION_DESTROY_ERROR(error));
      });
  };
}
