import { CategoryNames, Product } from '@omniafishing/core';

export const isProductInStock = (p: Product) => {
  if (!p) {
    return false;
  }

  if (!p.inventory_tracked) {
    return true;
  }
  // inventory is tracked
  if (p.inventory > 0) {
    return true;
  }

  return false;
};

export const getProductsByCategory = (products: Product[], category: CategoryNames) => {
  return products.filter((product) => product.category_name === category);
};
