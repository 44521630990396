import { CloseOutlined } from '@ant-design/icons';
import { LineItem } from '@omniafishing/core';
import { InputNumber } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { isCartUpsellProductActive } from '../../lib/cart_upsell_product';
import { roundToTwoDecimals } from '../../lib/numbers';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import {
  getCartUpsellProducts,
  PREMIUM_MEMBERSHIP_VARIANT_ID,
  PREMIUM_MEMBERSHIP_VARIANT_ID_ENCODED,
} from '../../redux/cart';
import { getPath } from '../../redux/router';
import { OtherLocationTypes, RoutePaths } from '../../routes';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { ProductLink } from '../product_link/product_link';
import styles from './cart_item.less';
import { useUpsellProductsClosedSkus } from './use_upsell_products_closed_skus';

export interface CartItemProps {
  lineItem: LineItem;
  onQuantityChange: (val: React.ReactText) => void;
  removeLineItem: () => void;
  showUpsell: boolean;
  className?: string;
  inventory: number;
}

export const CartItem = (props: CartItemProps) => {
  const { lineItem, className, showUpsell, inventory, onQuantityChange, removeLineItem } = props;
  const { variant, discountAllocations, quantity } = lineItem;
  const cartUpsellProducts = useSelector(getCartUpsellProducts);
  const path = useSelector(getPath);
  const { addToCart, lineItems, subtotalPrice, activeDiscountCode } = useCart();
  const [upsellQuantity, setUpsellQuantity] = useState(1);
  const list_uuid = useListAttributionUuid();
  const activeCartUpsellProducts = cartUpsellProducts.filter((cartUpsellProduct) =>
    isCartUpsellProductActive(cartUpsellProduct, new Date())
  );
  const cartUpsellProductMatch = activeCartUpsellProducts.find(
    (cup) => cup.item_in_cart.handle === variant.product.handle
  );
  const upsellProduct = cartUpsellProductMatch?.item_to_recommend;
  const cartContainsUpsellProduct = lineItems.some(
    (li) =>
      li.variant.product.handle === cartUpsellProductMatch?.item_to_recommend.shopify_product_handle
  );

  const src = variant.image ? setShopifyImgWidth(variant.image.url, 200) : null;
  const isPremiumMembership =
    variant.id === PREMIUM_MEMBERSHIP_VARIANT_ID ||
    variant.id === PREMIUM_MEMBERSHIP_VARIANT_ID_ENCODED;
  let price = roundToTwoDecimals(Number(variant.price.amount) * quantity);
  const nonSalePrice = roundToTwoDecimals(Number(variant.compareAtPrice.amount) * quantity);
  const totalDiscounts = discountAllocations.reduce((sum, allocation) => {
    return sum + Number(allocation.allocatedAmount.amount);
  }, 0);
  if (totalDiscounts > 0) {
    price = price - totalDiscounts;
  }

  const selectedVariantPrice = price > 0 ? toDollars(price) : 'FREE';
  const onSale =
    variant.compareAtPrice && Number(variant.compareAtPrice.amount) > Number(variant.price.amount);

  const lineItemIsPremiumMembership = variant.product.handle === 'omnia-premium-membership';

  const { cartUpsellProductsClosedSkus, setCartUpsellProductsClosedSkus } =
    useUpsellProductsClosedSkus();
  const upsellClosed = cartUpsellProductsClosedSkus.includes(upsellProduct?.sku);
  const validUpsell =
    showUpsell && cartUpsellProductMatch && !upsellClosed && !cartContainsUpsellProduct;

  const webAnalyticsCartClickArgs = {
    cart_total_amount: parseFloat(subtotalPrice),
    cart_total_items: lineItems.length,
    cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
  };

  useEffect(() => {
    if (validUpsell) {
      WebAnalytics.productListViewed({
        coupon: activeDiscountCode,
        list_id: `${path}:upsell:Recommended`,
        products: [upsellProduct],
        list_uuid,
      });
    }
  }, [validUpsell]);

  return (
    <>
      <div className={classNames(styles.item, className)}>
        <div className={styles.img}>
          <Link
            to={
              lineItemIsPremiumMembership
                ? RoutePaths.PREMIUM_PRO
                : OmniaUrls.product(variant.product.handle, variant.selectedOptions)
            }
            onClick={() => {
              WebAnalytics.cartClick('[cart].(line_item_product_click)', webAnalyticsCartClickArgs);
            }}
          >
            <img src={src} alt="" width="100" />
          </Link>
        </div>
        <div>
          <p className={styles.title}>
            <Link
              to={
                lineItemIsPremiumMembership
                  ? RoutePaths.PREMIUM_PRO
                  : OmniaUrls.product(variant.product.handle, variant.selectedOptions)
              }
              onClick={() => {
                WebAnalytics.cartClick(
                  '[cart].(line_item_product_click)',
                  webAnalyticsCartClickArgs
                );
              }}
            >
              {lineItem.title} - {variant.title}
            </Link>
          </p>
          <p className={styles.price}>
            <span className={classNames({ [styles.price__sale]: onSale })}>
              {selectedVariantPrice}
            </span>
            {onSale && <s className={styles.priceCompare}>{toDollars(nonSalePrice)}</s>}
          </p>

          <div>
            {!isPremiumMembership && (
              <InputNumber
                className={styles.input}
                min={1}
                max={inventory}
                value={lineItem.quantity}
                onChange={onQuantityChange}
                name="quantity"
              />
            )}
            <button className={styles.remove} onClick={removeLineItem}>
              Remove
            </button>
          </div>
        </div>
      </div>

      {validUpsell && (
        <>
          <div className={styles.upsell}>
            <div className={styles.upsellProduct}>
              <p className={styles.upsellHeading}>
                Recommended
                <span>with the {cartUpsellProductMatch.item_in_cart.title}</span>
              </p>
              <div className={styles.upsellContent}>
                <div className={styles.upsellLeft}>
                  <div className={styles.upsellImg}>
                    <ProductLink
                      product={upsellProduct}
                      locationType={OtherLocationTypes.CART_UPSELL}
                      list_uuid={list_uuid}
                    >
                      <img src={setShopifyImgWidth(upsellProduct.img_url, 100)} alt="" />
                    </ProductLink>
                  </div>
                  <p className={styles.upsellTitle}>
                    <ProductLink
                      product={upsellProduct}
                      currentColor
                      locationType={OtherLocationTypes.CART_UPSELL}
                      list_uuid={list_uuid}
                    >
                      {upsellProduct.title}
                    </ProductLink>
                  </p>
                </div>

                <div className={styles.upsellRight}>
                  <div className={styles.upsellPrice}>{toDollars(upsellProduct.price)}</div>
                  <div className={styles.inputWrapper}>
                    <InputNumber
                      value={upsellQuantity}
                      onChange={(val) => {
                        setUpsellQuantity(val);
                      }}
                    />
                    <OmniaButton
                      size="md"
                      kind="secondary"
                      fontSize={14}
                      block
                      style={{ padding: '4px 8px' }}
                      onPress={() => {
                        addToCart({
                          productsToAdd: [
                            {
                              product: upsellProduct,
                              quantity: upsellQuantity,
                            },
                          ],
                          locationType: OtherLocationTypes.CART_UPSELL,
                          position: null,
                          list_uuid,
                        });
                      }}
                    >
                      Add
                    </OmniaButton>
                  </div>
                  <OmniaLinkButton
                    kind="tertiary"
                    size="md"
                    to={OmniaUrls.product(
                      upsellProduct.shopify_product_handle,
                      upsellProduct.shopify_options
                    )}
                    block
                    onPress={() => {
                      WebAnalytics.productClick({
                        productOrProductFamily: upsellProduct,
                        show_modal: false,
                        position: null,
                        locationType: OtherLocationTypes.CART_UPSELL,
                        list_uuid,
                      });
                    }}
                  >
                    Explore Options
                  </OmniaLinkButton>
                </div>
              </div>
              <CloseOutlined
                className={styles.upsellClose}
                onClick={() => {
                  setCartUpsellProductsClosedSkus([
                    ...cartUpsellProductsClosedSkus,
                    upsellProduct.sku,
                  ]);
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
