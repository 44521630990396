export function roundToTwoDecimals(num: number) {
  return Number(Math.round(Number(num.toString() + 'e+2')) + 'e-2');
}

export function randomRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getPercentage = (amount: number, totalAmount: number): number => {
  if (totalAmount === 0) {
    return 0;
  }
  return Math.round((amount / totalAmount) * 100);
};
