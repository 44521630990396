import * as React from 'react';

const SvgCloseIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.707,0.293 C1.316,-0.098 0.683,-0.098 0.293,0.293 C-0.098,0.684 -0.098,1.317 0.293,1.707 L5.586,7 L0.293,12.293 C-0.098,12.683 -0.098,13.317 0.293,13.707 C0.684,14.098 1.317,14.098 1.707,13.707 L7,8.414 L12.293,13.707 C12.683,14.098 13.317,14.098 13.707,13.707 C14.098,13.317 14.098,12.683 13.707,12.293 L8.414,7 L13.707,1.707 C14.098,1.316 14.098,0.683 13.707,0.293 C13.317,-0.098 12.683,-0.098 12.293,0.293 L7,5.586 L1.707,0.293 Z"
    ></path>
  </svg>
);

export default SvgCloseIcon;
