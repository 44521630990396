import React from 'react';
import { LoadingState } from '../../constants/loading_state';
import { UserAuthHandlerProps } from './user_auth_handler_container';

const MINUTE = 1000 * 60;
export const CHECK_AUTH_INTERVAL = 9 * MINUTE;

export default class UserAuthHandler extends React.Component<UserAuthHandlerProps, {}> {
  interval = null as number;

  componentDidMount() {
    const { checkAuthExpired } = this.props;
    checkAuthExpired();
    this.interval = window.setInterval(checkAuthExpired, CHECK_AUTH_INTERVAL);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  componentDidUpdate(prevProps: UserAuthHandlerProps) {
    const {
      isAuthExpired,
      refreshAuth,
      refreshToken,
      user,
      userLoadingState,
      fetchUser,
      hasAccessToken,
    } = this.props;

    const tokenJustExpired = !prevProps.isAuthExpired && isAuthExpired;

    if (hasAccessToken && tokenJustExpired) {
      refreshAuth(refreshToken);
    }

    // 4/8/2021 - I think this is no longer needed, user is returned from login and signup, but not willing to trash now
    // logged in, user not loaded
    if (
      hasAccessToken &&
      !isAuthExpired &&
      user == null &&
      userLoadingState === LoadingState.NOT_STARTED
    ) {
      fetchUser();
    }
  }

  clearInterval = () => {
    window.clearInterval(this.interval);
  };

  render(): null {
    return null;
  }
}
