import React from 'react';
import { useDispatch } from 'react-redux';
import { useProPurchaseUrl } from '../../hooks/use_pro_purchase_url';
import { useUser } from '../../hooks/use_user';
import { SignupLoginModalActions } from '../../redux/signup_login_modal';
import { PRO_TRIAL_SIGNUP_LOGIN_MODAL_OPEN_PARAMS } from '../login_signup_modal/signup_login_modal';
import { OmniaButton, OmniaButtonProps } from '../omnia_button/omnia_button';

interface ProTrialButtonProps extends Omit<OmniaButtonProps, 'children'> {
  text?: string;
  trialPaymentLinkUrl?: string;
}

export const ProTrialButton = (props: ProTrialButtonProps) => {
  const { text = 'Start a 7-Day FREE Trial', trialPaymentLinkUrl, ...rest } = props;
  const { user, isPro } = useUser();
  const dispatch = useDispatch();
  const { proPurchaseUrl } = useProPurchaseUrl({
    trial: true,
    trialPurchaseUrl: trialPaymentLinkUrl,
  });

  const modalParams = PRO_TRIAL_SIGNUP_LOGIN_MODAL_OPEN_PARAMS;
  if (trialPaymentLinkUrl) {
    modalParams.forwardRoute = trialPaymentLinkUrl;
  }
  return (
    <OmniaButton
      onPress={() => {
        if (user) {
          window.location.href = proPurchaseUrl;
        } else {
          dispatch(SignupLoginModalActions.SIGNUP_LOGIN_MODAL_OPEN(modalParams));
        }
      }}
      isDisabled={isPro}
      {...rest}
    >
      {isPro ? 'Already PRO' : text}
    </OmniaButton>
  );
};
