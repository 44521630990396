import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerWaterClarity = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36" {...props}>
    <path
      d="M15.295 32.747a10.158 10.158 0 0 1-8.328-10.003c0-7.102 7.407-15.813 10.169-18.814 2.022 2.197 6.535 7.458 8.812 12.933a1.45 1.45 0 0 0 2.678-1.114C25.424 8.051 18.452 1.088 18.156.795a1.452 1.452 0 0 0-2.041 0c-.492.488-12.049 12.055-12.049 21.948 0 6.315 4.503 11.723 10.707 12.856a1.45 1.45 0 0 0 .522-2.854z"
      className="layer_water_clarity_svg__cls-1"
    />
    <path
      d="m31.509 33.024-3.064-3.064a7.194 7.194 0 0 0 1.2-3.998 7.2 7.2 0 0 0-2.123-5.126 7.2 7.2 0 0 0-5.126-2.123 7.2 7.2 0 0 0-5.126 2.123 7.2 7.2 0 0 0-2.123 5.126 7.2 7.2 0 0 0 2.123 5.126 7.2 7.2 0 0 0 5.126 2.123c1.444 0 2.822-.42 3.998-1.201l3.064 3.064a1.446 1.446 0 0 0 2.05 0 1.45 1.45 0 0 0 0-2.051h.001Zm-12.188-3.985a4.353 4.353 0 0 1 0-6.15c.848-.848 1.962-1.271 3.075-1.271s2.228.424 3.075 1.271a4.353 4.353 0 0 1 0 6.15 4.355 4.355 0 0 1-6.15 0"
      className="layer_water_clarity_svg__cls-1"
    />
  </svg>
);
export default SvgLayerWaterClarity;
