import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'router';

export enum StateKeys {
  baseUrl = 'baseUrl',
  fullUrl = 'fullUrl',
  path = 'path',
}

export const initialState = {
  [StateKeys.baseUrl]: '',
  [StateKeys.fullUrl]: '',
  [StateKeys.path]: '/',
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getBaseUrl = (state: ApplicationState) => state[reducerName][StateKeys.baseUrl];
export const getFullUrl = (state: ApplicationState) => state[reducerName][StateKeys.fullUrl];
export const getPath = (state: ApplicationState) => state[reducerName][StateKeys.path];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function RouterReducer(state = initialState, action: RouterActions) {
  switch (action.type) {
    case ReduxActions.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        [StateKeys.path]: action.payload.path,
        [StateKeys.fullUrl]: state[StateKeys.baseUrl] + action.payload.path,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const RouterActions = {
  ROUTER_LOCATION_CHANGE: (path: string) =>
    createAction(ReduxActions.ROUTER_LOCATION_CHANGE, { path }),
};
export type RouterActions = ActionsUnion<typeof RouterActions>;
