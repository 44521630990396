import { FishingReport, Video } from '@omniafishing/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import iconPlay from '../../assets/icon-play.svg';
import { apiV1 } from '../../lib/api';
import { getImgixPath } from '../../lib/imgix';
import { WebAnalytics } from '../../lib/web_analytics';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { ActiveMedia, MediaModal } from '../fishing_report_card/media_modal';
import { ShareReportButton } from '../fishing_report_card/share_report_button';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgExpandIcon from '../svg/expand_icon';
import styles from './user_report_card_media.less';
interface FishingReportMediaProps {
  fishingReport: FishingReport;
  image: string | null;
  userShouldAddMedia: boolean;
  position: number;
  video: Video;
}

const getBackgroundImageStyle = (formattedImage: string) => {
  return {
    backgroundImage: `url('${formattedImage}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    filter: 'blur(4px)',
  };
};

export const UserReportCardMedia = (props: FishingReportMediaProps) => {
  const { fishingReport, image, position, userShouldAddMedia = false, video } = props;
  const [activeMedia, setActiveMedia] = useState<ActiveMedia>(null);
  const dispatch = useDispatch();
  const hasImage = !!image;
  const hasVideo = !!video;
  const hasMedia = hasImage || hasVideo;
  const hasImageAndVideo = hasImage && hasVideo;

  const onVideoClick = (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveMedia({
      src: video.src,
      type: 'video',
    });

    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const onImageClick = (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveMedia({
      src: getImgixPath(image, { w: 1000 }),
      type: 'img',
    });
    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const VideoComponent = (
    <>
      <div
        style={getBackgroundImageStyle(
          getImgixPath(video?.image, {
            w: 170,
            ar: '1.5:1',
            fit: 'crop',
            crop: 'faces',
          })
        )}
        className={styles.imgBackground}
      />
      <img
        className={styles.image}
        src={getImgixPath(video?.image, {
          w: 174,
          h: 117,
          crop: 'faces',
        })}
        onClick={onVideoClick}
      />
      <button className={styles.expandIcon} onClick={onVideoClick}>
        <SvgExpandIcon />
      </button>
      <span className={styles.videoLabel}>
        <img src={iconPlay} className={styles.playIcon} /> Video
      </span>
      <ShareReportButton fishingReport={fishingReport} className={styles.shareButton} />
    </>
  );

  if (userShouldAddMedia) {
    return (
      <div className={styles.editWrapper}>
        <OmniaButton
          kind="tertiary"
          size="sm"
          onPress={async () => {
            const {
              data: { data: waterbodyDetail },
            } = await apiV1.waterbodyFetch(fishingReport.waterbody.url_slug);
            dispatch(
              FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(waterbodyDetail, fishingReport)
            );
          }}
        >
          Add Image or Video
        </OmniaButton>
        <span>Fishing reports with an image or video get more conversions.</span>
        <ShareReportButton fishingReport={fishingReport} className={styles.shareButton} />
      </div>
    );
  }

  return (
    <>
      {hasMedia && (
        <>
          {/*
            edit this conditional at your own risk.
            there is only space on mobile FR cards for one media
            and that is video, hence this first, funny looking conditional.
          */}
          {hasImageAndVideo ? (
            <div className={styles.mediaSection}>
              <div className={styles.videoContainer}>{VideoComponent}</div>
            </div>
          ) : (
            <div className={styles.mediaSection}>
              {video && <div className={styles.videoContainer}>{VideoComponent}</div>}
              {image && (
                <div className={styles.imgContainer}>
                  <div
                    style={getBackgroundImageStyle(
                      getImgixPath(image, {
                        w: 170,
                        ar: '1.5:1',
                        fit: 'crop',
                        crop: 'faces',
                      })
                    )}
                    className={styles.imgBackground}
                  />
                  <img
                    className={styles.image}
                    src={getImgixPath(image, {
                      w: 174,
                      h: 117,
                      crop: 'faces',
                    })}
                    onClick={onImageClick}
                  />
                  <button className={styles.expandIcon} onClick={onImageClick}>
                    <SvgExpandIcon />
                  </button>
                  <ShareReportButton fishingReport={fishingReport} className={styles.shareButton} />
                </div>
              )}
            </div>
          )}
          <MediaModal activeMedia={activeMedia} onCancel={() => setActiveMedia(null)} />
        </>
      )}
    </>
  );
};
