import { Product } from '@omniafishing/core';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { LocationTypes } from '../../routes';

// TODO: extend from LinkProps and pass ...rest props to Link
export const ProductLink = (props: {
  children: React.ReactNode;
  className?: string;
  currentColor?: boolean;
  locationType?: LocationTypes;
  onClick?: () => void;
  product: Product;
  target?: LinkProps['target'];
  search?: string;
  list_uuid?: string;
}) => {
  const {
    product,
    className,
    children,
    onClick,
    currentColor,
    locationType,
    target,
    search,
    list_uuid,
  } = props;

  const onLinkClick = () => {
    WebAnalytics.productClick({
      productOrProductFamily: product,
      show_modal: false,
      position: null,
      locationType,
      list_uuid,
    });
    onClick?.();
  };

  return (
    <Link
      to={{
        pathname: OmniaUrls.product(product.shopify_product_handle, product.shopify_options),
        search,
      }}
      className={className}
      onClick={onLinkClick}
      target={target}
      style={
        currentColor
          ? {
              color: 'currentColor',
            }
          : {}
      }
    >
      {children}
    </Link>
  );
};
