export const waterTempPaint = {
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', 'val'],
    32,
    '#1E1F2A',
    33,
    '#191C2F',
    34,
    '#111737',
    35,
    '#0D153B',
    36,
    '#0A133E',
    37,
    '#071141',
    38,
    '#040F44',
    39,
    '#020E46',
    40,
    '#000D48',
    41,
    '#001063',
    42,
    '#00147E',
    43,
    '#00189B',
    44,
    '#001CB9',
    45,
    '#0021D8',
    46,
    '#0026F7',
    47,
    '#0031F7',
    48,
    '#0647F8',
    49,
    '#1C62F8',
    50,
    '#2C81F9',
    51,
    '#3BA3FA',
    52,
    '#4BC7FB',
    53,
    '#53CDF3',
    54,
    '#60D3EA',
    55,
    '#73DAE2',
    56,
    '#8AE1DA',
    57,
    '#A4E7D2',
    58,
    '#C1EECA',
    59,
    '#E0F6C3',
    60,
    '#FFFEBC',
    61,
    '#FCE9A3',
    62,
    '#FAD58C',
    63,
    '#F7C175',
    64,
    '#F5AE5F',
    65,
    '#F39C4B',
    66,
    '#F18A3A',
    67,
    '#EF792B',
    68,
    '#EE6922',
    69,
    '#E0591F',
    70,
    '#D44C1C',
    71,
    '#C73E19',
    72,
    '#BB3317',
    73,
    '#B02A15',
    74,
    '#A32314',
    75,
    '#952013',
    76,
    '#881E12',
    77,
    '#7A1C12',
    78,
    '#6D1A12',
    79,
    '#611811',
    80,
    '#541711',
    100,
    'red',
  ],
} as any;

export const waterClarityPaint = {
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', 'val'],
    0,
    '#c7c7ff',
    1,
    '#8abcf4',
    2,
    '#91b7d8',
    3,
    '#8db3bf',
    4,
    '#8ab0a4',
    5,
    '#85ac86',
    6,
    '#81a867',
    7,
    '#859a5e',
    8,
    '#917d55',
    9,
    '#967152',
    10,
    '#a56149',
    10.1,
    '#000000',
  ],
} as any;
