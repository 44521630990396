import { UserPrivate } from '@omniafishing/core';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';

export interface InventoryFormProps {
  user: UserPrivate;
  formRef: React.RefObject<FormInstance>;
}

export const InventoryForm: React.FC<InventoryFormProps> = (props) => {
  const formFields = {
    email: 'email',
  };

  const { user, formRef } = props;
  const hasUser = user != null;

  return (
    <Form ref={formRef} layout="vertical">
      {hasUser ? (
        <p>
          We will email: <strong>{user.email}</strong>
        </p>
      ) : (
        <Form.Item
          name={formFields.email}
          rules={[
            {
              type: 'email',
              message: 'Please input a valid email',
            },
            {
              required: true,
              message: 'Please input your email',
            },
          ]}
          validateTrigger="onBlur"
          label="Email"
        >
          <Input autoFocus />
        </Form.Item>
      )}
    </Form>
  );
};
