import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerAirTemp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="layer_air_temp_svg__Layer_1"
    width={48}
    height={48}
    viewBox="0 0 36 36"
    {...props}
  >
    <defs>
      <style>{'.layer_air_temp_svg__cls-1{fill:currentColor}'}</style>
    </defs>
    <path
      d="M17.336 23.324a.979.979 0 0 1-.568-.895V12.282a.875.875 0 0 0-1.75 0v10.147a.988.988 0 0 1-.579.9 3.489 3.489 0 0 0-1.95 4.002 3.474 3.474 0 0 0 2.571 2.572 3.505 3.505 0 0 0 4.333-3.404 3.483 3.483 0 0 0-2.057-3.175M27.682 7.201h-3.219a.875.875 0 0 1 0-1.75h3.219a.875.875 0 0 1 0 1.75m0 12.26h-3.219a.875.875 0 0 1 0-1.75h3.219a.875.875 0 0 1 0 1.75m0-4.087h-3.219a.875.875 0 0 1 0-1.75h3.219a.875.875 0 0 1 0 1.75m0-4.086h-3.219a.875.875 0 0 1 0-1.75h3.219a.875.875 0 0 1 0 1.75"
      className="layer_air_temp_svg__cls-1"
    />
    <path
      d="M15.893 34.95c-4.659 0-8.45-3.791-8.45-8.45a8.46 8.46 0 0 1 2.996-6.454V6.504c0-3.007 2.447-5.454 5.454-5.454s5.454 2.447 5.454 5.454v13.542a8.46 8.46 0 0 1 2.996 6.454c0 4.659-3.791 8.45-8.45 8.45m0-31a2.557 2.557 0 0 0-2.554 2.554v15.01l-.62.433a5.558 5.558 0 0 0-2.376 4.554c0 3.061 2.489 5.55 5.55 5.55s5.55-2.489 5.55-5.55a5.56 5.56 0 0 0-2.376-4.554l-.62-.433V6.504a2.557 2.557 0 0 0-2.554-2.554"
      className="layer_air_temp_svg__cls-1"
    />
  </svg>
);
export default SvgLayerAirTemp;
