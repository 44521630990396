import { ProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ProductFamilyCarousel } from '../../components/carousel/product_family_carousel';
import { ProductFamilyCard } from '../../components/product_family_card/product_family_card';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { apiV1 } from '../../lib/api';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './related_products.less';

interface RelatedProductsProps {
  productFamily: ProductFamily;
}

export const RelatedProducts = ({ productFamily }: RelatedProductsProps) => {
  const { handle } = productFamily;
  const [relatedProductFamilies, setRelatedProductFamilies] = useState<ProductFamily[]>([]);
  const relatedProductFamiliesCount = relatedProductFamilies.length;
  const hasRelatedProducts = relatedProductFamiliesCount > 0;
  const list_uuid = useListAttributionUuid();

  useEffect(() => {
    if (handle) {
      apiV1.productFamilyRelatedProductFamiliesFetch(handle).then((res) => {
        setRelatedProductFamilies(res.data.data);
      });
    }
  }, [handle]);

  return (
    hasRelatedProducts && (
      <>
        <h3 className={styles.sectionHeading}>Related Products ({relatedProductFamiliesCount})</h3>
        <div className={classNames(styles.carousel, styles.carouselRelatedProducts)}>
          <ProductFamilyCarousel
            products_families={relatedProductFamilies}
            arrows={true}
            list_id_title="Related Products"
            list_uuid={list_uuid}
          >
            {relatedProductFamilies.map((relatedProductFamily, i) => (
              <div className={styles.productTile} key={relatedProductFamily.handle}>
                <ProductFamilyCard
                  recommendedFamily={{
                    product_family: relatedProductFamily,
                    products: relatedProductFamily.products,
                    contains_all_colors: true,
                  }}
                  onProductClick={() => {
                    WebAnalytics.productDetailPageClick('[related].(product_link)');
                  }}
                  position={i}
                  list_uuid={list_uuid}
                />
              </div>
            ))}
          </ProductFamilyCarousel>
        </div>
      </>
    )
  );
};
