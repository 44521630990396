import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerStorm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="layer_storm_svg__Layer_1"
    width={48}
    height={48}
    viewBox="0 0 36 36"
    {...props}
  >
    <defs>
      <style>{'.layer_storm_svg__cls-1{fill:currentColor}'}</style>
    </defs>
    <path
      d="M26.533 23.629H7.642a6.802 6.802 0 0 1-6.794-6.794 6.734 6.734 0 0 1 4.928-6.509C6.359 4.763 11.078.414 16.794.414c4.185 0 7.938 2.314 9.831 5.978 4.711.049 8.527 3.896 8.527 8.619 0 4.753-3.866 8.618-8.619 8.618M8.614 11.451l.003.089a1.45 1.45 0 0 1-1.321 1.444c-2.022.181-3.548 1.837-3.548 3.852a3.898 3.898 0 0 0 3.894 3.894h18.892c3.153 0 5.719-2.565 5.719-5.719s-2.565-5.72-5.719-5.72c-.22 0-.437.017-.649.04a1.442 1.442 0 0 1-1.509-.897 8.144 8.144 0 0 0-7.581-5.119c-4.497 0-8.157 3.646-8.18 8.137ZM10.955 35.586a.874.874 0 0 1-.757-1.312l4.521-7.822a.874.874 0 1 1 1.515.875l-4.521 7.822a.874.874 0 0 1-.759.438ZM22.866 35.586a.874.874 0 0 1-.757-1.312l4.521-7.822a.876.876 0 0 1 1.515.875l-4.521 7.822a.874.874 0 0 1-.759.438ZM19.343 31.379a.874.874 0 0 1-.757-1.312l2.089-3.615a.874.874 0 1 1 1.515.875l-2.089 3.615a.874.874 0 0 1-.759.438zM7.431 31.379a.874.874 0 0 1-.757-1.312l2.089-3.615a.876.876 0 0 1 1.515.875l-2.089 3.615a.874.874 0 0 1-.759.438z"
      className="layer_storm_svg__cls-1"
    />
  </svg>
);
export default SvgLayerStorm;
