interface AddToCartTextArgs {
  outOfStock: boolean;
  variantMatch?: boolean;
  isHidden: boolean;
  productFamily?: boolean;
}

export const addToCartText = (addToCartTextArgs: AddToCartTextArgs) => {
  const { outOfStock, variantMatch, isHidden, productFamily } = addToCartTextArgs;
  let addCartText = 'Add to Cart';
  if (outOfStock) {
    addCartText = 'Out of Stock';
  }
  if (variantMatch === false && productFamily) {
    addCartText = 'Choose Options';
  }
  if (isHidden) {
    addCartText = 'Not Carried';
  }
  return addCartText;
};
