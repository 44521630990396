import React from 'react';

const SvgUser = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" fill="none" fillRule="evenodd">
      <path d="M10.537 5.009c0 1.55-1.22 3.66-2.818 3.66s-2.805-2.16-2.805-3.66" />
      <path d="M7.56 4.997a6.965 6.965 0 003.806-.756L9.878 2.899l-.44-1.403a.732.732 0 00-.975-.44l-.549.245a.744.744 0 01-.56 0l-.635-.244a.72.72 0 00-.964.451l-.439 1.39-1.5 1.343a6.99 6.99 0 003.818.756H7.56zM5.853 8.254L4.56 9.474h0a2.818 2.818 0 00-2.83 2.28l-.708 3.66A1.342 1.342 0 002.328 17h10.71a1.342 1.342 0 001.353-1.586l-.707-3.66a2.818 2.818 0 00-2.77-2.256h0l-1.292-1.22M4.499 9.498V17M10.866 9.498V17M7.621 8.656v3.257" />
    </g>
  </svg>
);

export default SvgUser;
