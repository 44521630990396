import { ProductFamily, SelectedOption, ShopifyProduct } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { getProductBySelectedOptions } from '../../lib/get_product_by_selected_options';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { NewBadge } from '../new_badge/new_badge';
import { isItemNew } from '../product_variant_selector/product_title';
import { ColorOption } from '../product_variant_selector/product_variant_selector';
import { isUnavailable } from '../product_variant_selector/product_variant_selector_helpers';
import styles from './color_selector_drawer.less';

interface DrawerColorListProps {
  colorOptions: ColorOption[];
  isProductFamilyNew: boolean;
  onColorChange: (colorName: string) => void;
  onVisibleChange: (visible: boolean) => void;
  productFamily: ProductFamily;
  searchTerm: string;
  selectedColorOption: SelectedOption;
  selectedOptions: SelectedOption[];
  shopifyProduct: ShopifyProduct;
  sortOrderAscending: boolean;
}

export const DrawerColorList = React.memo((props: DrawerColorListProps) => {
  const {
    colorOptions,
    isProductFamilyNew,
    onColorChange,
    onVisibleChange,
    productFamily,
    searchTerm,
    selectedColorOption,
    selectedOptions,
    shopifyProduct,
    sortOrderAscending,
  } = props;

  const handleColorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onColorChange(e.target.value);
      onVisibleChange(false);
    },
    [selectedOptions]
  );

  const colorOptionsWithAvailability = useMemo(() => {
    return colorOptions.map((colorOption) => {
      const isAvailable = !isUnavailable({
        shopifyProduct,
        selectedOptions,
        optionName: 'color',
        value: colorOption.colorName,
      });

      const nonColorOptions = selectedOptions.filter(
        (option) => option.name.toLowerCase() !== 'color'
      );
      const allOptions = [...nonColorOptions, { name: 'color', value: colorOption.colorName }];
      const productFromOptions = getProductBySelectedOptions(productFamily.products, allOptions);
      const isNew =
        productFromOptions?.created_at && isItemNew(dayjs(productFromOptions.created_at));

      return { ...colorOption, isAvailable, isNew };
    });
  }, [
    shopifyProduct.id,
    colorOptions,
    selectedOptions.map((option) => option.value).join(','),
    productFamily,
  ]);

  const filteredSortedColorOptions = useMemo(() => {
    const filteredOptions = colorOptionsWithAvailability.filter((colorOption) =>
      colorOption.colorName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return _.orderBy(
      filteredOptions,
      ['isAvailable', 'colorName'],
      ['desc', sortOrderAscending ? 'asc' : 'desc']
    );
  }, [searchTerm, colorOptionsWithAvailability, sortOrderAscending]);

  return (
    <div className={styles.colorsWrapper}>
      {filteredSortedColorOptions.map((colorOption) => {
        const { isAvailable, isNew } = colorOption;
        const isSelected = selectedColorOption.value === colorOption.colorName;
        return (
          <label
            key={colorOption.colorName}
            className={classNames(styles.colorLabel, {
              [styles.optionUnavailable]: !isAvailable,
            })}
          >
            <img
              alt={colorOption.colorName}
              src={setShopifyImgWidth(colorOption.imgSrc, 100)}
              width="65"
              height="65"
              title={colorOption.colorName}
              className={classNames({ [styles.optionSelected]: isSelected })}
            />
            <input
              type="radio"
              name="color"
              value={colorOption.colorName}
              checked={isSelected}
              onChange={handleColorChange}
              onClick={(e: any) => {
                if (e.target.checked === true) {
                  onVisibleChange(false);
                }
              }}
              className={styles.optionRadio}
              disabled={!colorOption.isAvailable}
            />
            <span className={styles.drawerColorName}>{colorOption.colorName}</span>
            {isNew && !isProductFamilyNew && <NewBadge className={styles.newBadge} />}
          </label>
        );
      })}
    </div>
  );
});
