import { WebMercatorViewport } from '@math.gl/web-mercator';
import { Padding } from '@math.gl/web-mercator/src/fit-bounds';
import { WebMercatorViewportOptions } from '@math.gl/web-mercator/src/web-mercator-viewport';

// bounds = opposite corners specified as [[lon, lat], [lon, lat]]
export function fitBounds(
  bounds: [[number, number], [number, number]],
  viewport: WebMercatorViewportOptions,
  padding: number | Padding = 60
) {
  return new WebMercatorViewport(viewport).fitBounds(bounds, { padding });
}

export function getBounds(
  contentRect: {
    width: number;
    height: number;
  },
  bbox: number[]
) {
  return fitBounds(
    [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
    {
      width: contentRect.width,
      height: contentRect.height,
    },
    30
  );
}
