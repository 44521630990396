import { AlgoliaWaterbodyDetail, Waterbody } from '@omniafishing/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useExperiment } from 'statsig-react';
import { useQueryString } from '../../hooks/use_query_string';
import { OmniaUrls } from '../../lib/urls';
import { RoutePaths } from '../../routes';
import { MapQueryParams } from '../map_default/map_default';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import styles from './search_waterbody.less';

interface SearchWaterbodyProps {
  waterbody: AlgoliaWaterbodyDetail | Waterbody;
  onClick: (target_url: string) => void;
}

export function waterbodyStatesCountiesString(states: string[], counties: string[]) {
  if (!states.length && !counties.length) {
    return '';
  } else if (states.length && counties.length) {
    return `${states.join(', ')}, ${counties.join(', ')}`;
  } else if (states.length && !counties.length) {
    return `${states.join(', ')}`;
  } else {
    return `${counties.join(', ')}`;
  }
}

export const waterbodyCountiesStatesString = (states: string[], counties: string[]) => {
  if (!states.length && !counties.length) {
    return '';
  } else if (states.length && counties.length) {
    return `${counties.join(', ')}, ${states.join(', ')}`;
  } else if (states.length && !counties.length) {
    return `${states.join(', ')}`;
  } else {
    return `${counties.join(', ')}`;
  }
};

export const SearchWaterbody = (props: SearchWaterbodyProps) => {
  const { waterbody, onClick } = props;

  const primaryName = waterbody.primary_name;
  const states = waterbody.locales.map((l) => l.state.abbr);
  const counties = _.flatten(waterbody.locales.map((l) => l.counties));
  const location = waterbodyStatesCountiesString(states, counties);
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{ search_link_to_map: boolean }>();
  const defaultSearchLinkToMap = !!currentQuery.search_link_to_map;
  const waterbodyLink = useExperiment('search_link_to_map').config.get<'lake_page' | 'map'>(
    'destination',
    defaultSearchLinkToMap ? 'map' : 'lake_page'
  );

  const url =
    waterbodyLink === 'map'
      ? `${RoutePaths.MAP}?&${MapQueryParams.waterbody_slug}=${waterbody.url_slug}`
      : OmniaUrls.waterbody(waterbody);

  return (
    <Link to={url} className={styles.link} onClick={() => onClick(url)}>
      <div className={styles.img}>
        <WaterbodyImg waterbody={waterbody} />
      </div>
      <div className={styles.text}>
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: primaryName }} />
        <span className={styles.counties}>{location}</span>
      </div>
    </Link>
  );
};
