import { getServerEnv, ServerEnvs } from '../env';

export const INTERNALS = {
  getFbq: () => {
    const log = (...args: any[]) => {
      console.log('Facebook Track:', args);
    };
    if (getServerEnv() === ServerEnvs.PRODUCTION) {
      return (window as any).fbq as typeof log;
    }
    return log;
  },
};

class FacebookEventsClass {
  AddToCart(shopifyVariantIds: string[]) {
    const fbq = INTERNALS.getFbq();
    fbq('track', 'AddToCart', {
      content_ids: shopifyVariantIds,
      content_type: 'product',
    });
  }

  ViewContentProduct(shopifyVariantId: string, name: string) {
    const fbq = INTERNALS.getFbq();
    fbq('track', 'ViewContent', {
      content_name: name,
      content_ids: [shopifyVariantId],
      content_type: 'product',
    });
  }
}

export const FacebookEvents = new FacebookEventsClass();
