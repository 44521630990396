import { Waterbody } from '@omniafishing/core';

export const bestOfLakes = [
  {
    id: '35d0f12b-5d9d-42dc-b1fa-d052d3c34b55',
    waterbody_id: '35d0f12b-5d9d-42dc-b1fa-d052d3c34b55',
    primary_name: 'Lake Hartwell',
    url_slug: 'lake-hartwell-fishing-reports',
    lat: 34.415294,
    lng: -82.843506,
    locales: [
      {
        state: {
          name: 'Georgia',
          abbr: 'GA',
          position: 3,
          image:
            'https://omnia-fishing.imgix.net/production/states/20190606182627.ga.jpg?auto=format',
          slug: 'ga',
          bbox: '-85.605165,30.357851,-80.840549,35.000771',
          zone_id: 1,
        },
        counties: ['Hart'],
      },
      {
        state: {
          name: 'South Carolina',
          abbr: 'SC',
          position: 5,
          image:
            'https://omnia-fishing.imgix.net/production/states/20190606182645.sc.jpg?auto=format',
          slug: 'sc',
          bbox: '-83.353238,32.034600,-78.541087,35.215402',
          zone_id: 1,
        },
        counties: ['Anderson'],
      },
    ],
    bbox: [
      [-82.9662095149932, 34.3375660800345],
      [-82.9662095149932, 34.4511126131916],
      [-82.8018877152483, 34.4511126131916],
      [-82.8018877152483, 34.3375660800345],
      [-82.9662095149932, 34.3375660800345],
    ],
    hot_scale: 1,
    fishing_reports_count: 64,
    favorites_count: 706,
    ambassadors_count: 17,
    hotbaits_count: 50,
    distance: null,
    area_sqkm: 226.62396,
    omnia_clarity: 6,
  },
  {
    id: '30b40652-7b65-43da-a8f5-240180753327',
    waterbody_id: '30b40652-7b65-43da-a8f5-240180753327',
    primary_name: 'St. Johns River',
    url_slug: 'st-johns-river-florida-fishing-reports',
    lat: 29.976032,
    lng: -81.600403,
    locales: [
      {
        state: {
          name: 'Florida',
          abbr: 'FL',
          position: 7,
          image: 'https://omnia-fishing.imgix.net/staging/states/20190606182712.fl.jpg?auto=format',
          slug: 'fl',
          bbox: '-87.634938,24.544701,-80.031362,31.000888',
          zone_id: 1,
        },
        counties: ['Putnam'],
      },
    ],
    bbox: [
      [-81.673041, 29.91391],
      [-81.673041, 29.998537],
      [-81.526614, 29.998537],
      [-81.526614, 29.91391],
      [-81.673041, 29.91391],
    ],
    hot_scale: 1,
    fishing_reports_count: 27,
    favorites_count: 262,
    ambassadors_count: 2,
    hotbaits_count: 20,
    distance: null,
    area_sqkm: 5200,
    omnia_clarity: 8,
  },
  {
    id: '3967179e-5a68-4d4b-bbe1-a178e3a35aed',
    waterbody_id: '3967179e-5a68-4d4b-bbe1-a178e3a35aed',
    primary_name: 'Pickwick Lake',
    url_slug: 'pickwick-lake-fishing-reports',
    lat: 34.9085148124817,
    lng: -88.0396630154287,
    locales: [
      {
        state: {
          name: 'Alabama',
          abbr: 'AL',
          position: null,
          image: null,
          slug: 'al',
          bbox: '-88.473227,30.221132,-84.890894,35.008028',
          zone_id: 1,
        },
        counties: ['Colbert', 'Lauderdale'],
      },
    ],
    bbox: [
      [-88.2663871734329, 34.7273058794296],
      [-88.2663871734329, 35.0897164122004],
      [-87.624683774429, 35.0897164122004],
      [-87.624683774429, 34.7273058794296],
      [-88.2663871734329, 34.7273058794296],
    ],
    hot_scale: 1,
    fishing_reports_count: 64,
    favorites_count: 411,
    ambassadors_count: 28,
    hotbaits_count: 60,
    distance: null,
    area_sqkm: 140.750909977202,
    omnia_clarity: 7,
  },
  {
    id: '65b42dca-c2f9-45ce-a626-c43abb1cb354',
    waterbody_id: '65b42dca-c2f9-45ce-a626-c43abb1cb354',
    primary_name: 'St. Lawrence River',
    url_slug: 'st-lawrence-river-fishing-reports',
    lat: 44.460642,
    lng: -75.804016,
    locales: [
      {
        state: {
          name: 'New York',
          abbr: 'NY',
          position: null,
          image: null,
          slug: 'ny',
          bbox: '-79.762152,40.496103,-71.856214,45.015850',
          zone_id: 3,
        },
        counties: ['St. Lawrence'],
      },
    ],
    bbox: [
      [-75.3157708997849, 44.8327348002023],
      [-75.3157708997849, 45.0325035998922],
      [-74.78954519956, 45.0325035998922],
      [-74.78954519956, 44.8327348002023],
      [-75.3157708997849, 44.8327348002023],
    ],
    hot_scale: 1,
    fishing_reports_count: 78,
    favorites_count: 504,
    ambassadors_count: 15,
    hotbaits_count: 76,
    distance: null,
    area_sqkm: 500,
    omnia_clarity: null,
  },
  {
    id: '2b590457-e23d-446c-b095-4f8f6ffbc82f',
    waterbody_id: '2b590457-e23d-446c-b095-4f8f6ffbc82f',
    primary_name: 'Lake St Clair',
    url_slug: 'lake-saint-clair-fishing-reports',
    lat: 42.44008,
    lng: -82.700684,
    locales: [
      {
        state: {
          name: 'Michigan',
          abbr: 'MI',
          position: null,
          image: null,
          slug: 'mi',
          bbox: '-90.418136,41.696089,-82.412965,48.190975',
          zone_id: 3,
        },
        counties: ['Macomb', 'St. Clair', 'Wayne'],
      },
    ],
    bbox: [
      [-82.9283713817186, 42.3517904800948],
      [-82.9283713817186, 42.6903712670693],
      [-82.6086573822149, 42.6903712670693],
      [-82.6086573822149, 42.3517904800948],
      [-82.9283713817186, 42.3517904800948],
    ],
    hot_scale: 1,
    fishing_reports_count: 93,
    favorites_count: 1052,
    ambassadors_count: 32,
    hotbaits_count: 76,
    distance: null,
    area_sqkm: 419.100494072735,
    omnia_clarity: 3,
  },
  {
    id: 'deb4d525-ef3e-4e59-9556-b8a070c5455e',
    waterbody_id: 'deb4d525-ef3e-4e59-9556-b8a070c5455e',
    primary_name: 'California Delta',
    url_slug: 'california-delta-fishing-reports',
    lat: 38.049183825315,
    lng: -121.642844317171,
    locales: [
      {
        state: {
          name: 'California',
          abbr: 'CA',
          position: null,
          image: null,
          slug: 'ca',
          bbox: '-124.409591,32.534156,-114.134427,42.009518',
          zone_id: 2,
        },
        counties: ['Contra Costa'],
      },
    ],
    bbox: [
      [-121.657392403895, 38.0435095815739],
      [-121.657392403895, 38.0548845950979],
      [-121.632071369559, 38.0548845950979],
      [-121.632071369559, 38.0435095815739],
      [-121.657392403895, 38.0435095815739],
    ],
    hot_scale: 1,
    fishing_reports_count: 41,
    favorites_count: 316,
    ambassadors_count: 8,
    hotbaits_count: 31,
    distance: null,
    area_sqkm: 1.25423556837761,
    omnia_clarity: 7,
  },
  {
    id: 'e51fddd0-cbde-4d74-bb69-040add22df42',
    waterbody_id: 'e51fddd0-cbde-4d74-bb69-040add22df42',
    primary_name: 'Lake Marion ',
    url_slug: 'lake-marion-santee-cooper-south-carolina-fishing-reports',
    lat: 33.461005,
    lng: -80.346588,
    locales: [
      {
        state: {
          name: 'South Carolina',
          abbr: 'SC',
          position: 5,
          image:
            'https://omnia-fishing.imgix.net/production/states/20190606182645.sc.jpg?auto=format',
          slug: 'sc',
          bbox: '-83.353238,32.034600,-78.541087,35.215402',
          zone_id: 1,
        },
        counties: ['Berkeley', 'Calhoun', 'Clarendon', 'Orangeburg', 'Sumter'],
      },
    ],
    bbox: [
      [-80.6309055186181, 33.3794870815217],
      [-80.6309055186181, 33.7432020142904],
      [-80.1388121860485, 33.7432020142904],
      [-80.1388121860485, 33.3794870815217],
      [-80.6309055186181, 33.3794870815217],
    ],
    hot_scale: 1,
    fishing_reports_count: 28,
    favorites_count: 201,
    ambassadors_count: 14,
    hotbaits_count: 21,
    distance: null,
    area_sqkm: 323.925020131362,
    omnia_clarity: 6,
  },
  {
    id: 'aca93596-7315-48b3-85c1-b56e0e602375',
    waterbody_id: 'aca93596-7315-48b3-85c1-b56e0e602375',
    primary_name: 'Lake Of The Ozarks',
    url_slug: 'lake-of-the-ozarks-fishing-reports',
    lat: 38.182321,
    lng: -92.667343,
    locales: [],
    bbox: null,
    hot_scale: 1,
    fishing_reports_count: 54,
    favorites_count: 864,
    ambassadors_count: 14,
    hotbaits_count: 42,
    distance: null,
    area_sqkm: 218,
    omnia_clarity: 3,
  },
] as Waterbody[];
