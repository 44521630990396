import * as React from 'react';
import { SVGProps } from 'react';
import {
  FILL_FAVORITE,
  FILL_FEATURED,
  FILL_HIGH_ACTIVITY,
  FILL_LOW_ACTIVITY,
} from './pin_low_activity';

interface PinLegendProps extends SVGProps<SVGSVGElement> {
  size: number;
  type: 'low_activity' | 'high_activity' | 'favorite' | 'featured';
}

export const PinLegend = (props: PinLegendProps) => {
  const { size, type, ...svgProps } = props;
  let fill = FILL_LOW_ACTIVITY;
  if (type === 'high_activity') {
    fill = FILL_HIGH_ACTIVITY;
  } else if (type === 'favorite') {
    fill = FILL_FAVORITE;
  } else if (type === 'featured') {
    fill = FILL_FEATURED;
  }
  return (
    <svg
      width={size * 0.75}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.719 19.61a308.849 308.849 0 0 0-2.617-3.75C2.8 13.983 1.94 12.721 1.523 12.07.925 11.133.521 10.345.313 9.707.104 9.07 0 8.333 0 7.5c0-1.354.338-2.604 1.015-3.75A7.643 7.643 0 0 1 3.75 1.016 7.24 7.24 0 0 1 7.5 0a7.24 7.24 0 0 1 3.75 1.016 7.643 7.643 0 0 1 2.734 2.734A7.24 7.24 0 0 1 15 7.5c0 .833-.104 1.57-.313 2.207-.208.638-.612 1.426-1.21 2.363-.417.651-1.277 1.915-2.579 3.79l-2.617 3.75c-.182.26-.443.39-.781.39-.339 0-.6-.13-.781-.39Z"
        fill={fill}
      />
    </svg>
  );
};
