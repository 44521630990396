import React from 'react';

const SvgReports = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    id="reports_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={2}
    y={0}
    viewBox="0 0 26 26"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <g
      transform="translate(1 1)"
      stroke="currentColor"
      strokeWidth={props?.strokeWidth || 1.2}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M16.5 6.7v10.5M9.9.6l6.4 6.2c0 .1 0 .1-.1.2h-6c-.1 0-.3-.1-.3-.3V.8c0-.2-.1-.3-.3-.3H.7C.6.5.4.6.4.8V22c0 .1.1.3.3.3h11.9m3.7.4 8.1-8.2-2.8-2.6-8 8.1-1.8 4.2c-.1.2.1.3.2.2l4.3-1.7zm-8.3-9v-3.6l-3.8 3.8c-.8.8-1.2 1.9-1 2.9.2.9.7 1.9 2.2 2 1.1.1 2.2-.4 3-1.1l8.1-8M13.6 20l2.7 2.7-2.7-2.7z" />
    </g>
  </svg>
);

export default SvgReports;
