import { Card } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './auth_form_wrapper.less';

const AuthFormWrapper: React.SFC<{ className?: string; cardClassName?: string }> = (props) => (
  <section className={classNames(styles.formWrapper, props.className)}>
    <Card className={classNames(styles.card, props.cardClassName)}>{props.children}</Card>
  </section>
);
export default AuthFormWrapper;
