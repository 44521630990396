import { useQueryString } from './use_query_string';
import { GlobalQueryParams } from '../lib/query_string';
import { attributionQueryParamToCustomAttributes } from '../lib/line_item_attribution';
import { LineItemCustomAttribute } from '../redux/cart';

export const useQueryParamLineItemAttribution = () => {
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.attribution]: string[];
  }>();
  const lineItemAttribution: LineItemCustomAttribute[] = attributionQueryParamToCustomAttributes(
    currentQuery.attribution || []
  );

  return lineItemAttribution || [];
};
