import { CalendarOutlined } from '@ant-design/icons';
import { OmniaVideoItem, ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import { Modal } from 'antd';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useEffect, useState } from 'react';
import { LoadingState } from '../../constants/loading_state';
import { useResponsive } from '../../hooks/use_responsive';
import { articleCardDate } from '../../lib/prismic';
import { WebAnalytics } from '../../lib/web_analytics';
import { MediaCard } from '../media_card/media_card';
import { OmniaVideoComponent } from '../omnia_video/omnia_video';
import { ProductDetailMedia } from './product_detail_images';
import styles from './product_detail_images.less';
import { ProductDetailImagesHero } from './product_detail_images_hero';
import { ProductDetailMediaCarousel } from './product_detail_media_carousel';

interface SmallImage {
  src: string;
  isFluidWidth: boolean;
  alt: string;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

interface LargeImage {
  width: number;
  height: number;
  src: string;
  alt: string;
}

export interface MagnifyProps {
  smallImage: SmallImage;
  largeImage: LargeImage;
  enlargedImagePosition: string;
  enlargedImageContainerClassName: string;
  isHintEnabled: boolean;
}

interface ProductDetailImagesModalProps {
  allMedia: ProductDetailMedia[];
  isModalOpen: boolean;
  omniaVideoItems: OmniaVideoItem[];
  omniaVideoItemsLoadingState: LoadingState;
  omniaVideoSlug: string;
  onInitialImageHeightChange: (height: number) => void;
  onModalClose: (panelIndex: number, selectedMedia: ProductDetailMedia) => void;
  onOmniaVideoSlugChange: (slug: string) => void;
  onOmniaVideoItemsChange: (items: OmniaVideoItem[]) => void;
  productFamily: ProductFamily;
  selectedPanelIndex: number;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
}
export const ProductDetailImagesModal = (props: ProductDetailImagesModalProps) => {
  const {
    allMedia,
    isModalOpen,
    omniaVideoItems,
    omniaVideoItemsLoadingState,
    omniaVideoSlug,
    onInitialImageHeightChange,
    onModalClose,
    onOmniaVideoSlugChange,
    productFamily,
    selectedPanelIndex,
    selectedVariant,
    shopifyProduct,
  } = props;

  const { isMobile, isUnderTablet, isDesktop } = useResponsive();
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      dragFree: true,
    },
    []
  );

  const [selectedPanelModalIndex, setSelectedPanelModalIndex] = useState(selectedPanelIndex);
  const [selectedModalMedia, setSelectedModalMedia] = useState<ProductDetailMedia>(
    allMedia[selectedPanelIndex]
  );

  useEffect(() => {
    if (isModalOpen) {
      const selectedMedia = allMedia[selectedPanelIndex];
      if (selectedMedia !== selectedModalMedia) {
        setSelectedModalMedia(selectedMedia);
      }

      if (selectedPanelIndex !== selectedPanelModalIndex) {
        setSelectedPanelModalIndex(selectedPanelIndex);
      }
    }
  }, [allMedia, selectedPanelIndex, isModalOpen, emblaApi]);

  useEffect(
    function scrollEmblaOnMount() {
      if (!emblaApi) {
        return;
      }
      emblaApi.scrollTo(selectedPanelIndex, true);
    },
    [emblaApi, selectedPanelIndex]
  );

  const hasProductMedia =
    selectedModalMedia.type === 'product_360_images' ||
    selectedModalMedia.type === 'product_video' ||
    selectedModalMedia.type === 'product_image';

  let published: string;
  let mediaTitle: string;
  if (selectedModalMedia.type === 'omnia_media') {
    const { media_type, media_item } = selectedModalMedia.media;
    published =
      media_type === 'OmniaVideo' ? media_item?.publication_date : media_item?.published_at;
    mediaTitle = media_item.title;
  }

  const onModalMediaClick = (pdpMedia: ProductDetailMedia, i: number) => {
    if (emblaApi) {
      emblaApi.scrollTo(i);
      setSelectedPanelModalIndex(i);
    }
    if (pdpMedia.type !== 'omnia_media') {
      setSelectedModalMedia(pdpMedia);
      WebAnalytics.productDetailPageClick('[product_images].(product_image_view)');
    } else {
      setSelectedModalMedia({
        type: 'omnia_media',
        src: pdpMedia.media.media_item.thumbnail,
        alt_text: pdpMedia.media.media_item.title,
        media: pdpMedia.media,
      });

      if (pdpMedia.media.media_type === 'OmniaVideo') {
        const newSlug = pdpMedia.media.media_item.slug;
        if (newSlug !== omniaVideoSlug) {
          onOmniaVideoSlugChange(newSlug);
        }
      }
    }
  };

  return (
    <Modal
      centered={isDesktop}
      className={styles.modal}
      destroyOnClose
      open={isModalOpen}
      width={isDesktop && 'min-content'}
      zIndex={999}
      bodyStyle={{
        padding: isMobile ? '0 12px 24px' : '0 24px',
      }}
      onCancel={() => onModalClose(selectedPanelModalIndex, selectedModalMedia)}
      footer={
        <div className={styles.miniMediaCarousel}>
          <ProductDetailMediaCarousel
            allMedia={allMedia}
            onMediaClick={(m: ProductDetailMedia, i: number) => onModalMediaClick(m, i)}
            selectedPanelIndex={selectedPanelModalIndex}
            showNewWindowIcon={true}
            emblaRef={emblaRef}
            emblaApi={emblaApi}
          />
        </div>
      }
      title={
        selectedModalMedia.type === 'omnia_media' ? (
          <div className={styles.text}>
            <p className={styles.title}>{mediaTitle}</p>
            <p className={styles.publicationDate}>
              <CalendarOutlined />
              {published && <span>{articleCardDate(published)}</span>}
            </p>
          </div>
        ) : (
          <div className={styles.text}>
            <p className={styles.title}>{selectedVariant.product.title}</p>
            <p className={styles.publicationDate}>{selectedVariant.title}</p>
          </div>
        )
      }
    >
      <div className={styles.modalContent}>
        {selectedModalMedia.type === 'omnia_media' && (
          <>
            {selectedModalMedia.media.media_type === 'OmniaVideo' && (
              <OmniaVideoComponent
                key={omniaVideoSlug}
                className={styles.omniaVideoContainer}
                omnia_video_items={omniaVideoItems}
                omnia_video={selectedModalMedia.media.media_item}
                omniaVideoItemsLoadingState={omniaVideoItemsLoadingState}
                openCartOnAdd
                shortMobile={isMobile}
                showVideoInfo={false}
                verticalLayout={isUnderTablet}
              />
            )}
            {(selectedModalMedia.media.media_type === 'BassUArticle' ||
              selectedModalMedia.media.media_type === 'PrismicVideo') && (
              <MediaCard
                media={selectedModalMedia.media}
                className={styles.mediaCardContainer}
                showMediaInfo={false}
                target="_blank"
                linkToNewPageIcon
              />
            )}
          </>
        )}

        <div className={styles.productMediaContainer}>
          {hasProductMedia && (
            <ProductDetailImagesHero
              selectedMedia={selectedModalMedia}
              isModalOpen={isModalOpen}
              productFamily={productFamily}
              initialImageHeight={0}
              key={
                Array.isArray(selectedModalMedia.src)
                  ? selectedModalMedia.src[0]
                  : selectedModalMedia.src
              }
              shopifyProduct={shopifyProduct}
              selectedVariant={selectedVariant}
              onInitialImageHeightChange={onInitialImageHeightChange}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
