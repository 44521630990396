import { ReactNode, useCallback, useState } from 'react';

/* this hacky hook is essentially an after animation callback.  Was adapted from this:
  https://stackoverflow.com/questions/55838351/how-do-we-know-when-a-react-ref-current-value-has-changed/60066291#60066291
  */

export const useFlickingRenderRef = () => {
  const [isRendered, setIsRendered] = useState(false);
  const renderCallbackRef = useCallback((node: ReactNode) => {
    if (node !== null) {
      setTimeout(() => {
        setIsRendered(true);
      }, 0);
    }
  }, []);

  return { isRendered, setIsRendered, renderCallbackRef };
};
