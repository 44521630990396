import React from 'react';
import { useSelector } from 'react-redux';
import { getIsMobile } from '../../redux/window';

// yoinked from https://www.joshwcomeau.com/react/modern-spacer-gif/
interface SpacerProps {
  size: number | [number, number];
  horizontal?: boolean;
  style?: React.CSSProperties;
}

export const Spacer = (props: SpacerProps) => {
  const isMobile = useSelector(getIsMobile);
  const { size, horizontal, style = {} } = props;
  const isHorz = horizontal === true;
  const hasMobileSize = Array.isArray(size);
  let width = 0;
  let height = 0;
  if (hasMobileSize) {
    width = !isHorz ? 1 : isMobile ? size[0] : size[1];
    height = isHorz ? 1 : isMobile ? size[0] : size[1];
  } else {
    width = !isHorz ? 1 : size;
    height = isHorz ? 1 : size;
  }

  return (
    <span
      style={{
        display: 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
    />
  );
};
