import { UserPrivate } from '@omniafishing/core';
import React from 'react';
import { roleAsText } from '../../lib/user';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';

interface AmbassadorBadgeProps {
  user: UserPrivate;
  className?: string;
}

export const AmbassadorBadge = (props: AmbassadorBadgeProps) => {
  const { user, className } = props;
  return (
    <span className={className} style={{ display: 'flex', gap: '4px' }}>
      OMNIA {roleAsText(user?.role, '').toUpperCase()}
      <AmbassadorColorDot user={user} dotSize={8} />
    </span>
  );
};
