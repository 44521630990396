import {
  FishingReport,
  List,
  OmniaVideo,
  ProductFamily,
  WaterbodyDetail,
} from '@omniafishing/core';
import _ from 'lodash';
import Prismic from 'prismic-javascript';
import { Document } from 'prismic-javascript/types/documents';
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi';
import { RichTextBlock } from 'prismic-reactjs';
import { RoutePaths } from './routes';

export const prismicOmniaInstance = () => Prismic.api('https://omniafishingcom.prismic.io/api/v2');
export const prismicBassUInstance = () => Prismic.api('https://bassu.prismic.io/api/v2');
export const prismicNewsInstance = () => Prismic.api('https://omnia-news.prismic.io/api/v2');

export enum PrismicDocumentTypes {
  ABOUT_PAGE = 'about_page',
  ADVISORY_BOARD = 'advisory_board',
  ARTICLE = 'article',
  BLACK_FRIDAY_CYBER_MONDAY_PAGE = 'black_friday__cyber_monday_page',
  DEALS = 'deals',
  FAQS_PAGE = 'faqs_page',
  FISHING_REPORTS_PAGE = 'fishing_reports_page',
  FISHING_REPORTS_RULES = 'fishing_report_contest_rules__results',
  JOBS_PAGE = 'jobs',
  QA = 'qa',
  SHIPPING = 'shipping',
  VIDEO = 'video',
  WISHLIST_CONTEST = 'wishlist_contest',
}

export enum PrismicNewsDocumentTypes {
  ARTICLE = 'article',
}

export enum PrismicDocumentTags {
  HOME = 'home',
}

export enum PrismicTagPrefixes {
  AMBASSADOR = 'ambassador:',
  BRAND = 'brand:',
  CONTRIBUTOR = 'contributor:',
  LIST = 'list:',
  PRODUCT = 'product:',
  SERIES = 'series:',
  SPECIES = 'species:',
  STATE = 'state:',
  STYLE = 'style:',
  SUBCATEGORY = 'subcat:',
  SUBCAT_TYPE = 'subcat-type:',
  WATERBODY = 'lake:',
}

interface PrismicImg {
  alt: string;
  copyright: string;
  dimensions: {
    height: number;
    width: number;
  };
  url: string;
}

interface PrismicLink {
  link_type: 'Web' | 'Any';
  url: string;
}

export interface PrismicSliceText {
  slice_type: 'text';
  primary: {
    text: RichTextBlock[];
  };
}

interface PrismicSliceList {
  slice_type: 'list';
  primary: {
    list: List;
    list_expanded: 'expanded' | 'collapsed';
  };
}

interface PrismicSliceWaterbody {
  slice_type: 'waterbody';
  primary: {
    waterbody: WaterbodyDetail;
  };
}

interface PrismicSliceProductFamily {
  slice_type: 'product_family';
  primary: {
    product_family: ProductFamily;
    product_sku: string | null;
    card_type: 'product_family' | 'product' | null;
  };
}

interface PrismicSliceRawHtml {
  slice_type: 'raw_html';
  primary: {
    html: string;
  };
}

export interface PrismicSliceFishingReport {
  slice_type: 'fishing_report';
  primary: {
    fishing_report: FishingReport;
  };
}

export interface PrismicSliceOmniaVideo {
  slice_type: 'omnia_video';
  primary: {
    omnia_video: OmniaVideo;
  };
}

export interface PrismicCTA {
  button_text: RichTextBlock[];
  button_type: 'primary' | 'secondary' | 'tertiary';
  align: 'left' | 'center';
  url: Record<'url', string>;
}

interface PrismicSliceCTA {
  slice_type: 'cta_button';
  primary: PrismicCTA;
}

interface PrismicSliceFishingReportForm {
  slice_type: 'fishing_report_form';
  primary: null;
}

interface PrismicSliceUsStateMap {
  slice_type: 'us_states_map';
  primary: null;
}

export interface PrismicSliceWistiaVideo {
  slice_type: 'wistia_video';
  primary: {
    video_wistia: {
      html: string;
      embed_url: 'https://omniafishing.wistia.com/medias/34wbq0xl6x';
      // version: '1.0';
      // type: 'video';
      // width: 640;
      // height: 1138;
      // provider_name: 'Wistia, Inc.';
      // provider_url: 'https://wistia.com';
      // title: 'SP Garmin Pike Video.m4v';
      // thumbnail_url: 'https://embed-ssl.wistia.com/deliveries/ba20ddec6dd0a60d678c446f1b42a5002f169db9.jpg?image_crop_resized=640x1138';
      // thumbnail_width: 640;
      // thumbnail_height: 1138;
      // player_color: '54bbff';
      // duration: 45.2;
    };
  };
}

export interface PrismicSliceJob {
  slice_type: 'job';
  primary: {
    title: RichTextBlock[];
    text: RichTextBlock[];
  };
}

export type PrismicSlices =
  | PrismicSliceText
  | PrismicSliceList
  | PrismicSliceWaterbody
  | PrismicSliceProductFamily
  | PrismicSliceWistiaVideo
  | PrismicSliceJob
  | PrismicSliceRawHtml
  | PrismicSliceFishingReport
  | PrismicSliceCTA
  | PrismicSliceFishingReportForm
  | PrismicSliceUsStateMap
  | PrismicSliceOmniaVideo;

export function isTypePrismicFishingReport(
  value: PrismicSlices
): value is PrismicSliceFishingReport {
  return value.primary.hasOwnProperty('fishing_report');
}

export interface PrismicArticle {
  article_summary: RichTextBlock[];
  article_text: RichTextBlock[];
  body: PrismicSlices[];
  hero_img: PrismicImg;
  json_ld: RichTextBlock[];
  meta_description: string;
  meta_title: string;
  publication_date: string;
  source: 'omnia' | 'bass_utopia';
  thumbnail: PrismicImg;
  title: RichTextBlock[];
  wistia_video: {
    html: string;
  };
}

export interface PrismicPage {
  body: PrismicSlices[];
  meta_description: string;
  meta_title: string;
}

export interface PrismicQa {
  question: RichTextBlock[];
  answer: RichTextBlock[];
}

export const linkResolver = (doc: Document) => {
  const docType = doc.type as PrismicDocumentTypes;
  if (docType === PrismicDocumentTypes.ARTICLE) {
    return `${RoutePaths.ARTICLES}/${doc.uid}`;
  }

  throw new Error('Unknown document type encountered');
};

export const filterAndStripTagsByPrefix = (tags: string[], prefix: PrismicTagPrefixes) => {
  return tags.filter((tag) => tag.indexOf(prefix) > -1).map((tag) => tag.replace(prefix, ''));
};

export const getBassULink = (uid: string) => {
  return `https://bassutopia.com/articles/${uid}`;
};

// for unreleased V1 of prismic-react

// const myCustomLink = (type: PrismicDocumentTypes, document: Document, content: any) => (
//   <Link key={document.data.uid} to={linkResolver(document.data)}>
//     <a>{content}</a>
//   </Link>
// );

export interface PrismicNewsArticle {
  action_button_text: string;
  action_url: PrismicLink;
  body: PrismicSlices[];
  meta_description: string;
  meta_title: string;
  publication_date: string;
  summary: RichTextBlock[];
  title: RichTextBlock[];
}

export interface PrismicDocument<T = any> extends Document {
  data: T;
}

export interface PrismicVideo {
  body: (
    | PrismicSliceText
    | PrismicSliceList
    | PrismicSliceWaterbody
    | PrismicSliceProductFamily
    | PrismicSliceWistiaVideo
    | PrismicSliceRawHtml
  )[];
  meta_description: string;
  meta_title: string;
  publication_date: string;
  summary: RichTextBlock[];
  thumbnail: PrismicImg;
  title: RichTextBlock[];
}

export enum PrismicVideoTags {
  WHATS_NEW = 'whats-new',
  FEATURES = 'features',
  PRODUCT_REVIEWS = 'product-reviews',
}

export function isPrismicArticle(
  thing: PrismicDocument<PrismicArticle> | PrismicDocument<PrismicVideo> | OmniaVideo
): thing is PrismicDocument<PrismicArticle> {
  return (
    (thing as PrismicDocument<PrismicArticle>).data != null &&
    (thing as PrismicDocument<PrismicArticle>).data.source !== undefined
  );
}
export function isPrismicVideo(
  thing: PrismicDocument<PrismicArticle> | PrismicDocument<PrismicVideo> | OmniaVideo
): thing is PrismicDocument<PrismicVideo> {
  return !isOmniaVideo(thing) && !isPrismicArticle(thing);
}
export function isOmniaVideo(
  thing: PrismicDocument<PrismicArticle> | PrismicDocument<PrismicVideo> | OmniaVideo
): thing is OmniaVideo {
  return (thing as OmniaVideo).mux_playback_id !== undefined;
}

export function orderContentByDate(
  content: (PrismicDocument<PrismicArticle> | PrismicDocument<PrismicVideo> | OmniaVideo)[]
) {
  return _.orderBy(
    content,
    (c) => {
      if (isOmniaVideo(c)) {
        return new Date(c.publication_date).getTime();
      }
      return new Date(c.data.publication_date).getTime();
    },
    'desc'
  );
}

// ========================================================================== //
// Prismic Omnia API
// ========================================================================== //

const qasFetchByTag = async (tag: string, pageSize = 50) => {
  const predicates: any = [
    Prismic.Predicates.at('document.type', PrismicDocumentTypes.QA),
    Prismic.Predicates.at('document.tags', [tag]),
  ];

  const options: QueryOptions = {
    orderings: `[my.${PrismicDocumentTypes.QA}.display_order]`,
    pageSize,
  };

  const api = await prismicOmniaInstance();
  return api.query(predicates, options);
};

export const prismicOmniaApi = {
  qasFetchByTag,
};
