import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { hasAccessToken } from '../../redux/auth';

interface Props {
  to?: string;
  children: React.ReactNode;
}

export const GuestRedirect = (props: Props) => {
  const { to, children } = props;
  const isLoggedIn = useSelector(hasAccessToken);
  if (!isLoggedIn) {
    return <Redirect to={to} />;
  }
  return <>{children}</>;
};
