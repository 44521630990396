import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLayerMapPin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} viewBox="0 0 36 36" {...props}>
    <path
      d="M18 3.685c1.938 0 3.728.485 5.368 1.454a10.933 10.933 0 0 1 3.914 3.914c.969 1.64 1.454 3.43 1.454 5.368 0 1.193-.149 2.246-.448 3.16-.298.913-.876 2.041-1.733 3.383-.597.932-1.827 2.74-3.691 5.424l-3.746 5.368c-.261.372-.634.559-1.118.559s-.858-.187-1.118-.56a443.638 443.638 0 0 0-3.746-5.368c-1.864-2.684-3.094-4.491-3.691-5.424-.857-1.342-1.435-2.469-1.733-3.383-.298-.913-.448-1.967-.448-3.16 0-1.938.484-3.728 1.454-5.368a10.943 10.943 0 0 1 3.915-3.914c1.64-.969 3.43-1.454 5.368-1.454m0-2.9a13.27 13.27 0 0 0-6.844 1.858 13.858 13.858 0 0 0-4.942 4.945c-1.226 2.075-1.851 4.378-1.851 6.833 0 1.494.199 2.86.591 4.06.377 1.155 1.046 2.478 2.045 4.043.618.965 1.845 2.769 3.753 5.517a441.212 441.212 0 0 1 3.738 5.356c.813 1.162 2.087 1.817 3.509 1.817s2.693-.654 3.492-1.793l3.751-5.374c1.904-2.742 3.131-4.545 3.755-5.52 1.001-1.568 1.67-2.891 2.048-4.047.392-1.2.591-2.566.591-4.059 0-2.456-.625-4.758-1.858-6.844a13.85 13.85 0 0 0-4.946-4.941C22.758 1.408 20.455.783 18 .783Z"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default SvgLayerMapPin;
