import { apiV1 } from '../../lib/api';
import { FishingReport } from '@omniafishing/core';
import { getIpLatitude, getIpLongitude } from '../../redux/geographic_location';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useNearbyFishingReports = (selectedProductSku: string) => {
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);

  const [nearbyFishingReports, setNearbyFishingReports] = useState<FishingReport[]>([]);

  useEffect(() => {
    if (selectedProductSku && ipLongitude && ipLatitude) {
      apiV1
        .productFishingReportsNearby({
          sku: selectedProductSku,
          lat: ipLatitude,
          lng: ipLongitude,
        })
        .then((res) => {
          const nearbyReports = res.data.data;
          setNearbyFishingReports(nearbyReports);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedProductSku, ipLongitude, ipLatitude]);

  return nearbyFishingReports;
};
