import type { SVGProps } from 'react';
import * as React from 'react';
const SvgLayerWaterTempRealTime = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 38 38" {...props}>
    <g>
      <path
        d="M28.535 33.444c3.932 0 7.095-3.08 7.095-6.931 0-2.13-1.065-4.211-1.966-5.899l-3.654-6.8c-.393-.737-.754-1.049-1.475-1.049-.737 0-1.098.311-1.491 1.049l-3.621 6.8c-.901 1.688-1.966 3.769-1.966 5.899 0 3.851 3.146 6.931 7.079 6.931Zm0-2.163c-2.736 0-4.916-2.097-4.916-4.768 0-1.622.836-3.195 1.721-4.883l3.048-5.653c.082-.164.229-.164.328 0l2.999 5.653c.901 1.688 1.753 3.261 1.753 4.883 0 2.671-2.196 4.768-4.932 4.768ZM7.02 13.617c7.292 0 11.241-6.177 18.106-6.177 1.606 0 3.031.36 4.703 1.311.705.393 1.36.131 1.704-.36.41-.59.36-1.475-.557-2.015-1.852-1.065-3.752-1.573-5.85-1.573-7.439 0-11.355 6.177-18.106 6.177-1.622 0-3.048-.36-4.719-1.311-.705-.393-1.36-.131-1.704.377-.377.59-.344 1.475.557 1.999 1.852 1.065 3.752 1.573 5.866 1.573Zm.017 8.799c6.489 0 10.356-4.965 16.042-5.948.508-.967 1.032-1.901 1.54-2.868-7.275.344-10.962 6.177-17.582 6.177-1.622 0-3.048-.377-4.719-1.327-.688-.393-1.36-.131-1.688.377-.393.59-.36 1.475.541 1.999 1.852 1.065 3.769 1.589 5.866 1.589Zm-.017 8.783c4.948 0 8.389-2.917 12.273-4.703-.082-.721.115-2.015.41-2.982-4.359 1.753-7.767 5.047-12.683 5.047-1.622 0-3.048-.36-4.719-1.327-.705-.393-1.36-.131-1.704.377-.377.59-.344 1.491.557 1.999 1.852 1.082 3.752 1.589 5.866 1.589"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgLayerWaterTempRealTime;
