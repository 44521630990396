import { UserOutlined } from '@ant-design/icons';
import { UserRoles } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { getImgixPath } from '../../lib/imgix';
import styles from './user_profile_img_ringed.less';

interface UserProfileImgProps {
  image: string;
  imageSize: number;
  role: UserRoles;
  borderSize: number;
  size?: number;
  style?: React.CSSProperties;
}

export const UserProfileImgRinged = (props: UserProfileImgProps) => {
  const { role, image, imageSize, borderSize, size, style } = props;

  const offset = size ? size - borderSize * 2 : borderSize * 2;

  return (
    <div
      className={classNames(styles.imgWrapper, {
        [styles.bronze]: role === 'ambassador_bronze',
        [styles.silver]: role === 'ambassador_silver',
        [styles.gold]: role === 'ambassador_gold',
        [styles.blue]: role === 'ambassador_blue',
      })}
      style={{
        ...style,
        padding: borderSize,
        width: size,
      }}
    >
      <div
        className={classNames(styles.ring, {
          [styles.bronze]: role === 'ambassador_bronze',
          [styles.silver]: role === 'ambassador_silver',
          [styles.gold]: role === 'ambassador_gold',
          [styles.blue]: role === 'ambassador_blue',
        })}
        style={{
          margin: -borderSize,
        }}
      />
      {image != null ? (
        <img
          className={styles.img}
          src={getImgixPath(image, {
            fit: 'crop',
            mask: 'ellipse',
            w: imageSize,
            h: imageSize,
          })}
          loading="lazy"
          width={size || imageSize}
          height={size || imageSize}
        />
      ) : (
        <div
          className={styles.imgDefault}
          style={{
            width: offset,
            height: offset,
          }}
        >
          <UserOutlined />
        </div>
      )}
    </div>
  );
};
