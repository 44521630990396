import * as React from 'react';

const SvgStar = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m8 0 2.168 5.016 5.44.512-4.1 3.612 1.194 5.332L8 11.688l-4.702 2.784L4.492 9.14l-4.1-3.612 5.44-.512L8 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgStar;
