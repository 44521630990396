import { useState, useCallback } from 'react';

const useImageLoader = () => {
  const [isImageLoading, setImageLoading] = useState(true);
  const [isImageError, setImageError] = useState(false);

  const handleImageLoad = useCallback(() => {
    setImageLoading(false);
    setImageError(false);
  }, []);

  const handleImageReload = useCallback(() => {
    setImageLoading(true);
    setImageError(false);
  }, []);

  const handleImageError = useCallback(() => {
    setImageLoading(false);
    setImageError(true);
  }, []);

  return { isImageLoading, isImageError, handleImageLoad, handleImageReload, handleImageError };
};

export default useImageLoader;
