export interface EnvConfig {
  AERIS_ID: string;
  AERIS_SECRET_KEY: string;
  ALGOLIA_APPLICATION_ID: string;
  ALGOLIA_INDEX_AMBASSADORS: string;
  ALGOLIA_INDEX_ARTICLES: string;
  ALGOLIA_INDEX_BRANDS: string;
  ALGOLIA_INDEX_MEDIA_DATE_DESC: string;
  ALGOLIA_INDEX_MEDIA: string;
  ALGOLIA_INDEX_PRODUCT_FAMILIES: string;
  ALGOLIA_INDEX_PRODUCTS: string;
  ALGOLIA_INDEX_SPECIES: string;
  ALGOLIA_INDEX_TECHNIQUES: string;
  ALGOLIA_INDEX_VIDEOS: string;
  ALGOLIA_INDEX_WATERBODIES: string;
  ALGOLIA_SEARCH_KEY: string;
  API_BASE_URL: string;
  INTERCOM_APP_ID: string;
  IP_STACK_KEY: string;
  MAPBOX_ACCESS_TOKEN: string;
  RUDDERSTACK_DATA_PLANE: string;
  RUDDERSTACK_WRITE_KEY: string;
  SOCIALMAP_KEY: string;
  STATSIG_CLIENT_KEY: string;
  TRACKJS_APPLICATION: string;
  TRACKJS_ENABLED: boolean;
}

const DevEnv: EnvConfig = {
  AERIS_ID: 'clu1Mn5qk25xEyPaD3rZv',
  AERIS_SECRET_KEY: 'RmjIN0D6mgnJhb55z2Ay0Jw0xeUle2oT6nqAeUIN',
  ALGOLIA_APPLICATION_ID: '7HTPTMYFCQ',
  ALGOLIA_INDEX_AMBASSADORS: 'ambassadors_dev',
  ALGOLIA_INDEX_ARTICLES: 'articles_dev',
  ALGOLIA_INDEX_BRANDS: 'brands_dev',
  ALGOLIA_INDEX_MEDIA_DATE_DESC: 'media_dev_date_desc',
  ALGOLIA_INDEX_MEDIA: 'media_dev',
  ALGOLIA_INDEX_PRODUCT_FAMILIES: 'product_families_dev',
  ALGOLIA_INDEX_PRODUCTS: 'products_dev',
  ALGOLIA_INDEX_SPECIES: 'species_dev',
  ALGOLIA_INDEX_TECHNIQUES: 'techniques_dev',
  ALGOLIA_INDEX_VIDEOS: 'videos_dev',
  ALGOLIA_INDEX_WATERBODIES: 'waterbody_details_dev',
  ALGOLIA_SEARCH_KEY: '2284b0a765693d886e378f96caf98c9f',
  API_BASE_URL:
    typeof window !== 'undefined'
      ? `//${window.location.hostname}:3000/`
      : 'http://localhost:3000/',
  INTERCOM_APP_ID: 'ao8g92nc',
  IP_STACK_KEY: 'c604c50397009740722fb243b41a1d2e',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1Ijoib21uaWFmaXNoaW5nIiwiYSI6ImNqbHk0aTBkeDBpNDEzcW1nODAyZnYxMWMifQ.71E8mNoUSkBTfL2HAjV-LA',
  RUDDERSTACK_DATA_PLANE: 'https://omniafishivdvt.dataplane.rudderstack.com',
  RUDDERSTACK_WRITE_KEY: '2dbD78YpCJZdc6eSskEFQm4ved4',
  SOCIALMAP_KEY: '2d2538f2-0647-44ac-a2bb-32630aedbd9f',
  STATSIG_CLIENT_KEY: 'client-4gDS5h9YVIGWz9XpUb0zITIG3Xb3UcoKQmWXXT4bb57',
  TRACKJS_APPLICATION: 'staging',
  TRACKJS_ENABLED: false,
};

const StageEnv: EnvConfig = {
  AERIS_ID: 'clu1Mn5qk25xEyPaD3rZv',
  AERIS_SECRET_KEY: 'RmjIN0D6mgnJhb55z2Ay0Jw0xeUle2oT6nqAeUIN',
  ALGOLIA_APPLICATION_ID: '7HTPTMYFCQ',
  ALGOLIA_INDEX_AMBASSADORS: 'ambassadors_dev',
  ALGOLIA_INDEX_ARTICLES: 'articles_dev',
  ALGOLIA_INDEX_BRANDS: 'brands_dev',
  ALGOLIA_INDEX_MEDIA_DATE_DESC: 'media_dev_date_desc',
  ALGOLIA_INDEX_MEDIA: 'media_dev',
  ALGOLIA_INDEX_PRODUCT_FAMILIES: 'product_families_dev',
  ALGOLIA_INDEX_PRODUCTS: 'products_dev',
  ALGOLIA_INDEX_SPECIES: 'species_dev',
  ALGOLIA_INDEX_TECHNIQUES: 'techniques_dev',
  ALGOLIA_INDEX_VIDEOS: 'videos_dev',
  ALGOLIA_INDEX_WATERBODIES: 'waterbody_details_dev',
  ALGOLIA_SEARCH_KEY: '2284b0a765693d886e378f96caf98c9f',
  API_BASE_URL: 'https://stage-api.omniafishing.com/',
  INTERCOM_APP_ID: 'ao8g92nc',
  IP_STACK_KEY: 'c604c50397009740722fb243b41a1d2e',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1Ijoib21uaWFmaXNoaW5nIiwiYSI6ImNqbHk0aTBkeDBpNDEzcW1nODAyZnYxMWMifQ.71E8mNoUSkBTfL2HAjV-LA',
  RUDDERSTACK_DATA_PLANE: 'https://omniafishivdvt.dataplane.rudderstack.com',
  RUDDERSTACK_WRITE_KEY: '2dbD78YpCJZdc6eSskEFQm4ved4',
  SOCIALMAP_KEY: '2d2538f2-0647-44ac-a2bb-32630aedbd9f',
  STATSIG_CLIENT_KEY: 'client-4gDS5h9YVIGWz9XpUb0zITIG3Xb3UcoKQmWXXT4bb57',
  TRACKJS_APPLICATION: 'staging',
  TRACKJS_ENABLED: true,
};

const ProdEnv: EnvConfig = {
  AERIS_ID: 'clu1Mn5qk25xEyPaD3rZv',
  AERIS_SECRET_KEY: 'RmjIN0D6mgnJhb55z2Ay0Jw0xeUle2oT6nqAeUIN',
  ALGOLIA_APPLICATION_ID: '7HTPTMYFCQ',
  ALGOLIA_INDEX_AMBASSADORS: 'ambassadors_prod',
  ALGOLIA_INDEX_ARTICLES: 'articles_prod',
  ALGOLIA_INDEX_BRANDS: 'brands_prod',
  ALGOLIA_INDEX_MEDIA_DATE_DESC: 'media_prod_date_desc',
  ALGOLIA_INDEX_MEDIA: 'media_prod',
  ALGOLIA_INDEX_PRODUCT_FAMILIES: 'product_families_prod',
  ALGOLIA_INDEX_PRODUCTS: 'products_prod',
  ALGOLIA_INDEX_SPECIES: 'species_prod',
  ALGOLIA_INDEX_TECHNIQUES: 'techniques_prod',
  ALGOLIA_INDEX_VIDEOS: 'videos_prod',
  ALGOLIA_INDEX_WATERBODIES: 'waterbody_details_prod',
  ALGOLIA_SEARCH_KEY: '2284b0a765693d886e378f96caf98c9f',
  API_BASE_URL: 'https://prod-api.omniafishing.com/',
  INTERCOM_APP_ID: 'ao8g92nc',
  IP_STACK_KEY: 'c604c50397009740722fb243b41a1d2e',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1Ijoib21uaWFmaXNoaW5nIiwiYSI6ImNqbHk0aTBkeDBpNDEzcW1nODAyZnYxMWMifQ.71E8mNoUSkBTfL2HAjV-LA',
  RUDDERSTACK_DATA_PLANE: 'https://rsdata.omnia.direct',
  RUDDERSTACK_WRITE_KEY: '2gSfEpVcBewYUVyFu5HQE8HANp4',
  SOCIALMAP_KEY: '2d2538f2-0647-44ac-a2bb-32630aedbd9f',
  STATSIG_CLIENT_KEY: 'client-4gDS5h9YVIGWz9XpUb0zITIG3Xb3UcoKQmWXXT4bb57',
  TRACKJS_APPLICATION: 'production',
  TRACKJS_ENABLED: true,
};

export enum ServerEnvs {
  LOCAL = 'LOCAL',
  STAGE = 'STAGE',
  PRODUCTION = 'PRODUCTION',
}

export const getServerEnv = (): ServerEnvs => {
  if (typeof window === 'undefined') {
    const location = process.env.SERVER_NAME;
    switch (location) {
      case 'production':
        return ServerEnvs.PRODUCTION;
      case 'staging':
        return ServerEnvs.STAGE;
      default:
        return ServerEnvs.LOCAL;
    }
  } else {
    switch (window.location.hostname) {
      case 'www.omniafishing.com':
      case 'omniafishing.com':
      case 'prod-al2.omniafishing.com':
        return ServerEnvs.PRODUCTION;
      case 'stage.omniafishing.com':
      case 'stage.omnia.wtf':
        return ServerEnvs.STAGE;
      default:
        return ServerEnvs.LOCAL;
    }
  }
};

export const isLocalhost = () => {
  return getServerEnv() === ServerEnvs.LOCAL;
};

export const isProd = () => {
  return getServerEnv() === ServerEnvs.PRODUCTION;
};

export const getEnv = (): EnvConfig => {
  const location = getServerEnv();
  switch (location) {
    case ServerEnvs.PRODUCTION:
      return ProdEnv;
    case ServerEnvs.STAGE:
      return StageEnv;
    default:
      return DevEnv;
  }
};

export const isStagingOrLocal =
  getServerEnv() === ServerEnvs.STAGE || getServerEnv() === ServerEnvs.LOCAL;
