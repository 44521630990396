import { FishingReport, Product, SignupParams } from '@omniafishing/core';
import { Divider, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Cookies from 'js-cookie';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isPending, LoadingState } from '../../constants/loading_state';
import { useFlashMessage } from '../../hooks/use_flash_message';
import { usePrevious } from '../../hooks/use_previous';
import { useQueryString } from '../../hooks/use_query_string';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { useUserCampaignInfo } from '../../hooks/use_user_campaign_info';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { apiV1 } from '../../lib/api';
import { objectToFormData } from '../../lib/form';
import { OmniaQueryParams } from '../../lib/query_string';
import getNow from '../../lib/time';
import { WebAnalytics } from '../../lib/web_analytics';
import { AuthActions, getAccessToken } from '../../redux/auth';
import { CookieKeys, getCookieConfig } from '../../redux/cookie_storage_middleware';
import {
  FishingReportModalActions,
  getFishingReport,
  getWaterbody,
  isOpen,
} from '../../redux/fishing_report_modal';
import { getIpState } from '../../redux/geographic_location';
import { getSeasonGroupBySeasonName } from '../../redux/reference_data';
import { getExperimentUuid, UserActions } from '../../redux/user';
import { getIsWebview } from '../../redux/window';
import { RoutePaths } from '../../routes';
import { OmniaButton } from '../omnia_button/omnia_button';
import { FishingReportForm, formFields } from './fishing_report_form';
import styles from './fishing_report_modal.less';

export enum FishingReportModalParams {
  open = 'FishingReportModal_open',
  hotbait = 'FishingReportModal_hotbait',
}

const getAccessTokenExpiration = (expiresInSeconds: number) => {
  const expiresInMs = expiresInSeconds * 1000;
  return getNow() + expiresInMs;
};

export const setAuthCookies = (
  accessToken: string,
  refreshToken: string,
  expiresInSeconds: number
) => {
  const accessTokenExpiration = getAccessTokenExpiration(expiresInSeconds);
  Cookies.set(CookieKeys.refreshToken, refreshToken, getCookieConfig());
  Cookies.set(CookieKeys.accessToken, accessToken, getCookieConfig());
  Cookies.set(
    CookieKeys.accessTokenExpiration,
    JSON.stringify(accessTokenExpiration),
    getCookieConfig()
  );
};

export type AuthError = 'email_exists' | 'invalid_password' | 'unknown_error';

export const FishingReportModal = () => {
  const dispatch = useDispatch();
  const formRef = React.createRef<FormInstance>();
  const fishingReport = useSelector(getFishingReport);
  const isEdit = fishingReport != null;
  const { setFlashMessage } = useFlashMessage();
  const waterbody = useSelector(getWaterbody);
  const open = useSelector(isOpen);
  const location = useLocation<{ from: string }>();
  const isLoggedIn = useSelector(getAccessToken);
  const ip_state = useSelector(getIpState);
  const history = useHistory();
  const prevOpen = usePrevious(open);
  const isWebview = useSelector(getIsWebview);
  const seasonGroupBySeasonName = useSelector(getSeasonGroupBySeasonName);
  const { setUserPreferencesSeasonGroup, setUserPreferencesSpecies, setUserPreferencesTechnique } =
    useUserPreferences();
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [FishingReportModalParams.hotbait]: string;
    [FishingReportModalParams.open]: boolean;
  }>();

  const [authError, setAuthError] = useState<AuthError>();
  const [authLoadingState, setAuthLoadingState] = useState(LoadingState.NOT_STARTED);
  const [featuredProductFromQueryParams, setFeaturedProductFromQueryParams] = useState(
    null as Product
  );
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const [selectedHotbait, setSelectedHotbait] = useState<Product>(null);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [videoUploading, setVideoUploading] = useState(false);

  const queryParamFeaturedProductSku = currentQuery[FishingReportModalParams.hotbait];
  const queryParamOpen = currentQuery[FishingReportModalParams.open] === true;
  const userCampaignInfo = useUserCampaignInfo();
  const { anonymousId } = useRudderstack();
  const experimentUuid = useSelector(getExperimentUuid);
  const fromPath = location.state?.from || RoutePaths.HOME;

  if (!prevOpen && open) {
    WebAnalytics.fishingReportStarted(waterbody?.url_slug);
  }

  const openModal = () => {
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN());
  };

  useEffect(() => {
    if (queryParamOpen) {
      if (queryParamFeaturedProductSku) {
        apiV1.productFetch(queryParamFeaturedProductSku).then((response) => {
          setFeaturedProductFromQueryParams(response.data.data);
          openModal();
        });
      } else {
        openModal();
      }
    }
  }, [queryParamFeaturedProductSku, queryParamOpen]);

  const onCancel = () => {
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_CLOSE());
    setAuthError(undefined);
    setLoadingState(LoadingState.NOT_STARTED);
    setAuthLoadingState(LoadingState.NOT_STARTED);
    setSelectedHotbait(null);
    setSelectedProducts([]);
    setVideoUploading(false);
  };

  const onSuccess = (report: FishingReport) => {
    const waterbody_name = report.waterbody.primary_name;

    WebAnalytics.fishingReportCompleted(report);

    setLoadingState(LoadingState.DONE);
    onCancel();
    if (isEdit) {
      setFlashMessage({
        header: 'Fishing Report Updated',
        subheader: `Your ${waterbody_name} fishing report has been updated`,
      });
    } else {
      setFlashMessage({
        header: 'Fishing Report Submitted',
        subheader: `Your ${waterbody_name} fishing report has been submitted`,
      });
    }
  };

  const onOk = () => {
    const form = formRef.current;
    const productsField = form.getFieldValue(formFields.products);
    if (selectedProducts?.length || productsField?.length) {
      form.setFieldsValue({
        [formFields.products]: selectedProducts.map((p) => p.sku),
      });
    }
    // this is for a bug for sentry issue #4598702883 where productsField or selectedProducts is somehow being submitted as an empty string
    if (productsField === '' || selectedProducts?.length === 0) {
      form.setFieldsValue({
        [formFields.products]: [],
      });
    }
    if (selectedHotbait) {
      form.setFieldsValue({
        [formFields.featured_product]: selectedHotbait?.sku,
      });
    }

    form
      .validateFields()
      .then(async (values: Record<keyof typeof formFields, any>) => {
        const { username, password } = values;
        setLoadingState(LoadingState.PENDING);

        if (values?.waterbody) {
          values.waterbody = values.waterbody.value;
        }

        const valuesWithPlatform = {
          ...values,
          platform: 'web',
        };
        const formDataWithUserCampaignInfo = {
          ...valuesWithPlatform,
          ...userCampaignInfo,
        };
        const userParams: SignupParams = {
          username,
          password,
          ip_state,
          organization_registration_code: null,
          experiment_group_id: 0,
          experiment_uuid: experimentUuid,
          anonymous_id: anonymousId,
          ...userCampaignInfo,
        };

        const formData = isEdit
          ? objectToFormData(valuesWithPlatform)
          : objectToFormData(formDataWithUserCampaignInfo);

        const imgFile = form.getFieldInstance(formFields.img)?.input?.files[0];
        if (imgFile != null) {
          formData.append('img', imgFile);
        }

        if (isEdit) {
          const res = await apiV1.userFishingReportUpdate(fishingReport.id, formData);
          onSuccess(res.data.data);
          dispatch(UserActions.USER_FISHING_REPORT_UPDATE_SUCCESS(res.data));
        } else {
          const waterbodyId = waterbody ? waterbody.waterbody_id : valuesWithPlatform.waterbody;
          formData.append('waterbody', waterbodyId);

          if (isLoggedIn) {
            try {
              const res = await apiV1.fishingReportCreate(formData);
              onSuccess(res.data.data);
              setLoadingState(LoadingState.DONE);
            } catch (err) {
              setLoadingState(LoadingState.ERROR);
            }
          } else {
            try {
              setAuthLoadingState(LoadingState.PENDING);
              if (authError) {
                const loginResponse = await apiV1.userLogin({
                  username,
                  password,
                  anonymous_id: anonymousId,
                });
                if (loginResponse.status === 200) {
                  const { access_token_expires_in_sec, access_token, refresh_token } =
                    loginResponse.data.data;
                  setAuthCookies(access_token, refresh_token, access_token_expires_in_sec);
                  const res = await apiV1.fishingReportCreate(formData);
                  dispatch(AuthActions.AUTH_LOGIN_SUCCESS(loginResponse.data));
                  onSuccess(res.data.data);
                  setAuthError(undefined);
                }
              } else {
                const signupResponse = await apiV1.userSignup(userParams);
                if (signupResponse.status === 201) {
                  const { access_token_expires_in_sec, access_token, refresh_token } =
                    signupResponse.data.data;
                  setAuthCookies(access_token, refresh_token, access_token_expires_in_sec);
                  const res = await apiV1.fishingReportCreate(formData);
                  dispatch(
                    AuthActions.AUTH_SIGNUP_SUCCESS({
                      ...signupResponse.data,
                      data: {
                        ...signupResponse.data.data,
                        ...userCampaignInfo,
                      },
                    })
                  );
                  WebAnalytics.followUpEnabled(username);
                  onSuccess(res.data.data);
                  history.push({
                    pathname: RoutePaths.SIGNUP_FOLLOWUP,
                    state: { from: fromPath, waterbody },
                    search: `?${OmniaQueryParams.species}=${values.species}`,
                  });
                }
              }
            } catch (e) {
              const errorCode = e.response.data.error_code;
              if (errorCode === 'USERNAME_EXISTS') {
                setAuthError('email_exists');
              } else if (errorCode === 'NOT_AUTHORIZED') {
                setAuthError('invalid_password');
              } else {
                setAuthError('unknown_error');
              }
              setAuthLoadingState(LoadingState.ERROR);
              setLoadingState(LoadingState.ERROR);
            } finally {
              setLoadingState(LoadingState.DONE);
              setAuthLoadingState(LoadingState.DONE);
            }
          }
          setUserPreferencesSeasonGroup(seasonGroupBySeasonName(valuesWithPlatform.season)?.name);
          setUserPreferencesSpecies(valuesWithPlatform.species);
          setUserPreferencesTechnique(valuesWithPlatform.style);
        }
      })
      .catch((e) => {
        const firstErrorField = e.errorFields?.[0];
        const firstErrorFieldName = firstErrorField?.name?.[0];
        if (firstErrorFieldName) {
          const modalWrap = document.getElementsByClassName('ant-modal-wrap')[0];
          const el = document.getElementById(firstErrorFieldName);
          const elTop = el.getBoundingClientRect().top;
          const labelPadding = 24;
          if (elTop < 0) {
            modalWrap.scrollTo({
              top: modalWrap.scrollTop + elTop - labelPadding,
              behavior: 'smooth',
            });
          }
        }
      });
  };

  const onOkDebounced = _.debounce(onOk, 500, {
    leading: true,
    trailing: false,
  });

  const isLoading = isPending(authLoadingState) || isPending(loadingState) || videoUploading;

  return (
    <Modal
      className={styles.modal}
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={!isWebview && open}
      title={isEdit ? 'Edit Your Fishing Report' : 'Submit a Fishing Report'}
    >
      <FishingReportForm
        authError={authError}
        featuredProductFromQueryParams={featuredProductFromQueryParams}
        fishingReport={fishingReport}
        formRef={formRef}
        isEdit={isEdit}
        loadingState={loadingState}
        onSelectedHotbaitChange={setSelectedHotbait}
        onSelectedProductsChange={setSelectedProducts}
        onUploadDone={() => setVideoUploading(false)}
        onUploadStart={() => setVideoUploading(true)}
        selectedHotbait={selectedHotbait}
        selectedProducts={selectedProducts}
        waterbody={waterbody}
      />
      <Divider />
      <div className={styles.modalFooter}>
        <OmniaButton
          kind="tertiary"
          size="lg"
          isDisabled={isPending(authLoadingState) || isPending(loadingState)}
          onPress={onCancel}
        >
          Cancel
        </OmniaButton>
        <OmniaButton kind="secondary" size="lg" onPress={onOkDebounced} loading={isLoading}>
          {videoUploading ? 'Video Uploading...' : isEdit ? 'Update' : 'Submit'}
        </OmniaButton>
      </div>
    </Modal>
  );
};
