import { Divider } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { ClickKey_Footer } from '../../lib/web_analytics';
import { RoutePaths } from '../../routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import styles from './footer.less';

interface SubFooterProps {
  webAnalyticsFooterHelper: (
    click_key: ClickKey_Footer,
    target_url?: string,
    isExternal_url?: boolean
  ) => void;
}

export const CURRENT_YEAR = new Date().getFullYear();

export const SubFooter = (props: SubFooterProps) => {
  const { webAnalyticsFooterHelper } = props;
  const location = useLocation();
  const { isMobile } = useResponsive();

  const addPadding = location?.pathname === RoutePaths.MAP && isMobile;

  return (
    <section
      className={classNames(styles.subfooter, { [styles.subfooter__extraPadding]: addPadding })}
    >
      <ContentWrapper>
        <ul className={styles.subfooterList}>
          <li className={styles.currentYear}>
            <span>&copy; {CURRENT_YEAR} Omnia Fishing Inc. All Rights Reserved</span>
          </li>
          {!isMobile && <Divider type="vertical" />}
          <li>
            <Link
              to={RoutePaths.TERMS}
              onClick={() => webAnalyticsFooterHelper('[footer].(legal)', RoutePaths.TERMS)}
            >
              Terms &amp; Conditions
            </Link>
          </li>
          <Divider type="vertical" />
          <li>
            <Link
              to={RoutePaths.PRIVACY}
              onClick={() => webAnalyticsFooterHelper('[footer].(legal)', RoutePaths.PRIVACY)}
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </ContentWrapper>
    </section>
  );
};
