import { OmniaVideo, OmniaVideoItem, Product } from '@omniafishing/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AlgoliaEvents } from '../../lib/algolia_events';
import { FacebookEvents } from '../../lib/facebook_events';
import { GoogleEvents } from '../../lib/google_events';
import { getLineItemCustomAttributes } from '../../lib/line_item_attributes';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import {
  addLineItems,
  CartActions,
  getCheckoutId,
  LineItemCustomAttribute,
  LINE_ATTRIBUTION,
} from '../../redux/cart';
import { getUser } from '../../redux/user';

export const webAnalyticsVideoItemClick = (
  omnia_video_item: OmniaVideoItem,
  position: number,
  omnia_video: OmniaVideo
) => {
  const { title, publication_date, slug, livestream } = omnia_video;
  WebAnalytics.videoShoppableItemClick({
    video_title: title,
    video_pub_date: publication_date,
    video_slug: slug,
    is_livestream: livestream,
    is_top_item: position === 0,
    item_id: omnia_video_item.item_id,
    item_type: omnia_video_item.item_type,
  });
};

export const useOmniaVideoAddToCart = () => {
  const user = useSelector(getUser);
  const location = useLocation();
  const checkoutId = useSelector(getCheckoutId);
  const dispatch = useDispatch();

  const omniaVideoAddToCart =
    (
      product: Product,
      position: number,
      omnia_video_item: OmniaVideoItem,
      omnia_video: OmniaVideo,
      list_uuid: string,
      openCart?: boolean
    ) =>
    () => {
      const { shopify_graphql_id, shopify_variant_id } = product;

      const lineItemCustomAttributes: LineItemCustomAttribute[] = [
        ...getLineItemCustomAttributes(location.pathname),
        {
          key: LINE_ATTRIBUTION.OMNIA_VIDEO_ID,
          value: omnia_video.id.toString(),
        },
      ];

      if (omnia_video.affiliate?.campaign_source) {
        lineItemCustomAttributes.push({
          key: LINE_ATTRIBUTION.AFFILIATE,
          value: omnia_video.affiliate.campaign_source,
        });
      }

      dispatch(
        addLineItems(
          checkoutId,
          [
            {
              quantity: 1,
              variantId: shopify_graphql_id,
              customAttributes: lineItemCustomAttributes,
            },
          ],
          'flash',
          `${product.title} was successfully added to your cart.`
        )
      );

      if (openCart) {
        dispatch(CartActions.CART_OPEN());
      }

      FacebookEvents.AddToCart([shopify_variant_id]);
      GoogleEvents.AddToCart([product]);
      AlgoliaEvents.AddToCart([product], user?.id?.toString());
      WebAnalytics.addToCart({
        product,
        area: WebAnalyticsEventAreas.MAIN_VIEW,
        position,
        list_uuid,
      });
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };

  return { omniaVideoAddToCart };
};
