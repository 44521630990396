import * as React from 'react';

const SvgHamburgerWithCaret = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20 8-3.464-5.25h6.928L20 8ZM0 0h13v2.053H0zM0 4.105h13v2.053H0zM0 8.211h13v2.053H0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHamburgerWithCaret;
