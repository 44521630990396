import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWaypointMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <circle cx={12.5} cy={12.5} r={11.5} fill="#10B981" stroke="#fff" strokeWidth={2} />
    <path
      fill="#fff"
      d="M12.5 10.47a1.52 1.52 0 0 0-1.083.45 1.526 1.526 0 0 0-.448 1.082 1.526 1.526 0 0 0 .945 1.415 1.526 1.526 0 0 0 1.669-.332c.29-.29.448-.674.448-1.083a1.52 1.52 0 0 0-.448-1.083 1.52 1.52 0 0 0-1.083-.448m4.684-.517a4.956 4.956 0 0 0-1.092-1.582A5.128 5.128 0 0 0 12.5 6.916c-.685 0-1.35.131-1.976.39a5.093 5.093 0 0 0-1.616 1.063 4.971 4.971 0 0 0-1.092 1.582 4.855 4.855 0 0 0-.402 1.942c0 .965.231 1.926.685 2.853a9.03 9.03 0 0 0 1.518 2.161A12.213 12.213 0 0 0 12.192 19a.6.6 0 0 0 .613 0 12.215 12.215 0 0 0 2.576-2.092 9.004 9.004 0 0 0 1.517-2.161 6.408 6.408 0 0 0 .688-2.852c0-.673-.135-1.326-.402-1.941M12.5 14.408a2.406 2.406 0 1 1 0-4.812 2.406 2.406 0 0 1 0 4.812"
    />
  </svg>
);
export default SvgWaypointMarkerIcon;
