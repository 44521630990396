import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { getQueryStringItem } from '../lib/query_string';

type ParamValues = string | string[] | number | number[] | boolean | boolean[];

export const omniaStringify = (params: Record<string, ParamValues>) => {
  return queryString.stringify(params, { arrayFormat: 'bracket' });
};

export function useQueryString() {
  const history = useHistory();
  const location = useLocation();

  const stringify = omniaStringify;

  function getCurrentQuery<ExpectedParams = ParsedQuery<string>>() {
    return queryString.parse(location.search, {
      parseBooleans: true,
      arrayFormat: 'bracket',
    }) as unknown as ExpectedParams;
  }

  const getQueryStringProperty = (property: string) => {
    return getQueryStringItem(location.search, property);
  };

  function replaceQueryString<Params = Record<string, ParamValues>>(params: Partial<Params>) {
    const newParams = {
      ...getCurrentQuery(),
      ...params,
    };
    history.replace({
      search: stringify(newParams),
    });
  }

  function pushQueryString<Params = Record<string, ParamValues>>(params: Partial<Params>) {
    const newParams = {
      ...getCurrentQuery(),
      ...params,
    };
    history.push({
      search: stringify(newParams),
    });
  }
  const currentQuery = getCurrentQuery();

  const FRAGMENTIDENTIFIER = 'fragmentIdentifier';
  // fragment identifier aka hash at the end of url
  const idHash = queryString.parseUrl(location.hash, { parseFragmentIdentifier: true })[
    FRAGMENTIDENTIFIER
  ];

  return {
    currentQuery,
    getCurrentQuery,
    replaceQueryString,
    pushQueryString,
    getQueryStringProperty,
    stringify,
    paramsString: location.search,
    idHash,
  };
}
