import {
  BestSeller,
  OmniaResponse,
  Product,
  RecommendedProductFamily,
  StateHotbait,
} from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1, RecommendationsFacets } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'landingPage';

export enum StateKeys {
  loadingState = 'loadingState',
  productFamilies = 'productFamilies',
  products = 'products',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.productFamilies]: [] as RecommendedProductFamily[],
  [StateKeys.products]: [] as Product[],
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getProductFamilies = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamilies];
export const getProducts = (state: ApplicationState) => state[reducerName][StateKeys.products];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function LandingPageReducer(state = initialState, action: LandingPageActions) {
  switch (action.type) {
    case ReduxActions.LANDING_PAGE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.LANDING_PAGE_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.productFamilies]: action.payload.data,
      };

    case ReduxActions.LANDING_PAGE_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    case ReduxActions.BEST_SELLERS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.BEST_SELLERS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.products]: action.payload.data.best_sellers.map((b) => b.product),
      };

    case ReduxActions.BEST_SELLERS_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const LandingPageActions = {
  LANDING_PAGE_FETCH_PENDING: () => createAction(ReduxActions.LANDING_PAGE_FETCH_PENDING),
  LANDING_PAGE_FETCH_SUCCESS: (response: OmniaResponse<RecommendedProductFamily[]>) =>
    createAction(ReduxActions.LANDING_PAGE_FETCH_SUCCESS, response),
  LANDING_PAGE_FETCH_ERROR: (err: any) => createAction(ReduxActions.LANDING_PAGE_FETCH_ERROR, err),

  BEST_SELLERS_FETCH_PENDING: () => createAction(ReduxActions.BEST_SELLERS_FETCH_PENDING),
  BEST_SELLERS_FETCH_SUCCESS: (
    response: OmniaResponse<{ best_sellers: BestSeller[]; hotbaits: StateHotbait[] }>
  ) => createAction(ReduxActions.BEST_SELLERS_FETCH_SUCCESS, response),
  BEST_SELLERS_FETCH_ERROR: (err: any) => createAction(ReduxActions.BEST_SELLERS_FETCH_ERROR, err),
};
export type LandingPageActions = ActionsUnion<typeof LandingPageActions>;

export function fetchLandingPage(
  slug: string,
  params: {
    [RecommendationsFacets.styles]?: string[];
    [RecommendationsFacets.subcategories]?: string[];
  }
): RequestThunk {
  return (dispatch) => {
    dispatch(LandingPageActions.LANDING_PAGE_FETCH_PENDING());

    return apiV1
      .landingPageFetch(slug, params)
      .then((response) => {
        return dispatch(LandingPageActions.LANDING_PAGE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchLandingPage: ${JSON.stringify(params)}`, error);
        return dispatch(LandingPageActions.LANDING_PAGE_FETCH_ERROR(error.response));
      });
  };
}

export function fetchBestSellers(): RequestThunk {
  return (dispatch) => {
    dispatch(LandingPageActions.BEST_SELLERS_FETCH_PENDING());

    return apiV1
      .bestSellersFetch()
      .then((response) => {
        return dispatch(LandingPageActions.BEST_SELLERS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchBestSellers`, error);
        return dispatch(LandingPageActions.BEST_SELLERS_FETCH_ERROR(error.response));
      });
  };
}
