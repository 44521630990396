import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWaypointStructureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <circle cx={12.5} cy={12.5} r={11.5} fill="#78350F" stroke="#fff" strokeWidth={2} />
    <g clipPath="url(#waypoint_structure_icon_svg__a)">
      <path
        fill="#fff"
        d="m10.662 14.877-2.975-3.253-1.738.76-.451-1.032 2.476-1.084 2.688 2.937 2.82-3.081 2.118 2.185 2.119-1.541 1.783 1.378-.69.893-1.113-.861-2.228 1.62-1.965-2.03z"
      />
    </g>
    <defs>
      <clipPath id="waypoint_structure_icon_svg__a">
        <path fill="#fff" d="M5.5 5.5h14v14h-14z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWaypointStructureIcon;
