import * as React from 'react';

const SvgEmail = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" fill={props.fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#email_svg__a)">
      <path
        d="M.47 0H0v11.012h16V0H.47Zm13.836.941L8 6.682 1.694.942h12.612ZM.94 10.071V1.6L8 8l7.059-6.494v8.47H.94v.095Z"
        fill="#002C3A"
      />
    </g>
    <defs>
      <clipPath id="email_svg__a">
        <path fill="#fff" d="M0 0h16v11.012H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEmail;
