import React from 'react';

const SvgFire = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 30" {...props}>
    <path
      d="M10.297.219C16.04 3.849 23 11.286 23 19.304c0 5.346-2.926 8.854-8.778 10.526 1.312-1.724 1.968-3.498 1.968-5.322 0-2.736-.81-4.65-2.86-7.073-1.103 4.342-5.073 4.33-5.76 8.695-.259 1.647.803 3.7.803 3.7S-.97 27.646.083 19.858c1.05-7.788 9.01-7.788 10.214-19.64z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgFire;
