import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import errorPlaceholderImage from '../../assets/placeholder.svg';

interface ImageLoaderProps {
  alt?: string;
  className?: string;
  lazyLoad?: boolean;
  loadingClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => void;
  onError?: () => void;
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  src: string;
  styles?: { [key: string]: string };
}

export const ImageLoaderWithState = (props: ImageLoaderProps) => {
  const { alt, className, lazyLoad, loadingClassName, onClick, onError, onLoad, src } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setIsLoading(false);
    onLoad?.(e);
  };

  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
    onError?.();
  };

  return (
    <>
      <img
        alt={alt}
        className={className}
        loading={lazyLoad ? 'lazy' : 'eager'}
        onClick={onClick}
        onError={handleError}
        onLoad={hasError ? null : handleLoad}
        src={hasError ? errorPlaceholderImage : src}
      />
      {isLoading && !hasError && (
        <div className={loadingClassName}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      )}
    </>
  );
};
