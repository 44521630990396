import { ProductFamily } from '@omniafishing/core';
import React, { useEffect, useState } from 'react';
import { ProductFamilyCarousel } from '../../components/carousel/product_family_carousel';
import { ProductFamilyCard } from '../../components/product_family_card/product_family_card';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { apiV1 } from '../../lib/api';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './complementary_products.less';

interface ComplementaryProductsProps {
  productFamily: ProductFamily;
}

export const ComplementaryProducts = ({ productFamily }: ComplementaryProductsProps) => {
  const { handle } = productFamily;
  const [complimentaryProductFamilies, setComplimentaryProductFamilies] = useState<ProductFamily[]>(
    []
  );
  const complimentaryProductsCount = complimentaryProductFamilies.length;
  const hasComplementaryProducts = complimentaryProductsCount > 0;
  const list_uuid = useListAttributionUuid();

  useEffect(() => {
    if (handle) {
      apiV1.productFamilyComplementaryProductFamiliesFetch(handle).then((res) => {
        setComplimentaryProductFamilies(res.data.data);
      });
    }
  }, [handle]);

  return (
    hasComplementaryProducts && (
      <>
        <h3 className={styles.sectionHeading}>
          Complementary Products ({complimentaryProductsCount})
        </h3>
        <div className={styles.carousel}>
          <ProductFamilyCarousel
            products_families={complimentaryProductFamilies}
            arrows={true}
            list_id_title="Complementary Products"
            list_uuid={list_uuid}
          >
            {complimentaryProductFamilies.map((similarProductFamily, i) => (
              <div className={styles.productTile} key={similarProductFamily.handle}>
                <ProductFamilyCard
                  recommendedFamily={{
                    product_family: similarProductFamily,
                    products: similarProductFamily.products,
                    contains_all_colors: true,
                  }}
                  onProductClick={() => {
                    WebAnalytics.productDetailPageClick('[complementary].(product_link)');
                  }}
                  position={i}
                  list_uuid={list_uuid}
                />
              </div>
            ))}
          </ProductFamilyCarousel>
        </div>
      </>
    )
  );
};
