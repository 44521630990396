import { MailOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useResponsive } from '../../hooks/use_responsive';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { isProductInStock } from '../../lib/products';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgFire from '../svg/fire';
import styles from './product_card_mini.less';
interface ProductCardMiniProps {
  product: Product;
  productPosition?: number;
  isHotbait?: boolean;
  showHotbaitIcon?: boolean;
  lineItemCustomAttributes?: LineItemCustomAttribute[];
  list_uuid?: string;
  handleProductClick?: (isHotbait: boolean) => void;
  isMobileDirectionColumn?: boolean;
  className?: string;
}

export const ProductCardMini = (props: ProductCardMiniProps) => {
  const {
    product,
    productPosition,
    isHotbait,
    showHotbaitIcon = true,
    lineItemCustomAttributes,
    list_uuid,
    handleProductClick,
    isMobileDirectionColumn = true,
    className,
  } = props;

  const { addToCart } = useCart();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useResponsive();

  if (product == null) {
    return null;
  }

  const {
    img_url,
    title,
    on_sale,
    price,
    compare_at_price,
    shopify_product_handle,
    shopify_options,
  } = product;

  const outOfStock = !isProductInStock(product);
  const productUrl = OmniaUrls.product(shopify_product_handle, shopify_options);
  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemCustomAttributes)}`;
  const isMobileColumn = isMobileDirectionColumn && isMobile;
  const isMobileRow = !isMobileDirectionColumn && isMobile;

  return (
    <div
      className={classNames(styles.product, className, {
        [styles.product__mobileRow]: isMobileRow,
      })}
    >
      <Link
        to={{
          pathname: productUrl,
          search: productUrlSearch,
        }}
        className={styles.imgLink}
        onClick={() => {
          if (list_uuid) {
            WebAnalytics.productClick({
              productOrProductFamily: product,
              show_modal: false,
              position: productPosition,
              list_uuid,
            });
            handleProductClick?.(isHotbait);
          }
        }}
        target="_blank"
      >
        <div
          className={styles.productImgContainer}
          style={{ width: isMobileColumn ? '125px' : '100px' }}
        >
          <img src={setShopifyImgWidth(img_url, 300)} alt={title} className={styles.productImg} />
          {isHotbait && showHotbaitIcon && (
            <span className={styles.hotbaitContainer}>
              <SvgFire className={styles.fire} />
              Hotbait
            </span>
          )}
        </div>
      </Link>

      <div className={styles.cardContents}>
        <div className={styles.description}>
          <Link
            to={{
              pathname: productUrl,
              search: productUrlSearch,
            }}
            onClick={() => {
              WebAnalytics.productClick({
                productOrProductFamily: product,
                show_modal: false,
                position: productPosition,
                list_uuid,
              });
              handleProductClick?.(isHotbait);
            }}
            target={isDesktop ? '_blank' : undefined}
          >
            <p className={classNames(styles.title, { [styles.title__mobileRow]: isMobileRow })}>
              {title}
            </p>
          </Link>
          {outOfStock ? (
            <p className={styles.price}>Out of Stock</p>
          ) : on_sale ? (
            <div className={styles.sale}>
              <p className={styles.price}>{toDollars(price)}</p>
              <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>
            </div>
          ) : (
            <p className={styles.price}>{toDollars(price)}</p>
          )}
        </div>
        <div className={styles.cardFooter}>
          {outOfStock ? (
            <Tooltip title="Sign up to be emailed as soon as it's back in stock">
              <div>
                <OmniaButton
                  size="md"
                  kind="tertiary"
                  fontSize={14}
                  onClick={() => {
                    dispatch(
                      InventoryModalActions.INVENTORY_MODAL_OPEN(
                        product,
                        WebAnalyticsEventAreas.REPORTS_VIEW
                      )
                    );
                  }}
                >
                  <MailOutlined />
                  Email Me
                </OmniaButton>
              </div>
            </Tooltip>
          ) : (
            <OmniaButton
              kind="primary"
              size="md"
              fontSize={14}
              onPress={() =>
                addToCart({
                  productsToAdd: [
                    {
                      product,
                      quantity: 1,
                    },
                  ],
                  addToCartArea: WebAnalyticsEventAreas.REPORTS_VIEW,
                  customAttributes: lineItemCustomAttributes,
                  position: productPosition,
                  list_uuid,
                })
              }
            >
              <ShoppingCartOutlined /> Add to Cart
            </OmniaButton>
          )}
        </div>
      </div>
    </div>
  );
};
