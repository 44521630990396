import { Input, Select } from 'antd';
import { NonColorOptionValuesList } from '../product_variant_selector/non_color_options_picker';
import { ProductVariantOption, SelectedOption, ShopifyProduct } from '@omniafishing/core';
import React from 'react';
import styles from './drawer_header.less';
import { NewBadge } from '../new_badge/new_badge';

interface DrawerHeaderProps {
  isProductFamilyNew: boolean;
  nonColorOptions: ProductVariantOption[];
  onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: (value: boolean) => void;
  onVariantChange: (optionName: string, value: string) => void;
  selectedOptions: SelectedOption[];
  shopifyProduct: ShopifyProduct;
}

export const DrawerHeader = (props: DrawerHeaderProps) => {
  const {
    isProductFamilyNew,
    nonColorOptions,
    onSearchInputChange,
    onSelectChange,
    onVariantChange,
    selectedOptions,
    shopifyProduct,
  } = props;

  return (
    <div className={styles.drawerHeader}>
      <div className={styles.header}>
        <p>Colors {isProductFamilyNew && <NewBadge>All New</NewBadge>}</p>
      </div>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputs}>
          <Input.Search placeholder="Search colors" allowClear onChange={onSearchInputChange} />
          <Select defaultValue={true} onSelect={onSelectChange}>
            <Select.Option value={true}>A to Z</Select.Option>
            <Select.Option value={false}>Z to A</Select.Option>
          </Select>
        </div>
        <div className={styles.nonColorSelectors}>
          {nonColorOptions.map((option) => {
            const selectedOption = selectedOptions.find(
              (stateSelectedOption) => stateSelectedOption.name === option.name
            );
            return (
              <React.Fragment key={option.name}>
                {option.values.length > 1 && (
                  <fieldset className={styles.fieldset} key={option.name}>
                    <NonColorOptionValuesList
                      className={styles.nonColorOptionsLabel}
                      onVariantChange={onVariantChange}
                      option={option}
                      selectedOption={selectedOption}
                      selectedOptions={selectedOptions}
                      shopifyProduct={shopifyProduct}
                      showOptionWithList
                    />
                  </fieldset>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
