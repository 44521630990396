import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getPath, RouterActions } from '../../redux/router';

export const LocationChanger: React.FC<{}> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = useSelector(getPath);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      dispatch(RouterActions.ROUTER_LOCATION_CHANGE(location.pathname));
    }
  }, [location.pathname]);

  return null;
};
