import { OmniaResponse, TechniqueDetail } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'styles';

export enum StateKeys {
  styleDetail = 'styleDetail',
  loadingState = 'loadingState',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.styleDetail]: null as TechniqueDetail,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getStyleDetail = (state: ApplicationState) =>
  state[reducerName][StateKeys.styleDetail];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function StylesReducer(state = initialState, action: StylesActions) {
  switch (action.type) {
    case ReduxActions.STYLE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.STYLE_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.styleDetail]: action.payload.data,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const StylesActions = {
  STYLE_FETCH_PENDING: () => createAction(ReduxActions.STYLE_FETCH_PENDING),
  STYLE_FETCH_SUCCESS: (response: OmniaResponse<TechniqueDetail>) =>
    createAction(ReduxActions.STYLE_FETCH_SUCCESS, response),
  STYLE_FETCH_ERROR: (err: any) => createAction(ReduxActions.STYLE_FETCH_ERROR, err),
};
export type StylesActions = ActionsUnion<typeof StylesActions>;

export function fetchStyle(urlPath: string): RequestThunk {
  return (dispatch) => {
    dispatch(StylesActions.STYLE_FETCH_PENDING());

    return apiV1
      .techniqueFetch(urlPath)
      .then((response) => {
        return dispatch(StylesActions.STYLE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchStyle', error);
        return dispatch(StylesActions.STYLE_FETCH_ERROR(error));
      });
  };
}
