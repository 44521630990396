import { AlgoliaProductFamily, ProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import React, { MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import searchInsights from 'search-insights';
import { AlgoliaSearchEvents } from '../../algolia';
import { getEnv } from '../../env';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { useListViewed } from '../../hooks/use_list_viewed';
import { WebAnalytics } from '../../lib/web_analytics';
import { getUser } from '../../redux/user';
import { SearchProductFamily } from './search_product_family';
import styles from './search_universal.less';

interface SearchProductsTabProps {
  productFamilies: AlgoliaProductFamily[] | ProductFamily[];
  currentQueryString: string;
  lastQueryId: string;
  listViewedRef: MutableRefObject<boolean>;
}

const env = getEnv();

export const SearchProductFamilyDefaultResultsList = (props: SearchProductsTabProps) => {
  const { productFamilies, currentQueryString, lastQueryId, listViewedRef } = props;
  const user = useSelector(getUser);
  const { coupon, createListId } = useListViewed();
  const list_uuid = useListAttributionUuid();

  useEffect(() => {
    if (!listViewedRef.current) {
      listViewedRef.current = true;
      WebAnalytics.productFamilyListViewed({
        productFamilies,
        coupon,
        list_id: createListId('search'),
        list_uuid,
      });
    }
  }, [productFamilies, listViewedRef.current]);

  return (
    <>
      <ul className={classNames(styles.list, styles.list__wrap, styles.list__products)}>
        {productFamilies.map((p, index) => (
          <li key={p.handle}>
            <SearchProductFamily
              productFamily={p}
              position={index}
              list_uuid={list_uuid}
              onClick={(target_url: string) => {
                if (lastQueryId) {
                  searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                    index: env.ALGOLIA_INDEX_PRODUCT_FAMILIES,
                    eventName: 'search_bar_product_family_clicked',
                    queryID: lastQueryId,
                    objectIDs: [currentQueryString],
                    positions: [index + 1],
                    userToken: user?.id?.toString(),
                  });
                }
                WebAnalytics.searchResultClick(currentQueryString, 'product_family', target_url);
              }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
