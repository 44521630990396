export type AnyFunction = (...args: any[]) => any;
export interface StringMap<T> {
  [key: string]: T;
}

export interface Action<T extends string> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  const action = payload === undefined ? { type } : { type, payload };
  return action;
}

export type ActionsUnion<A extends StringMap<AnyFunction>> = ReturnType<A[keyof A]>;
