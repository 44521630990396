import { SeasonGroup } from '@omniafishing/core';
import { Radio, Select } from 'antd';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { isCurrentSeasonGroupText } from '../../lib/seasons';
import { WebAnalytics } from '../../lib/web_analytics';
import { getIpCurrentSeasonGroup, getIpZone } from '../../redux/geographic_location';
import { getSeasonGroups, SEASON_GROUP_NAME_ICE } from '../../redux/reference_data';
import styles from './product_detail_fishing_reports.less';

interface SeasonSelectProps {
  seasonGroupCounts: number[];
  selectedSeasonGroup: SeasonGroupSelectValue;
  onChange: (seasonGroup: SeasonGroupSelectValue) => void;
}

export interface SeasonGroupSelectValue {
  label: ReactElement<{ 'data-result': SeasonGroup }>;
  value: string;
}

export const removeIceSeasonPerUserZone = (IPzone: number, seasonGroups: SeasonGroup[]) => {
  if (IPzone !== 3) {
    return seasonGroups.filter((seasonGroup) => seasonGroup.name !== SEASON_GROUP_NAME_ICE);
  }
  return seasonGroups;
};

export const labelBuilderSeasonGroupSelect = (
  seasonGroup: SeasonGroup,
  ipCurrentSeasonGroup: SeasonGroup,
  seasonGroupCounts: number[],
  index: number
) => {
  return (
    <Radio value={seasonGroup?.name} data-result={seasonGroup} className={styles.selectLabel}>
      <span>
        {`${seasonGroup?.display_name}${isCurrentSeasonGroupText(
          seasonGroup,
          ipCurrentSeasonGroup
        )}`}
      </span>
      <span className={styles.selectLabelReportCount}>{seasonGroupCounts[index]}</span>
    </Radio>
  );
};

export const defaultSeasonGroupValue: SeasonGroupSelectValue = {
  label: (
    <Radio value={'All Seasons'} defaultChecked className={styles.selectLabel}>
      <span>
        <span>All Seasons</span>
      </span>
    </Radio>
  ),
  value: null as string,
};

export const SeasonGroupSelect = (props: SeasonSelectProps) => {
  const [radioSelectSeasons, setRadioSelectSeasons] = useState<string>();
  const ipCurrentSeasonGroup = useSelector(getIpCurrentSeasonGroup);
  const seasonGroups = useSelector(getSeasonGroups);
  const ipZone = useSelector(getIpZone);
  const { setUserPreferencesSeasonGroup } = useUserPreferences();
  const { seasonGroupCounts, selectedSeasonGroup, onChange } = props;

  const zoneSpecificSeasonGroups = removeIceSeasonPerUserZone(ipZone, seasonGroups);

  const seasonSelectOptions = [defaultSeasonGroupValue].concat(
    zoneSpecificSeasonGroups.map((seasonGroup, i) => {
      return {
        label: (
          <Radio
            value={seasonGroup.name}
            data-result={seasonGroup}
            className={styles.selectLabel}
            disabled={seasonGroupCounts[i] === 0}
          >
            <span>
              {seasonGroup.display_name}
              {isCurrentSeasonGroupText(seasonGroup, ipCurrentSeasonGroup)}
            </span>
            <span className={styles.selectLabelReportCount}>{seasonGroupCounts[i]}</span>
          </Radio>
        ),
        value: seasonGroup.name,
        disabled: seasonGroupCounts[i] === 0,
      };
    })
  );

  return (
    <div className={styles.selectContainer}>
      <Radio.Group
        value={radioSelectSeasons}
        onChange={(e) => setRadioSelectSeasons(e.target.value)}
        defaultValue={'All Seasons'}
      >
        <Select
          options={seasonSelectOptions}
          value={selectedSeasonGroup}
          defaultValue={defaultSeasonGroupValue}
          labelInValue
          onChange={(val: SeasonGroupSelectValue) => {
            onChange(val);
            WebAnalytics.contentFiltered('season', val.value);
            setUserPreferencesSeasonGroup(val.value);
          }}
          className={classNames(styles.seasonSelect, styles.selects)}
          dropdownStyle={{ minWidth: 206 }}
        />
      </Radio.Group>
    </div>
  );
};
