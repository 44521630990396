import { PRO_PURCHASE_TEST_URL, PRO_PURCHASE_URL, PRO_TRIAL_PURCHASE_URL } from '../lib/stripe';
import { useQueryString } from './use_query_string';
import { useUser } from './use_user';
import { useUtm } from './use_utm';

export const useProPurchaseUrl = (props: {
  trial: boolean;
  trialPurchaseUrl?: string;
  purchaseUrl?: string;
}) => {
  const {
    trial,
    trialPurchaseUrl = PRO_TRIAL_PURCHASE_URL,
    purchaseUrl = PRO_PURCHASE_URL,
  } = props;
  const { user } = useUser();
  const { getCurrentQuery, stringify } = useQueryString();
  const { source, content, medium, term, name } = useUtm();
  const utmParams = {
    utm_source: source,
    utm_medium: medium,
    utm_term: term,
    utm_content: content,
    utm_campaign: name,
  };
  Object.keys(utmParams).forEach(
    (key: keyof typeof utmParams) => utmParams[key] === undefined && delete utmParams[key]
  );

  const currentQuery = getCurrentQuery<{
    stripe_test_mode: boolean;
    pro_promo_code: string;
  }>();

  const linkParams: Record<string, string> = {
    ...utmParams,
  };

  if (user) {
    linkParams.client_reference_id = user.id.toString();
    linkParams.prefilled_email = user.email;
  }

  if (currentQuery.pro_promo_code) {
    linkParams.prefilled_promo_code = currentQuery.pro_promo_code;
  }

  const proPurchaseUrl =
    (currentQuery.stripe_test_mode
      ? PRO_PURCHASE_TEST_URL
      : trial
      ? trialPurchaseUrl
      : purchaseUrl) + `?${stringify(linkParams)}`;

  return { proPurchaseUrl };
};
