import React from 'react';

const SvgProducts = (props: React.SVGAttributes<SVGElement>) => {
  const { strokeWidth = 0.3, ...rest } = props;
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d="M11.402 5.885a1.257 1.257 0 0 1-1.287-1.287c0-.736.552-1.288 1.287-1.288.736 0 1.288.552 1.288 1.288-.062.735-.613 1.287-1.288 1.287Zm0-1.962a.68.68 0 0 0-.674.675.68.68 0 0 0 .674.674.68.68 0 0 0 .675-.674c-.062-.368-.307-.675-.675-.675Z"
        fill="currentColor"
      />
      <path
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d="M16 .43 15.57 0l-1.777 1.778L12.444.429 8.828.736 0 9.44l6.498 6.498 8.766-8.767.307-3.616-1.349-1.35L16 .43ZM6.498 15.08.858 9.44l1.717-1.716c.735 0 1.042.368 1.471.858.43.49.92 1.043 1.962 1.043.98 0 1.47-.552 1.961-1.043.43-.429.797-.858 1.472-.858.735 0 1.103.368 1.47.858.307.368.675.736 1.227.92l-5.64 5.579Zm8.215-8.153L12.69 8.95c-.613-.061-.92-.429-1.288-.797-.429-.49-.92-1.042-1.961-1.042-.981 0-1.533.552-1.962 1.042-.43.43-.736.858-1.471.858-.736 0-1.104-.367-1.472-.858-.367-.368-.735-.797-1.41-.98l5.885-5.886 3.127-.245 2.759 2.759-.184 3.126Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgProducts;
