import { FishingReport, Video } from '@omniafishing/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useResponsive } from '../../../hooks/use_responsive';
import { apiV1 } from '../../../lib/api';
import { getImgixPath } from '../../../lib/imgix';
import { WebAnalytics } from '../../../lib/web_analytics';
import { FishingReportModalActions } from '../../../redux/fishing_report_modal';
import { ActiveMedia, MediaModal } from '../../fishing_report_card/media_modal';
import { ShareReportButton } from '../../fishing_report_card/share_report_button';
import { OmniaButton } from '../../omnia_button/omnia_button';
import { UserDetails } from '../user_details';
import { FishingReportCardImage } from './fishing_report_card_image';
import styles from './fishing_report_card_media.less';
import { FishingReportCardVideo } from './fishing_report_card_video';

interface FishingReportMediaProps {
  fishingReport: FishingReport;
  image: string;
  isLakePage?: boolean;
  onLakeClick?: () => void;
  onMediaClick?: () => void;
  onShareClick?: () => void;
  onUserClick?: () => void;
  position: number;
  showLakeName: boolean;
  showUserDetails?: boolean;
  userShouldAddMedia?: boolean;
  video: Video;
}

export const FishingReportCardMedia = (props: FishingReportMediaProps) => {
  const {
    fishingReport,
    image,
    isLakePage,
    onLakeClick,
    onMediaClick,
    onShareClick,
    onUserClick,
    position,
    showLakeName,
    showUserDetails = true,
    userShouldAddMedia = false,
    video,
  } = props;
  const { isMobile } = useResponsive();
  const [activeMedia, setActiveMedia] = useState<ActiveMedia>(null);
  const dispatch = useDispatch();
  const hasImage = !!image;
  const hasVideo = !!video;
  const hasMedia = hasImage || hasVideo;
  const hasImageAndVideo = hasImage && hasVideo;

  const onVideoClick = (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveMedia({
      src: video.src,
      type: 'video',
    });
    onMediaClick?.();
    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const onImageClick = (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveMedia({
      src: getImgixPath(image, { w: 1000 }),
      type: 'img',
    });
    onMediaClick?.();
    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const UserDetailsComponent = (
    <>
      {showUserDetails && (
        <div className={styles.userDetailsWrapper}>
          <UserDetails
            fishingReport={fishingReport}
            position={position}
            onLakeClick={onLakeClick}
            onUserClick={onUserClick}
            isLakePage={isLakePage}
            className={styles.userDetails}
            showLakeName={showLakeName}
          />
        </div>
      )}
    </>
  );

  if (userShouldAddMedia) {
    return (
      <div className={styles.editWrapper}>
        <OmniaButton
          kind="tertiary"
          size="sm"
          onPress={async () => {
            const {
              data: { data: waterbodyDetail },
            } = await apiV1.waterbodyFetch(fishingReport.waterbody.url_slug);
            dispatch(
              FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(waterbodyDetail, fishingReport)
            );
          }}
        >
          Add Image or Video
        </OmniaButton>
        <span>Fishing reports with an image or video get more views.</span>
      </div>
    );
  }

  return (
    <>
      {hasMedia && (
        <>
          {/*
            edit this conditional at your own risk.
            there is only space on mobile FR cards for one media
            and that is video, hence this first, funny looking conditional.
          */}
          {hasImageAndVideo && isMobile ? (
            <div className={styles.mediaSection}>
              <div className={styles.videoContainer}>
                <FishingReportCardVideo video={video} onVideoClick={onVideoClick} />
                {UserDetailsComponent}
              </div>
            </div>
          ) : (
            <div className={styles.mediaSection}>
              {video && (
                <div className={styles.videoContainer}>
                  <FishingReportCardVideo video={video} onVideoClick={onVideoClick} />
                  {isMobile && UserDetailsComponent}
                </div>
              )}
              {image && (
                <div className={styles.imgContainer}>
                  <FishingReportCardImage image={image} onImageClick={onImageClick} />
                  {isMobile && UserDetailsComponent}
                </div>
              )}
            </div>
          )}
          {isMobile && (
            <ShareReportButton fishingReport={fishingReport} onShareClick={onShareClick} />
          )}
          <MediaModal activeMedia={activeMedia} onCancel={() => setActiveMedia(null)} />
        </>
      )}
    </>
  );
};
