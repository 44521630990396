import { NextFunction } from 'connect';
import Cookies from 'js-cookie';
import { Store } from 'redux';
import { ReduxActions } from '../constants/redux_actions';
import { getServerEnv, ServerEnvs } from '../env';
import getNow from '../lib/time';
import { AuthActions } from './auth';
import { CartActions } from './cart';
import { UserActions } from './user';
import { UtmActions } from './utm';

export enum CookieKeys {
  refreshToken = 'omnia_refreshToken',
  accessToken = 'omnia_accessToken',
  accessTokenExpiration = 'omnia_accessTokenExpiration',
  checkoutId = 'omnia_checkoutId',
  experimentUuid = 'omnia_experimentUuid',
  affiliate = 'omnia_affiliate',
  newsletterClosed = 'omnia_newsletterClosed', // this should be in local storage, but its not...
  segment_anonymous_id = 'ajs_anonymous_id',
}

export interface AffiliateCookie {
  source: string;
  campaign: string;
  referer: string;
}

export function getCookieConfig(): Cookies.CookieAttributes {
  const isLocal = getServerEnv() === ServerEnvs.LOCAL;

  return {
    expires: 365,
    secure: isLocal ? false : true,
    sameSite: 'Lax',
  };
}

export function getAffiliateConfig(days?: number): Cookies.CookieAttributes {
  const config = {
    ...getCookieConfig(),
    expires: days || null,
  };

  return config;
}

export const INTERNALS = {
  getCookieConfig,
};

const createCookieStorageMiddleware =
  () =>
  (store: Store) =>
  (next: NextFunction) =>
  (action: AuthActions | CartActions | UserActions | UtmActions) => {
    if (action) {
      const omniaCookieConfig = INTERNALS.getCookieConfig();

      switch (action.type) {
        case ReduxActions.AUTH_LOGIN_SUCCESS:
        case ReduxActions.AUTH_SIGNUP_SUCCESS: {
          const { refresh_token, access_token, access_token_expires_in_sec, user } =
            action.payload.data;
          const expiresInMs = access_token_expires_in_sec * 1000;
          const accessTokenExpiration = getNow() + expiresInMs;
          Cookies.set(CookieKeys.refreshToken, refresh_token, omniaCookieConfig);
          Cookies.set(CookieKeys.accessToken, access_token, omniaCookieConfig);
          Cookies.set(
            CookieKeys.accessTokenExpiration,
            JSON.stringify(accessTokenExpiration),
            omniaCookieConfig
          );
          Cookies.set(CookieKeys.experimentUuid, user.experiment_uuid, omniaCookieConfig);

          break;
        }

        case ReduxActions.AUTH_REFRESH_SUCCESS: {
          const expiresInMs = action.payload.access_token_expires_in_sec * 1000;
          const accessTokenExpiration = getNow() + expiresInMs;
          Cookies.set(CookieKeys.accessToken, action.payload.access_token, omniaCookieConfig);
          Cookies.set(
            CookieKeys.accessTokenExpiration,
            JSON.stringify(accessTokenExpiration),
            omniaCookieConfig
          );
          break;
        }

        case ReduxActions.CART_ID_FETCH_SUCCESS: {
          const { checkout_id, was_checkout_complete } = action.payload.data;
          Cookies.set(CookieKeys.checkoutId, checkout_id, omniaCookieConfig);
          if (was_checkout_complete) {
            Cookies.remove(CookieKeys.affiliate, getAffiliateConfig());
          }

          break;
        }

        case ReduxActions.AFFILIATE_FETCH_SUCCESS: {
          const { data, affiliate_campaign, referer } = action.payload;
          const { cookie_expiration, campaign_source } = data;
          Cookies.set(
            CookieKeys.affiliate,
            JSON.stringify({
              source: campaign_source,
              campaign: affiliate_campaign,
              referer,
            } as AffiliateCookie),
            getAffiliateConfig(cookie_expiration)
          );

          break;
        }

        case ReduxActions.USER_FETCH_SUCCESS: {
          const { experiment_uuid } = action.payload.data;
          Cookies.set(CookieKeys.experimentUuid, experiment_uuid, omniaCookieConfig);
          break;
        }

        case ReduxActions.USER_EXPERIMENT_IDS_SET: {
          const { uuid } = action.payload;
          Cookies.set(CookieKeys.experimentUuid, uuid, omniaCookieConfig);
          break;
        }

        case ReduxActions.AUTH_LOGOUT:
        case ReduxActions.AUTH_REFRESH_ERROR:
          Cookies.remove(CookieKeys.refreshToken, omniaCookieConfig);
          Cookies.remove(CookieKeys.accessToken, omniaCookieConfig);
          Cookies.remove(CookieKeys.accessTokenExpiration, omniaCookieConfig);
          Cookies.remove(CookieKeys.checkoutId, omniaCookieConfig);
          // should not clear experimentUuid, it remains forever
          break;

        default:
          break;
      }
    }

    return next(action);
  };

const cookieStorageMiddleware = createCookieStorageMiddleware();

export default cookieStorageMiddleware;
