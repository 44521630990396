import { Modal } from 'antd';
import { getImgixPath } from '../../lib/imgix';
import { Brand, Species, TechniqueFull, Waterbody } from '@omniafishing/core';
import React from 'react';
import { RoutePaths } from '../../routes';
import { LinkButton } from '../link_button/link_button';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import SvgFire from '../svg/fire';
import { OmniaUrls } from '../../lib/urls';
import styles from './omnia_video_modals.less';

export const BrandModal = (props: { open: boolean; onCancel: () => void; brand: Brand }) => {
  const { open, onCancel, brand } = props;
  if (!brand) {
    return null;
  }

  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      {brand.image && <img src={getImgixPath(brand.image, { w: 600 })} alt={brand.display_name} />}
      <h1>{brand.display_name}</h1>
      {brand.description && <div dangerouslySetInnerHTML={{ __html: brand.description }} />}
      <LinkButton to={`${RoutePaths.BRANDS}/${brand.url_slug}`}>
        Shop all {brand.display_name} products
      </LinkButton>
    </Modal>
  );
};

export const SpeciesModal = (props: { open: boolean; onCancel: () => void; species: Species }) => {
  const { open, onCancel, species } = props;
  if (!species) {
    return null;
  }
  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      {species.image && (
        <img src={getImgixPath(species.image, { w: 600 })} alt={species.display_name} />
      )}
      <h1>{species.display_name}</h1>
      <LinkButton to={`${RoutePaths.SPECIES}/${species.url_path}`}>
        Shop all {species.display_name} products
      </LinkButton>
    </Modal>
  );
};

export const WaterbodyModal = (props: {
  open: boolean;
  onCancel: () => void;
  waterbody: Waterbody;
}) => {
  const { open, onCancel, waterbody } = props;
  if (!waterbody) {
    return null;
  }

  const { favorites_count, fishing_reports_count, hotbaits_count, ambassadors_count } = waterbody;

  const hasFishingReports = fishing_reports_count > 0;
  const hasUserFavs = favorites_count > 0;
  const hasAmbassadors = ambassadors_count > 0;
  const hasHotbaits = hotbaits_count > 0;

  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <WaterbodyImg waterbody={waterbody} />
      <h1>{waterbody.primary_name} </h1>

      <div className={styles.quickDetails}>
        {hasFishingReports && (
          <p className={styles.quickDetail}>
            <span className={styles.quickDetailIcon}>🎣</span>
            {fishing_reports_count} Fishing Report{fishing_reports_count > 1 && 's'}
          </p>
        )}
        {hasHotbaits && (
          <p className={styles.quickDetail}>
            <span className={styles.quickDetailIcon}>
              <SvgFire className={styles.fire} />
            </span>
            {hotbaits_count} Hotbait{hotbaits_count > 1 && 's'}
          </p>
        )}
        {hasAmbassadors && (
          <p className={styles.quickDetail}>
            <span className={styles.quickDetailIcon}>🏆</span>
            {ambassadors_count} Ambassador{ambassadors_count > 1 && 's'}
          </p>
        )}
        {hasUserFavs && (
          <p className={styles.quickDetail}>
            <span className={styles.quickDetailIcon}>❤️</span>
            {favorites_count} User Favorite{favorites_count > 1 && 's'}
          </p>
        )}
      </div>

      <LinkButton to={`${OmniaUrls.waterbody(waterbody)}`}>See {waterbody.primary_name}</LinkButton>
    </Modal>
  );
};

export const TechniqueModal = (props: {
  open: boolean;
  onCancel: () => void;
  technique: TechniqueFull;
}) => {
  const { open, onCancel, technique } = props;

  if (!technique) {
    return null;
  }

  return (
    <Modal open={open} onCancel={onCancel} footer={null}>
      <h1>{technique.display_name}</h1>
      <LinkButton to={`${RoutePaths.TECHNIQUES}/${technique.url_path}`}>
        Shop all {technique.display_name} products
      </LinkButton>
    </Modal>
  );
};
