import {
  Media,
  OmniaVideoItem,
  ProductFamily,
  ShopifyProduct,
  ShopifyVariant,
} from '@omniafishing/core';
import useEmblaCarousel from 'embla-carousel-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingState } from '../../constants/loading_state';
import { apiV1 } from '../../lib/api';
import { getImgixPath } from '../../lib/imgix';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { WebAnalytics } from '../../lib/web_analytics';
import { ColorOption } from '../product_variant_selector/product_variant_selector';
import { SEO } from '../seo/seo';
import styles from './product_detail_images.less';
import { ProductDetailImagesHero } from './product_detail_images_hero';
import { ProductDetailImagesModal } from './product_detail_images_modal';
import { ProductDetailMediaCarousel } from './product_detail_media_carousel';

// sort media by product family images display order, omnia media pdp_order_# tags
export const sortProductDetailMedia = (allMedia: ProductDetailMedia[]) => {
  return _.orderBy(allMedia, (pdpMedia) => {
    let order = Infinity;
    if (pdpMedia.type === 'product_image') {
      order = pdpMedia.display_order ?? Infinity;
    } else if (pdpMedia.type === 'omnia_media') {
      const isPdpOrderTagRegex = /^pdp_order_\d+$/;
      const lowestOrderTag = _.min(
        pdpMedia.media.media_item.tags
          .filter((t) => isPdpOrderTagRegex.test(t))
          .map((t) => parseInt(t.replace('pdp_order_', ''), 10))
      );
      order = lowestOrderTag ?? Infinity;
    }
    return order;
  });
};

export interface ProductDetailImagesProps {
  shopifyProduct: ShopifyProduct;
  selectedVariant: ShopifyVariant;
  productFamily: ProductFamily;
  media: Media[];
  hoveredColor: ColorOption;
}

interface ProductDetailMediaImg {
  type: 'product_image';
  src: string;
  alt_text: string;
  display_order: number | null;
}
interface ProductDetailMediaOmniaMedia {
  type: 'omnia_media';
  src: string;
  alt_text: string;
  media: Media;
}
interface ProductDetailMedia360 {
  type: 'product_360_images';
  src: string[];
  alt_text: string;
}
interface ProductDetailMediaVideoEmbed {
  type: 'product_video';
  src: string;
  alt_text: string;
}
export type ProductDetailMedia =
  | ProductDetailMediaImg
  | ProductDetailMedia360
  | ProductDetailMediaVideoEmbed
  | ProductDetailMediaOmniaMedia;

export const imgSrcTransformed = (src: string) => {
  if (!src) {
    return null;
  }
  // if selected is a shopify img
  if (src.includes('cdn.shopify.com')) {
    return setShopifyImgWidth(src, 1000);
  }
  if (src.includes('imgix')) {
    return getImgixPath(src, { w: 1000 });
  }

  return src;
};

export const ProductDetailImages = (props: ProductDetailImagesProps) => {
  const { shopifyProduct, selectedVariant, productFamily, media, hoveredColor } = props;

  // this implementation works, but a better solution
  // would be to store all media in one array and have a selected index
  const [selectedMedia, setSelectedMedia] = useState<ProductDetailMedia>({
    type: 'product_image',
    src: selectedVariant.image.url,
    alt_text: selectedVariant.title,
    display_order: null,
  });
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);

  const [omniaVideoSlug, setOmniaVideoSlug] = useState<string>();
  const [omniaVideoItems, setOmniaVideoItems] = useState<OmniaVideoItem[]>([]);
  const [omniaVideoItemsLoadingState, setOmniaVideoItemsLoadingState] = useState(
    LoadingState.NOT_STARTED
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialImageHeight, setInitialImageHeight] = useState<number>();

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      dragFree: true,
    },
    []
  );

  useEffect(() => {
    if (omniaVideoSlug) {
      setOmniaVideoItemsLoadingState(LoadingState.PENDING);
      apiV1.omniaVideoFetch(omniaVideoSlug).then((res) => {
        setOmniaVideoItems(res.data.data.omnia_video_items);
        setOmniaVideoItemsLoadingState(LoadingState.DONE);
      });
    }
  }, [omniaVideoSlug]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    const currentIndex = emblaApi.selectedScrollSnap();
    if (currentIndex !== selectedPanelIndex) {
      emblaApi.scrollTo(selectedPanelIndex, true);
    }
  }, [selectedPanelIndex, emblaApi]);

  // should this be useAfterMountEffect?
  useEffect(() => {
    if (selectedVariant) {
      setSelectedMedia({
        type: 'product_image',
        src: selectedVariant.image.url,
        alt_text: selectedVariant.title,
        display_order: null,
      });
    }
  }, [selectedVariant.image.url]);

  const videoFilteredMedia = media
    .filter((m) => {
      const { media_type, media_item } = m;
      const isVideo =
        media_type === 'PrismicVideo' ||
        media_type === 'OmniaVideo' ||
        media_item.tags.includes('video'); // BassU videos
      return isVideo;
    })
    .filter((m) => m.media_type !== 'PrismicArticle');

  const allMedia: ProductDetailMedia[] = [];
  if (productFamily.images.length) {
    allMedia.push(
      ...productFamily.images.map((image) => ({
        type: 'product_image' as const,
        src: image.image,
        alt_text: image.alt_text,
        display_order: image.display_order,
      }))
    );
  }
  if (productFamily.rotate_images.length) {
    allMedia.push({
      type: 'product_360_images',
      src: productFamily.rotate_images,
      alt_text: '360',
    });
  }
  if (productFamily.video_embed) {
    allMedia.push({
      type: 'product_video',
      src: productFamily.video_embed,
      alt_text: '',
    });
  }
  if (videoFilteredMedia.length) {
    allMedia.push(
      ...videoFilteredMedia.map((m) => ({
        type: 'omnia_media' as const,
        src: m.media_item.thumbnail,
        alt_text: m.media_item.title,
        media: m,
      }))
    );
  }

  const allMediaSorted = useMemo(() => {
    // selected variant image should always be first
    const sortedMedia = allMedia ? sortProductDetailMedia(allMedia) : [];
    if (selectedVariant && selectedVariant.image) {
      sortedMedia.unshift({
        type: 'product_image',
        src: selectedVariant.image.url,
        alt_text: selectedVariant.title,
        display_order: null,
      });
    }
    return sortedMedia;
  }, [allMedia.map((m) => m.src), selectedVariant]);

  const onMediaClick = useCallback(
    (pdpMedia: ProductDetailMedia, i: number) => {
      if (emblaApi) {
        emblaApi.scrollTo(i);
        setSelectedPanelIndex(i);
      }
      if (pdpMedia.type !== 'omnia_media') {
        setSelectedMedia(pdpMedia);
        WebAnalytics.productDetailPageClick('[product_images].(product_image_view)');
      } else {
        if (
          pdpMedia.media.media_type === 'OmniaVideo' &&
          pdpMedia.media.media_item.slug !== omniaVideoSlug
        ) {
          setOmniaVideoSlug(pdpMedia.media.media_item.slug);
        }
        setSelectedMedia({
          type: 'omnia_media',
          src: pdpMedia.media.media_item.thumbnail,
          alt_text: pdpMedia.media.media_item.title,
          media: pdpMedia.media,
        });
        setIsModalOpen(true);
      }
    },
    [setSelectedMedia, setOmniaVideoSlug, setIsModalOpen, , omniaVideoSlug, emblaApi]
  );

  return (
    <>
      <SEO>
        <link
          rel="preload"
          as="image"
          href={
            selectedMedia.type === 'product_image' && imgSrcTransformed(selectedMedia.src as string)
          }
          {...{ fetchpriority: 'high' }}
        />
      </SEO>
      <div className={styles.heroImgContainer} style={{ height: initialImageHeight || 'auto' }}>
        <ProductDetailImagesHero
          hoveredColor={hoveredColor}
          initialImageHeight={initialImageHeight}
          isModalOpen={isModalOpen}
          onInitialImageHeightChange={setInitialImageHeight}
          productFamily={productFamily}
          selectedMedia={selectedMedia}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
        />
      </div>
      <ul className={styles.carouselWrapper}>
        <ProductDetailMediaCarousel
          onMediaClick={onMediaClick}
          selectedPanelIndex={selectedPanelIndex}
          allMedia={allMediaSorted}
          showNewWindowIcon={false}
          emblaRef={emblaRef}
          emblaApi={emblaApi}
        />
      </ul>
      <ProductDetailImagesModal
        allMedia={allMediaSorted}
        isModalOpen={isModalOpen}
        omniaVideoSlug={omniaVideoSlug}
        omniaVideoItems={omniaVideoItems}
        omniaVideoItemsLoadingState={omniaVideoItemsLoadingState}
        onOmniaVideoSlugChange={setOmniaVideoSlug}
        onOmniaVideoItemsChange={setOmniaVideoItems}
        onInitialImageHeightChange={setInitialImageHeight}
        productFamily={productFamily}
        selectedPanelIndex={selectedPanelIndex}
        selectedVariant={selectedVariant}
        shopifyProduct={shopifyProduct}
        onModalClose={(panelIndex: number, sm: ProductDetailMedia) => {
          setIsModalOpen(false);
          setSelectedMedia(sm);
          setSelectedPanelIndex(panelIndex);
        }}
      />
    </>
  );
};
