import {
  AlgoliaProductFamily,
  CategoryNames,
  FishingReport,
  KitIntent,
  OmniaVideoItemTypes,
  Product,
  ProductFamily,
  ShopifyVariant,
  SortValues,
} from '@omniafishing/core';
import { RudderAnalytics } from '@rudderstack/analytics-js/*';
import { noop } from 'lodash';
import { WindowWithPreloadedState } from '../app_client';
import { variantProductTypeToSubcategoryName } from '../categories_subcategories';
import { MapLayerState } from '../components/map/map_types';
import { TabHashes } from '../components/search_universal/search_universal';
import { waterbodyImgUrl } from '../components/waterbody_img/waterbody_img';
import { getPageTypeByPathname, LocationTypes } from '../routes';
import { isBase64 } from './base64';
import { brandTransformName } from './brand';
import { isProductInStock } from './products';
import { OmniaUrls } from './urls';

export function isProductFamily(
  productOrFamily: Product | ProductFamily
): productOrFamily is ProductFamily {
  return (productOrFamily as ProductFamily).handle != null;
}

export function isVariant(
  productOrVariant: Product | ShopifyVariant
): productOrVariant is ShopifyVariant {
  return (productOrVariant as ShopifyVariant).price != null;
}

function track(event: string, properties?: object): void {
  const analyticsData = (window as unknown as WindowWithPreloadedState).__ANALYTICS_DATA__;
  const trackData = {
    platform: analyticsData?.isMobileDevice ? 'mobile' : 'desktop',
    location_state: analyticsData?.ipState,
    ...properties,
  };

  window.rudderanalytics.track(event, trackData);
}

function page(properties?: object): void {
  const analyticsData = (window as unknown as WindowWithPreloadedState).__ANALYTICS_DATA__;

  const pageData = {
    platform: analyticsData?.isMobileDevice ? 'mobile' : 'desktop',
    location_state: analyticsData?.ipState,
    ...properties,
  };

  window.rudderanalytics.page(pageData);
}

export const INTERNALS = {
  getAnalyticsWithGlobalProperties: () => {
    if ((window as any).__IS_BOT__) {
      return {
        track: noop,
        page: noop,
        rudderstackIdentify: noop,
        rudderstackReset: noop,
        rudderstackAnonymousId: noop,
        rudderstackGetUserTraits: noop,
      };
    }

    return {
      track,
      page,
      rudderstackIdentify: (window.rudderanalytics as RudderAnalytics).identify,
      rudderstackReset: (window.rudderanalytics as RudderAnalytics).reset,
      rudderstackAnonymousId: (window.rudderanalytics as RudderAnalytics).getAnonymousId,
      rudderstackGetUserTraits: window.rudderanalytics.getUserTraits,
    };
  },
};

export const EXPERIMENT_UUID = 'experimentUUID';

export enum WebAnalyticsEventAreas {
  MAIN_VIEW = 'main-view',
  QUICK_VIEW = 'quick-view',
  REPORTS_VIEW = 'reports-view',
  VARIANTS_VIEW = 'variants-view',
  SLICE_VIEW = 'slice-view',
  KIT_VIEW = 'kit-view',
}

type FilterType =
  | 'article_type'
  | 'brand'
  | 'in_stock_only'
  | 'media'
  | 'product'
  | 'season'
  | 'species'
  | 'structure'
  | 'technique'
  | 'waterbody';

type ItemType =
  | 'waterbody'
  | 'ambassador'
  | 'brand'
  | 'organization'
  | 'species'
  | 'article'
  | 'product'
  | 'product_family'
  | 'technique'
  | 'video';

export type ClickKey_AccountWidget =
  | '[account].(ambassador_link)'
  | '[account].(premium_link)'
  | '[account].(edit_profile_link)'
  | '[account].(view_dashboard)'
  | '[account].(account_logout_link)'
  | '[account].(fishing_report_link)'
  | '[account].(wishlist_link)'
  | '[account].(order_history_link)'
  | '[account].(email_preferences_link)';

type ClickKey_Cart =
  | '[cart].(line_item_product_click)'
  | '[cart].(premium_learn_more)'
  | '[cart].(deals_product_click)';

type ClickKey_DynamicSeo =
  | '[fishing_report].(user_link)'
  | '[fishing_report].(show_more)'
  | '[products].(view_all)'
  | '[gear_up].(category_link)'
  | '[expert].(get_started)'
  | '[expert].(personal_shopping)';

type ClickKey_FishingReportEmbedded =
  | '[reports].(full_report_link)'
  | '[reports].(lake_link)'
  | '[reports].(media_link)'
  | '[reports].(user_link)'
  | '[reports].(hotbait)'
  | '[reports].(products)';

type ClickKey_FishingReportDetails =
  | '[pg_navigation].(back_to_lake)'
  | '[pg_navigation].(breadcrumb)'
  | '[pg_navigation].(lake_link)'
  | '[articles].(bu_link)'
  | '[articles].(omnia_article_link)'
  | '[articles].(omnia_video_link)'
  | '[products].(recommended_product)'
  | '[products].(essentials_product)'
  | '[products].(shop_this_report)';

export type ClickKey_Footer =
  | '[footer].(about)'
  | '[footer].(app_store_apple)'
  | '[footer].(app_store_google)'
  | '[footer].(contact)'
  | '[footer].(content)'
  | '[footer].(legal)'
  | '[footer].(logo)'
  | '[footer].(shop)'
  | '[footer].(social)'
  | '[footer].(support)';

type ClickKey_HomePageContent =
  | '[homepage].(get_started_today)'
  | '[homepage].(ambassador)'
  | '[homepage].(article)'
  | '[homepage].(create_my_account)'
  | '[homepage].(create_my_account2)'
  | '[homepage].(go_to_dashboard)'
  | '[homepage].(go_to_dashboard2)'
  | '[homepage].(omnia_video)'
  | '[homepage].(premium_add)'
  | '[homepage].(premium_learn_more)'
  | '[homepage].(state)';

type ClickKey_HomePageProduct =
  | '[homepage].(popular_products_near_you)'
  | '[homepage].(best_sellers)'
  | '[homepage].(popular_products_for_lake)'
  | '[homepage].(recently_added)';

type ClickKey_HomePageWaterbody =
  | '[homepage].(popular_products_near_you)'
  | '[homepage].(popular_products_for_lake)';

export type ClickKey_LakePage =
  | '[ambassadors].(user_link)'
  | '[donut].(technique_selector)'
  | '[graph].(season_selector)'
  | '[learn_about].(bu_link)'
  | '[learn_about].(omnia_article_link)'
  | '[learn_about].(omnia_video_link)'
  | '[learn_more].(accordion)'
  | '[map].(expand)'
  | '[map].(pin)'
  | '[map].(satellite)'
  | '[map].(search)'
  | '[map].(zoom)'
  | '[nearby].(count_filter)'
  | '[nearby].(distance_filter)'
  | '[nearby].(lake)'
  | '[nearby].(map_link)'
  | '[overview].(fishing_reports)'
  | '[overview].(state_link)'
  | '[overview].(subscribe)'
  | '[overview].(view_lake_details)'
  | '[pg_navigation].(back_to_map)'
  | '[pg_navigation].(left_nav)'
  | '[products].(build_your_kit)'
  | '[products].(expert)'
  | '[reports].(all_filters)'
  | '[reports].(subscribe)'
  | '[reports].(subscribe2)'
  | '[style_articles].(bu_link)'
  | '[style_articles].(omnia_article_link)'
  | '[style_articles].(omnia_video_link)'
  | '[techniques].(store_link)'
  | '[tournaments].(view_details)';

type ClickKey_Map =
  | '[map_search].(form_field)'
  | '[map_search].(results_list)'
  | '[map].(pin)'
  | '[map].(zoom)'
  | '[map_modal].(lake_details_button)'
  | '[map_modal].(state_link)';

export type ClickKey_ProductDetailPage =
  | '[articles].(bu_link)'
  | '[articles].(omnia_article_link)'
  | '[articles].(omnia_video_link)'
  | '[complementary].(product_link)'
  | '[pg_navigation].(breadcrumbs)'
  | '[pg_navigation].(left_nav)'
  | '[product_images].(product_image_view)'
  | '[related].(product_link)'
  | '[reports].(full_report_link)'
  | '[reports].(lake_link)'
  | '[reports].(media_link)'
  | '[reports].(product_link)'
  | '[reports].(user_link)'
  | '[report].(all_filters)'
  | '[report].(clear_all_filters)'
  | '[sidebar].(reports_link)'
  | '[stats].(count)'
  | '[stats].(top_species)'
  | '[stats].(top_technique)'
  | '[stats].(top_variation)'
  | '[stats].(view_all)'
  | '[title].(related_reports_by_lake_link)'
  | '[title].(reports_count_link)'
  | '[variants_table].(related_reports_by_lake_link)';

export type ClickKey_TopNav =
  | '[top_nav].(logo)'
  | '[top_nav].(signup)'
  | '[top_nav].(login)'
  | '[top_nav].(cart)'
  | '[top_nav].(baits)'
  | '[top_nav].(line)'
  | '[top_nav].(rods)'
  | '[top_nav].(reels)'
  | '[top_nav].(terminal)'
  | '[top_nav].(accessories)'
  | '[top_nav].(apparel)'
  | '[top_nav].(marine)'
  | '[top_nav].(deals)'
  | '[top_nav].(ice)'
  | '[top_nav].(category2)'
  | '[top_nav].(find_lakes)'
  | '[top_nav].(hamburger)'
  | '[top_nav].(map)'
  | '[top_nav].(search)'
  | '[top_nav].(states)'
  | '[top_nav].(dashboard)'
  | '[top_nav].(report)'
  | '[top_nav].(brands)'
  | '[top_nav].(what is pro?)'
  | '[top_nav].(media)';

type ClickKey_Wishlist =
  | '[wishlist].(product_link)'
  | '[wishlist].(add_to_cart)'
  | '[wishlist].(delete)'
  | '[wishlist].(email_me)';

type ClickKey_GetStartedPage =
  | '[top_techniques].(learn_more)'
  | '[gear_up].(expert)'
  | '[gear_up].(product_link_specific)'
  | '[gear_up].(product_link_category)'
  | '[tips_tricks].(browse)'
  | '[tips_tricks].(article)'
  | '[explore_nearby].(lake_link)'
  | '[explore_nearby].(nearby_lake_link)'
  | '[cta].(get_recommendations)'
  | '[cta].(expert)';

export type ClickKey_ProductCard =
  | '[product_card].(image)'
  | '[product_card].(brand)'
  | '[product_card].(title)'
  | '[product_card].(stats_widget)'
  | '[product_card].(reported_on)'
  | '[product_card].(cta)'
  | '[product_card].(variant_img)'
  | '[product_card].(variant_plus)'
  | '[product_slice].(image)'
  | '[product_slice].(title)'
  | '[product_slice].(variant_img)'
  | '[product_slice].(reported_on)'
  | '[product_slice].(brand)'
  | '[product_slice].(stats_widget)';

export type SearchItemType =
  | 'waterbody'
  | 'ambassador'
  | 'brand'
  | 'media'
  | 'species'
  | 'product_family'
  | 'technique';

export type WaterTempDataTrackedActions =
  | 'widget'
  | 'sample_date'
  | 'learn_more'
  | 'activate'
  | 'premium'
  | 'toggle'
  | 'modal'
  | 'find_lake';

export type ClickKeyAmbassadorProfilePage =
  | 'content_tab_favorites'
  | 'content_tab_reports'
  | 'content_tab_media'
  | 'content_tab_products'
  | 'state_link'
  | 'lake_link'
  | 'social_link'
  | 'reports_filter'
  | 'book_guide'
  | 'book_guide_link'
  | 'share';

export interface LineItemProduct {
  sku: string;
  name: string;
  price: number;
  quantity: number;
  url: string;
  imageUrl: string;
}

export interface FishingReportOrder {
  report_id: number;
  position: number;
}

export interface SegmentSocialShareParams {
  location: 'report' | 'ambassador' | 'map_share_button' | 'map_more_button';
  type: 'api' | 'link';
  share_url: string;
  share_text?: string | null;
  share_title?: string | null;
  id?: number;
}

export interface AddToCartParams {
  product: Product;
  area: WebAnalyticsEventAreas;
  locationType?: LocationTypes;
  recommendation_id?: string;
  position?: number;
  list_uuid?: string;
}

export interface ProductClickParams {
  productOrProductFamily: Product | ProductFamily;
  show_modal: boolean;
  position: number;
  locationType?: LocationTypes;
  recommendation_id?: string;
  list_uuid?: string;
}

export type WaypointFilterType = 'waypoint_type' | 'waterbody' | 'date_created';
export type ProButtonLocation =
  | 'account_widget'
  | 'current_conditions_beneath_value_props'
  | 'current_conditions_hero'
  | 'current_conditions_map'
  | 'home_pro_section'
  | 'home_video_section'
  | 'map_layers_panel'
  | 'map_sidebar'
  | 'pdp_banner_top'
  | 'pdp_banner_top_popover'
  | 'pro_offer_beneath_comparison_chart'
  | 'pro_offer_beneath_value_props'
  | 'pro_offer_page_hero'
  | 'pro_page_beneath_chart'
  | 'pro_page_beneath_value_props'
  | 'pro_page_hero'
  | 'pro_upsell_modal'
  | 'omnia_video'
  | 'waterbody_detail_map';
export interface ProButtonClickParams {
  location: ProButtonLocation;
  type: 'purchase' | 'trial' | 'learn_more';
  style: 'button' | 'link' | 'logo';
}
export class WebAnalyticsClass {
  accountWidgetClick(args: {
    click_key: ClickKey_AccountWidget;
    logged_in: boolean;
    target_url?: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { click_key, logged_in, target_url } = args;
    webAnalytics.track('Top Nav Click', {
      click_key,
      logged_in,
      target_url: window.location.origin + target_url,
    });
  }

  addToCart(params: AddToCartParams) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { product, area, locationType, recommendation_id, position, list_uuid } = params;
    const pageType = locationType || getPageTypeByPathname(window.location.pathname);
    const { sku, shopify_product_handle, shopify_product_id, subcategory_name, brand, title } =
      product;
    webAnalytics.track('Add to Cart', {
      product_brand: brand,
      product_family_id: shopify_product_id,
      product_subcategory: subcategory_name,
      product_slug: shopify_product_handle,
      product_title: title,
      product_sku: sku,
      area,
      location: window.location.href,
      locationType: pageType,
      recommendation_id,
      position,
      list_uuid,
    });
  }

  ambassadorApplicationStep(status: 'viewed' | 'completed') {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Ambassador Application Step', {
      status,
    });
  }
  ambassadorProfilePageClick(click_key: ClickKeyAmbassadorProfilePage) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Ambassador Profile Page Click', {
      click_key,
      locationType: 'Ambassador',
    });
  }

  appStoreClick(app_store: 'apple' | 'google') {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('App Store Click', {
      app_store,
    });
  }

  articlesModalOpened(args: {
    article_title: string;
    omnia_brand: 'bass_utopia' | 'omnia';
    item_type: 'article' | 'video';
    target_url: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { article_title, omnia_brand, item_type, target_url } = args;
    webAnalytics.track('Articles Modal Opened', {
      article_title,
      item_type,
      omnia_brand,
      target_url,
    });
  }

  articlePageView(args: {
    article_title: string;
    article_slug: string;
    species_list: string[];
    style_list: string[];
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.page(args);
  }

  omniaVideoPageView(args: { video_title: string; video_pub_date: string; video_slug: string }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();

    webAnalytics.page(args);
  }

  cartViewed(cart_total_amount: number, cart_total_items: number, cart_total_quantity: number) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Cart Viewed', {
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartClick(
    click_key: ClickKey_Cart,
    args: {
      cart_total_amount: number;
      cart_total_items: number;
      cart_total_quantity: number;
    }
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { cart_total_amount, cart_total_items, cart_total_quantity } = args;
    webAnalytics.track('Cart Click', {
      click_key,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartPromoAdded(args: {
    code: string;
    cart_discount_amount: number;
    cart_total_amount: number;
    cart_total_items: number;
    cart_total_quantity: number;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { code, cart_discount_amount, cart_total_amount, cart_total_items, cart_total_quantity } =
      args;
    webAnalytics.track('Cart Promo Code Added', {
      code,
      cart_discount_amount,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartPromoRemoved(args: {
    code: string;
    cart_discount_amount: number;
    cart_total_amount: number;
    cart_total_items: number;
    cart_total_quantity: number;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { code, cart_discount_amount, cart_total_amount, cart_total_items, cart_total_quantity } =
      args;
    webAnalytics.track('Cart Promo Code Removed', {
      code,
      cart_discount_amount,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartDealAdded(args: {
    deal_message: string;
    cart_total_amount: number;
    cart_total_items: number;
    cart_total_quantity: number;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { deal_message, cart_total_amount, cart_total_items, cart_total_quantity } = args;
    webAnalytics.track('Cart Deal Added', {
      deal_message,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartRewardsAdded(args: {
    cart_rewards_amount: number;
    cart_total_amount: number;
    cart_total_items: number;
    cart_total_quantity: number;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { cart_rewards_amount, cart_total_amount, cart_total_items, cart_total_quantity } = args;
    webAnalytics.track('Cart Rewards Added', {
      cart_rewards_amount,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  cartRewardsRemoved(args: {
    cart_rewards_amount: number;
    cart_total_amount: number;
    cart_total_items: number;
    cart_total_quantity: number;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { cart_rewards_amount, cart_total_amount, cart_total_items, cart_total_quantity } = args;
    webAnalytics.track('Cart Rewards Removed', {
      cart_rewards_amount,
      cart_total_amount,
      cart_total_items,
      cart_total_quantity,
    });
  }

  contentFiltered(filter_type: FilterType, filter_value: string | boolean) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Content Filtered', {
      locationType: getPageTypeByPathname(window.location.pathname),
      filter_type,
      filter_value,
    });
  }

  contentSorted(sortType: 'nearby' | 'most_recent') {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Content Filtered', {
      locationType: getPageTypeByPathname(window.location.pathname),
      sortType,
    });
  }

  dynamicKitScreenViewed(
    screen_name:
      | 'initial_view'
      | 'change_option'
      | 'swap_product_screen1'
      | 'swap_product_screen2'
      | 'add_product_screen1'
      | 'add_product_screen2'
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Dynamic Kit Screen Viewed', {
      screen_name,
    });
  }

  dynamicSeoPageClick(
    click_key: ClickKey_DynamicSeo,
    args: {
      product_category: CategoryNames;
      product_subcategory: string;
      technique: string;
      keyword_slug: string;
    }
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { product_category, product_subcategory, technique, keyword_slug } = args;
    webAnalytics.track('Dynamic SEO Page Click', {
      click_key,
      product_category,
      product_subcategory,
      style: technique,
      technique,
      keyword_slug,
    });
  }

  fishingIntentChange(args: {
    seasonGroupName?: string;
    techniqueName?: string;
    speciesName?: string;
    waterbodyId?: string;
    stateAbbreviation?: string;
  }) {
    const { seasonGroupName, techniqueName, speciesName, waterbodyId, stateAbbreviation } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();

    webAnalytics.track('Fishing Intent Change', {
      seasonGroup: seasonGroupName || null,
      technique: techniqueName || null,
      species: speciesName || null,
      waterbody: waterbodyId || null,
      state: stateAbbreviation || null,
      location: window.location.pathname,
    });
  }

  fishingReportStarted(waterbody_slug: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report Started', {
      waterbody_slug,
      locationType: getPageTypeByPathname(window.location.pathname),
    });
  }

  fishingReportCompleted(report: FishingReport) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report Completed', {
      waterbody_slug: report.waterbody.url_slug,
      waterbody_name: report.waterbody.primary_name,
      waterbody_img_url: waterbodyImgUrl(report.waterbody, {
        imgSize: {
          width: 100,
          height: 80,
          padding: 10,
        },
        hires: false,
      }),
      forage: report.forage?.name,
      has_description: report.comments != null,
      has_hotbait: report.featured_product != null,
      has_photo: report.image != null,
      has_video: report.video != null,
      locationType: getPageTypeByPathname(window.location.pathname),
      season: report.season.name,
      species: report.species.name,
      structure: report.structure.name,
      style: report.technique.name,
      technique: report.technique.name,
    });
  }

  fishingReportDetailsClick(click_key: ClickKey_FishingReportDetails, report_slug: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report Details Click', {
      click_key,
      report_slug,
    });
  }

  fishingReportEmbeddedClick(
    click_key: ClickKey_FishingReportEmbedded,
    report: FishingReport,
    context: 'embedded' | 'standalone',
    position: number
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report Embedded Click', {
      click_key,
      context,
      locationType: getPageTypeByPathname(window.location.pathname),
      position,
      report_id: report.id,
      report_slug: report.slug,
      report_user_id: report.user.id,
      waterbody_slug: report.waterbody.url_slug,
    });
  }

  fishingReportPageView(args: {
    report_slug: string;
    season: string;
    species: string;
    structure: string;
    technique: string;
    has_description: boolean;
    has_hotbait: boolean;
    has_photo: boolean;
    has_video: boolean;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.page({ ...args, style: args.technique });
  }

  fishingReportListViewed(
    context: 'modal' | 'embedded' | 'standalone',
    reports: FishingReportOrder[]
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report List Viewed', {
      context,
      locationType: getPageTypeByPathname(window.location.pathname),
      reports,
    });
  }

  fishingReportReadMoreClicked(report_id: number) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Fishing Report Read More Clicked', {
      report_id,
    });
  }

  followUpEnabled(email: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Follow-up Enabled', {
      email,
    });
  }

  footerNavClick(args: {
    click_key: ClickKey_Footer;
    logged_in: boolean;
    target_url?: string;
    isExternal_url?: boolean;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { click_key, logged_in, target_url, isExternal_url } = args;
    webAnalytics.track('Footer Nav Click', {
      click_key,
      logged_in,
      target_url: isExternal_url ? target_url : window.location.origin + target_url,
    });
  }

  getRudderstackAnonymousId() {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    return webAnalytics.rudderstackAnonymousId({
      autoCapture: {
        enabled: true,
        source: 'segment',
      },
    }) as unknown as undefined | string;
  }

  getRudderstackExperimentUuid(): string {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    // goofy typing because the bot check
    const userTraits = webAnalytics.rudderstackGetUserTraits() as unknown as Record<string, string>;
    return userTraits?.[EXPERIMENT_UUID];
  }

  getStartedShare(args: {
    season: string;
    species: string;
    technique: string;
    waterbody_slug: string;
  }) {
    const { season, species, technique, waterbody_slug } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Get Started Share', {
      season,
      species,
      style: technique,
      technique,
      waterbody_slug,
    });
  }

  getStartedPageClick(
    click_key: ClickKey_GetStartedPage,
    args: {
      season: string;
      species: string;
      technique: string;
      waterbody_slug: string;
    }
  ) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { season, species, technique, waterbody_slug } = args;
    webAnalytics.track('Get Started Page Click', {
      click_key,
      season,
      species,
      style: technique,
      technique,
      waterbody_slug,
    });
  }

  getStartedSavePrefs(args: {
    season: string;
    species: string;
    technique: string;
    waterbody_slug: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { season, species, technique, waterbody_slug } = args;
    webAnalytics.track('Get Started Save Preferences', {
      season,
      species,
      style: technique,
      technique,
      waterbody_slug,
    });
  }

  getStartedFormStatus(status: 'started' | 'completed', species: string, waterbody_slug: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const props =
      status === 'completed'
        ? {
            status,
            species,
            waterbody_slug,
          }
        : { status };

    webAnalytics.track('Get Started Form Status', props);
  }

  homepageAdClick(hero_img_url: string, target_url: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Homepage Ad Click', {
      hero_img_url,
      target_url,
    });
  }

  homepageContentClick(click_key: ClickKey_HomePageContent, target_url?: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Homepage Content Click', {
      click_key,
      target_url: window.location.origin + target_url,
    });
  }

  homepageProductClick(click_key: ClickKey_HomePageProduct, product: Product) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Homepage Product Click', {
      click_key,
      product_family_id: product.shopify_product_id,
      product_brand: product.brand,
      product_subcategory: product.subcategory_name,
      product_sku: product.sku,
      product_slug: product.shopify_product_handle,
      product_title: product.title,
      target_url:
        window.location.origin +
        OmniaUrls.product(product.shopify_product_handle, product.shopify_options),
    });
  }

  homepageWaterbodyClick(click_key: ClickKey_HomePageWaterbody, waterbody_slug: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Homepage Waterbody Click', {
      click_key,
      waterbody_slug,
    });
  }

  itemFavorited(item_type: ItemType, slug: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Item Favorited', {
      item_type,
      slug,
    });
  }

  kitModalOpened(kit_intent: KitIntent) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Kit Modal Opened', kit_intent);
  }

  lakePageClick(
    click_key: ClickKey_LakePage,
    args: { season: string; species: string; technique: string; waterbody_slug: string }
  ) {
    const { season, species, technique, waterbody_slug } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Lake Page Click', {
      click_key,
      season,
      species,
      style: technique,
      technique,
      waterbody_slug,
    });
  }

  mapClick(click_key: ClickKey_Map, waterbody_slug?: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Map Click', {
      click_key,
      waterbody_slug,
    });
  }

  mapRendered(args: {
    bounds: {
      north: number;
      south: number;
      east: number;
      west: number;
    }; // These are the WGS84 coordinates of the bounding box of the map view
    layers: string[];
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Map Rendered', args);
  }

  mapLayerChanged(mapLayerState: MapLayerState) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Map Layer Changed', mapLayerState);
  }

  mapInteracted(args: {
    point: {
      lat: number;
      lon: number;
    };
    type: 'pin' | 'fishing_report' | 'boat_landing' | 'waypoint';
    type_id: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Map Interacted', args);
  }

  mapFirstPartyLayerInteracted(args: {
    layer_type: 'historical_temp' | 'near_real_time_temp' | 'clarity';
    interaction_type: 'slider' | 'waterbody';
    waterbody_id: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Map First Party Layer Interacted', args);
  }

  outOfStockNotified(product: Product, area: WebAnalyticsEventAreas) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Out of Stock Notified', {
      locationType: getPageTypeByPathname(window.location.pathname),
      area,
      product_family_id: product.shopify_product_id,
      product_brand: product.brand,
      product_subcategory: product.subcategory_name,
      product_sku: product.sku,
      product_slug: product.shopify_product_handle,
      product_title: product.title,
    });
  }

  page(...args: Parameters<typeof page>) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.page(args);
  }

  productAdded(args: {
    productOrVariant: Product | ShopifyVariant;
    quantity: number;
    cart_id: string;
    coupon: string;
    position: number;
    total: number;
    products: LineItemProduct[];
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { cart_id, productOrVariant, quantity, coupon, position, total, products } = args;
    if (isVariant(productOrVariant)) {
      const variant = productOrVariant;
      const variantId = isBase64(variant.id) ? atob(variant.id) : variant.id;
      const productId = isBase64(variant.product.id)
        ? atob(variant.product.id)
        : variant.product.id;
      webAnalytics.track('Product Added', {
        cart_id,
        quantity,
        coupon,
        position,
        product_id: productId.split('/').pop(),
        sku: variant.sku,
        category: variantProductTypeToSubcategoryName(variant.product.productType),
        name: `${variant.product.title} ${variant.title}`,
        brand: brandTransformName(variant.product.vendor),
        variant: variantId.split('/').pop(),
        price: Number(variant.price.amount),
        total,
        products,
        url:
          window.location.origin +
          OmniaUrls.product(variant.product.handle, variant.selectedOptions),
        image_url: variant.image.url,
      });
    } else {
      const product = productOrVariant;
      webAnalytics.track('Product Added', {
        cart_id,
        quantity,
        coupon,
        position,
        product_id: product.shopify_product_id,
        sku: product.sku,
        category: product.subcategory_name,
        name: product.title,
        brand: product.brand,
        variant: product.shopify_variant_id,
        price: Number(product.price),
        total,
        products,
        url:
          window.location.origin +
          OmniaUrls.product(product.shopify_product_handle, product.shopify_options),
        image_url: product.img_url,
      });
    }
  }

  proButtonClicked(args: ProButtonClickParams) {
    const { location, type, style } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Pro Button Clicked', {
      location,
      type,
      style,
    });
  }

  productRemoved(args: {
    variant: ShopifyVariant;
    quantity: number;
    cart_id: string;
    coupon: string;
    total: number;
    products: LineItemProduct[];
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { cart_id, variant, quantity, coupon, total, products } = args;
    const variantId = isBase64(variant.id) ? atob(variant.id) : variant.id;
    const productId = isBase64(variant.product.id) ? atob(variant.product.id) : variant.product.id;
    webAnalytics.track('Product Removed', {
      cart_id,
      product_id: productId.split('/').pop(),
      sku: variant.sku,
      category: variantProductTypeToSubcategoryName(variant.product.productType),
      name: `${variant.product.title} ${variant.title}`,
      brand: brandTransformName(variant.product.vendor),
      variant: variantId.split('/').pop(),
      price: Number(variant.price.amount),
      total,
      products,
      quantity,
      coupon,
      position: null,
      url:
        window.location.origin + OmniaUrls.product(variant.product.handle, variant.selectedOptions),
      image_url: variant.image.url,
    });
  }

  productAddedToWishlist(product: Product) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Product Added to Wishlist', {
      product_brand: product.brand,
      product_family_id: product.shopify_product_id,
      product_subcategory: product.subcategory_name,
      product_sku: product.sku,
      product_slug: product.shopify_product_handle,
      product_title: product.title,
    });
  }

  productCardClick(params: {
    card_type: 'Product' | 'ProductFamily';
    click_key: ClickKey_ProductCard;
    product_family_id: number;
    product_sku: string | null;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Product Card Clicked', params);
  }

  productCategoryFiltered(args: {
    category_name: CategoryNames;
    subcategory_name: string;
    filter_type: FilterType;
    filter_value: string | boolean;
    sort_type: SortValues;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { category_name, subcategory_name, filter_type, filter_value, sort_type } = args;
    webAnalytics.track('Product Category Filtered', {
      product_category: category_name,
      product_subcategory: subcategory_name,
      filter_type,
      filter_value,
      sort_type,
    });
  }

  productClick(args: ProductClickParams) {
    const {
      productOrProductFamily,
      show_modal,
      position,
      locationType,
      recommendation_id,
      list_uuid,
    } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();

    const isPf = isProductFamily(productOrProductFamily);
    webAnalytics.track('Product Click', {
      product_family_id: isPf
        ? String(productOrProductFamily.id)
        : productOrProductFamily.shopify_product_id,
      product_brand: productOrProductFamily.brand,
      product_subcategory: isPf
        ? productOrProductFamily.subcategory
        : productOrProductFamily.subcategory_name,
      product_sku: isPf ? null : productOrProductFamily.sku,
      product_slug: isPf
        ? productOrProductFamily.handle
        : productOrProductFamily.shopify_product_handle,
      product_title: productOrProductFamily.title,
      locationType: locationType || getPageTypeByPathname(window.location.pathname),
      show_modal,
      position,
      recommendation_id,
      list_uuid,
    });
  }

  productDetailPageClick(click_key: ClickKey_ProductDetailPage) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Product Details Page Click', {
      click_key,
    });
  }

  productDetailPageView(args: {
    product_title: string;
    product_brand: string;
    product_category: string;
    product_sku: string;
    product_slug: string;
    variant_out_of_stock: boolean;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.page(args);
  }

  productFamilyListViewed(params: {
    coupon: string;
    list_id: string;
    productFamilies: ProductFamily[] | AlgoliaProductFamily[];
    recommendation_id?: string;
    list_uuid?: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { list_id, productFamilies, coupon, recommendation_id, list_uuid } = params;
    if (!productFamilies.length) {
      return;
    }

    webAnalytics.track('Product List Viewed', {
      list_id,
      category: 'families',
      recommendation_id,
      products: productFamilies.slice(0, 20).map((pf, i) => ({
        product_id: pf.id.toString(),
        sku: pf.handle,
        category: pf.subcategory,
        name: pf.title,
        brand: pf.brand,
        variant: null,
        price: pf.price_max,
        quantity: 1,
        coupon,
        position: i,
        url: window.location.origin + OmniaUrls.productFamily(pf.handle),
        image_url: pf.img_url,
      })),
      list_uuid,
    });
  }

  productListViewed(params: {
    coupon: string;
    list_id: string;
    products: Product[];
    recommendation_id?: string;
    list_uuid: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { list_id, products, coupon, recommendation_id, list_uuid } = params;

    if (!products.length) {
      return;
    }

    webAnalytics.track('Product List Viewed', {
      list_id,
      category: 'products',
      recommendation_id,
      list_uuid,
      products: products.map((p, i) => ({
        product_id: p.id.toString(),
        sku: p.sku,
        category: p.subcategory_name,
        name: p.title,
        brand: p.brand,
        variant: p.shopify_variant_id,
        price: parseFloat(p.price),
        quantity: isProductInStock(p) ? 1 : 0,
        coupon,
        position: i,
        url:
          window.location.origin + OmniaUrls.product(p.shopify_product_handle, p.shopify_options),
        image_url: p.img_url,
      })),
    });
  }

  promoBarClick(banner_content: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Promo Bar Click', {
      banner_content,
    });
  }

  scrollToTopClick() {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Scroll to Top Click');
  }

  shortUrlClicked(short_url_id: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Short Link Click', {
      short_url_id,
      target_url: window.location.href,
    });
  }

  searchResultClick(search_term: string, item_type: SearchItemType, target_url: string) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const isBassU = target_url.includes('bassutopia.com/articles');
    const target_url_full = isBassU ? target_url : window.location.origin + target_url;
    webAnalytics.track('Search Result Click', { search_term, item_type, target_url_full });
  }

  searchResultFilter(search_term: string, search_filter: TabHashes) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    if (search_filter === TabHashes.top) {
      return;
    }
    webAnalytics.track('Search Filter Click', { search_term, search_filter });
  }

  socialShare(params: SegmentSocialShareParams) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Social Share', params);
  }

  topNavClick(args: { click_key: ClickKey_TopNav; logged_in: boolean; target_url?: string }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { click_key, logged_in, target_url } = args;
    webAnalytics.track('Top Nav Click', {
      click_key,
      logged_in,
      target_url: window.location.origin + target_url,
    });
  }

  videoShoppableItemClick(args: {
    video_title: string;
    video_pub_date: string;
    video_slug: string;
    is_livestream: boolean;
    is_top_item: boolean;
    item_id: string;
    item_type: OmniaVideoItemTypes;
  }) {
    const {
      video_title,
      video_pub_date,
      video_slug,
      is_livestream,
      is_top_item,
      item_id,
      item_type,
    } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Shoppable Video Item Click', {
      video_title,
      video_pub_date,
      video_slug,
      is_livestream,
      is_top_item,
      item_id,
      item_type,
    });
  }

  videoShoppablePlaybackStatus(args: {
    video_title: string;
    video_pub_date: string;
    video_slug: string;
    status: string;
  }) {
    const { video_title, video_pub_date, video_slug, status } = args;
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Shoppable Video Playback Status', {
      video_title,
      video_pub_date,
      video_slug,
      status,
    });
  }

  waterbodyPageView(args: {
    season: string;
    species: string;
    technique: string;
    waterbody_slug: string;
  }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.page({ ...args, style: args.technique });
  }

  waterbodyShared(args: { id: string }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { id } = args;
    webAnalytics.track('Waterbody Shared', {
      id,
    });
  }

  waterTempDataTracked(args: { action: WaterTempDataTrackedActions; premium: boolean }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { action, premium } = args;
    webAnalytics.track('Water Temp Data Tracked', {
      action,
      premium,
    });
  }

  waypointShared(args: { id_hash: string }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Waypoint Shared', args);
  }

  waypointFiltered(args: { filter_values: WaypointFilterType[] }) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Waypoints Filtered', args);
  }

  wishlistShare() {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    webAnalytics.track('Wishlist Share');
  }

  wishlistItemClick(click_key: ClickKey_Wishlist, product: Product) {
    const webAnalytics = INTERNALS.getAnalyticsWithGlobalProperties();
    const { sku, shopify_product_handle, subcategory_name, brand, title, shopify_product_id } =
      product;
    webAnalytics.track('Wishlist Item Click', {
      click_key,
      product_sku: sku,
      product_family_id: shopify_product_id,
      product_brand: brand,
      product_subcategory: subcategory_name,
      product_slug: shopify_product_handle,
      product_title: title,
    });
  }
}

export const WebAnalytics = new WebAnalyticsClass();
