import { NextFunction } from 'connect';
import { Store } from 'redux';
import { ReduxActions } from '../constants/redux_actions';
import { WebViewEvents } from '../lib/webview_events';
import { CartActions } from './cart';
import { FishingReportModalActions } from './fishing_report_modal';
import { RouterActions } from './router';

const createWebViewMiddleware =
  () =>
  (store: Store) =>
  (next: NextFunction) =>
  (action: CartActions | FishingReportModalActions | RouterActions) => {
    if (action) {
      switch (action.type) {
        case ReduxActions.ROUTER_LOCATION_CHANGE: {
          WebViewEvents.urlChange(action.payload.path);
          break;
        }

        case ReduxActions.CART_OPEN: {
          WebViewEvents.cartOpen();
          break;
        }

        case ReduxActions.CART_ITEM_ADD_SUCCESS: {
          if (action.payload.notification === 'cart') {
            WebViewEvents.cartOpen();
          }
          break;
        }

        case ReduxActions.FISHING_REPORT_MODAL_OPEN: {
          WebViewEvents.fishingReportModalOpen(action.payload.waterbody);
          break;
        }

        default:
          break;
      }
    }

    return next(action);
  };

export const WebViewMiddleware = createWebViewMiddleware();
