import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isProd } from '../../env';
import { waitFor } from '../../lib/wait_for';
import { RoutePaths } from '../../routes';

export const AppsFlyerSmartBannerResolver = (): null => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (isProd() && !pathname.startsWith(RoutePaths.MAP)) {
      waitFor(() => typeof (window as any).AF !== 'undefined', 10_000).then(() => {
        (window as any).AF('banners', 'showBanner');
      });
    }
    return () => {
      if (isProd() && !pathname.startsWith(RoutePaths.MAP)) {
        waitFor(() => typeof (window as any).AF !== 'undefined', 10_000).then(() => {
          (window as any).AF('banners', 'hideBanner');
        });
      }
    };
  }, [pathname]);
  return null;
};
