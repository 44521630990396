import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../helpers/app_state';
import {
  getContainsAllColors,
  getContext,
  getListUuid,
  getProductFamily,
  getProducts,
  getRecommendationId,
  isOpen,
  ProductFamilyQuickModalActions,
} from '../../redux/product_family_quick_modal';
import { getBrandsNotHidden } from '../../redux/reference_data';
import { getPath } from '../../redux/router';
import { getUser } from '../../redux/user';
import { ProductFamilyQuickModal } from './product_family_quick_modal';

function mapStateToProps(state: ApplicationState, {}) {
  return {
    productFamily: getProductFamily(state),
    products: getProducts(state),
    visible: isOpen(state),
    brands: getBrandsNotHidden(state),
    context: getContext(state),
    containsAllColors: getContainsAllColors(state),
    user: getUser(state),
    recommendation_id: getRecommendationId(state),
    path: getPath(state),
    list_uuid: getListUuid(state),
  };
}

const dispatchProps = {
  close: ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_CLOSE,
  clear: ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_CLEAR,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(dispatchProps, dispatch);

const ProductFamilyQuickModalContainer = connect(
  mapStateToProps,
  dispatchProps
)(ProductFamilyQuickModal);

export type ProductFamilyQuickModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default ProductFamilyQuickModalContainer;
