import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerPrecipitation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 36 36" {...props}>
    <path
      d="M33.337 18.14c-.008-.073-.018-.118-.023-.184-.636-7.389-6.599-13.274-13.869-13.973V2.272a1.45 1.45 0 1 0-2.9 0v1.711c-7.27.698-13.233 6.584-13.869 13.973-.009.107-.011.164-.018.306-.019.631.251 1.182.806 1.464a1.45 1.45 0 0 0 1.949-.637c.507-.999 1.817-2.01 3.341-2.01 1.534 0 2.707 1.022 3.293 1.951.131.289.353.54.658.695.159.081.327.12.494.138.04.005.08.012.12.014.014 0 .027.005.041.005.019 0 .036-.007.054-.007.238-.004.479-.06.699-.188.292-.169.498-.427.614-.718.354-.62 1.004-1.222 1.818-1.574v12.872c0 .013.003.026.004.039 0 .013-.004.026-.004.039a1.934 1.934 0 0 1-3.867 0 1.45 1.45 0 1 0-2.9 0c0 2.665 2.168 4.833 4.833 4.833s4.834-2.168 4.834-4.833c0-.013-.003-.026-.004-.039 0-.013.004-.026.004-.039V17.409a4.423 4.423 0 0 1 1.929 1.751c.021.035.047.064.069.096.029.042.056.085.09.124.038.044.081.081.124.12.027.025.052.052.082.075.058.045.121.083.185.119.018.01.032.023.05.032l.006.002c.095.048.195.084.299.11.031.008.062.01.094.016.069.013.139.022.21.025.015 0 .03.005.045.005.021 0 .04-.007.06-.008a1.46 1.46 0 0 0 .271-.037c.028-.007.056-.011.083-.019a1.46 1.46 0 0 0 .313-.13l.015-.011.031-.023a1.436 1.436 0 0 0 .312-.268c.078-.089.147-.184.201-.287.002-.005.006-.008.008-.012.507-.998 1.816-2.009 3.34-2.009 1.503 0 2.779 1.045 3.379 2.08.4.692 1.29.929 1.981.528.55-.319.77-.858.716-1.547Zm-10.74-2.028c-1.116-1.063-2.688-1.933-4.603-1.933s-3.522.832-4.651 1.921c-1.115-1.057-2.682-1.921-4.59-1.921-.837 0-1.608.171-2.316.44 1.872-4.578 6.402-7.806 11.557-7.806s9.692 3.233 11.562 7.816a6.247 6.247 0 0 0-2.295-.451c-1.923 0-3.535.839-4.664 1.934"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default SvgLayerPrecipitation;
