import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWaypointHazardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <circle cx={12.5} cy={12.5} r={11.5} fill="#B91C1C" stroke="#fff" strokeWidth={2} />
    <g clipPath="url(#waypoint_hazard_icon_svg__a)">
      <path
        fill="#fff"
        d="m12.5 5.083-7 12.834h14zm-.583 4.667h1.166v4.083h-1.166zm.583 6.563a.73.73 0 1 1 0-1.46.73.73 0 0 1 0 1.46"
      />
    </g>
    <defs>
      <clipPath id="waypoint_hazard_icon_svg__a">
        <path fill="#fff" d="M5.5 4.5h14v14h-14z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWaypointHazardIcon;
