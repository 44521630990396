import { useDispatch } from 'react-redux';
import { FlashMessageActions, FlashMessageType } from '../redux/flash_message';

export interface FlashMessageArgs {
  header: string;
  subheader: string;
  seconds?: number;
  type?: FlashMessageType;
}

export const useFlashMessage = () => {
  const dispatch = useDispatch();

  const setFlashMessage = (args: FlashMessageArgs) => {
    const { header, subheader, seconds, type } = args;
    const params: FlashMessageArgs = {
      header,
      subheader,
    };

    if (seconds) {
      params.seconds = seconds;
    }
    if (type) {
      params.type = type;
    }

    dispatch(FlashMessageActions.FLASH_MESSAGE_SET(params));
  };
  return { setFlashMessage };
};
