import { NextFunction } from 'connect';
import { Store } from 'redux';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { StorageKeys } from '../lib/local_storage';
import { StateKeys, UserPreferencesActions, userPreferencesReducerName } from './user_preferences';

const createUserPreferencesMiddleware =
  () => (store: Store) => (next: NextFunction) => (action: UserPreferencesActions) => {
    // next first so the reducer has run
    next(action);
    const state = store.getState() as ApplicationState;

    if (action) {
      switch (action.type) {
        case ReduxActions.USER_PREFERENCES_SPECIES_SAVE: {
          localStorage.setItem(
            StorageKeys.userPreferencesSpecies,
            JSON.stringify(state[userPreferencesReducerName][StateKeys.species])
          );
          break;
        }

        case ReduxActions.USER_PREFERENCES_BRAND_SAVE: {
          localStorage.setItem(
            StorageKeys.userPreferencesBrands,
            JSON.stringify(state[userPreferencesReducerName][StateKeys.brands])
          );
          break;
        }

        case ReduxActions.USER_PREFERENCES_TECHNIQUE_SAVE: {
          localStorage.setItem(
            StorageKeys.userPreferencesTechniques,
            JSON.stringify(state[userPreferencesReducerName][StateKeys.techniques])
          );
          break;
        }

        case ReduxActions.USER_PREFERENCES_WATERBODY_SAVE: {
          localStorage.setItem(
            StorageKeys.userPreferencesWaterbodies,
            JSON.stringify(state[userPreferencesReducerName][StateKeys.waterbodies])
          );
          break;
        }

        default:
          break;
      }
    }
  };

export const UserPreferencesMiddleware = createUserPreferencesMiddleware();
