import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { OmniaButton } from '../omnia_button/omnia_button';

interface QuantityInputProps {
  className?: string;
  disabled?: boolean;
  max?: number;
  onChange: (quantity: number) => void;
  quantity: number;
}

export const QuantityInput = (props: QuantityInputProps) => {
  const { quantity, onChange, max, disabled, className } = props;
  return (
    <div className={classNames('inline-flex gap-1', className)}>
      <OmniaButton
        kind="tertiary"
        size="md"
        shape="square"
        isDisabled={disabled || quantity === 1}
        onPress={() => {
          if (quantity === 1) {
            return;
          }
          onChange(quantity - 1);
        }}
      >
        <MinusOutlined />
      </OmniaButton>
      <input
        inputMode="numeric"
        onChange={(e) => {
          const inputValue = e.target.value;
          const numericValue = inputValue.replace(/[^0-9]/g, '');

          if (numericValue === '') {
            return;
          }

          onChange(Number(numericValue));
        }}
        value={quantity}
        min={1}
        max={max}
        className="appearance-none border border-gray-300 text-center rounded-lg w-10 hover:border-blue-950  focus:border-blue-950 focus:outline-none focus:ring-2 "
      />

      <OmniaButton
        kind="tertiary"
        size="md"
        shape="square"
        isDisabled={disabled || (max && quantity === max)}
        onPress={() => {
          if (max && quantity === max) {
            return;
          }
          onChange(quantity + 1);
        }}
      >
        <PlusOutlined />
      </OmniaButton>
    </div>
  );
};
