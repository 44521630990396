import { FishingReport, WaterbodyDetail } from '@omniafishing/core';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';
import { FishingReportsActions } from './fishing_reports';

export const reducerName = 'fishingReportModal';

export enum StateKeys {
  fishingReport = 'fishingReport',
  loadingState = 'loadingState',
  open = 'open',
  waterbody = 'waterbody',
}

export const initialState = {
  [StateKeys.fishingReport]: null as FishingReport,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.open]: false,
  [StateKeys.waterbody]: null as WaterbodyDetail | null,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const isOpen = (state: ApplicationState) => state[reducerName][StateKeys.open];
export const getWaterbody = (state: ApplicationState) => state[reducerName][StateKeys.waterbody];
export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getFishingReport = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReport];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function FishingReportModalReducer(
  state = initialState,
  action: FishingReportModalActions | FishingReportsActions
) {
  switch (action.type) {
    case ReduxActions.FISHING_REPORT_MODAL_OPEN:
      return {
        ...state,
        [StateKeys.open]: true,
        [StateKeys.waterbody]: action.payload.waterbody,
        [StateKeys.fishingReport]: action.payload.fishingReport,
      };

    case ReduxActions.FISHING_REPORT_MODAL_CLOSE:
      return {
        ...state,
        [StateKeys.open]: false,
        [StateKeys.waterbody]: null,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const FishingReportModalActions = {
  FISHING_REPORT_MODAL_OPEN: (
    waterbody: WaterbodyDetail = null,
    fishingReport: FishingReport = null
  ) => createAction(ReduxActions.FISHING_REPORT_MODAL_OPEN, { waterbody, fishingReport }),
  FISHING_REPORT_MODAL_CLOSE: () => createAction(ReduxActions.FISHING_REPORT_MODAL_CLOSE),
};
export type FishingReportModalActions = ActionsUnion<typeof FishingReportModalActions>;
