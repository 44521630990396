import { FishingReport, Product, Species } from '@omniafishing/core';
import { Button, Popover, PopoverProps, Progress } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isPending, LoadingState } from '../../constants/loading_state';
import {
  fishingReportContainsSku,
  fishingReportContainsSpecieName,
  fishingReportContainsTechniqueName,
} from '../../lib/fishing_reports';
import { getSpecies, getTechniques } from '../../redux/reference_data';
import Loading from '../loading/loading';
import styles from './fishing_reports_stats_popover.less';

interface FishingReportsStatsPopoverProps {
  children: React.ReactNode;
  className?: string;
  fishingReports: FishingReport[];
  getPopupContainer?: () => HTMLElement;
  hideArrow?: boolean;
  hideTechniquesSection?: boolean;
  hideViewAllLink?: boolean;
  loadingState?: LoadingState;
  onClick?: () => void;
  onProductSkuChange?: (val: string) => void;
  onSpeciesNameChange?: (val: string) => void;
  onTechniqueNameChange?: (val: string[]) => void;
  onViewAllReportsClick: () => void;
  onVisibleChange?: (visible: boolean) => void;
  placement?: PopoverProps['placement'];
  products: Product[];
  visible?: boolean;
}

interface ProductAndReportsCount {
  product: Product;
  reportCount: number;
}

interface SpeciesAndReportsCount {
  species: Species;
  reportCount: number;
}

export const FishingReportsStatsPopover = (props: FishingReportsStatsPopoverProps) => {
  const {
    children,
    className,
    fishingReports,
    getPopupContainer,
    hideArrow,
    hideViewAllLink,
    hideTechniquesSection,
    loadingState,
    onClick,
    onProductSkuChange,
    onSpeciesNameChange,
    onTechniqueNameChange,
    onViewAllReportsClick,
    onVisibleChange,
    placement,
    products,
    visible,
  } = props;

  const allSpecies = useSelector(getSpecies);
  const allTechniques = useSelector(getTechniques);
  const [open, setOpen] = useState(visible || false);

  const topProductsAndCounts: ProductAndReportsCount[] = products
    .map((product) => {
      const reportCount = fishingReports.filter((report) => {
        return fishingReportContainsSku(report, product.sku);
      }).length;

      return { product, reportCount };
    })
    .sort((a, b) => b.reportCount - a.reportCount)
    .slice(0, 3);

  const topSpeciesAndCounts: SpeciesAndReportsCount[] = allSpecies
    .map((species) => {
      const reportCount = fishingReports.filter((report) => {
        return fishingReportContainsSpecieName(report, species.name);
      }).length;

      return { species, reportCount };
    })
    .sort((a, b) => b.reportCount - a.reportCount)
    .slice(0, 3);

  const topTechniquesAndCounts = allTechniques
    .map((technique) => {
      const reportCount = fishingReports.filter((report) =>
        fishingReportContainsTechniqueName(report, technique.name)
      ).length;

      return { technique, reportCount };
    })
    .sort((a, b) => b.reportCount - a.reportCount)
    .slice(0, 3);

  const trailColor = '#002B3A';
  const strokeColor = '#E84B25';

  const showViewAllReportsLink = !hideViewAllLink;

  const popoverContent = (
    <>
      {loadingState && isPending(loadingState) ? (
        <Loading
          color={'#fff'}
          className={classNames(styles.loading)}
          text="Loading Fishing Reports"
        />
      ) : (
        <>
          <div className={styles.popoverContainer}>
            <div className={styles.topVariations}>
              <p className={classNames(styles.sectionHeader)}>Top Variations</p>
              {topProductsAndCounts.map(({ product, reportCount }) => {
                return (
                  <div key={product.sku}>
                    {reportCount > 0 && (
                      <span className={classNames(styles.topItem)}>
                        <span className={styles.productImg}>
                          <img
                            className={classNames(styles.productImg)}
                            src={product.img_url}
                            alt={product.shopify_product_handle}
                          />
                        </span>
                        <Button
                          type="link"
                          className={classNames(styles.topItemName, styles.topItemName__product, {
                            [styles.topItemName__notClickable]: onProductSkuChange == null,
                          })}
                          onClick={() => {
                            onProductSkuChange?.(product.sku);
                          }}
                        >
                          {product.shopify_options.map((option) => option.value).join(' / ')}
                        </Button>
                        <span className={classNames(styles.reportStats)}>({reportCount})</span>
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              <div className={styles.speciesSection}>
                <p className={classNames(styles.sectionHeader)}>Top Species</p>
                {topSpeciesAndCounts.map(({ species, reportCount }) => {
                  const percentage: number = Number(
                    Math.round((reportCount / fishingReports?.length) * 100)
                  );
                  return (
                    <div key={species.name}>
                      {reportCount > 0 && (
                        <span className={classNames(styles.topItem)}>
                          <Button
                            type="link"
                            className={classNames(styles.topItemName, {
                              [styles.topItemName__notClickable]: onSpeciesNameChange == null,
                            })}
                            onClick={() => {
                              onSpeciesNameChange?.(species.name);
                            }}
                          >
                            {species.display_name}
                          </Button>

                          <Progress
                            percent={percentage}
                            showInfo={false}
                            trailColor={trailColor}
                            strokeColor={strokeColor}
                          />
                          <div className={classNames(styles.reportStats)}>
                            <span>{percentage}%</span>
                            <span className={styles.reportCount}>({reportCount})</span>
                          </div>
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {!hideTechniquesSection && (
              <div>
                <div className={styles.techniqueSection}>
                  <p className={classNames(styles.sectionHeader)}>Top Techniques</p>
                  {topTechniquesAndCounts.map(({ technique, reportCount }) => {
                    const percentage: number = Number(
                      Math.round((reportCount / fishingReports?.length) * 100)
                    );
                    return (
                      <div key={technique.name}>
                        {reportCount > 0 && (
                          <span className={classNames(styles.topItem)}>
                            <Button
                              type="link"
                              className={classNames(styles.topItemName, {
                                [styles.topItemName__notClickable]: onTechniqueNameChange == null,
                              })}
                              onClick={() => {
                                onTechniqueNameChange?.([technique.name]);
                              }}
                            >
                              {technique.display_name}
                            </Button>
                            <Progress
                              percent={percentage}
                              showInfo={false}
                              trailColor={trailColor}
                              strokeColor={strokeColor}
                            />
                            <div className={classNames(styles.reportStats)}>
                              <span>{percentage}%</span>
                              <span className={styles.reportCount}>({reportCount})</span>
                            </div>
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {showViewAllReportsLink && (
            <div className={classNames(styles.allReports)}>
              <Button
                type="link"
                onClick={() => {
                  onViewAllReportsClick();
                }}
                block
              >
                VIEW ALL REPORTS ({fishingReports?.length})
              </Button>
            </div>
          )}
        </>
      )}
      <div
        className={styles.mask}
        onClick={() => {
          setOpen(false);
          onVisibleChange?.(false);
        }}
      ></div>
    </>
  );

  return (
    <Popover
      placement={placement ?? 'bottom'}
      trigger="click"
      content={popoverContent}
      overlayClassName={classNames(styles.popover, {
        [styles.popover__hiddenArrow]: hideArrow,
      })}
      align={{ offset: [0, -4] }}
      getPopupContainer={getPopupContainer}
      open={open}
    >
      <span
        onClick={() => {
          setOpen(true);
          onVisibleChange?.(true);
          onClick?.();
        }}
        className={className}
      >
        {children}
      </span>
    </Popover>
  );
};
