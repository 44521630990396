export function articleCardDate(date: string) {
  if (date == null) {
    return null;
  }

  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date));
}
