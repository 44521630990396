import { LineItem } from '@omniafishing/core';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone } from '../../constants/loading_state';
import { useCart } from '../../hooks/use_cart';
import { usePrevious } from '../../hooks/use_previous';
import { useQueryString } from '../../hooks/use_query_string';
import { isBase64 } from '../../lib/base64';
import { attributionQueryParamToCustomAttributes } from '../../lib/line_item_attribution';
import { GlobalQueryParams } from '../../lib/query_string';
import { addLineItems, getCheckoutId, getLoadingState, LineItemToAdd } from '../../redux/cart';
import { FlashMessageActions } from '../../redux/flash_message';
import { getLineItemsAdded } from '../cart/cart';

export const CartProductAdder = (): null => {
  const dispatch = useDispatch();
  const loadingState = useSelector(getLoadingState);
  const checkoutId = useSelector(getCheckoutId);
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.add_to_cart]: string;
    [GlobalQueryParams.attribution]: string[];
  }>();
  let addToCartId = currentQuery.add_to_cart;
  if (isBase64(addToCartId)) {
    addToCartId = atob(addToCartId);
  }
  const { lineItems } = useCart();
  const lineItemAttribution = attributionQueryParamToCustomAttributes(
    currentQuery.attribution || []
  );

  const prevLineItems = usePrevious<LineItem[]>(lineItems);
  const previousLineItems = prevLineItems || []; // prevLineItems can be undefined
  const lineItemsAdded = getLineItemsAdded(previousLineItems, lineItems);
  const flashMessageShown = useRef(false);
  const itemAddedToCart = useRef(false);

  useEffect(() => {
    if (checkoutId && isDone(loadingState) && addToCartId != null && !itemAddedToCart.current) {
      const lineItem: LineItemToAdd = {
        variantId: addToCartId,
        quantity: 1,
        customAttributes: [...lineItemAttribution],
      };

      dispatch(addLineItems(checkoutId, [lineItem], 'none'));
      itemAddedToCart.current = true;
    }
  }, [checkoutId, loadingState, addToCartId]);

  useEffect(() => {
    if (addToCartId) {
      const addToCartLineItem = lineItemsAdded.find((li) => li.variant.id === addToCartId);
      if (addToCartLineItem && !flashMessageShown.current) {
        flashMessageShown.current = true;

        setTimeout(() => {
          // without this timeout, the flash message will not show, not sure why
          dispatch(
            FlashMessageActions.FLASH_MESSAGE_SET({
              header: 'Added to Cart',
              subheader: `${addToCartLineItem.title} - ${addToCartLineItem.variant.title} has been added to your cart`,
            })
          );
        });
      }
    }
  }, [
    lineItemsAdded
      .map((li) => li.id)
      .sort()
      .join(),
    addToCartId,
  ]);

  return null;
};
