import classNames from 'classnames';
import React, { useState } from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import SvgLongNextArrow from './long_next_arrow';
import styles from './map_legend.less';
import { PinLegend } from './pin_legend';

export const MapLegend = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDesktop } = useResponsive();

  return (
    <>
      {isDesktop ? (
        <ul className={styles.list}>
          <li className={styles.item}>
            <PinLegend size={20} type="low_activity" className={styles.pin} /> Less Active
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="high_activity" className={styles.pin} /> Most Active
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="favorite" className={styles.pin} /> Your Favorites
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="featured" className={styles.pin} /> Featured
          </li>
        </ul>
      ) : (
        <ul
          className={classNames(styles.list, { [styles.list__open]: isOpen })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <li className={styles.item}>
            <PinLegend size={20} type="low_activity" className={styles.pin} />
            <span
              className={classNames(
                { [styles.listText__open]: isOpen },
                { [styles.listText__closed]: !isOpen }
              )}
            >
              Less Active
            </span>
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="high_activity" className={styles.pin} />
            <span
              className={classNames(
                { [styles.listText__open]: isOpen },
                { [styles.listText__closed]: !isOpen }
              )}
            >
              Most Active
            </span>
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="favorite" className={styles.pin} />
            <span
              className={classNames(
                { [styles.listText__open]: isOpen },
                { [styles.listText__closed]: !isOpen }
              )}
            >
              Your Favorites
            </span>
          </li>
          <li className={styles.item}>
            <PinLegend size={20} type="featured" className={styles.pin} />
            <span
              className={classNames(
                { [styles.listText__open]: isOpen },
                { [styles.listText__closed]: !isOpen }
              )}
            >
              Featured
            </span>
          </li>
          <span className={styles.openIconBackground}>
            <SvgLongNextArrow
              className={classNames(
                { [styles.rotatedArrow]: isOpen },
                { [styles.rotatedArrow__reverse]: !isOpen }
              )}
              width={7}
              height={9}
            />
          </span>
        </ul>
      )}
    </>
  );
};
