import React from 'react';

const SvgMap = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    fill="none"
    viewBox="0 0 19 18"
    {...props}
  >
    <g clipPath="url(#map_icon_svg__a)">
      <path
        fill="currentColor"
        d="M18.142.583a.724.724 0 0 0-.72-.011l-5.164 2.847L7.093.572c-.025-.014-.053-.02-.079-.03C6.985.53 6.956.515 6.926.507 6.897.5 6.866.498 6.837.494 6.806.49 6.775.483 6.743.483S6.68.49 6.648.494c-.03.004-.06.005-.089.013-.03.008-.059.023-.088.035-.026.01-.053.017-.078.03L.877 3.612a.724.724 0 0 0-.375.635v12.546a.724.724 0 0 0 1.075.634l5.165-2.846 5.166 2.846c.034.02.071.03.107.044.02.006.038.017.058.023a.718.718 0 0 0 .368 0c.02-.006.039-.017.058-.023.037-.014.074-.024.108-.044l5.515-3.04a.724.724 0 0 0 .375-.635V1.207a.724.724 0 0 0-.355-.624M1.951 4.675l4.065-2.24v10.89l-4.065 2.24zm5.515-2.24 4.066 2.24v10.89l-4.066-2.24zm9.58 10.89-4.065 2.24V4.676l4.066-2.24z"
      />
    </g>
    <defs>
      <clipPath id="map_icon_svg__a">
        <path fill="#fff" d="M.5 0h18v18H.5z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMap;
