import { FastForwardOutlined, MailOutlined } from '@ant-design/icons';
import { OmniaVideo, OmniaVideoItemProduct } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isProductInStock } from '../../lib/products';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { formatSecondsToMinutes } from '../../lib/videos';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product.less';
import { OmniaUrls } from '../../lib/urls';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { getLineItemCustomAttributes } from '../../lib/line_item_attributes';
import { LINE_ATTRIBUTION, LineItemCustomAttribute } from '../../redux/cart';
import { OmniaButton } from '../omnia_button/omnia_button';

export const VideoItemSidebarProduct = (
  props: VideoItemProps & {
    omnia_video_item: OmniaVideoItemProduct;
    omnia_video?: OmniaVideo;
    selected: boolean;
    showJump: boolean;
    onOutOfStockClick: () => void;
    onAddToCartClick?: () => void;
  }
) => {
  const {
    onClick,
    omnia_video_item,
    omnia_video,
    onJumpClick,
    selected,
    showJump,
    onOutOfStockClick,
    onAddToCartClick,
  } = props;
  const { item, start_sec, end_sec, comments } = omnia_video_item;

  const {
    title,
    img_url,
    price,
    on_sale,
    compare_at_price,
    shopify_product_handle,
    shopify_options,
  } = item;

  const isLive = end_sec == null;
  const outOfStock = !isProductInStock(item);

  const location = useLocation();

  const lineItemCustomAttributes: LineItemCustomAttribute[] = [
    ...getLineItemCustomAttributes(location?.pathname),
    {
      key: LINE_ATTRIBUTION.OMNIA_VIDEO_ID,
      value: omnia_video?.id.toString(),
    },
  ];

  if (omnia_video?.affiliate?.campaign_source) {
    lineItemCustomAttributes.push({
      key: LINE_ATTRIBUTION.AFFILIATE,
      value: omnia_video.affiliate.campaign_source,
    });
  }

  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemCustomAttributes)}`;

  return (
    <div
      className={classNames(styles.productSidebar, {
        [styles.productSidebar__selected]: selected,
        [styles.productSidebar__live]: isLive,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.content__selected]: selected,
        })}
      >
        <div className={styles.productSidebarImg}>
          <Link
            to={{
              pathname: OmniaUrls.product(shopify_product_handle, shopify_options),
              search: productUrlSearch,
            }}
            target="_blank"
            onClick={onClick}
          >
            <img
              src={setShopifyImgWidth(img_url, 200)}
              alt={title}
              loading="lazy"
              width="200"
              height="200"
            />
          </Link>
        </div>

        <div
          className={classNames(styles.productSidebarText, {
            [styles.productSidebarText__selected]: selected,
          })}
        >
          <Link
            to={{
              pathname: OmniaUrls.product(shopify_product_handle, shopify_options),
              search: productUrlSearch,
            }}
            target="_blank"
            className={styles.link}
            onClick={onClick}
          >
            <p
              className={classNames(styles.productSidebarTitle, {
                [styles.productSidebarTitle__selected]: selected,
              })}
            >
              {title}
            </p>
          </Link>
          {showJump && (
            <p className={styles.productSidebarJump} onClick={onJumpClick}>
              <FastForwardOutlined className={styles.productSidebarJumpIcon} />
              Jump to {formatSecondsToMinutes(start_sec)}
            </p>
          )}
        </div>

        <div className={styles.productSidebarButtonPrice}>
          {outOfStock ? (
            <OmniaButton
              kind="tertiary"
              size="md"
              block
              className={styles.productSidebarButton}
              onClick={onOutOfStockClick}
            >
              <MailOutlined />
              Email Me
            </OmniaButton>
          ) : (
            <OmniaButton kind="primary" size="md" fontSize={14} block onClick={onAddToCartClick}>
              Add To Cart
            </OmniaButton>
          )}
          <Link
            to={{
              pathname: OmniaUrls.product(shopify_product_handle, shopify_options),
              search: productUrlSearch,
            }}
            target="_blank"
            className={styles.link}
          >
            <p className={styles.productSidebarPrice}>
              <span
                className={classNames({
                  [styles.price__sale]: on_sale,
                })}
              >
                {toDollars(price)}
              </span>
              {on_sale && (
                <s className={styles.productSidebarPriceCompare}>{toDollars(compare_at_price)}</s>
              )}
            </p>
          </Link>
        </div>
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
