import { FishingReport, Product, SeasonGroup, Species } from '@omniafishing/core';
import {
  fishingReportMatchesId,
  fishingReportContainsSeasonGroup,
  fishingReportContainsSku,
  fishingReportContainsSpecieName,
  fishingReportContainsWaterbodyId,
} from '../../lib/fishing_reports';
import { SeasonGroupSelectValue } from './season_group_select';
import { SpeciesSelectValue } from './species_select';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

export interface FilterByAllCategoriesArgs {
  fishingReports: FishingReport[];
  reportWaterbodyId: string;
  reportId: number;
  selectedProductSku: string;
  selectedSeasonGroup: SeasonGroupSelectValue;
  selectedSpecies: SpeciesSelectValue;
  selectedStructureNames: CheckboxValueType[];
  selectedTechniqueNames: CheckboxValueType[];
  selectedMedia: CheckboxValueType[];
}

// TODO: get rid of all of this hot garbage and replace with functions from fishing_reports.ts
export const filterByAllCategories = (args: FilterByAllCategoriesArgs) => {
  const {
    fishingReports,
    selectedProductSku,
    reportWaterbodyId,
    reportId,
    selectedSeasonGroup,
    selectedSpecies,
    selectedStructureNames,
    selectedTechniqueNames,
    selectedMedia,
  } = args;
  return fishingReports
    .filter((report) => {
      return (
        reportWaterbodyId == null || fishingReportContainsWaterbodyId(report, reportWaterbodyId)
      );
    })
    .filter((report) => {
      return reportId == null || fishingReportMatchesId(report, reportId);
    })
    .filter((report) => {
      return selectedProductSku == null || fishingReportContainsSku(report, selectedProductSku);
    })
    .filter((report) => {
      return (
        selectedSeasonGroup?.value == null ||
        fishingReportContainsSeasonGroup(report, selectedSeasonGroup.label.props['data-result'])
      );
    })
    .filter((report) => {
      return (
        selectedSpecies?.value == null ||
        fishingReportContainsSpecieName(report, selectedSpecies.value)
      );
    })
    .filter((report) => {
      return (
        selectedStructureNames == null ||
        selectedStructureNames?.length === 0 ||
        selectedStructureNames?.includes(report.structure.name)
      );
    })
    .filter((report) => {
      return (
        selectedTechniqueNames == null ||
        selectedTechniqueNames?.length === 0 ||
        selectedTechniqueNames?.includes(report.technique.name)
      );
    })
    .filter((report) => {
      const filterReportsContainingVideos =
        selectedMedia?.includes('video') && report.video !== null;
      const filterReportsContainingImages =
        selectedMedia?.includes('image') && report.image !== null;
      const filterReportsContainingAllMedia =
        filterReportsContainingImages && filterReportsContainingVideos;

      return (
        selectedMedia == null ||
        selectedMedia?.length === 0 ||
        filterReportsContainingAllMedia ||
        filterReportsContainingVideos ||
        filterReportsContainingImages
      );
    });
};

interface GetCountsArgs {
  fishingReports: FishingReport[];
  selectedProductSku: string;
  selectedSeasonGroup: SeasonGroup;
  selectedSpeciesName: string;
  productsInFishingReports: Product[];
  zoneSpecificSeasonGroups: SeasonGroup[];
  speciesInFishingReports: Species[];
}

type ProductCountsArgs = Omit<
  GetCountsArgs,
  'speciesInFishingReports' | 'zoneSpecificSeasonGroups' | 'selectedProductSku'
>;

export const getProductCounts = (productCountArgs: ProductCountsArgs) => {
  const { fishingReports, selectedSeasonGroup, selectedSpeciesName, productsInFishingReports } =
    productCountArgs;

  return productsInFishingReports.map((product) => {
    if (selectedSpeciesName || selectedSeasonGroup?.name) {
      return fishingReports.filter((report) => {
        const reportContainsProduct = fishingReportContainsSku(report, product.sku);
        const reportContainsSpecies =
          selectedSpeciesName && fishingReportContainsSpecieName(report, selectedSpeciesName);
        const reportContainsSeason =
          selectedSeasonGroup?.name &&
          fishingReportContainsSeasonGroup(report, selectedSeasonGroup);

        if (selectedSpeciesName && selectedSeasonGroup?.name) {
          return reportContainsProduct && reportContainsSpecies && reportContainsSeason;
        } else if (selectedSpeciesName) {
          return reportContainsProduct && reportContainsSpecies;
        } else if (selectedSeasonGroup?.name) {
          return reportContainsProduct && reportContainsSeason;
        }
      }).length;
    }

    return fishingReports.filter((report) => {
      return fishingReportContainsSku(report, product.sku);
    }).length;
  });
};

type SeasonGroupCountsArgs = Omit<
  GetCountsArgs,
  'speciesInFishingReports' | 'productsInFishingReports' | 'selectedSeasonGroup'
>;

export const getSeasonGroupCounts = (args: SeasonGroupCountsArgs) => {
  const { zoneSpecificSeasonGroups, selectedProductSku, selectedSpeciesName, fishingReports } =
    args;
  return zoneSpecificSeasonGroups.map((seasonGroup) => {
    if (selectedProductSku || selectedSpeciesName) {
      return fishingReports.filter((report) => {
        const reportContainsSeason = fishingReportContainsSeasonGroup(report, seasonGroup);
        const reportContainsSpecies =
          selectedSpeciesName && fishingReportContainsSpecieName(report, selectedSpeciesName);
        const reportContainsProduct =
          selectedProductSku && fishingReportContainsSku(report, selectedProductSku);

        if (selectedProductSku && selectedSpeciesName) {
          return reportContainsSeason && reportContainsSpecies && reportContainsProduct;
        } else if (selectedProductSku) {
          return reportContainsSeason && reportContainsProduct;
        } else if (selectedSpeciesName) {
          return reportContainsSeason && reportContainsSpecies;
        }
      }).length;
    }

    return fishingReports.filter((report) => {
      return fishingReportContainsSeasonGroup(report, seasonGroup);
    }).length;
  });
};

type SpeciesCountsArgs = Omit<
  GetCountsArgs,
  'zoneSpecificSeasonGroups' | 'productsInFishingReports' | 'selectedSpeciesName'
>;

export const getSpeciesCounts = (args: SpeciesCountsArgs) => {
  const { speciesInFishingReports, selectedProductSku, selectedSeasonGroup, fishingReports } = args;

  return speciesInFishingReports.map((species) => {
    if (selectedProductSku || selectedSeasonGroup?.name) {
      return fishingReports.filter((report) => {
        const reportContainsSpecies = fishingReportContainsSpecieName(report, species.name);
        const reportContainsProduct =
          selectedProductSku && fishingReportContainsSku(report, selectedProductSku);
        const reportContainsSeason =
          selectedSeasonGroup?.name &&
          fishingReportContainsSeasonGroup(report, selectedSeasonGroup);
        if (selectedSeasonGroup?.name && selectedProductSku) {
          return reportContainsSpecies && reportContainsSeason && reportContainsProduct;
        } else if (selectedProductSku) {
          return reportContainsSpecies && reportContainsProduct;
        } else if (selectedSeasonGroup?.name) {
          return reportContainsSpecies && reportContainsSeason;
        }
      }).length;
    }

    return fishingReports.filter((report) => {
      return fishingReportContainsSpecieName(report, species.name);
    }).length;
  });
};

export const getCountsOfProductsSeasonsSpecies = (args: GetCountsArgs) => {
  const {
    fishingReports,
    selectedProductSku,
    selectedSeasonGroup,
    selectedSpeciesName,
    productsInFishingReports,
    zoneSpecificSeasonGroups,
    speciesInFishingReports,
  } = args;

  const productCounts = getProductCounts({
    productsInFishingReports,
    selectedSeasonGroup,
    selectedSpeciesName,
    fishingReports,
  });

  const seasonGroupCounts = getSeasonGroupCounts({
    zoneSpecificSeasonGroups,
    fishingReports,
    selectedSpeciesName,
    selectedProductSku,
  });

  const speciesCounts = getSpeciesCounts({
    speciesInFishingReports,
    fishingReports,
    selectedSeasonGroup,
    selectedProductSku,
  });

  return { productCounts, seasonGroupCounts, speciesCounts };
};
