import { Product, ProductFamily, ProductFamilyOption, SelectedOption } from '@omniafishing/core';
import _ from 'lodash';
import { getProductByPartialSelectedOptions } from '../../lib/get_product_by_selected_options';
import { ColorOption } from '../product_variant_selector/product_variant_selector';

const getUnavailableOptionValues = (params: {
  products: Product[];
  productFamilyOptions: ProductFamilyOption[];
  selectedOptions: SelectedOption[];
}) => {
  const { products, productFamilyOptions, selectedOptions } = params;
  const optionsWithValues = selectedOptions.filter((option) => !!option.value);
  const optionsWithoutValues = selectedOptions.filter((option) => !option.value);

  const unavailableOptions: { [key: string]: string[] } = {};

  optionsWithoutValues.forEach((optionWithoutValue) => {
    const name = optionWithoutValue.name;
    const productOptions = productFamilyOptions.filter(
      (option) => option.name.toLowerCase() === name.toLowerCase()
    )[0];
    const valuesWithoutMatches = productOptions.values
      .map((value) => value.value)
      .filter((value) => {
        const optionsToTest = [
          ...optionsWithValues,
          {
            name,
            value,
          },
        ];
        const match = getProductByPartialSelectedOptions(products, optionsToTest);
        return !match;
      });

    unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
  });

  return unavailableOptions;
};

const getOtherAvailableOptionValues = (params: {
  products: Product[];
  productFamilyOptions: ProductFamilyOption[];
  selectedOptions: SelectedOption[];
}) => {
  const { products, productFamilyOptions, selectedOptions } = params;
  const unavailableOptions: Record<string, string[]> = {};

  selectedOptions.forEach((currentOption) => {
    const selectedOptionsWithoutThisOption = selectedOptions.filter(
      (option) => option.name !== currentOption.name
    );
    const name = currentOption.name;
    const productOptions = productFamilyOptions.filter(
      (option) => option.name.toLowerCase() === name.toLowerCase()
    )[0];
    const valuesWithoutMatches = productOptions.values
      .map((value) => value.value)
      .filter((value) => {
        const optionsToTest = [
          ...selectedOptionsWithoutThisOption,
          {
            name,
            value,
          },
        ];
        const match = getProductByPartialSelectedOptions(products, optionsToTest);
        return !match;
      });

    unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
  });

  return unavailableOptions;
};

export const isUnavailable = (params: {
  products: Product[];
  productFamilyOptions: ProductFamilyOption[];
  selectedOptions: SelectedOption[];
  optionName: string;
  value: string;
}) => {
  const { products, productFamilyOptions, selectedOptions, optionName, value } = params;
  let unavailableOptions = getUnavailableOptionValues({
    products,
    productFamilyOptions,
    selectedOptions,
  });
  const optionsWithoutValues = selectedOptions.filter((option) => !option.value);
  if (!optionsWithoutValues.length) {
    unavailableOptions = getOtherAvailableOptionValues({
      products,
      productFamilyOptions,
      selectedOptions,
    });
  }

  const name = optionName.toLowerCase();
  return unavailableOptions[name] && unavailableOptions[name].indexOf(value) > -1;
};

export const getProductFamilyOptionsFromProducts = (
  productFamily: ProductFamily,
  products: Product[]
): ProductFamilyOption[] => {
  const productOptions: Record<string, string[]> = {};

  const productOptionsNames = _.uniq(
    _.flatten(products.map((p) => p.shopify_options.map((o) => o.name)))
  );
  productOptionsNames.forEach((name) => {
    if (!productOptions[name]) {
      productOptions[name] = [];
    }
  });

  products.forEach((product) => {
    product.shopify_options.forEach((option) => {
      if (!productOptions[option.name].includes(option.value)) {
        productOptions[option.name].push(option.value);
      }
    });
  });

  return productFamily.options.map((option) => {
    return {
      ...option,
      values: option.values.filter((value) => productOptions[option.name]?.includes(value.value)),
    };
  });
};

export const getProductFamilyColorOptions = (productFamilyOptions: ProductFamilyOption[]) => {
  const colorOption = productFamilyOptions.find((option) => option.name.toLowerCase() === 'color');

  if (!colorOption) {
    return [];
  }

  return colorOption.values.map((value) => {
    return {
      colorName: value.value,
      imgSrc: value.src,
    };
  });
};

export const getSelectedColorOption = (selectedOptions: SelectedOption[]) => {
  if (selectedOptions) {
    return selectedOptions.find((selectedOption) => selectedOption.name.toLowerCase() === 'color');
  }
};

export const getColorOptionAvailability = (params: {
  products: Product[];
  productFamilyOptions: ProductFamilyOption[];
  selectedOptions: SelectedOption[];
  colorOptions: ColorOption[];
}) => {
  const { products, productFamilyOptions, selectedOptions, colorOptions } = params;

  return colorOptions.map((colorOption) => {
    const available = !isUnavailable({
      products,
      productFamilyOptions,
      selectedOptions,
      optionName: 'color',
      value: colorOption.colorName,
    });
    return { ...colorOption, available };
  });
};
