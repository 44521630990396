import { FishingReport, Product, Waterbody } from '@omniafishing/core';
import { Popover } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fishingReportContainsSku, getFishingReportAttributes } from '../../lib/fishing_reports';
import { OmniaUrls } from '../../lib/urls';
import { NAME_FALLBACK } from '../../lib/user';
import { WebAnalytics } from '../../lib/web_analytics';
import { getIpState } from '../../redux/geographic_location';
import { getIsMobile } from '../../redux/window';
import variantSelectorStyles from '../product_variant_selector/product_variant_selector.less';
import quickSelectorStyles from './quick_select_variant_selector.less';

interface ProductTitleProps {
  selectedProduct: Product;
  fishingReports: FishingReport[];
  fishingReportsNearby: FishingReport[];
  userWaterbodies: Waterbody[];
}

export const ProductTitle = (props: ProductTitleProps) => {
  const { selectedProduct, fishingReports, userWaterbodies, fishingReportsNearby } = props;

  const ipState = useSelector(getIpState);
  const isMobile = useSelector(getIsMobile);

  const isBestSellerInIpState = selectedProduct.best_seller_states.includes(ipState);
  const productFishingReports = fishingReports.filter((report) =>
    fishingReportContainsSku(report, selectedProduct?.sku)
  );
  const hasFishingReportsNearby = fishingReportsNearby.length > 0;
  const fishingReportsInIpState = productFishingReports.filter((f) =>
    f.waterbody.locales.map((l) => l.state.abbr).includes(ipState)
  );
  const hasFishingReportsInIpState = fishingReportsInIpState.length > 0;
  const userWaterbodyIds = userWaterbodies.map((w) => w.id);
  const fishingReportsUserWaterbodies = fishingReports.filter((r) =>
    userWaterbodyIds.includes(r.waterbody.id)
  );

  const hasReportRecommendation =
    fishingReportsUserWaterbodies.length > 0 ||
    hasFishingReportsNearby ||
    hasFishingReportsInIpState;
  const reportRecommendationBadge =
    fishingReportsUserWaterbodies.length > 0 ? (
      <Popover
        trigger={isMobile ? 'click' : 'hover'}
        content={
          <div>
            <ul className={variantSelectorStyles.lakeList}>
              {fishingReportsUserWaterbodies.map((report) => (
                <li key={report.id}>
                  <Link
                    to={OmniaUrls.fishingReport(report)}
                    rel="noopener nofollow"
                    onClick={() => {
                      WebAnalytics.productDetailPageClick('[title].(related_reports_by_lake_link)');
                    }}
                  >
                    {getFishingReportAttributes(report)} on {report.waterbody.primary_name} by{' '}
                    {report.user.full_name || NAME_FALLBACK}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span className={classNames(variantSelectorStyles.pill)}>
          On Your Lake{fishingReportsUserWaterbodies.length > 1 ? 's' : ''}
        </span>
      </Popover>
    ) : hasFishingReportsNearby ? (
      <Popover
        trigger={isMobile ? 'click' : 'hover'}
        content={
          <div>
            <ul className={variantSelectorStyles.lakeList}>
              {fishingReportsNearby.map((report) => (
                <li key={report.id}>
                  <Link
                    to={OmniaUrls.fishingReport(report)}
                    rel="noopener nofollow"
                    onClick={() => {
                      WebAnalytics.productDetailPageClick('[title].(related_reports_by_lake_link)');
                    }}
                  >
                    {getFishingReportAttributes(report)} on {report.waterbody.primary_name} by{' '}
                    {report.user.full_name || NAME_FALLBACK}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span className={variantSelectorStyles.pill}>Reported Nearby</span>
      </Popover>
    ) : (
      <Popover
        trigger={isMobile ? 'click' : 'hover'}
        content={
          <div>
            <ul className={variantSelectorStyles.lakeList}>
              {fishingReportsInIpState.map((report) => (
                <li key={report.id}>
                  <Link
                    to={OmniaUrls.fishingReport(report)}
                    rel="noopener nofollow"
                    onClick={() => {
                      WebAnalytics.productDetailPageClick('[title].(related_reports_by_lake_link)');
                    }}
                  >
                    {getFishingReportAttributes(report)} on {report.waterbody.primary_name} by{' '}
                    {report.user.full_name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span className={variantSelectorStyles.pill}>Reported in {ipState}</span>
      </Popover>
    );

  return (
    <div className={variantSelectorStyles.subTitleSection}>
      <p className={quickSelectorStyles.variantTitle}>
        {selectedProduct.shopify_options.map((option) => option.value).join(' / ')}
      </p>

      {(isBestSellerInIpState || hasReportRecommendation) && (
        <span className={variantSelectorStyles.pillsWrapper}>
          {isBestSellerInIpState && (
            <span className={variantSelectorStyles.pill}>Best Seller in {ipState}</span>
          )}
          {hasReportRecommendation && reportRecommendationBadge}
        </span>
      )}
    </div>
  );
};
