import { useEffect } from 'react';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { waitFor } from '../../lib/wait_for';

export const MicrosoftClarityIntegration = (): null => {
  const { anonymousId } = useRudderstack();

  useEffect(() => {
    if (anonymousId) {
      waitFor(() => window.hasOwnProperty('clarity'), 10_000)
        .then(() => {
          (window as any).clarity('identify', anonymousId);
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [anonymousId]);

  return null;
};
