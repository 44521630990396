import React from 'react';
import { useDispatch } from 'react-redux';
import { SignupLoginModalActions } from '../../redux/signup_login_modal';
import { OmniaButton } from '../omnia_button/omnia_button';

export const WaypointsDescription = () => {
  const dispatch = useDispatch();
  return (
    <span>
      Waypoints are a great way to mark your favorite fishing spots, catches, or other points of
      interest on the map. Use waypoints by{' '}
      <OmniaButton
        onPress={() => dispatch(SignupLoginModalActions.SIGNUP_LOGIN_MODAL_OPEN())}
        size="md"
        kind="link"
        style={{ padding: 0 }}
      >
        creating an Omnia account
      </OmniaButton>
      . Available to all users.
    </span>
  );
};
