import { Popover, PopoverProps } from 'antd';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { hashGenerator } from '../../lib/hashGenerator';
import { hasAccessToken } from '../../redux/auth';
import { RoutePaths } from '../../routes';

type Placement = PopoverProps['placement'];

interface SignupPopoverProps {
  from?: string;
  actionText?: string;
  placement?: Placement;
  initialOpen?: boolean;
  children: ReactNode;
}

const SignupPopover = (props: SignupPopoverProps) => {
  const { from, actionText, children, placement, initialOpen } = props;
  const location = useLocation();
  const isLoggedIn = useSelector(hasAccessToken);
  const [open, setOpen] = useState(initialOpen || false);

  const handleOpenChange = (updatedOpen: boolean) => {
    setOpen(updatedOpen);
  };

  if (isLoggedIn) {
    return <>{children}</>;
  }
  // this fixes a mobile bug where popover do not reopen on multiple clicks
  const key = hashGenerator(actionText);

  const text = actionText != null ? actionText : 'fill out your Angler Profile';
  const blurb = (
    <span>
      <Link
        to={{
          pathname: RoutePaths.SIGNUP,
          state: { from: from || location.pathname },
        }}
        className="text-link-blue underline"
      >
        Create an account
      </Link>{' '}
      to {text}.
    </span>
  );
  return (
    <Popover
      content={blurb}
      placement={placement}
      key={key}
      open={open}
      onOpenChange={handleOpenChange}
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default SignupPopover;
