import { AreaChartOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';
import { ShortUrl } from '@omniafishing/core';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { isPending, LoadingState } from '../../constants/loading_state';
import { apiV1 } from '../../lib/api';
import { FlashMessageActions } from '../../redux/flash_message';
import { getPath } from '../../redux/router';
import { getUser } from '../../redux/user';
import { getIsMobile, getIsWebview } from '../../redux/window';
import { OmniaButton } from '../omnia_button/omnia_button';
import { SpanLink } from '../span_link/span_link';
import styles from './affiliate_link_builder.less';

export const AffiliateLinkBuilder = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const path = useSelector(getPath);
  const [form] = Form.useForm();
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const [shortUrl, setShortUrl] = useState(null as ShortUrl);
  const [hidden, setHidden] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const isWebview = useSelector(getIsWebview);
  const renderNull = hidden || !user || (user && !user.affiliate) || isWebview;
  const [hasFormValues, setHasFormValues] = useState(false);

  useEffect(() => {
    if (path && !renderNull) {
      resetState();
    }
  }, [path]);

  const resetState = () => {
    clearForm();
    setLoadingState(LoadingState.NOT_STARTED);
    setShortUrl(null);
    setHasFormValues(false);
  };

  const clearForm = () => {
    form.resetFields(['campaign_name', 'id_hash']);
  };

  const submitForm = (values: Record<'campaign_name' | 'id_hash', string>) => {
    setLoadingState(LoadingState.PENDING);
    const { id_hash, campaign_name } = values;

    apiV1
      .shortUrlCreate({
        id_hash,
        campaign_name,
        target_path: window.location.href,
      })
      .then((res) => {
        setLoadingState(LoadingState.DONE);
        setShortUrl(res.data.data);
      })
      .catch((err) => {
        setLoadingState(LoadingState.ERROR);
        if (err.response.data.error_code === 'SHORT_URL_HASH_EXISTS') {
          dispatch(
            FlashMessageActions.FLASH_MESSAGE_SET({
              header: 'Custom code already in use',
              subheader: `That custom code is already in use. Please try another.`,
              type: 'error',
            })
          );
        }
      });
  };

  if (renderNull) {
    return null;
  }

  const shortUrlLink = `https://omnia.direct/${shortUrl?.id_hash}`;

  return (
    <>
      {showForm ? (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <header className={styles.header}>
              <p className={styles.title}>
                Link Builder{' '}
                {hasFormValues && (
                  <>
                    (
                    <SpanLink onClick={clearForm} className={styles.clear}>
                      Clear
                    </SpanLink>
                    )
                  </>
                )}
              </p>
              <CloseOutlined onClick={() => setShowForm(false)} />
            </header>
            <Form
              layout={isMobile ? 'vertical' : 'inline'}
              onFinish={submitForm}
              form={form}
              onChange={() => {
                const fieldValuesPresent = Object.values(form.getFieldsValue()).some((v) => !!v);
                setHasFormValues(fieldValuesPresent);
              }}
            >
              <Form.Item name="campaign_name" className={styles.inputCampaign}>
                <Input
                  type="text"
                  placeholder={'Campaign (Optional)'}
                  prefix={<AreaChartOutlined />}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="id_hash" className={styles.inputHash}>
                <Input
                  type="text"
                  placeholder="Custom Code (Optional)"
                  prefix={<LinkOutlined />}
                  size="large"
                />
              </Form.Item>

              <Button
                loading={isPending(loadingState)}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Create Link
              </Button>
            </Form>
            {shortUrl && (
              <CopyToClipboard
                text={shortUrlLink}
                onCopy={() => {
                  dispatch(
                    FlashMessageActions.FLASH_MESSAGE_SET({
                      header: 'Link copied',
                      subheader: 'Your affiliate link short URL has been copied',
                    })
                  );
                }}
              >
                <div className={styles.linkDetails}>
                  <div className={styles.url}>{shortUrlLink}</div>

                  <Button
                    type="text"
                    icon={<LinkOutlined />}
                    size="large"
                    className={styles.copyButton}
                  >
                    Copy Link
                  </Button>
                </div>
              </CopyToClipboard>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.toggleContainer}>
          <div className={styles.toggle}>
            <OmniaButton onPress={() => setShowForm(true)} kind="primary" size="lg">
              Open Affiliate Link Builder
            </OmniaButton>
            <CloseOutlined onClick={() => setHidden(true)} />
          </div>
        </div>
      )}
    </>
  );
};
