import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { PRO_PURCHASE_URL, PRO_TRIAL_PURCHASE_URL } from '../../lib/stripe';
import {
  getFormToDisplay,
  getForwardRoute,
  getHeading,
  getHeadingBlurb,
  isOpen,
  SignupLoginModalActions,
  SignupLoginModalOpenPayload,
} from '../../redux/signup_login_modal';
import { LoginForm } from '../login/login_form';
import { SignupForm } from '../signup/signup_form';
import styles from './signup_login_modal.less';

export const PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS = {
  forwardRoute: PRO_PURCHASE_URL,
  heading: 'Create an Account & Upgrade to Omnia PRO',
  headingBlurb:
    'With a PRO account you’ll have access to map layers, 10% cashback, and FREE shipping.',
} as Partial<SignupLoginModalOpenPayload>;

export const PRO_TRIAL_SIGNUP_LOGIN_MODAL_OPEN_PARAMS = {
  ...PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS,
  forwardRoute: PRO_TRIAL_PURCHASE_URL,
} as Partial<SignupLoginModalOpenPayload>;

export const SignupLoginModal = React.memo(() => {
  const dispatch = useDispatch();
  const open = useSelector(isOpen);
  const forwardRoute = useSelector(getForwardRoute);
  const heading = useSelector(getHeading);
  const headingBlurb = useSelector(getHeadingBlurb);
  const initialFormToDisplay = useSelector(getFormToDisplay);
  const [showLogin, setShowLogin] = useState(false);
  const showSignup = !showLogin;
  const currentPath = useLocation().pathname;

  useEffect(() => {
    return () => {
      dispatch(SignupLoginModalActions.SIGNUP_LOGIN_MODAL_CLOSE());
    };
  }, []);

  useEffect(() => {
    setShowLogin(initialFormToDisplay === 'login');
  }, [initialFormToDisplay]);

  return (
    <>
      <Modal
        open={open}
        onCancel={() => {
          dispatch(SignupLoginModalActions.SIGNUP_LOGIN_MODAL_CLOSE());
        }}
        footer={null}
        title={null}
        wrapClassName={styles.modal}
        centered
      >
        <SignupForm
          onLoginClick={() => setShowLogin(true)}
          cardClassName={styles.signupCard}
          forwardRoute={forwardRoute}
          renderForm={showSignup}
          heading={
            <div className={styles.headingContainer}>
              <p className={styles.heading}>{heading}</p>
              {headingBlurb && (
                <p className="leading-tight w-4/5 text-center mb-3">{headingBlurb}</p>
              )}
            </div>
          }
        />
        <LoginForm
          onSignupClick={() => setShowLogin(false)}
          cardClassName={styles.signupCard}
          forwardRoute={forwardRoute}
          renderForm={showLogin}
          currentPath={currentPath}
        />
      </Modal>
    </>
  );
});
