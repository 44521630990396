import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './filter_tag.less';
import classNames from 'classnames';

interface FilterTagProps {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
}

export const FilterTag = (props: FilterTagProps) => {
  const { children, onClose, className } = props;

  return (
    <div className={classNames(styles.tag, className)}>
      {children}
      <CloseOutlined onClick={onClose} className={styles.icon} />
    </div>
  );
};
