import React from 'react';

// Only uses effect after mounting, so it does not run on first render
// This is for pages that have initial state prerendered on the server
export const useAfterMountedEffect = (effect: () => any, dependencies: any[]) => {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => unmount && unmount();
    } else {
      mounted.current = true;
    }
  }, dependencies);

  // Reset on unmount for the next mount.
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
};
