import * as React from 'react';
const SvgExpandIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity={0.7} cx={16} cy={16} r={16} fill="#000" />
    <path
      d="M15.922 10.07c0-.587.47-1.07 1.058-1.07h4.714c.9 0 1.306.483 1.306 1.331v4.672c0 .6-.47 1.07-1.07 1.07-.588 0-1.058-.47-1.058-1.07v-.614l.157-1.905-2.73 2.884c-.43.47-1.201.47-1.658.013-.457-.457-.47-1.213.013-1.657l2.911-2.74-1.958.156h-.627c-.587 0-1.058-.47-1.058-1.07Zm-6.92 6.928a1.064 1.064 0 1 1 2.128 0v.626l-.157 1.892 2.729-2.883c.43-.457 1.201-.457 1.658 0 .457.456.47 1.213-.013 1.657l-2.912 2.74 1.958-.17h.627c.587 0 1.07.483 1.07 1.07 0 .6-.483 1.07-1.07 1.07h-4.714C9.406 23 9 22.53 9 21.682v-4.684h.002Z"
      fill="#fff"
    />
  </svg>
);
export default SvgExpandIcon;
