import {
  ProductFamily,
  ProductVariantOption,
  SelectedOption,
  ShopifyProduct,
  ShopifyVariant,
} from '@omniafishing/core';
import _ from 'lodash';
import { ColorOption } from '../product_variant_selector/product_variant_selector';
import { DownOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { DrawerColorList } from './drawer_list';
import { DrawerHeader } from './drawer_header';
import { OmniaButton } from '../omnia_button/omnia_button';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './color_selector_drawer.less';
import { isItemNew } from '../product_variant_selector/product_title';
import dayjs from 'dayjs';

interface ColorSelectorDrawerProps {
  colorOptions: ColorOption[];
  hoverColorOption: ColorOption | null;
  isDrawerVisible: boolean;
  nonColorOptions: ProductVariantOption[];
  onColorChange: (colorName: string) => void;
  onVariantChange: (optionName: string, value: string) => void;
  onVisibleChange: (visible: boolean) => void;
  productFamily: ProductFamily;
  selectedColorOption: SelectedOption;
  selectedOptions: SelectedOption[];
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
}

export const ColorSelectorDrawer = React.memo((props: ColorSelectorDrawerProps) => {
  const {
    colorOptions,
    hoverColorOption,
    isDrawerVisible,
    nonColorOptions,
    onColorChange,
    onVariantChange,
    onVisibleChange,
    productFamily,
    selectedColorOption,
    selectedOptions,
    selectedVariant,
    shopifyProduct,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrderAscending, setSortOrderAscending] = useState(true);

  const onSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const isProductFamilyNew = useMemo(
    () => isItemNew(dayjs(productFamily.published_at)),
    [productFamily]
  );

  const showNoColorMessage = !(hoverColorOption?.colorName || selectedColorOption?.value);
  return (
    <>
      <div>
        <p className={styles.colorTag}>COLOR</p>
        <OmniaButton
          kind="tertiary"
          size="md"
          onClick={() => {
            onVisibleChange(true);
          }}
          block
          className={styles.colorSelectorButton}
        >
          {showNoColorMessage ? (
            <span className={styles.emptyColor}>Please Select A Color</span>
          ) : (
            <>
              <img
                src={setShopifyImgWidth(
                  hoverColorOption ? hoverColorOption.imgSrc : selectedVariant.image.url,
                  100
                )}
                alt={selectedVariant.title}
              />
              <span className={styles.colorName}>
                {hoverColorOption ? hoverColorOption.colorName : selectedColorOption.value}
              </span>
            </>
          )}
          <DownOutlined />
        </OmniaButton>
      </div>
      <Drawer
        className={styles.drawerContainer}
        onClose={() => {
          onVisibleChange(false);
        }}
        open={isDrawerVisible}
        placement="right"
        title={
          <DrawerHeader
            nonColorOptions={nonColorOptions}
            onSearchInputChange={onSearchInputChange}
            onSelectChange={setSortOrderAscending}
            onVariantChange={onVariantChange}
            selectedOptions={selectedOptions}
            shopifyProduct={shopifyProduct}
            isProductFamilyNew={isProductFamilyNew}
          />
        }
      >
        <DrawerColorList
          colorOptions={colorOptions}
          isProductFamilyNew={isProductFamilyNew}
          onColorChange={onColorChange}
          onVisibleChange={onVisibleChange}
          productFamily={productFamily}
          searchTerm={searchTerm}
          selectedColorOption={selectedColorOption}
          selectedOptions={selectedOptions}
          shopifyProduct={shopifyProduct}
          sortOrderAscending={sortOrderAscending}
        />
      </Drawer>
    </>
  );
});
