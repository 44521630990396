import { WaterbodyDetail } from '@omniafishing/core';
import _ from 'lodash';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';

export const userPreferencesReducerName = 'userPreferences';
export type userPreferencesReducerType = typeof initialState;

export enum StateKeys {
  species = 'species',
  brands = 'brands',
  techniques = 'techniques',
  waterbodies = 'waterbodies',
}

export const initialState = {
  [StateKeys.species]: [] as string[],
  [StateKeys.brands]: [] as string[],
  [StateKeys.techniques]: [] as string[],
  [StateKeys.waterbodies]: [] as WaterbodyDetail[],
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getUserPrefsSpecies = (state: ApplicationState) =>
  state[userPreferencesReducerName][StateKeys.species];
export const getUserPrefsBrands = (state: ApplicationState) =>
  state[userPreferencesReducerName][StateKeys.brands];
export const getUserPrefsTechniques = (state: ApplicationState) =>
  state[userPreferencesReducerName][StateKeys.techniques];
export const getUserPrefsWaterbodies = (state: ApplicationState) =>
  state[userPreferencesReducerName][StateKeys.waterbodies];

// ========================================================================== //
// Reducer
// ========================================================================== //

export function UserPreferencesReducer(
  state = initialState,
  action: UserPreferencesActions
): userPreferencesReducerType {
  switch (action.type) {
    case ReduxActions.USER_PREFERENCES_SPECIES_SAVE: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        [StateKeys.species]: prependToStringArray(action.payload, state[StateKeys.species], 3),
      };
    }

    case ReduxActions.USER_PREFERENCES_BRAND_SAVE: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        [StateKeys.brands]: prependToStringArray(action.payload, state[StateKeys.brands], 5),
      };
    }

    case ReduxActions.USER_PREFERENCES_TECHNIQUE_SAVE: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        [StateKeys.techniques]: prependToStringArray(
          action.payload,
          state[StateKeys.techniques],
          3
        ),
      };
    }

    case ReduxActions.USER_PREFERENCES_WATERBODY_SAVE: {
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        [StateKeys.waterbodies]: _.uniqBy(
          [action.payload, ...state[StateKeys.waterbodies]],
          'id'
        ).slice(0, 5),
      };
    }

    default:
      return state;
  }
}

export function prependToStringArray(str: string, arr: string[], limit: number) {
  return _.uniq([str, ...arr]).slice(0, limit);
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const UserPreferencesActions = {
  USER_PREFERENCES_SPECIES_SAVE: (species_name: string) =>
    createAction(ReduxActions.USER_PREFERENCES_SPECIES_SAVE, species_name),
  USER_PREFERENCES_BRAND_SAVE: (species_name: string) =>
    createAction(ReduxActions.USER_PREFERENCES_BRAND_SAVE, species_name),
  USER_PREFERENCES_TECHNIQUE_SAVE: (species_name: string) =>
    createAction(ReduxActions.USER_PREFERENCES_TECHNIQUE_SAVE, species_name),
  USER_PREFERENCES_WATERBODY_SAVE: (waterbodyDetail: WaterbodyDetail) =>
    createAction(ReduxActions.USER_PREFERENCES_WATERBODY_SAVE, waterbodyDetail),
};
export type UserPreferencesActions = ActionsUnion<typeof UserPreferencesActions>;
