import { ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import Measure from 'react-measure';
import { MediaCard } from '../media_card/media_card';
import { WistiaEmbed } from '../wistia_embed/wistia_embed';
import { imgSrcTransformed, ProductDetailMedia } from './product_detail_images';
import styles from './product_detail_images.less';
import { MagnifyProps } from './product_detail_images_modal';
import { RotateImages } from './rotate_images';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { ColorOption } from '../product_variant_selector/product_variant_selector';

interface ProductDetailImagesHeroProps {
  hoveredColor?: ColorOption;
  initialImageHeight: number;
  isModalOpen: boolean;
  onInitialImageHeightChange: (height: number) => void;
  productFamily: ProductFamily;
  selectedMedia: ProductDetailMedia;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
}

export const ProductDetailImagesHero = (props: ProductDetailImagesHeroProps) => {
  const {
    hoveredColor,
    initialImageHeight,
    isModalOpen,
    onInitialImageHeightChange,
    productFamily,
    selectedMedia,
    selectedVariant,
    shopifyProduct,
  } = props;

  let magnifyProps = {} as MagnifyProps;
  if (selectedMedia.type === 'product_image') {
    magnifyProps = {
      smallImage: {
        src: imgSrcTransformed(selectedMedia.src as string),
        isFluidWidth: true,
        alt: `${shopifyProduct.title} ${selectedVariant.title}`,
        onLoad: (e: SyntheticEvent<HTMLImageElement, Event>) => {
          const img = e.target as HTMLImageElement;
          if (!isModalOpen && !initialImageHeight) {
            onInitialImageHeightChange(img.height);
          }
        },
      },
      largeImage: {
        width: 2400,
        height: 2400,
        src: imgSrcTransformed(selectedMedia.src as string),
        alt: `${shopifyProduct.title} ${selectedVariant.title}`,
      },
      enlargedImagePosition: 'over',
      enlargedImageContainerClassName: styles.imgZoomed,
      isHintEnabled: true,
    };
  }

  return (
    <>
      {hoveredColor ? (
        <div
          className={styles.hoverImageContainer}
          style={{ height: initialImageHeight || 'auto' }}
        >
          <img src={setShopifyImgWidth(hoveredColor?.imgSrc, 1000)} alt={hoveredColor.colorName} />
        </div>
      ) : (
        <>
          {selectedMedia.type === 'product_image' && (
            <div
              className={classNames({
                [styles.magnifyContainer]: isModalOpen,
              })}
            >
              <ReactImageMagnify
                {...magnifyProps}
                key={selectedMedia.src}
                style={{ minHeight: initialImageHeight || 0 }}
              />
            </div>
          )}
          {selectedMedia.type === 'product_video' && (
            <div
              style={{ height: !isModalOpen && initialImageHeight }}
              className={classNames(styles.wistiaContainer, {
                [styles.wistiaContainer__modal]: isModalOpen,
              })}
            >
              <WistiaEmbed embedUrl={productFamily.video_embed} className={styles.wistia} />
            </div>
          )}
          {selectedMedia.type === 'product_360_images' && (
            <div className={styles.threeSixtyContainer__modal}>
              <Measure bounds>
                {({ measureRef, contentRect }) => {
                  const { width } = contentRect.bounds;
                  return (
                    <div
                      className={classNames(styles.rotateImagesWrapper, {
                        [styles.rotateImagesWrapper__modal]: isModalOpen,
                      })}
                      ref={measureRef}
                    >
                      <RotateImages images={productFamily.rotate_images} width={width} />
                    </div>
                  );
                }}
              </Measure>
            </div>
          )}{' '}
          {selectedMedia.type === 'omnia_media' && (
            <div className={styles.omniaMediaHeroContainer} style={{ height: initialImageHeight }}>
              <MediaCard media={selectedMedia.media} showMediaInfo={false} />
            </div>
          )}
        </>
      )}
    </>
  );
};
