import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseDimensionsHook = [
  (node: HTMLElement | null) => void,
  DimensionObject,
  HTMLElement | null
];

export interface UseDimensionsArgs {
  onScroll?: boolean;
  onResize?: boolean;
  externalNode?: HTMLElement;
  onElementResize?: boolean;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom,
    x: rect.x,
    y: rect.y,
  };
}

export function useDimensions({
  onScroll = false,
  onResize = false,
  externalNode = null,
  onElementResize = false,
}: UseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);

  const ref = useCallback(
    (el) => {
      if (externalNode) {
        setNode(externalNode);
        return;
      }
      setNode(el);
    },
    [externalNode]
  );

  const isClient = typeof window !== 'undefined';
  const useEffectForEnv = isClient ? useLayoutEffect : useEffect;

  useEffectForEnv(() => {
    if (node) {
      const measure = () => {
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      };

      measure();

      if (onScroll) {
        window.addEventListener('scroll', measure);
      }
      if (onResize) {
        window.addEventListener('resize', measure);
      }
      let resizeObserver: any;
      if (onElementResize) {
        resizeObserver = new ResizeObserver(measure);
        resizeObserver.observe(node);
      }

      return () => {
        if (onScroll) {
          window.removeEventListener('scroll', measure);
        }
        if (onResize) {
          window.removeEventListener('resize', measure);
        }
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [node, externalNode]);

  return [ref, dimensions as DimensionObject, node];
}
