import { SelectedOption, ShopifyProduct } from '@omniafishing/core';
import _ from 'lodash';

export function getVariantBySelectedOptions(
  product: ShopifyProduct,
  selectedOptions: SelectedOption[]
) {
  if (selectedOptions == null) {
    return null;
  }

  const selectedOptionsLowercase = selectedOptions.map(option => {
    return {
      name: option.name.toLowerCase(),
      value: option.value.toLowerCase(),
    };
  });
  const sortedSelectedOptions = _.sortBy(selectedOptionsLowercase, 'name');

  const variantNode = product.variants.edges.filter(variant => {
    const variantSelectedOptionsLowercase = variant.node.selectedOptions.map(option => {
      return {
        name: option.name.toLowerCase(),
        value: option.value.toLowerCase(),
      };
    });
    const sortedVariantSelectedOptions = _.sortBy(variantSelectedOptionsLowercase, 'name');
    return _.isEqual(sortedSelectedOptions, sortedVariantSelectedOptions);
  })[0];
  if (variantNode) {
    return variantNode.node;
  }
  return null;
}

export function getVariantByPartialSelectedOptions(
  product: ShopifyProduct,
  selectedOptions: SelectedOption[]
) {
  const selectedOptionsLowercase = selectedOptions.map(option => {
    return {
      name: option.name.toLowerCase(),
      value: option.value.toLowerCase(),
    };
  });
  const sortedSelectedOptions = _.sortBy(selectedOptionsLowercase, 'name');
  const selectedOptionsNames = selectedOptionsLowercase.map(option => option.name);

  const variantNode = product.variants.edges.filter(variant => {
    const variantSelectedOptionsLowercase = variant.node.selectedOptions.map(option => {
      return {
        name: option.name.toLowerCase(),
        value: option.value.toLowerCase(),
      };
    });
    const sortedVariantSelectedOptions = _.sortBy(variantSelectedOptionsLowercase, 'name');

    const matchingVariantOptions = sortedVariantSelectedOptions.filter(
      option => selectedOptionsNames.indexOf(option.name) > -1
    );

    return _.isEqual(sortedSelectedOptions, matchingVariantOptions);
  })[0];
  if (variantNode) {
    return variantNode.node;
  }
  return null;
}
