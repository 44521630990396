import { AmbassadorMini, Brand, BrandEventNotifications, OmniaResponse } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'brands';

export enum StateKeys {
  ambassadors = 'ambassadors',
  eventNotificationLoadingState = 'eventNotificationLoadingState',
  favoriteLoadingState = 'favoriteLoadingState',
}

export const initialState = {
  [StateKeys.ambassadors]: [] as AmbassadorMini[],
  [StateKeys.eventNotificationLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.favoriteLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getAmbassadors = (state: ApplicationState) =>
  state[reducerName][StateKeys.ambassadors];
export const getFavoriteLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.favoriteLoadingState];
export const getEventNotificationLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.eventNotificationLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function BrandsReducer(state = initialState, action: BrandsActions) {
  switch (action.type) {
    case ReduxActions.BRAND_AMBASSADORS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.ambassadors]: action.payload.data,
      };

    case ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING:
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.BRAND_FAVORITE_PENDING:
      return {
        ...state,
        [StateKeys.favoriteLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.BRAND_FAVORITE_SUCCESS:
      return {
        ...state,
        [StateKeys.favoriteLoadingState]: LoadingState.DONE,
      };

    default:
      return state;
  }
}
// ========================================================================== //
// Actions
// ========================================================================== //

export const BrandsActions = {
  BRAND_FETCH_PENDING: () => createAction(ReduxActions.BRAND_FETCH_PENDING),
  BRAND_FETCH_SUCCESS: (response: OmniaResponse<Brand>) =>
    createAction(ReduxActions.BRAND_FETCH_SUCCESS, response),
  BRAND_FETCH_ERROR: (err: any) => createAction(ReduxActions.BRAND_FETCH_ERROR, err),

  BRAND_FAVORITE_PENDING: () => createAction(ReduxActions.BRAND_FAVORITE_PENDING),
  BRAND_FAVORITE_SUCCESS: (response: OmniaResponse<Brand>) =>
    createAction(ReduxActions.BRAND_FAVORITE_SUCCESS, response),
  BRAND_FAVORITE_ERROR: (err: any) => createAction(ReduxActions.BRAND_FAVORITE_ERROR, err),

  BRAND_UNFAVORITE_PENDING: () => createAction(ReduxActions.BRAND_UNFAVORITE_PENDING),
  BRAND_UNFAVORITE_SUCCESS: (response: OmniaResponse<Brand>) =>
    createAction(ReduxActions.BRAND_UNFAVORITE_SUCCESS, response),
  BRAND_UNFAVORITE_ERROR: (err: any) => createAction(ReduxActions.BRAND_UNFAVORITE_ERROR, err),

  BRAND_AMBASSADORS_FETCH_PENDING: () => createAction(ReduxActions.BRAND_AMBASSADORS_FETCH_PENDING),
  BRAND_AMBASSADORS_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorMini[]>) =>
    createAction(ReduxActions.BRAND_AMBASSADORS_FETCH_SUCCESS, response),
  BRAND_AMBASSADORS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.BRAND_AMBASSADORS_FETCH_ERROR, err),

  BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING: () =>
    createAction(ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING),
  BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: (response: OmniaResponse<Brand>) =>
    createAction(ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS, response),
  BRAND_EVENT_NOTIFICATIONS_UPDATE_ERROR: (err: any) =>
    createAction(ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_ERROR, err),
};
export type BrandsActions = ActionsUnion<typeof BrandsActions>;

export function fetchBrand(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(BrandsActions.BRAND_FETCH_PENDING());

    return apiV1
      .brandFetch(slug)
      .then((response) => {
        return dispatch(BrandsActions.BRAND_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchBrand: ${slug}`, error);
        return dispatch(BrandsActions.BRAND_FETCH_ERROR(error));
      });
  };
}

export function fetchBrandAmbassadors(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(BrandsActions.BRAND_AMBASSADORS_FETCH_PENDING());

    return apiV1
      .brandAmbassadorsFetch(slug)
      .then((response) => {
        return dispatch(BrandsActions.BRAND_AMBASSADORS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchBrandAmbassadors: ${slug}`, error);
        return dispatch(BrandsActions.BRAND_AMBASSADORS_FETCH_ERROR(error));
      });
  };
}

export function favoriteBrand(
  slug: string,
  event_notifications: BrandEventNotifications,
  email: string
): RequestThunk {
  return (dispatch) => {
    dispatch(BrandsActions.BRAND_FAVORITE_PENDING());

    return apiV1
      .brandFavorite(slug, { event_notifications, email })
      .then((response) => {
        return dispatch(BrandsActions.BRAND_FAVORITE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: favoriteBrand: ${slug}`, error);
        return dispatch(BrandsActions.BRAND_FAVORITE_ERROR(error));
      });
  };
}

export function unfavoriteBrand(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(BrandsActions.BRAND_UNFAVORITE_PENDING());

    return apiV1
      .brandUnfavorite(slug)
      .then((response) => {
        return dispatch(BrandsActions.BRAND_UNFAVORITE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: unfavoriteBrand: ${slug}`, error);
        return dispatch(BrandsActions.BRAND_UNFAVORITE_ERROR(error));
      });
  };
}

export function updateUserNotifications(
  slug: string,
  event_notifications: BrandEventNotifications,
  email: string
): RequestThunk {
  return (dispatch) => {
    dispatch(BrandsActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING());

    return apiV1
      .brandEventNotificationsUpdate(slug, { event_notifications, email })
      .then((response) => {
        return dispatch(BrandsActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: updateUserNotifications: ${slug}`, error);
        return dispatch(BrandsActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_ERROR(error));
      });
  };
}
