import * as React from 'react';

export const SvgShare = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="16px"
    height="22px"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.992 14.267a.963.963 0 0 1-.955-.967V4.295l.07-.895-.766.86-.711.752c-.156.197-.4.287-.678.287-.504 0-.868-.34-.868-.86 0-.269.07-.482.295-.68L7.299.84C7.523.608 7.75.5 7.993.5c.225 0 .45.108.678.34l2.936 2.919a.878.878 0 0 1 .294.68c0 .52-.366.86-.885.86-.295 0-.522-.09-.678-.269l-.712-.77-.765-.86.087.895V13.3a.964.964 0 0 1-.955.967h-.002ZM2.99 21.5C1.044 21.5 0 20.443 0 18.42V9.542c0-2.005 1.042-3.08 2.989-3.08h2.536v2.022H3.007c-.712 0-1.06.359-1.06 1.129v8.754c0 .77.348 1.145 1.06 1.145h9.973c.711 0 1.077-.375 1.077-1.145V9.613c0-.752-.366-1.129-1.078-1.129h-2.502V6.462h2.518c1.981 0 3.005 1.057 3.005 3.08v8.878c0 2.023-1.042 3.08-3.005 3.08H2.987h.002Z"
      fill="currentColor"
    />
  </svg>
);
