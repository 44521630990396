import 'core-js/stable';
import Cookies from 'js-cookie';
import 'native-promise-only';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createFrontloadState } from 'react-frontload';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TrackJS } from 'trackjs';
import { AnalyticsWindowData } from '../server/html';
import { App } from './app';
import {
  migrateLocalData,
  migrateUserPrefsWaterbodiesMissingLocales,
  migrateUserPrefsWaterbodiesMissingSpecies,
} from './data_migrations';
import { getEnv } from './env';
import { StorageKeys } from './lib/local_storage';
import { reducerName as authReducerName, StateKeys as authStateKeys } from './redux/auth';
import { configureStore } from './redux/configure_store';
import { CookieKeys } from './redux/cookie_storage_middleware';
import {
  StateKeys as userPreferencesStateKeys,
  userPreferencesReducerName,
} from './redux/user_preferences';
import {
  isMobile,
  reducerName as windowReducerName,
  StateKeys as windowStateKeys,
} from './redux/window';

TrackJS.install({
  token: 'a07285acf5cb49ff831a54f5488d427b',
  application: getEnv().TRACKJS_APPLICATION,
  enabled: getEnv().TRACKJS_ENABLED,
});

migrateLocalData();
migrateUserPrefsWaterbodiesMissingLocales();
migrateUserPrefsWaterbodiesMissingSpecies();

export interface WindowWithPreloadedState extends Window {
  __PRELOADED_STATE__: any;
  __X_FORWARDED_FOR__: string;
  __FRONTLOAD_DATA__: string;
  __ANALYTICS_DATA__: AnalyticsWindowData;
  __STATSIG_DATA__: Record<string, unknown> | null;
}

const preloadedState = (window as unknown as WindowWithPreloadedState).__PRELOADED_STATE__ || {};

// TODO create a buildInitialState function for all this and test it

const userPreferencesState = {
  [userPreferencesStateKeys.species]:
    JSON.parse(localStorage.getItem(StorageKeys.userPreferencesSpecies)) ||
    preloadedState[userPreferencesReducerName][userPreferencesStateKeys.species],
  [userPreferencesStateKeys.brands]:
    JSON.parse(localStorage.getItem(StorageKeys.userPreferencesBrands)) ||
    preloadedState[userPreferencesReducerName][userPreferencesStateKeys.brands],
  [userPreferencesStateKeys.techniques]:
    JSON.parse(localStorage.getItem(StorageKeys.userPreferencesTechniques)) ||
    preloadedState[userPreferencesReducerName][userPreferencesStateKeys.techniques],
  [userPreferencesStateKeys.waterbodies]:
    JSON.parse(localStorage.getItem(StorageKeys.userPreferencesWaterbodies)) ||
    preloadedState[userPreferencesReducerName][userPreferencesStateKeys.waterbodies],
};

// initial state overrides with client values
const initialState = {
  ...preloadedState,
  [authReducerName]: {
    ...preloadedState[authReducerName],
    [authStateKeys.accessToken]: Cookies.get(CookieKeys.accessToken),
    [authStateKeys.accessTokenExpiration]: Number(Cookies.get(CookieKeys.accessTokenExpiration)),
    [authStateKeys.refreshToken]: Cookies.get(CookieKeys.refreshToken),
  },
  [userPreferencesReducerName]: {
    ...preloadedState[userPreferencesReducerName],
    ...userPreferencesState,
  },
  [windowReducerName]: {
    ...preloadedState[windowReducerName],
    [windowStateKeys.width]: window.innerWidth,
    [windowStateKeys.isMobile]: isMobile(window.innerWidth), // possibly if you don't set this until after mounting, SSR with hydrate will work correctly, with an extra render on desktop.
  },
};

// I don't see a point to doing this...
delete (window as unknown as WindowWithPreloadedState).__PRELOADED_STATE__;

const store = configureStore(initialState);

const frontloadState = createFrontloadState.client({
  context: {},
  serverRenderedData: (window as unknown as WindowWithPreloadedState).__FRONTLOAD_DATA__,
  logging: false,
});
export type FrontLoadStateType = typeof frontloadState;

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);

const scrollY = window.pageYOffset;

// SSR with hydrate was resulting in some confusing and unexpected rendering differences that were
// not solved by adding a mounted state rerender.
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App
        frontloadState={frontloadState}
        statsigInitializeValues={(window as unknown as WindowWithPreloadedState).__STATSIG_DATA__}
      />
    </BrowserRouter>
  </Provider>
);

window.scrollTo(0, scrollY);
