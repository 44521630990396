import { Product, ProductFamily } from '@omniafishing/core';
import searchInsights from 'search-insights';
import { AlgoliaNonSearchEvents } from '../algolia';
import { getEnv } from '../env';

const env = getEnv();

class AlgoliaEventsClass {
  AddToCart(products: Product[], userId: string) {
    searchInsights(AlgoliaNonSearchEvents.convertedObjectIds, {
      userToken: userId, // TODO: change to some UUID, experiment uuid or rudderstack uuid
      authenticatedUserToken: userId,
      index: env.ALGOLIA_INDEX_PRODUCTS,
      eventName: 'add_to_cart',
      objectIDs: products.map((p) => p.sku),
    });
  }

  ViewItem(product_family: ProductFamily, userId: string) {
    searchInsights(AlgoliaNonSearchEvents.viewedObjectIds, {
      userToken: userId,
      index: env.ALGOLIA_INDEX_PRODUCT_FAMILIES,
      eventName: 'view_product_family',
      objectIDs: [product_family.handle],
    });
  }
}

export const AlgoliaEvents = new AlgoliaEventsClass();
