import * as React from 'react';
const SvgStates = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        d="M4.5 10.4c3.6 1.1 7.4 1.6 11 1.6.9 0 1.9 0 2.8-.1.1 0 .2.1.4.2 0 0 .1 0 .1.1l.2.3 1 1.3 1.6-.1h2.2c.2.2.4.4.6.5 1.1 1 2.2 2.1 3.9 2.1.4 0 .8-.1 1.1-.2 0 .1-.1.2-.1.3-.2.6-.3 1.3-.3 2.4l-.4.4c-.6.6-1.8 1.7-2.2 3.3-.8-.2-1.6-.5-2.4-.5-1 0-1.9.4-2.5 1h-.8c-1.8 0-3.1.6-3.9 1.4-.5-.7-1.5-1.6-2.9-1.6h-.1c-.6-1-1.8-2-3.9-2H8.4c-.8-.3-3.5-2.2-4.2-2.8-1.3-1.3-1.1-4-.3-6 .2-.5.5-1 .6-1.6m-.9-3.3c-.4.1-1.1.1-1.5.2 0 1.3-.3 2.5-.8 3.6-1.2 2.9-1.4 7 1 9.3.4.4 3.7 2.8 5.1 3.3.3.2.7.2 1.1.2.5 0 .9-.1 1.4-.1.6 0 1.2.1 1.5.8.3.7.7 1.7 1.5 1.7.2 0 .5-.1.7-.3.1-.1.2-.1.3-.1.5 0 .8.9 1.1 1.2.4.9 1.1 2 2.2 2 .2 0 .4 0 .6-.1-.3-.4.2-1 .5-1.5.6-1.2 1.5-1.4 2.5-1.4.9 0 1.9.2 2.9.2-.4-.9-.1-1.2.4-1.2.8 0 2.3.7 2.9.7h.6c.2 0 .4.1.5.4.6 1.8 1.4 2.6 2 2.6.9 0 1.2-1.6-.5-3.7-1.2-2.5 1.5-3.3 2.4-5 0-.6 0-2.2.2-2.4.6-1.6 2.4-6.5 3.6-7-.4-1-1-2.2-2.1-2.2-.1 0-.3 0-.4.1-.5 1-.6 2.6-2 2.7-.6.1-.9.3-1.1 1-.1.3-1 1.2-1.6 1.4h-.1c-.9 0-2.6-2.4-3.5-2.6-.4-.1-1.6-.1-2.5-.1h-.9c.2-.2.5-.7.5-.8h-.4c-1 0-1.9-.9-3-.9h-.3c-.9.1-1.7.1-2.6.1-4.3-.1-8.4-.8-12.2-2.1z"
        style={{
          fill: 'currentColor',
        }}
      />
    </svg>
  );
};
export default SvgStates;
