import { MailOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { FishingReport, Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useResponsive } from '../../hooks/use_responsive';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import ImageLoader from '../image_loader/image_loader';
import useImageLoader from '../image_loader/use_image_loader';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgFire from '../svg/fire';
import styles from './fishing_report_product.less';

interface FishingReportProductProps {
  buttonSize?: 'sm' | 'md';
  className?: string;
  fishingReport: FishingReport;
  imgContainerClassName?: string;
  isHotbait?: boolean;
  lineItemAttribution?: LineItemCustomAttribute[];
  list_uuid?: string;
  onProductClick?: () => void;
  position: number;
  product: Product;
}

export const FishingReportProduct = (props: FishingReportProductProps) => {
  const {
    buttonSize,
    className,
    fishingReport,
    imgContainerClassName,
    isHotbait,
    lineItemAttribution = [],
    list_uuid,
    onProductClick,
    position,
    product,
  } = props;

  const {
    compare_at_price,
    img_url,
    on_sale,
    price,
    shopify_options,
    shopify_product_handle,
    title,
  } = product;

  const { addToCart } = useCart();
  const dispatch = useDispatch();
  const { isDesktop } = useResponsive();

  const lineItemCustomAttributes: LineItemCustomAttribute[] = _.uniqBy(
    [
      ...lineItemAttribution,
      {
        key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
        value: fishingReport.id.toString(),
      },
    ],
    'key'
  );

  const outOfStock = !product.in_stock;
  const productUrl = OmniaUrls.product(shopify_product_handle, shopify_options);
  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemCustomAttributes)}`;

  const handleProductClick = useCallback(
    (_isHotbait: boolean) => {
      onProductClick?.();
      WebAnalytics.fishingReportEmbeddedClick(
        _isHotbait ? '[reports].(hotbait)' : '[reports].(products)',
        fishingReport,
        'embedded',
        position
      );
    },
    [fishingReport, list_uuid, position]
  );

  const { isImageLoading, isImageError, handleImageLoad, handleImageError } = useImageLoader();
  return (
    <div className={classNames(styles.product, className)}>
      <Link
        to={{
          pathname: productUrl,
          search: productUrlSearch,
        }}
        onClick={() => {
          if (list_uuid) {
            WebAnalytics.productClick({
              productOrProductFamily: product,
              show_modal: false,
              position,
              list_uuid,
            });
            handleProductClick?.(isHotbait);
          }
        }}
        target={isDesktop ? '_blank' : undefined}
      >
        <div className={classNames(styles.productImgContainer, imgContainerClassName)}>
          <ImageLoader
            alt={title}
            className={styles.productImg}
            isImageError={isImageError}
            isImageLoading={isImageLoading}
            onError={handleImageError}
            onLoad={handleImageLoad}
            src={setShopifyImgWidth(img_url, 200)}
          />
          {isHotbait && (
            <span className={styles.hotbaitContainer}>
              <SvgFire className={styles.fire} />
              Hotbait
            </span>
          )}
        </div>
      </Link>

      <div className={styles.cardContents}>
        <div className={styles.description}>
          <Link
            to={{
              pathname: productUrl,
              search: productUrlSearch,
            }}
            onClick={() => {
              WebAnalytics.productClick({
                productOrProductFamily: product,
                show_modal: false,
                position,
                list_uuid,
              });
              handleProductClick?.(isHotbait);
            }}
            target={isDesktop ? '_blank' : undefined}
          >
            <p className={styles.title}>{title}</p>
          </Link>
          {outOfStock ? (
            <p className={styles.price}>Out of Stock</p>
          ) : on_sale ? (
            <div className={styles.sale}>
              <p className={styles.price}>{toDollars(price)}</p>
              <s className={styles.regPrice}>{toDollars(compare_at_price)}</s>
            </div>
          ) : (
            <p className={styles.price}>{toDollars(price)}</p>
          )}
        </div>
        <div className={styles.productCardFooter}>
          {outOfStock ? (
            <Tooltip title="Sign up to be emailed as soon as it's back in stock">
              <OmniaButton
                size={buttonSize || 'md'}
                kind="tertiary"
                fontSize={14}
                onClick={() => {
                  dispatch(
                    InventoryModalActions.INVENTORY_MODAL_OPEN(
                      product,
                      WebAnalyticsEventAreas.REPORTS_VIEW
                    )
                  );
                }}
              >
                <MailOutlined />
                Email Me
              </OmniaButton>
            </Tooltip>
          ) : (
            <OmniaButton
              kind="primary"
              size={buttonSize || 'md'}
              fontSize={14}
              onPress={() => {
                addToCart({
                  productsToAdd: [
                    {
                      product,
                      quantity: 1,
                    },
                  ],
                  addToCartArea: WebAnalyticsEventAreas.REPORTS_VIEW,
                  customAttributes: lineItemCustomAttributes,
                  position,
                  list_uuid,
                });
              }}
            >
              <ShoppingCartOutlined /> Add to Cart
            </OmniaButton>
          )}
        </div>
      </div>
    </div>
  );
};
