import { Species } from '@omniafishing/core';
import { Radio, Select } from 'antd';
import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './product_detail_fishing_reports.less';

interface SpeciesSelectProps {
  speciesCounts: number[];
  speciesInFishingReports: Species[];
  selectedSpecies: SpeciesSelectValue;
  onChange: (species: SpeciesSelectValue) => void;
}

export interface SpeciesSelectValue {
  label: ReactElement;
  value: string;
}

export const labelBuilderSpeciesSelect = (
  species: Species,
  speciesCounts: number[],
  index: number
) => {
  return (
    <Radio value={species?.name} className={styles.selectLabel}>
      <span>{species?.display_name}</span>
      <span className={styles.selectLabelReportCount}>{speciesCounts[index]}</span>
    </Radio>
  );
};

export const defaultSpeciesValue = {
  label: (
    <Radio value={'All Species'} className={styles.selectLabel}>
      <span>
        <span>All Species</span>
      </span>
    </Radio>
  ),
  value: null as string,
};

export const SpeciesSelect = (props: SpeciesSelectProps) => {
  const [radioSelectSpecies, setRadioSelectSpecies] = useState(undefined as string);
  const { setUserPreferencesSpecies } = useUserPreferences();

  const { speciesCounts, selectedSpecies, onChange, speciesInFishingReports } = props;

  const speciesSelectOptions = [defaultSpeciesValue].concat(
    speciesInFishingReports.map((specie, i) => {
      return {
        label: (
          <Radio
            value={specie.name}
            className={styles.selectLabel}
            disabled={speciesCounts[i] === 0}
          >
            <span>{specie.display_name}</span>
            <span className={styles.selectLabelReportCount}>{speciesCounts[i]}</span>
          </Radio>
        ),
        value: specie.name,
        disabled: speciesCounts[i] === 0,
      };
    })
  );

  return (
    <div className={styles.selectContainer}>
      <Radio.Group
        value={radioSelectSpecies}
        onChange={(e) => setRadioSelectSpecies(e.target.value)}
        defaultValue={'All Species'}
      >
        <Select
          options={speciesSelectOptions}
          value={selectedSpecies}
          defaultValue={defaultSpeciesValue}
          labelInValue
          onChange={(val: SpeciesSelectValue) => {
            onChange(val);
            WebAnalytics.contentFiltered('species', val.value);
            setUserPreferencesSpecies(val.value);
          }}
          className={classNames(styles.speciesSelect, styles.selects)}
          dropdownStyle={{ minWidth: 212 }}
        />
      </Radio.Group>
    </div>
  );
};
