import classNames from 'classnames';
import React from 'react';
import SvgCheckMark from '../../components/svg/checkmark';
import styles from './pro_comparison_chart.less';
import { OmniaProgramComparisonItem, omniaProgramComparisons } from './pro_page_data';

const ComparisonListItem = ({
  item,
  fontSize,
}: {
  item: OmniaProgramComparisonItem;
  fontSize: number;
}) => {
  const { heading, text, standardBlurb, proBlurb } = item;
  return (
    <React.Fragment key={item.text}>
      {heading && (
        <span className={styles.comparisonHeading} style={{ fontSize }}>
          {heading}
        </span>
      )}
      <li>
        <span className={styles.item}>
          <span className={styles.itemText} style={{ fontSize }}>
            {text}
          </span>
        </span>
        <span className={styles.proCheckContainer}>
          <span className={styles.proCheck}>
            <SvgCheckMark color="#061622" fontSize={24} viewBox="0 0 24 24" />
            {proBlurb && <span className={styles.checkmarkBlurb__pro}>{proBlurb}</span>}
          </span>
        </span>
        <span className={styles.standardCheck}>
          {item.standard && <SvgCheckMark color="#59636B" fontSize={24} viewBox="0 0 24 24" />}
          {standardBlurb && <span className={styles.checkmarkBlurb}> {standardBlurb}</span>}
        </span>
      </li>
    </React.Fragment>
  );
};

export const ProComparisonChart = ({
  fontSize,
  className,
}: {
  fontSize?: number;
  className?: string;
}) => {
  return (
    <div className={classNames(styles.comparisonTable, className)}>
      <ul>
        <li className={styles.header}>
          <p>
            <strong>
              With <br /> Omnia PRO
            </strong>
          </p>
          <p>
            Without <br />
            Omnia PRO
          </p>
        </li>
        {omniaProgramComparisons.map((item) => (
          <ComparisonListItem key={item.text} item={item} fontSize={fontSize} />
        ))}
        <li>
          <span className={styles.item}>
            <span className={styles.itemText}></span>
          </span>
          <span className={styles.proCheckContainer}>
            <span className={styles.proCheck}></span>
          </span>
          <span className={styles.standardCheck}></span>
        </li>
      </ul>
    </div>
  );
};
