import { omniaStringify } from '../hooks/use_query_string';

import { LINE_ATTRIBUTION, LineItemCustomAttribute } from '../redux/cart';
import { GlobalQueryParams } from './query_string';

export const attributionQueryParamsDivider = '___';

export const formatAttributesAsStrings = (customAttributes: LineItemCustomAttribute[]) =>
  customAttributes.map(
    (attribute) => `${attribute.key}${attributionQueryParamsDivider}${attribute.value}`
  );
export const customAttributesToAttributionQueryParams = (
  customAttributes: LineItemCustomAttribute[] | null
) => {
  if (!customAttributes) {
    return null;
  }
  const attributesAsStrings = formatAttributesAsStrings(customAttributes);

  return omniaStringify({
    [GlobalQueryParams.attribution]: attributesAsStrings,
  });
};

export const attributionQueryParamToCustomAttributes = (
  attributionStrings: string[]
): LineItemCustomAttribute[] => {
  return attributionStrings.map((s) => {
    const [key, value] = s.split(attributionQueryParamsDivider);
    return {
      key: key as LINE_ATTRIBUTION,
      value,
    };
  });
};
