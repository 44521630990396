export const PRO_PURCHASE_URL = 'https://pay.omniafishing.com/b/bIY6pf1fy5bC3PWaEE';
export const PRO_TRIAL_PURCHASE_URL = 'https://pay.omniafishing.com/b/aEU3d3bUcavWdqwbIK';
//  test mode url
export const PRO_PURCHASE_TEST_URL = 'https://pay.omniafishing.com/b/test_bIY4hU1WrbuC6KQ3cd';
// credit purchase urls urls
export const PRO_PURCHASE_CREDIT_URL = 'https://pay.omniafishing.com/b/4gw3d3cYg5bC4U03cg';
export const PRO_TRIAL_CREDIT_URL = 'https://pay.omniafishing.com/b/3cs14V5vO1ZqfyE8wz';

export const proPaymentLinkUrls = [
  PRO_PURCHASE_URL,
  PRO_TRIAL_PURCHASE_URL,
  PRO_PURCHASE_CREDIT_URL,
  PRO_TRIAL_CREDIT_URL,
];

export const proTrialPaymentLinkUrls = [PRO_TRIAL_PURCHASE_URL, PRO_TRIAL_CREDIT_URL];
