import { ColorOption } from './product_variant_selector';
import { ProductColorOptions } from './product_color_options';
import { SelectedOption, ShopifyProduct } from '@omniafishing/core';
import React from 'react';
import styles from './product_variant_color_picker.less';
import classNames from 'classnames';

interface ProductVariantColorPickerProps {
  colorOptions: ColorOption[];
  hoverColorName: string;
  onColorChange: (colorName: string) => void;
  onColorHover: (hoveredColor: ColorOption) => void;
  selectedOptions: SelectedOption[];
  shopifyProduct: ShopifyProduct;
}

export const ProductVariantColorPicker = (props: ProductVariantColorPickerProps) => {
  const {
    shopifyProduct,
    selectedOptions,
    onColorChange,
    onColorHover,
    hoverColorName,
    colorOptions,
  } = props;

  const selectedColorOption = selectedOptions.find(
    (selectedOption) => selectedOption.name.toLowerCase() === 'color'
  );

  if (!colorOptions.length) {
    return null;
  }

  const shouldBeSticky = colorOptions.length > 21;

  return (
    <fieldset className={styles.fieldsetPdp}>
      <div
        className={classNames(styles.optionLegendColor, {
          [styles.optionLegendColor__sticky]: shouldBeSticky,
        })}
      >
        Color:{' '}
        <span className={styles.optionNameColor}>
          {hoverColorName ? hoverColorName : selectedColorOption.value}
        </span>
      </div>

      <ProductColorOptions
        shopifyProduct={shopifyProduct}
        selectedOptions={selectedOptions}
        colorOptions={colorOptions}
        onColorChange={onColorChange}
        onColorHover={onColorHover}
      />
    </fieldset>
  );
};
