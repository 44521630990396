import { Radio, Select } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './product_detail_fishing_reports.less';

interface SortingSelectProps {
  isSortedByDistance: boolean;
  onChange: (val: boolean) => void;
  className?: string;
}

export const SortingSelect = (props: SortingSelectProps) => {
  const { isSortedByDistance, onChange, className } = props;

  const sortingOptions = [
    {
      label: (
        <span className={styles.selectLabel}>
          <Radio value={true}>
            <span>Nearby</span>
          </Radio>
        </span>
      ),
      value: true,
    },
    {
      label: (
        <span className={styles.selectLabel}>
          <Radio value={false}>
            <span>Most recent</span>
          </Radio>
        </span>
      ),
      value: false,
    },
  ];

  return (
    <div className={classNames(styles.selectContainer__sorting, className)}>
      <Radio.Group value={isSortedByDistance}>
        <Select
          options={sortingOptions}
          style={{ width: 375 }}
          value={isSortedByDistance}
          onChange={(val) => {
            onChange(val);
            WebAnalytics.contentSorted(val ? 'nearby' : 'most_recent');
          }}
          dropdownStyle={{ minWidth: '140px' }}
          className={classNames(styles.sortingSelect, styles.selects)}
        />
      </Radio.Group>
    </div>
  );
};
