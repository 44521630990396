import { OmniaResponse, Page } from '@omniafishing/core';
import { AxiosResponse } from 'axios';
import { Document } from 'prismic-javascript/types/documents';
import { RequestThunk } from '../../types/generic';
import { axiosOmniaV1 } from '../axios_omnia';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import {
  PrismicDocument,
  PrismicDocumentTypes,
  prismicOmniaApi,
  prismicOmniaInstance,
  PrismicPage,
  PrismicQa,
} from '../prismic';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'pages';

export enum StateKeys {
  page = 'page',
  loadingState = 'loadingState',
  prismicPage = 'prismicPage',
  prismicloadingState = 'prismicloadingState',
  qas = 'qas',
  genericPageData = 'genericPageData',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.page]: null as Page | null,
  [StateKeys.prismicPage]: null as PrismicPage | null,
  [StateKeys.prismicloadingState]: LoadingState.NOT_STARTED,
  [StateKeys.qas]: [] as PrismicDocument<PrismicQa>[],
  [StateKeys.genericPageData]: null as any | null,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getPage = (state: ApplicationState) => state[reducerName][StateKeys.page];
export const getPrismicPage = (state: ApplicationState) =>
  state[reducerName][StateKeys.prismicPage];
export const getPrismicLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.prismicloadingState];
export const getQas = (state: ApplicationState) => state[reducerName][StateKeys.qas];
export const getGenericPageData = (state: ApplicationState) =>
  state[reducerName][StateKeys.genericPageData];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function PagesReducer(state = initialState, action: PageActions) {
  switch (action.type) {
    case ReduxActions.PAGE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.PAGE_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.page]: action.payload.data,
      };

    case ReduxActions.PRISMIC_PAGE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.prismicloadingState]: LoadingState.PENDING,
        [StateKeys.prismicPage]: null,
      };

    case ReduxActions.PRISMIC_PAGE_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.prismicloadingState]: LoadingState.DONE,
        [StateKeys.prismicPage]: action.payload.data,
      };

    case ReduxActions.PRISMIC_QAS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.qas]: [],
      };

    case ReduxActions.PRISMIC_QAS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.qas]: action.payload,
      };

    case ReduxActions.GENERIC_PAGE_DATA_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.genericPageData]: null,
      };

    case ReduxActions.GENERIC_PAGE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.genericPageData]: action.payload.data,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const PageActions = {
  PAGE_FETCH_PENDING: () => createAction(ReduxActions.PAGE_FETCH_PENDING),
  PAGE_FETCH_SUCCESS: (response: OmniaResponse<Page>) =>
    createAction(ReduxActions.PAGE_FETCH_SUCCESS, response),
  PAGE_FETCH_ERROR: (err: any) => createAction(ReduxActions.PAGE_FETCH_ERROR, err),

  PRISMIC_PAGE_FETCH_PENDING: () => createAction(ReduxActions.PRISMIC_PAGE_FETCH_PENDING),
  PRISMIC_PAGE_FETCH_SUCCESS: (response: Document) =>
    createAction(ReduxActions.PRISMIC_PAGE_FETCH_SUCCESS, response),
  PRISMIC_PAGE_FETCH_ERROR: (err: any) => createAction(ReduxActions.PRISMIC_PAGE_FETCH_ERROR, err),

  PRISMIC_QAS_FETCH_PENDING: () => createAction(ReduxActions.PRISMIC_QAS_FETCH_PENDING),
  PRISMIC_QAS_FETCH_SUCCESS: (response: PrismicDocument<PrismicQa>[]) =>
    createAction(ReduxActions.PRISMIC_QAS_FETCH_SUCCESS, response),
  PRISMIC_QAS_FETCH_ERROR: (err: any) => createAction(ReduxActions.PRISMIC_QAS_FETCH_ERROR, err),

  GENERIC_PAGE_DATA_FETCH_PENDING: () => createAction(ReduxActions.GENERIC_PAGE_DATA_FETCH_PENDING),
  GENERIC_PAGE_DATA_FETCH_SUCCESS: (response: OmniaResponse<any>) =>
    createAction(ReduxActions.GENERIC_PAGE_DATA_FETCH_SUCCESS, response),
  GENERIC_PAGE_DATA_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.GENERIC_PAGE_DATA_FETCH_ERROR, err),
};
export type PageActions = ActionsUnion<typeof PageActions>;

export function fetchPage(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(PageActions.PAGE_FETCH_PENDING());

    return apiV1
      .pageFetch(slug)
      .then((response) => {
        return dispatch(PageActions.PAGE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchPage', error);
        return dispatch(PageActions.PAGE_FETCH_ERROR(error));
      });
  };
}

export function fetchPrismicPage(documentType: PrismicDocumentTypes): RequestThunk {
  return (dispatch) => {
    dispatch(PageActions.PRISMIC_PAGE_FETCH_PENDING());

    return prismicOmniaInstance()
      .then((api) => {
        return api.getSingle(documentType);
      })
      .then((response) => {
        return dispatch(PageActions.PRISMIC_PAGE_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`Error: fetchPrismicPage`, error);
        return dispatch(PageActions.PRISMIC_PAGE_FETCH_ERROR(error));
      });
  };
}

export function fetchPrismicQasByTag(tag: string, pageSize = 50): RequestThunk {
  return (dispatch) => {
    dispatch(PageActions.PRISMIC_QAS_FETCH_PENDING());

    return prismicOmniaApi
      .qasFetchByTag(tag, pageSize)
      .then((response) => {
        return dispatch(PageActions.PRISMIC_QAS_FETCH_SUCCESS(response.results));
      })
      .catch((error) => {
        errorHandler(`Error: fetchPrismicPage`, error);
        return dispatch(PageActions.PRISMIC_QAS_FETCH_ERROR(error));
      });
  };
}

export function fetchGenericPageData(url: string): RequestThunk {
  return (dispatch) => {
    dispatch(PageActions.GENERIC_PAGE_DATA_FETCH_PENDING());

    return axiosOmniaV1
      .get(url)
      .then((response: AxiosResponse<OmniaResponse<any>>) => {
        return dispatch(PageActions.GENERIC_PAGE_DATA_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchGenericPageData', error);
        return dispatch(PageActions.GENERIC_PAGE_DATA_FETCH_ERROR(error));
      });
  };
}
