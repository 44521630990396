import { FishingReport, Product } from '@omniafishing/core';
import { Button, Radio } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fishingReportContainsSku } from '../../../lib/fishing_reports';
import { getSeasonGroups } from '../../../redux/reference_data';
import { convertSeasonGroupNameToSeasonGroup } from './season_group_radios';
import styles from './all_filters_drawer.less';
import { getProductCounts } from '../filters';

interface ProductRadiosProps {
  onChange: (val: string) => void;
  fishingReports: FishingReport[];
  productFamilyProducts: Product[];
  drawerProductSku: string;
  drawerSpeciesName: string;
  drawerSeasonGroupName: string;
}

export const ProductRadios = (props: ProductRadiosProps) => {
  const seasonGroups = useSelector(getSeasonGroups);
  const [showMore, setShowMore] = useState(false);
  const {
    fishingReports,
    productFamilyProducts,
    onChange,
    drawerProductSku,
    drawerSpeciesName,
    drawerSeasonGroupName,
  } = props;

  const productsInFishingReports = productFamilyProducts.filter((product) => {
    const reportCount = fishingReports.filter((report) => {
      return fishingReportContainsSku(report, product.sku);
    }).length;
    if (reportCount > 0) {
      return product;
    }
  });

  const selectedSeasonGroup =
    drawerSeasonGroupName &&
    convertSeasonGroupNameToSeasonGroup(drawerSeasonGroupName, seasonGroups);

  const productCounts = getProductCounts({
    fishingReports,
    selectedSeasonGroup,
    selectedSpeciesName: drawerSpeciesName,
    productsInFishingReports,
  });

  const productList = (products: Product[]) =>
    products.map((product, i) => {
      return (
        <Radio
          value={product.sku}
          className={styles.radioLabel__product}
          key={product.sku}
          disabled={productCounts[i] === 0}
        >
          <span className={styles.radioLabelImg}>
            <img src={product.img_url} alt="" />
          </span>
          <span className={styles.radioName}>
            {product?.shopify_options.map((option) => option.value).join(' / ')}
          </span>
          <span className={styles.selectLabelReportCount__product}>{productCounts[i]}</span>
        </Radio>
      );
    });

  return (
    <div className={styles.variantRadios}>
      <Radio.Group
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={drawerProductSku}
      >
        <Radio value={undefined} className={styles.selectLabelDefault__product}>
          <span>
            <span>All Variations</span>
          </span>
        </Radio>
        {showMore
          ? productList(productsInFishingReports)
          : productList(productsInFishingReports.slice(0, 10))}
      </Radio.Group>
      {productsInFishingReports.length > 9 ? (
        <Button
          type="link"
          size="small"
          className={styles.showMoreLinkButton}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show All'}
        </Button>
      ) : null}
    </div>
  );
};
