import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styles from './omnia_link.less';

interface OmniaLinkProps extends LinkProps {
  underline?: boolean;
  currentColor?: boolean;
}

export const OmniaLink = (props: OmniaLinkProps) => {
  const { children, className, currentColor, underline, ...rest } = props;
  return (
    <Link
      className={classNames(className, {
        [styles.underline]: underline,
        [styles.currentColor]: currentColor,
      })}
      {...rest}
    >
      {children}
    </Link>
  );
};
