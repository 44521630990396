import { List, OmniaResponse } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'lists';

export enum StateKeys {
  list = 'list',
  loadingState = 'loadingState',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.list]: null as List,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getList = (state: ApplicationState) => state[reducerName][StateKeys.list];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function ListReducer(state = initialState, action: ListActions) {
  switch (action.type) {
    case ReduxActions.LIST_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.list]: null,
      };

    case ReduxActions.LIST_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.list]: action.payload.data,
      };

    case ReduxActions.LIST_CLEAR:
      return initialState;

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const ListActions = {
  LIST_FETCH_PENDING: () => createAction(ReduxActions.LIST_FETCH_PENDING),
  LIST_FETCH_SUCCESS: (response: OmniaResponse<List>) =>
    createAction(ReduxActions.LIST_FETCH_SUCCESS, response),
  LIST_FETCH_ERROR: (err: any) => createAction(ReduxActions.LIST_FETCH_ERROR, err),

  LIST_CLEAR: () => createAction(ReduxActions.LIST_CLEAR),
};
export type ListActions = ActionsUnion<typeof ListActions>;

export function fetchList(urlSlug: string): RequestThunk {
  return (dispatch) => {
    dispatch(ListActions.LIST_FETCH_PENDING());

    return apiV1
      .listFetch(urlSlug)
      .then((response) => {
        return dispatch(ListActions.LIST_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchList', error);
        return dispatch(ListActions.LIST_FETCH_ERROR(error));
      });
  };
}
