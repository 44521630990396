import {
  CalendarFilled,
  EnvironmentFilled,
  MailOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { FishingReport, Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import placeholderImg from '../../assets/placeholder.svg';
import { useCart } from '../../hooks/use_cart';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { getImgixPath } from '../../lib/imgix';
import { GlobalQueryParams } from '../../lib/query_string';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { NAME_FALLBACK } from '../../lib/user';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { getTechniqueByTechniqueName } from '../../redux/reference_data';
import { PDPMediaFishingReport } from '../../routes/product_detail_page/product_detail_page';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import ImageLoader from '../image_loader/image_loader';
import useImageLoader from '../image_loader/use_image_loader';
import { OmniaButton } from '../omnia_button/omnia_button';
import { SeasonIcon } from '../season_icon/season_icon';
import SvgExpandIcon from '../svg/expand_icon';
import SvgFire from '../svg/fire';
import styles from './media_fishing_report_card.less';

const HERO_PIXEL_WIDTH = 330;

interface MediaFishingReportCardProps {
  report: FishingReport | PDPMediaFishingReport;
  position: number;
  list_uuid: string;
  lineItemAttribution?: LineItemCustomAttribute[];
  showTechnique?: boolean;
  showSeason?: boolean;
  preferProductTitle?: boolean;
}
export const MediaFishingReportCard = (props: MediaFishingReportCardProps) => {
  const {
    lineItemAttribution = [],
    list_uuid,
    position,
    report,
    showSeason,
    showTechnique,
    preferProductTitle,
  } = props;
  const { featured_product, species, season, image, user, outing_date, accepted_at, technique } =
    report;

  let productToFeature: Product;
  if ('alternateProductToDisplay' in report) {
    productToFeature = report.alternateProductToDisplay;
  } else {
    productToFeature = featured_product;
  }
  const { img_url, title, on_sale, price, compare_at_price, shopify_options } = productToFeature;

  const lineItemCustomAttributes: LineItemCustomAttribute[] = _.uniqBy(
    [
      ...lineItemAttribution,
      {
        key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
        value: report.id.toString(),
      },
    ],
    'key'
  );
  const outOfStock = !productToFeature.in_stock;

  const { replaceQueryString } = useQueryString();
  const setFishingReportId = () => {
    replaceQueryString({
      [GlobalQueryParams.report_id]: report.id,
    });
  };
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const { addToCart } = useCart();

  const [imageBelowMinHeight, setImageBelowMinHeight] = useState(false);
  const { isImageLoading, isImageError, handleImageLoad, handleImageError } = useImageLoader();

  const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight: height } = event.target as HTMLImageElement;
    const desktopMinHeight = 293;
    const mobileMinHeight = window.innerHeight * 0.33;
    setImageBelowMinHeight(isDesktop ? height < desktopMinHeight : height < mobileMinHeight);
    handleImageLoad();
  };

  const techniqueByTechniqueName = useSelector(getTechniqueByTechniqueName);

  const imagePath =
    image &&
    getImgixPath(image, {
      w: HERO_PIXEL_WIDTH,
      crop: 'faces',
    });

  const userFullName = user.full_name || NAME_FALLBACK;
  const formattedDate = dayjs(outing_date || accepted_at).format('M/D/YY');

  const handleProductClick = () => {
    WebAnalytics.productClick({
      productOrProductFamily: productToFeature,
      show_modal: false,
      position,
      list_uuid,
    });
    WebAnalytics.fishingReportEmbeddedClick('[reports].(hotbait)', report, 'embedded', position);
    setFishingReportId();
  };

  const isHotbait = featured_product?.id === productToFeature.id;
  const productTitleToDisplay = preferProductTitle
    ? title
    : shopify_options.map(({ value }) => value).join(' ');

  return (
    <div className={classNames(styles.fishingReportCard, styles.card)} onClick={setFishingReportId}>
      <div className={styles.hero}>
        <div
          className={classNames(styles.mediaContainer, {
            [styles.imgBackground__error]: isImageError,
          })}
        >
          <img className={styles.imgBackground} src={imagePath} />
          <ImageLoader
            className={classNames(styles.image, {
              [styles.image__minHeight]: imageBelowMinHeight,
            })}
            isImageError={isImageError}
            isImageLoading={isImageLoading}
            loadingClassName={styles.loading}
            onClick={setFishingReportId}
            onError={handleImageError}
            onLoad={(e) => onImageLoad(e)}
            src={isImageError ? placeholderImg : imagePath}
          />
          <button className={styles.expandIcon} onClick={setFishingReportId}>
            <SvgExpandIcon />
          </button>
          <div className={styles.chipContainer}>
            <div className={styles.chipDetails}>
              <p className={styles.chipDetail__date}>
                <CalendarFilled />
                <span>{formattedDate}</span>
              </p>
              <p className={styles.chipDetail}>
                <AmbassadorColorDot user={user} bordered />
                <AmbassadorLink ambassador={user} onClick={(e) => e.stopPropagation()}>
                  {userFullName}
                </AmbassadorLink>
              </p>
              <p className={styles.chipDetail}>
                <Link
                  to={OmniaUrls.waterbody(report.waterbody)}
                  className={styles.reportedOnLake}
                  onClick={(e) => e.stopPropagation()}
                >
                  <EnvironmentFilled />
                  <span className={styles.noBreak}>
                    <em>{report.waterbody.primary_name}</em>
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.reportDetails}>
        <ul className={styles.listDetails}>
          <li className={styles.species}>
            <p className={styles.reportProperty}>Species</p>
            <p className={styles.detail}>
              {species.image && <img src={species.image} />}
              {species.display_name}
            </p>
          </li>
          {showSeason && (
            <li className={styles.species}>
              <p className={styles.reportProperty}>Season</p>
              <p className={styles.detail}>
                <SeasonIcon seasonName={season.name} className={styles.seasonIcon} tag={'span'} />
                {season.display_name}
              </p>
            </li>
          )}
          {showTechnique && (
            <li className={styles.technique}>
              <p className={styles.reportProperty}>Technique</p>
              <p>{techniqueByTechniqueName(technique.name).display_name_alternate}</p>
            </li>
          )}
        </ul>
        <div className={styles.productCard}>
          <div className={styles.product}>
            <div className={styles.productImgContainer} onClick={handleProductClick}>
              <img
                src={setShopifyImgWidth(img_url, 300)}
                alt={title}
                className={styles.productImg}
              />
              {isHotbait && (
                <span className={styles.hotbaitContainer}>
                  <SvgFire className={styles.fire} />
                  Hotbait
                </span>
              )}
            </div>
            <div className={styles.cardContents}>
              <div className={styles.description}>
                <div onClick={handleProductClick}>
                  <p className={styles.title}>{productTitleToDisplay}</p>
                </div>
                {outOfStock ? (
                  <p className={styles.price}>Out of Stock</p>
                ) : on_sale ? (
                  <div className={styles.sale}>
                    <p className={styles.price}>{toDollars(price)}</p>
                    <s className={styles.priceCompare}>{toDollars(compare_at_price)}</s>
                  </div>
                ) : (
                  <p className={styles.price}>{toDollars(price)}</p>
                )}
              </div>
              <div className={styles.productCardFooter}>
                {outOfStock ? (
                  <Tooltip title="Sign up to be emailed as soon as it's back in stock">
                    <div>
                      <OmniaButton
                        size="md"
                        kind="tertiary"
                        block
                        fontSize={14}
                        onClick={() => {
                          dispatch(
                            InventoryModalActions.INVENTORY_MODAL_OPEN(
                              productToFeature,
                              WebAnalyticsEventAreas.REPORTS_VIEW
                            )
                          );
                        }}
                      >
                        <MailOutlined />
                        Email Me
                      </OmniaButton>
                    </div>
                  </Tooltip>
                ) : (
                  <OmniaButton
                    kind="primary"
                    size="md"
                    fontSize={14}
                    block
                    onPress={() => {
                      addToCart({
                        productsToAdd: [
                          {
                            product: productToFeature,
                            quantity: 1,
                          },
                        ],
                        position,
                        addToCartArea: WebAnalyticsEventAreas.REPORTS_VIEW,
                        customAttributes: lineItemCustomAttributes,
                        list_uuid,
                      });
                    }}
                  >
                    <ShoppingCartOutlined /> Add to Cart
                  </OmniaButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
