import React from 'react';
import styles from './product_chip.less';
import dayjs from 'dayjs';
import classNames from 'classnames';

interface ProductChipProps {
  onSale: boolean;
  popularity: number;
  stateBestSeller?: boolean;
  trending?: boolean;
  createdAt: string;
  className?: string;
}

export const ProductChip = (props: ProductChipProps) => {
  const { trending, onSale, popularity, stateBestSeller, createdAt, className } = props;
  const now = dayjs();
  const createdAtDate = dayjs(createdAt);
  let productChip: JSX.Element;
  if (trending) {
    productChip = (
      <span className={classNames(styles.badge, className, styles.badge__trending)}>Trending</span>
    );
  } else if (onSale) {
    productChip = (
      <span className={classNames(styles.badge, className, styles.badge__onSale)}>On Sale</span>
    );
  } else if (popularity >= 60) {
    productChip = (
      <span className={classNames(styles.badge, className, styles.badge__bestSeller)}>
        Best Seller
      </span>
    );
  } else if (stateBestSeller) {
    productChip = (
      <span className={classNames(styles.badge, className, styles.badge__bestSeller)}>
        {stateBestSeller} Best Seller
      </span>
    );
  } else if (now.diff(createdAtDate, 'days') <= 30) {
    productChip = (
      <span className={classNames(styles.badge, className, styles.badge__new)}>New</span>
    );
  }
  return <>{productChip}</>;
};
