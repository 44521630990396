import { Media } from '@omniafishing/core';
import React from 'react';
import OmniaCarousel from '../../components/carousel/omnia_carousel';
import { MediaCard } from '../../components/media_card/media_card';
import { ClickKey_ProductDetailPage, WebAnalytics } from '../../lib/web_analytics';
import styles from './product_videos_articles.less';

interface ProductVideosAndArticlesProps {
  media: Media[];
}

export const ProductVideosAndArticles = ({ media }: ProductVideosAndArticlesProps) => {
  return (
    <>
      <div className={styles.productDetailsContainer}>
        <h3 className={styles.sectionHeading}>Videos & Articles ({media.length})</h3>
        <ul className={styles.contentList}>
          <OmniaCarousel
            key={media.map((m) => m.media_item.title).join('')}
            arrows={true}
            fadedEdges
          >
            {media.map((m) => {
              return (
                <li className={styles.content} key={m.uid}>
                  <MediaCard
                    media={m}
                    imgWidth={427}
                    onClick={() => {
                      let clickKey: ClickKey_ProductDetailPage;
                      if (m.media_type === 'BassUArticle') {
                        clickKey = '[articles].(bu_link)';
                      } else if (m.media_type === 'PrismicArticle') {
                        clickKey = '[articles].(omnia_article_link)';
                      } else if (m.media_type === 'PrismicVideo') {
                        clickKey = '[articles].(omnia_video_link)';
                      } else {
                        clickKey = '[articles].(omnia_video_link)';
                      }
                      WebAnalytics.productDetailPageClick(clickKey);
                    }}
                  />
                </li>
              );
            })}
          </OmniaCarousel>
        </ul>
      </div>
    </>
  );
};
