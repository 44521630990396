import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWaypointFishIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    viewBox="0 0 26 25"
    fill="none"
    {...props}
  >
    <circle cx={13} cy={12.5} r={11.5} fill="#1E40AF" stroke="#fff" strokeWidth={2} />
    <g clipPath="url(#waypoint_fish_icon_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.25 11.917a.583.583 0 1 0-1.167 0 .583.583 0 0 0 1.167 0M20 12.2c-.69 1.184-1.92 2.38-3.302 3.105-.779-.742-1.222-1.725-1.226-2.786-.005-1.12.48-2.161 1.34-2.931 1.319.575 2.483 1.52 3.188 2.612m-3.867 3.368c-.358.15-.721.261-1.086.335-.654.786-1.631 1.264-3.084 1.264-.226 0-.463-.012-.713-.036a3.03 3.03 0 0 0 .595-1.547c-1.585-.586-2.728-1.677-3.512-2.501-.605.685-1.418 1.166-2.333 1.166.624-1.103.648-2.748 0-4.082.79.013 1.75.52 2.333 1.166.806-.7 1.915-1.552 3.505-2.007a3.041 3.041 0 0 0-.588-1.458c.25-.023.488-.035.715-.035 1.439 0 2.412.468 3.066 1.242.396.062.791.158 1.177.288-.847.857-1.324 1.966-1.32 3.158a4.408 4.408 0 0 0 1.245 3.047"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="waypoint_fish_icon_svg__a">
        <path fill="#fff" d="M6 5.5h14v14H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWaypointFishIcon;
