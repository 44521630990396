import * as React from 'react';
const SvgOpenInNewWindow = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="open_in_new_window_svg__Layer_1"
    width={49}
    height={49}
    {...props}
  >
    <defs>
      <style>{'.open_in_new_window_svg__cls-2{fill:#595959}'}</style>
    </defs>
    <path
      d="M24.5 0C10.969 0 0 10.969 0 24.5S10.969 49 24.5 49 49 38.031 49 24.5 38.031 0 24.5 0Zm8.787 34.357a.857.857 0 0 1-.858.858H14.645a.858.858 0 0 1-.858-.858V16.571h-.002c0-.474.384-.858.858-.858h9.644c.118 0 .214.096.214.214v1.5a.214.214 0 0 1-.214.214h-8.572v15.644h15.644v-8.572c0-.118.096-.214.214-.214h1.5c.118 0 .214.096.214.214v9.644Zm1.929-12.002a.214.214 0 0 1-.214.214h-1.5a.214.214 0 0 1-.214-.214v-5.196L22.64 27.801a.212.212 0 0 1-.302 0l-1.136-1.136a.212.212 0 0 1 0-.302l10.65-10.65h-5.208a.214.214 0 0 1-.214-.214v-1.5c0-.118.096-.214.214-.214h8.358c.118 0 .214.096.214.214v8.356Z"
      style={{
        fill: 'rgb(255, 255, 255, 0.85)',
      }}
    />
    <path
      d="M13.787 16.571v17.786c0 .474.384.858.858.858h17.784a.857.857 0 0 0 .858-.858v-9.644a.214.214 0 0 0-.214-.214h-1.5a.214.214 0 0 0-.214.214v8.572H15.715V17.641h8.572a.214.214 0 0 0 .214-.214v-1.5a.214.214 0 0 0-.214-.214h-9.644a.858.858 0 0 0-.858.858h.002Z"
      className="open_in_new_window_svg__cls-2"
    />
    <path
      d="M35.001 13.785h-8.358a.214.214 0 0 0-.214.214v1.5c0 .118.096.214.214.214h5.208l-10.65 10.65a.212.212 0 0 0 0 .302l1.136 1.136c.084.084.22.084.302 0l10.648-10.642v5.196c0 .118.096.214.214.214h1.5a.214.214 0 0 0 .214-.214v-8.356a.214.214 0 0 0-.214-.214Z"
      className="open_in_new_window_svg__cls-2"
    />
  </svg>
);
export default SvgOpenInNewWindow;
