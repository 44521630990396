import { ApolloQueryResult } from '@apollo/client';
import {
  FishingReport,
  OmniaResponse,
  ProductFamily,
  SelectedOption,
  ShopifyProduct,
  ShopifyVariant,
} from '@omniafishing/core';
import gql from 'graphql-tag';
import { RequestThunk } from '../../types/generic';
import { shopifyClientInstance } from '../apollo';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { getVariantBySelectedOptions } from '../lib/get_variant_by_selected_options';
import { decodeVariantUrl } from '../routes';
import { ActionsUnion, createAction } from './actions_helper';
import { VariantFragment } from './fragments';

export const reducerName = 'productDetail';

export enum StateKeys {
  fishingReports = 'fishingReports',
  fishingReportsLoadingState = 'fishingReportsLoadingState',
  shopifyProductLoadingState = 'loadingState',
  productFamily = 'productFamily',
  productFamilyLoadingState = 'productFamilyLoadingState',
  selectedVariant = 'selectedVariant',
  shopifyProduct = 'shopifyProduct',
}

export const initialState = {
  [StateKeys.fishingReports]: [] as FishingReport[],
  [StateKeys.fishingReportsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.productFamily]: null as ProductFamily,
  [StateKeys.productFamilyLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.selectedVariant]: null as ShopifyVariant,
  [StateKeys.shopifyProduct]: null as ShopifyProduct,
  [StateKeys.shopifyProductLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getShopifyProductLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.shopifyProductLoadingState];
export const getShopifyProduct = (state: ApplicationState) =>
  state[reducerName][StateKeys.shopifyProduct];
export const getSelectedVariant = (state: ApplicationState) =>
  state[reducerName][StateKeys.selectedVariant];
export const getProductFamily = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamily];
export const getProductFamilyLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamilyLoadingState];
export const getFishingReports = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReports];
export const getFishingReportsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReportsLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function productsReducer(state = initialState, action: ProductDetailActions) {
  switch (action.type) {
    case ReduxActions.SHOPIFY_PRODUCT_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.shopifyProductLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.SHOPIFY_PRODUCT_FETCH_SUCCESS: {
      const pathVariantAttributes = action.payload.pathVariantAttributes;
      const product = action.payload.response.data.product;
      const selectedVariant = INTERNALS.getVariantFromPath(product, pathVariantAttributes);
      return {
        ...state,
        [StateKeys.shopifyProduct]: product,
        [StateKeys.selectedVariant]: selectedVariant,
        [StateKeys.shopifyProductLoadingState]: LoadingState.DONE,
      };
    }

    case ReduxActions.SHOPIFY_PRODUCT_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.shopifyProductLoadingState]: LoadingState.ERROR,
      };

    case ReduxActions.VARIANT_SELECTED:
      return {
        ...state,
        [StateKeys.selectedVariant]: action.payload.variant,
      };

    case ReduxActions.PRODUCT_FAMILY_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.productFamily]: null,
        [StateKeys.productFamilyLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.PRODUCT_FAMILY_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.productFamily]: action.payload.data,
        [StateKeys.productFamilyLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.PRODUCT_FAMILY_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.productFamilyLoadingState]: LoadingState.ERROR,
      };

    case ReduxActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.fishingReports]: [],
        [StateKeys.fishingReportsLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.fishingReports]: action.payload.data,
        [StateKeys.fishingReportsLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.PRODUCT_FISHING_REPORTS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.fishingReports]: [],
      };
    }

    case ReduxActions.PRODUCT_FISHING_REPORTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.fishingReports]: action.payload.data,
      };
    }

    case ReduxActions.PRODUCT_DETAIL_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export function getVariantFromPath(
  product: ShopifyProduct,
  pathVariantAttributes: SelectedOption[]
): ShopifyVariant {
  const variantMatch = INTERNALS.getVariantBySelectedOptions(product, pathVariantAttributes);
  return (
    variantMatch ||
    product.variants.edges.map((e) => e.node).find((v) => v.availableForSale) ||
    product.variants.edges[0].node
  );
}

export const INTERNALS = {
  getVariantFromPath,
  getVariantBySelectedOptions,
};

// ========================================================================== //
// Actions
// ========================================================================== //

export const ProductDetailActions = {
  SHOPIFY_PRODUCT_FETCH_PENDING: () => createAction(ReduxActions.SHOPIFY_PRODUCT_FETCH_PENDING),
  SHOPIFY_PRODUCT_FETCH_SUCCESS: (payload: FetchProductByHandlePayload) =>
    createAction(ReduxActions.SHOPIFY_PRODUCT_FETCH_SUCCESS, payload),
  SHOPIFY_PRODUCT_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.SHOPIFY_PRODUCT_FETCH_ERROR, err),

  PRODUCT_FAMILY_FETCH_PENDING: () => createAction(ReduxActions.PRODUCT_FAMILY_FETCH_PENDING),
  PRODUCT_FAMILY_FETCH_SUCCESS: (response: OmniaResponse<ProductFamily>) =>
    createAction(ReduxActions.PRODUCT_FAMILY_FETCH_SUCCESS, response),
  PRODUCT_FAMILY_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.PRODUCT_FAMILY_FETCH_ERROR, err),

  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING: () =>
    createAction(ReduxActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING),
  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS: (response: OmniaResponse<FishingReport[]>) =>
    createAction(ReduxActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS, response),
  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_ERROR, err),

  PRODUCT_FISHING_REPORTS_FETCH_PENDING: () =>
    createAction(ReduxActions.PRODUCT_FISHING_REPORTS_FETCH_PENDING),
  PRODUCT_FISHING_REPORTS_FETCH_SUCCESS: (response: OmniaResponse<FishingReport[]>) =>
    createAction(ReduxActions.PRODUCT_FISHING_REPORTS_FETCH_SUCCESS, response),
  PRODUCT_FISHING_REPORTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.PRODUCT_FISHING_REPORTS_FETCH_ERROR, err),

  VARIANT_SELECTED: (variant: ShopifyVariant) =>
    createAction(ReduxActions.VARIANT_SELECTED, { variant }),

  PRODUCT_DETAIL_CLEAR: () => createAction(ReduxActions.PRODUCT_DETAIL_CLEAR),
};
export type ProductDetailActions = ActionsUnion<typeof ProductDetailActions>;

const FETCH_PRODUCT_BY_HANDLE_QUERY = gql`
  query FetchProductByHandle($handle: String!) {
    product(handle: $handle) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      vendor
      tags
      options {
        name
        values
      }
      variants(first: 250) {
        edges {
          node {
            ...VariantFragment
          }
        }
      }
    }
  }
  ${VariantFragment}
`;

export interface FetchProductByHandleResponse {
  product: ShopifyProduct;
}

export interface FetchProductByHandlePayload {
  response: ApolloQueryResult<FetchProductByHandleResponse>;
  pathVariantAttributes: SelectedOption[];
}

export function fetchShopifyProductByHandle(
  productHandle: string,
  variantOptionsEncoded: string
): RequestThunk {
  const variantOptions = decodeVariantUrl(variantOptionsEncoded);
  const pathVariantAttributes = variantOptions;
  const handle = productHandle;

  return (dispatch) => {
    dispatch(ProductDetailActions.SHOPIFY_PRODUCT_FETCH_PENDING());

    return shopifyClientInstance
      .query({
        query: FETCH_PRODUCT_BY_HANDLE_QUERY,
        variables: {
          handle,
        },
      })
      .then((response: ApolloQueryResult<FetchProductByHandleResponse>) => {
        return dispatch(
          ProductDetailActions.SHOPIFY_PRODUCT_FETCH_SUCCESS({ response, pathVariantAttributes })
        );
      })
      .catch((error) => {
        errorHandler(
          `ERROR: fetchProductByHandle: ${productHandle}, ${variantOptionsEncoded}`,
          error
        );
        return dispatch(ProductDetailActions.SHOPIFY_PRODUCT_FETCH_ERROR(error));
      });
  };
}

export function fetchProductFamily(handle: string): RequestThunk {
  return (dispatch) => {
    dispatch(ProductDetailActions.PRODUCT_FAMILY_FETCH_PENDING());

    return apiV1
      .productFamilyFetch(handle)
      .then((response) => {
        return dispatch(ProductDetailActions.PRODUCT_FAMILY_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchProductFamily: ${handle}`, error);
        return dispatch(ProductDetailActions.PRODUCT_FAMILY_FETCH_ERROR(error));
      });
  };
}

export function fetchProductFamilyFishingReports(handle: string): RequestThunk {
  return (dispatch) => {
    dispatch(ProductDetailActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING());

    return apiV1
      .productFamilyFishingReportsFetch(handle)
      .then((response) => {
        return dispatch(
          ProductDetailActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchProductFamilyFishingReports: ${handle}`, error);
        return dispatch(ProductDetailActions.PRODUCT_FAMILY_FISHING_REPORTS_FETCH_ERROR(error));
      });
  };
}

export function fetchProductFishingReports(sku: string): RequestThunk {
  return (dispatch) => {
    dispatch(ProductDetailActions.PRODUCT_FISHING_REPORTS_FETCH_PENDING());

    return apiV1
      .productFishingReportsFetch(sku)
      .then((response) => {
        return dispatch(ProductDetailActions.PRODUCT_FISHING_REPORTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchProductFishingReports: ${sku}`, error);
        return dispatch(ProductDetailActions.PRODUCT_FISHING_REPORTS_FETCH_ERROR(error));
      });
  };
}
