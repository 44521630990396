import React, { useCallback, useState } from 'react';
import { DashboardUserReport } from './dashboard_user_report';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFishingReport, getUserFishingReports } from '../../redux/user';
import styles from './dashboard.less';
import { Alert, Modal } from 'antd';
import { OmniaButton } from '../omnia_button/omnia_button';

export const DashboardUserReports = () => {
  const userFishingReports = useSelector(getUserFishingReports);
  const dispatch = useDispatch();

  const [deleteReportModal, setDeleteReportModal] = useState({ open: false, reportId: null });
  const [numberOfReportsToShow, setNumberOfReportsToShow] = useState(5);
  const handleDeleteReport = useCallback(() => {
    dispatch(deleteFishingReport(deleteReportModal.reportId));
    setDeleteReportModal({ open: false, reportId: null });
  }, [deleteReportModal.reportId]);

  return (
    <>
      <div className={styles.fishingReportsListWrapper}>
        <ul className={styles.fishingReports}>
          {userFishingReports.slice(0, numberOfReportsToShow).map((report, i) => {
            return (
              <React.Fragment key={report.id}>
                <DashboardUserReport
                  fishingReport={report}
                  position={i}
                  onDeleteClick={() => {
                    setDeleteReportModal({ open: true, reportId: report.id });
                  }}
                />
              </React.Fragment>
            );
          })}
        </ul>
        {userFishingReports.length > numberOfReportsToShow && (
          <OmniaButton
            kind="tertiary"
            size="lg"
            onPress={() => setNumberOfReportsToShow((prevNumber) => prevNumber + 10)}
            className={styles.showMoreReportsButton}
          >
            Show More Fishing Reports
          </OmniaButton>
        )}
      </div>
      <Modal
        destroyOnClose
        onOk={handleDeleteReport}
        onCancel={() => {
          setDeleteReportModal({ open: false, reportId: null });
        }}
        open={deleteReportModal.open}
        className={styles.deleteReportModal}
        centered
        footer={
          <>
            <OmniaButton
              kind="tertiary"
              size="lg"
              onPress={() => {
                setDeleteReportModal({ open: false, reportId: null });
              }}
              style={{ marginRight: '12px' }}
            >
              Cancel
            </OmniaButton>
            <OmniaButton kind="primary" size="lg" onPress={handleDeleteReport}>
              Delete
            </OmniaButton>
          </>
        }
      >
        <Alert
          message={
            <>
              <p>Deleting your fishing report is irreversible! </p>
              <p>Are you sure?</p>
            </>
          }
          type="warning"
          showIcon
        />
      </Modal>
    </>
  );
};
