import { FishingReport, Product } from '@omniafishing/core';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { getWidth } from '../../redux/window';
import SvgFire from '../svg/fire';
import styles from './fishing_report_card_mini_products.less';

interface FishingReportCardMiniProductsProps {
  products: Product[];
  featured_product: Product;
  fishingReport: FishingReport;
  position: number;
  onProductClick?: () => void;
  lineItemAttribution?: LineItemCustomAttribute[];
}

export const FishingReportCardMiniProducts = (props: FishingReportCardMiniProductsProps) => {
  const {
    products,
    featured_product,
    fishingReport,
    position,
    onProductClick,
    lineItemAttribution = [],
  } = props;
  const pageWidth = useSelector(getWidth);
  const PAGE_PADDING = 60;
  const PRODUCT_CIRCLE_WIDTH = 38;

  const hotbaitExtraWidth = featured_product ? 16 : 0;
  const productCirclesDivWidth = pageWidth - PAGE_PADDING - hotbaitExtraWidth;
  const numberOfAllowedCircles = Math.floor(productCirclesDivWidth / PRODUCT_CIRCLE_WIDTH);

  // this array is reversed only because the product avatars
  // are stacked using flex-direction: row-reverse
  const truncatedProducts = products.slice(0, numberOfAllowedCircles - 1).reverse();
  const showRemainingProductCount = products.length > numberOfAllowedCircles - 1;

  const lineItemCustomAttributes: LineItemCustomAttribute[] = _.uniqBy(
    [
      ...lineItemAttribution,
      {
        key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
        value: fishingReport.id.toString(),
      },
    ],
    'key'
  );

  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemCustomAttributes)}`;
  const hasFeaturedProduct = fishingReport.featured_product != null;

  const handleProductClick = (product: Product, i: number, isFeatured: boolean) => {
    onProductClick?.();
    WebAnalytics.productClick({
      productOrProductFamily: product,
      show_modal: false,
      position: i,
    });
    WebAnalytics.fishingReportEmbeddedClick(
      isFeatured ? '[reports].(hotbait)' : '[reports].(products)',
      fishingReport,
      'embedded',
      position
    );
  };

  return (
    <div className={styles.productCircles}>
      {/* this is last circle that displays */}
      {showRemainingProductCount && (
        <span className={styles.remainingProductCount}>
          +{products.length - numberOfAllowedCircles + 1}
        </span>
      )}
      {truncatedProducts.map((product, i) => {
        const isFeatured = hasFeaturedProduct && i === 0;
        const isHotbait = featured_product?.id === product.id;
        const productUrl = OmniaUrls.product(
          product.shopify_product_handle,
          product.shopify_options
        );
        return isHotbait ? (
          <span className={styles.product__hotbait} key={product.id}>
            <SvgFire />
            <Link
              to={{
                pathname: productUrl,
                search: productUrlSearch,
              }}
              onClick={() => handleProductClick(product, i, isFeatured)}
            >
              <img src={product.img_url} alt={product.shopify_product_title} />
            </Link>
          </span>
        ) : (
          <span className={styles.product} key={product.id}>
            <Link
              to={{
                pathname: productUrl,
                search: productUrlSearch,
              }}
              onClick={() => handleProductClick(product, i, isFeatured)}
            >
              <img src={product.img_url} alt={product.shopify_product_title} />
            </Link>
          </span>
        );
      })}
    </div>
  );
};
