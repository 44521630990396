import * as React from 'react';

const SvgCrown = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.012.563 7.84 5.518l3.94-2.93-1.76 7.912H1.796L.262 2.62l3.9 2.9L6.012.562Zm-.006 2.874-1.373 3.68-2.895-2.154L2.62 9.5h6.596l1.003-4.506-2.855 2.124-1.359-3.681Z"
      fill="#231F20"
    />
  </svg>
);

export default SvgCrown;
