import { ProductVariantOption, SelectedOption, ShopifyProduct } from '@omniafishing/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo } from 'react';
import styles from './non_color_options_picker.less';
import { isUnavailable } from './product_variant_selector_helpers';

interface NonColorOptionsBase {
  onVariantChange: (optionName: string, value: string) => void;
  selectedOptions: SelectedOption[];
  shopifyProduct: ShopifyProduct;
}

interface NonColorOptionsPickerProps extends NonColorOptionsBase {
  nonColorOptions: ProductVariantOption[];
}

interface NonColorOptionValuesList extends NonColorOptionsBase {
  className?: string;
  option: ProductVariantOption;
  selectedOption: SelectedOption;
  showOptionWithList?: boolean;
}

export const NonColorOptionValuesList = (props: NonColorOptionValuesList) => {
  const {
    className,
    onVariantChange,
    option,
    selectedOption,
    selectedOptions,
    shopifyProduct,
    showOptionWithList,
  } = props;

  const optionValuesWithAvailableProp = useMemo(() => {
    return option.values.map((value) => {
      const available = !isUnavailable({
        shopifyProduct,
        selectedOptions,
        optionName: option.name,
        value,
      });
      return { value, available };
    });
  }, [shopifyProduct.id, selectedOptions.map((o) => o.value).join(','), option.values.join(',')]);

  return (
    <>
      {showOptionWithList && <span className={styles.optionNameWithList}>{option.name}:</span>}
      {optionValuesWithAvailableProp.map(({ value, available }) => {
        const isSelected = selectedOption.value === value;
        return (
          <label
            className={classNames(styles.textLabel, className, {
              [styles.optionUnavailable]: !available,
              [styles.optionSelected]: isSelected,
            })}
            key={value}
          >
            <span>{value}</span>
            <input
              type="radio"
              name={option.name}
              value={value}
              checked={isSelected}
              onChange={(e) => onVariantChange(option.name, e.target.value)}
              className={styles.optionRadio}
            />
          </label>
        );
      })}
    </>
  );
};

export const NonColorOptionsPicker = (props: NonColorOptionsPickerProps) => {
  const { nonColorOptions, selectedOptions, shopifyProduct, onVariantChange } = props;

  return (
    <>
      {nonColorOptions.map((option) => {
        const selectedOption = selectedOptions.find(
          (stateSelectedOption) => stateSelectedOption.name === option.name
        );
        return (
          <fieldset className={styles.fieldset} key={option.name}>
            <legend className={styles.optionLegend}>
              {_.capitalize(option.name)}:{' '}
              <span className={styles.optionName}>{selectedOption.value} </span>
            </legend>
            <NonColorOptionValuesList
              onVariantChange={onVariantChange}
              option={option}
              selectedOption={selectedOption}
              selectedOptions={selectedOptions}
              shopifyProduct={shopifyProduct}
            />
          </fieldset>
        );
      })}
    </>
  );
};
