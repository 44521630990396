import { MouseEvent, RefObject } from 'react';
import { useEventListener } from './use_event_listener';

export type Handler = (event: MouseEvent) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  desktopEvent: string = 'click',
  mobileEvent: string = 'touchend',
  ignore?: boolean
): void {
  useEventListener<MouseEvent>(desktopEvent, (event) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node) || ignore) {
      return;
    }

    handler(event);
  });
  useEventListener<MouseEvent>(
    mobileEvent,
    (event) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node) || ignore) {
        return;
      }

      handler(event);
    },
    null
  );
}
