import { FastForwardOutlined } from '@ant-design/icons';
import { OmniaVideoItemProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { productFamilyPriceRange } from '../../lib/product_families';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { formatSecondsToMinutes } from '../../lib/videos';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product_family.less';
import { OmniaButton } from '../omnia_button/omnia_button';

export const VideoItemSidebarProductFamily = (
  props: VideoItemProps & {
    omnia_video_item: OmniaVideoItemProductFamily;
    selected: boolean;
    showJump: boolean;
  }
) => {
  const { onClick, omnia_video_item, onJumpClick, selected, showJump } = props;
  const { item, start_sec, end_sec, comments } = omnia_video_item;
  const { title, img_url, products } = item;
  const isLive = end_sec == null;

  const priceRange = productFamilyPriceRange(item);
  const hasCompareAt = products.some((product) => product.on_sale);
  const highestCompareAt = products
    .map((p) => Number(p.compare_at_price))
    .filter(Boolean)
    .sort()[0];

  return (
    <div
      className={classNames(styles.productSidebar, {
        [styles.productSidebar__selected]: selected,
        [styles.productSidebar__live]: isLive,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.content__selected]: selected,
        })}
      >
        <div onClick={onClick} className={styles.productSidebarImg}>
          <img
            src={setShopifyImgWidth(img_url, 200)}
            alt={title}
            loading="lazy"
            width="200"
            height="200"
          />
        </div>

        <div className={styles.productSidebarText}>
          <p className={styles.productSidebarTitle} onClick={onClick}>
            {title}
          </p>
          {showJump && (
            <p className={styles.productSidebarJump} onClick={onJumpClick}>
              <FastForwardOutlined className={styles.productSidebarJumpIcon} />
              Jump to {formatSecondsToMinutes(start_sec)}
            </p>
          )}
        </div>

        <div className={styles.productSidebarButtonPrice}>
          <OmniaButton
            kind="secondary"
            size="md"
            block
            onClick={onClick}
            className={styles.productSidebarButton}
          >
            Quick View
          </OmniaButton>

          <p className={styles.productSidebarPrice}>
            <span
              className={classNames({
                [styles.price__sale]: hasCompareAt,
              })}
            >
              {priceRange}
            </span>
            {hasCompareAt && (
              <s className={styles.productSidebarPriceCompare}>{toDollars(highestCompareAt)}</s>
            )}
          </p>
        </div>
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
