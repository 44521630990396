import { Product, ProductFamily, RecommendedProductFamily } from '@omniafishing/core';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';
import { LineItemCustomAttribute } from './cart';

export const reducerName = 'productFamilyQuickModal';

export enum StateKeys {
  open = 'open',
  productFamily = 'productFamily',
  products = 'products',
  context = 'context',
  lineItemAttribution = 'lineItemAttribution',
  containsAllColors = 'containsAllColors',
  onCartAdd = 'onCartAdd',
  recommendationId = 'recommendationId',
  selectedProductSku = 'selectedProductSku',
  list_uuid = 'list_uuid',
}

export type QuickModalContexts = 'lake' | 'shopping';

export const initialState = {
  [StateKeys.open]: false,
  [StateKeys.productFamily]: null as ProductFamily | null,
  [StateKeys.products]: [] as Product[],
  [StateKeys.containsAllColors]: true,
  [StateKeys.context]: null as QuickModalContexts | null,
  [StateKeys.lineItemAttribution]: [] as LineItemCustomAttribute[],
  [StateKeys.onCartAdd]: null as () => void | null,
  [StateKeys.recommendationId]: null as string,
  [StateKeys.selectedProductSku]: null as string,
  [StateKeys.list_uuid]: null as string,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const isOpen = (state: ApplicationState) => state[reducerName][StateKeys.open];
export const getProductFamily = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamily];
export const getProducts = (state: ApplicationState) => state[reducerName][StateKeys.products];
export const getContext = (state: ApplicationState) => state[reducerName][StateKeys.context];
export const getLineItemAttribution = (state: ApplicationState) =>
  state[reducerName][StateKeys.lineItemAttribution];
export const getContainsAllColors = (state: ApplicationState) =>
  state[reducerName][StateKeys.containsAllColors];
export const getOnCartAdd = (state: ApplicationState) => state[reducerName][StateKeys.onCartAdd];
export const getRecommendationId = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendationId];
export const getSelectedProductSku = (state: ApplicationState) =>
  state[reducerName][StateKeys.selectedProductSku];
export const getListUuid = (state: ApplicationState) => state[reducerName][StateKeys.list_uuid];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function ProductFamilyQuickModalReducer(
  state = initialState,
  action: ProductFamilyQuickModalActions
) {
  switch (action.type) {
    case ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN:
      return {
        ...state,
        [StateKeys.open]: true,
        [StateKeys.productFamily]: action.payload.recommendedFamily.product_family,
        [StateKeys.products]: action.payload.recommendedFamily.products,
        [StateKeys.containsAllColors]: action.payload.recommendedFamily.contains_all_colors,
        [StateKeys.context]: action.payload.context,
        [StateKeys.lineItemAttribution]: action.payload.lineItemAttribution,
        [StateKeys.onCartAdd]: action.payload.onCartAdd || null,
        [StateKeys.recommendationId]: action.payload.recommendation_id,
        [StateKeys.selectedProductSku]: action.payload.selectedProductSku,
        [StateKeys.list_uuid]: action.payload.list_uuid,
      };

    case ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_CLOSE:
      return {
        ...state,
        [StateKeys.open]: false,
        [StateKeys.recommendationId]: null,
        [StateKeys.list_uuid]: null,
      };

    case ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_CLEAR:
      return initialState;

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

interface ProductFamilyQuickModalOpenArgs {
  recommendedFamily: RecommendedProductFamily;
  context: QuickModalContexts;
  lineItemAttribution?: LineItemCustomAttribute[];
  onCartAdd?: () => void;
  recommendation_id?: string;
  selectedProductSku?: string;
  list_uuid?: string;
}

export const ProductFamilyQuickModalActions = {
  PRODUCT_FAMILY_QUICK_MODAL_OPEN: ({
    recommendedFamily,
    context,
    lineItemAttribution = [],
    onCartAdd,
    recommendation_id = null,
    selectedProductSku,
    list_uuid,
  }: ProductFamilyQuickModalOpenArgs) =>
    createAction(ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN, {
      recommendedFamily,
      context,
      lineItemAttribution,
      onCartAdd,
      recommendation_id,
      selectedProductSku,
      list_uuid,
    }),
  PRODUCT_FAMILY_QUICK_MODAL_CLOSE: () =>
    createAction(ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_CLOSE),
  PRODUCT_FAMILY_QUICK_MODAL_CLEAR: () =>
    createAction(ReduxActions.PRODUCT_FAMILY_QUICK_MODAL_CLEAR),
};
export type ProductFamilyQuickModalActions = ActionsUnion<typeof ProductFamilyQuickModalActions>;
