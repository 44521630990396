import * as React from 'react';

const SvgFallIcon = (props: React.SVGAttributes<SVGElement>) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.556 8.917c0-2.613-4.537-7.876-5.054-8.467a.688.688 0 0 0-1.004 0c-.517.591-5.054 5.854-5.054 8.467 0 2.666 2.256 4.854 5.112 5.062v1.716c0 .061.05.111.11.111h.667c.062 0 .111-.05.111-.11v-1.717c2.856-.208 5.112-2.396 5.112-5.062Zm-5.112 3.728v-1.374l2.226-1.285a.11.11 0 0 0 .04-.152l-.333-.577a.111.111 0 0 0-.152-.041l-1.78 1.028V8l1.946-1.124a.11.11 0 0 0 .04-.152l-.333-.577a.111.111 0 0 0-.152-.041l-1.502.867V4.333a.111.111 0 0 0-.11-.11h-.667a.111.111 0 0 0-.111.11v2.64l-1.502-.868a.111.111 0 0 0-.152.04l-.334.578a.11.11 0 0 0 .041.152l1.947 1.124v2.245L5.775 9.216a.111.111 0 0 0-.152.04l-.334.578a.111.111 0 0 0 .04.152l2.227 1.285v1.375c-2.12-.198-3.778-1.794-3.778-3.728C3.778 7.464 6.19 4.084 8 1.916c1.81 2.168 4.222 5.548 4.222 7.002 0 1.934-1.657 3.53-3.778 3.727Z"
        fill={fill || '#657D7D'}
      />
    </svg>
  );
};

export default SvgFallIcon;
