import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isPending } from '../../constants/loading_state';
import { useProPurchaseUrl } from '../../hooks/use_pro_purchase_url';
import { useQueryString } from '../../hooks/use_query_string';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { useUser } from '../../hooks/use_user';
import { proPaymentLinkUrls, proTrialPaymentLinkUrls } from '../../lib/stripe';
import { getError, getLoginLoadingState, hasAccessToken, loginUser } from '../../redux/auth';
import { RoutePaths } from '../../routes';
import utilities from '../../styles/utilities.less';
import AuthFormError from '../auth_form_wrapper/auth_form_error';
import AuthFormWrapper from '../auth_form_wrapper/auth_form_wrapper';
import authStyles from '../auth_form_wrapper/auth_form_wrapper.less';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';

interface LoginFormProps {
  onSignupClick?: () => void;
  cardClassName?: string;
  forwardRoute?: string;
  currentPath?: string;
  renderForm?: boolean;
}

export const LoginForm = (props: LoginFormProps) => {
  const { onSignupClick, cardClassName, forwardRoute, renderForm = true, currentPath } = props;
  const isLoggedIn = useSelector(hasAccessToken);
  const { isPro } = useUser();
  const error = useSelector(getError);
  const loadingState = useSelector(getLoginLoadingState);
  const isSubmitting = isPending(loadingState);
  const location = useLocation<{ username?: string; from?: string }>();
  const username = location.state?.username ?? null;
  const fromPath = currentPath || location.state?.from || RoutePaths.DASHBOARD;
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { anonymousId } = useRudderstack();
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    email: string;
  }>();
  const userEmail = currentQuery.email;
  const { proPurchaseUrl } = useProPurchaseUrl({
    trial: proTrialPaymentLinkUrls.includes(forwardRoute),
    purchaseUrl: forwardRoute,
    trialPurchaseUrl: forwardRoute,
  });

  const formFields = {
    username: 'username',
    password: 'password',
  };

  const handleSubmit = (values: any) => {
    dispatch(loginUser(values[formFields.username], values[formFields.password], anonymousId));
  };

  let message: ReactNode;
  if (error === 'NOT_AUTHORIZED') {
    message = <>Username or Password invalid. Please try again.</>;
  }
  if (error === 'USER_NOT_CONFIRMED') {
    message = <>Check your email for a confirmation code and a link to confirm your account.</>;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    if (!forwardRoute) {
      history.push({
        pathname: fromPath,
        state: { from: fromPath },
      });
    } else if (proPaymentLinkUrls.includes(forwardRoute)) {
      // this should be changed to a callback, not a hardcoded URL, but that may not work with the app remounting
      !isPro
        ? (window.location.href = proPurchaseUrl)
        : history.push({
            pathname: fromPath,
            state: { from: fromPath },
          });
    }
  }, [isLoggedIn, isPro, forwardRoute, fromPath]);

  if (!renderForm) {
    return null;
  }

  return (
    <AuthFormWrapper className={authStyles.cardContainer} cardClassName={cardClassName}>
      <AuthFormError errorMessage={message} />

      <h2 className={authStyles.headingText}>
        Log In to <br /> Omnia Fishing
      </h2>

      <Form
        layout="vertical"
        onFinish={handleSubmit}
        className={authStyles.form}
        initialValues={{
          [formFields.username]: username ? username : userEmail,
        }}
        form={form}
      >
        <Form.Item
          name={formFields.username}
          rules={[{ required: true, message: 'Please input your email' }]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            type="email"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name={formFields.password}
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>

        <div className={classNames(utilities.marginBottom__gutter, utilities.textRight)}>
          <Link to={RoutePaths.PASSWORD_RESET}>Forgot password</Link>
        </div>

        <OmniaButton
          kind="secondary"
          type="submit"
          size="lg"
          block
          style={{ marginBottom: 12 }}
          isDisabled={isSubmitting}
        >
          Log in {isSubmitting ? <LoadingOutlined /> : ''}
        </OmniaButton>

        <div className={utilities.textCenter} style={{ display: 'block', marginBottom: 12 }}>
          OR{' '}
        </div>
        {onSignupClick ? (
          <OmniaButton onPress={onSignupClick} block fontSize={14} size="lg" kind="tertiary">
            Create a New Account
          </OmniaButton>
        ) : (
          <OmniaLinkButton
            to={{
              pathname: RoutePaths.SIGNUP,
              state: { from: fromPath },
            }}
            block
            size="lg"
            kind="tertiary"
          >
            Create a New Account
          </OmniaLinkButton>
        )}
      </Form>
    </AuthFormWrapper>
  );
};
