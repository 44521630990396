import { Breadcrumb } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBaseUrl } from '../../redux/router';
import styles from './omnia_breadcrumb.less';

export interface OmniaBreadcrumbItem {
  name: string;
  url: string;
}

interface OmniaBreadcrumbProps {
  items: OmniaBreadcrumbItem[];
  style?: CSSProperties;
  className?: string;
  onLinkClick?: () => void;
}

export const OmniaBreadcrumb = (props: OmniaBreadcrumbProps) => {
  const { style, className, items, onLinkClick } = props;
  const baseUrl = useSelector(getBaseUrl);

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => {
      const seoObejct: any = {
        '@type': 'ListItem',
        position: index + 1,
        name: item.name,
      };
      if (item.url) {
        seoObejct.item = `${baseUrl}${item.url}`;
      }
      return seoObejct;
    }),
  };

  return (
    <>
      <Breadcrumb style={style} className={classNames(styles.breadcrumb, className)}>
        {items.map((item) => (
          <Breadcrumb.Item key={item.name + item.url}>
            {item.url ? (
              <Link
                to={item.url}
                onClick={() => {
                  onLinkClick?.();
                }}
              >
                {item.name}
              </Link>
            ) : (
              item.name
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    </>
  );
};
