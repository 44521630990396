import {
  AmbassadorMini,
  FishingReport,
  FishingReportsFetchParams,
  FishingReportsFetchResponse,
  OmniaResponse,
  SeasonGroup,
  Species,
  Waterbody,
} from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'fishingReports';

export enum StateKeys {
  fishingReport = 'fishingReport',
  fishingReports = 'fishingReports',
  users = 'users',
  facets = 'facets',
  totalCount = 'totalCount',
  loadingState = 'loadingState',
}

export const initialState = {
  [StateKeys.fishingReport]: null as FishingReport,
  [StateKeys.fishingReports]: [] as FishingReport[],
  [StateKeys.users]: [] as AmbassadorMini[],
  [StateKeys.facets]: {
    species: [] as Species[],
    waterbodies: [] as Waterbody[],
    season_groups: [] as SeasonGroup[],
  },
  [StateKeys.totalCount]: null as number,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //
export const getFishingReport = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReport];
export const getFishingReports = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReports];
export const getUsers = (state: ApplicationState) => state[reducerName][StateKeys.users];
export const getFacets = (state: ApplicationState) => state[reducerName][StateKeys.facets];
export const getTotalCount = (state: ApplicationState) => state[reducerName][StateKeys.totalCount];
export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function FishingReportsReducer(state = initialState, action: FishingReportsActions) {
  switch (action.type) {
    case ReduxActions.FISHING_REPORTS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.fishingReports]: initialState[StateKeys.fishingReports],
        [StateKeys.facets]: initialState[StateKeys.facets],
      };
    }

    case ReduxActions.FISHING_REPORTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.fishingReports]: action.payload.data,
        [StateKeys.facets]: action.payload.metadata.facets,
        [StateKeys.totalCount]: action.payload.metadata.total_count,
      };
    }

    case ReduxActions.FISHING_REPORT_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.fishingReport]: null,
      };
    }

    case ReduxActions.FISHING_REPORT_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.fishingReport]: action.payload.data,
      };
    }

    case ReduxActions.FISHING_REPORTS_USERS_FETCH_SUCCESS: {
      return {
        ...state,

        [StateKeys.users]: action.payload.data,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const FishingReportsActions = {
  FISHING_REPORTS_FETCH_PENDING: () => createAction(ReduxActions.FISHING_REPORTS_FETCH_PENDING),
  FISHING_REPORTS_FETCH_SUCCESS: (response: FishingReportsFetchResponse) =>
    createAction(ReduxActions.FISHING_REPORTS_FETCH_SUCCESS, response),
  FISHING_REPORTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.FISHING_REPORTS_FETCH_ERROR, err),

  FISHING_REPORT_FETCH_PENDING: () => createAction(ReduxActions.FISHING_REPORT_FETCH_PENDING),
  FISHING_REPORT_FETCH_SUCCESS: (response: OmniaResponse<FishingReport>) =>
    createAction(ReduxActions.FISHING_REPORT_FETCH_SUCCESS, response),
  FISHING_REPORT_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.FISHING_REPORT_FETCH_ERROR, err),

  FISHING_REPORTS_USERS_FETCH_PENDING: () =>
    createAction(ReduxActions.FISHING_REPORTS_USERS_FETCH_PENDING),
  FISHING_REPORTS_USERS_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorMini[]>) =>
    createAction(ReduxActions.FISHING_REPORTS_USERS_FETCH_SUCCESS, response),
  FISHING_REPORTS_USERS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.FISHING_REPORTS_USERS_FETCH_ERROR, err),
};
export type FishingReportsActions = ActionsUnion<typeof FishingReportsActions>;

export function fetchFishingReport(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(FishingReportsActions.FISHING_REPORT_FETCH_PENDING());

    return apiV1
      .fishingReportFetch(slug)
      .then((response) => {
        return dispatch(FishingReportsActions.FISHING_REPORT_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchFishingReport', error);
        return dispatch(FishingReportsActions.FISHING_REPORT_FETCH_ERROR(error));
      });
  };
}

export function fetchFishingReports(params: FishingReportsFetchParams = {}): RequestThunk {
  return (dispatch) => {
    dispatch(FishingReportsActions.FISHING_REPORTS_FETCH_PENDING());

    return apiV1
      .fishingReportsFetch(params)
      .then((response) => {
        return dispatch(FishingReportsActions.FISHING_REPORTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchFishingReports', error);
        return dispatch(FishingReportsActions.FISHING_REPORTS_FETCH_ERROR(error));
      });
  };
}

export function fetchFishingReportUsers(): RequestThunk {
  return (dispatch) => {
    dispatch(FishingReportsActions.FISHING_REPORTS_USERS_FETCH_PENDING());

    return apiV1
      .fishingReportsUsersFetch()
      .then((response) => {
        return dispatch(FishingReportsActions.FISHING_REPORTS_USERS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchFishingReportUsers', error);
        return dispatch(FishingReportsActions.FISHING_REPORTS_USERS_FETCH_ERROR(error));
      });
  };
}
