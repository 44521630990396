import { Modal } from 'antd';
import React from 'react';
import { MapDefault, MapDefaultProps } from '../map_default/map_default';
import styles from './map_modal.less';

interface MapModalProps extends MapDefaultProps {
  centered: boolean;
  open: boolean;
  onCancel: () => void;
  width?: string;
}

export const MapModal = (props: MapModalProps) => {
  const { centered, open, onCancel, width, className, ...mapProps } = props;
  return (
    <Modal
      centered={centered}
      footer={null}
      className={styles.miniMapModal}
      open={open}
      onCancel={onCancel}
      width={width}
      destroyOnClose
    >
      <div className={styles.mapModalWrapper}>
        <MapDefault geoCoderCentered={centered} className={styles.map} {...mapProps} />
      </div>
    </Modal>
  );
};
