import { Page } from '@omniafishing/core';

interface MediaItem {
  src: string;
  srcWide: string;
  linkUrl: string;
}
/* 
    this is a stretch of what this model was intended for. 
    the design relies on both image sizes and url's, hence the checks for them.
  */
export const parseMediaItemsFromPage = (page: Page): MediaItem[] => {
  const items: MediaItem[] = [];

  if (!page) {
    return items;
  }

  const {
    hero_img,
    hero_img_wide,
    hero_url,
    hero_2_img,
    hero_2_img_wide,
    hero_2_url,
    hero_3_img,
    hero_3_img_wide,
    hero_3_url,
    subhero_1_img,
    subhero_1_img_wide,
    subhero_1_url,
    subhero_2_img,
    subhero_2_img_wide,
    subhero_2_url,
    subhero_3_img,
    subhero_3_img_wide,
    subhero_3_url,
    subhero_4_img,
    subhero_4_img_wide,
    subhero_4_url,
    subhero_5_img,
    subhero_5_img_wide,
    subhero_5_url,
    subhero_6_img,
    subhero_6_img_wide,
    subhero_6_url,
  } = page;

  if (hero_img && hero_img_wide && hero_url) {
    items.push({ src: hero_img, srcWide: hero_img_wide, linkUrl: hero_url });
  }
  if (hero_2_img && hero_2_img_wide && hero_2_url) {
    items.push({ src: hero_2_img, srcWide: hero_2_img_wide, linkUrl: hero_2_url });
  }
  if (hero_3_img && hero_3_img_wide && hero_3_url) {
    items.push({ src: hero_3_img, srcWide: hero_3_img_wide, linkUrl: hero_3_url });
  }
  if (subhero_1_img && subhero_1_img_wide && subhero_1_url) {
    items.push({ src: subhero_1_img, srcWide: subhero_1_img_wide, linkUrl: subhero_1_url });
  }
  if (subhero_2_img && subhero_2_img_wide && subhero_2_url) {
    items.push({ src: subhero_2_img, srcWide: subhero_2_img_wide, linkUrl: subhero_2_url });
  }
  if (subhero_3_img && subhero_3_img_wide && subhero_3_url) {
    items.push({ src: subhero_3_img, srcWide: subhero_3_img_wide, linkUrl: subhero_3_url });
  }
  if (subhero_4_img && subhero_4_img_wide && subhero_4_url) {
    items.push({ src: subhero_4_img, srcWide: subhero_4_img_wide, linkUrl: subhero_4_url });
  }
  if (subhero_5_img && subhero_5_img_wide && subhero_5_url) {
    items.push({ src: subhero_5_img, srcWide: subhero_5_img_wide, linkUrl: subhero_5_url });
  }
  if (subhero_6_img && subhero_6_img_wide && subhero_6_url) {
    items.push({ src: subhero_6_img, srcWide: subhero_6_img_wide, linkUrl: subhero_6_url });
  }

  return items;
};
