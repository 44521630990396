export enum StorageKeys {
  dashboardVideoShow = 'omnia_dashboardVideoShow',
  videoLiveAppPromoClosed = 'omnia_videoLiveAppPromoClosed',
  userPreferencesSpecies = 'omnia_userPreferences_species',
  userPreferencesBrands = 'omnia_userPreferences_brands',
  userPreferencesTechniques = 'omnia_userPreferences_styles',
  userPreferencesSeasonGroup = 'omnia_userPreferences_seasonGroup',
  userPreferencesWaterbodies = 'omnia_userPreferences_waterbodies',
  getStartedNewsletterClosed = 'omnia_get_started_newsletter_closed',
  cartUpsellProductsClosed = 'omnia_cartUpsellProductsClosed',
  waterbodySubscribeModalClosed = 'omnia_waterbody_subscribe_modal_closed',
  waterTempEolModalClosed = 'omnia_water_tem_eol_modal_closed',
  isFirstTimeHomePageVisit = 'omnia_is_first_time_home_page_visit',
}
