import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasAccessToken } from '../../redux/auth';
import { CookieKeys, getCookieConfig } from '../../redux/cookie_storage_middleware';
import { getPath } from '../../redux/router';
import { getIsWebview } from '../../redux/window';
import { RoutePaths } from '../../routes';
import { NewsletterForm } from '../newsletter_form/newsletter_form';
import styles from './newsletter_popup.less';

const pathMatch = (pathname: string) => {
  return (
    pathname &&
    (pathname.startsWith(RoutePaths.BRANDS) ||
      pathname.startsWith(RoutePaths.TECHNIQUES) ||
      pathname.startsWith(RoutePaths.SPECIES) ||
      pathname.startsWith(RoutePaths.CATEGORIES) ||
      pathname.startsWith(RoutePaths.PRODUCTS) ||
      pathname.startsWith(RoutePaths.ARTICLES) ||
      pathname.startsWith(RoutePaths.NEW) ||
      pathname.startsWith(RoutePaths.BEST_SELLERS) ||
      pathname.startsWith(RoutePaths.DEALS) ||
      pathname.startsWith(RoutePaths.GIFT_GUIDE)) &&
    pathname !== '/p/simms-prodry-fishering-jacket' &&
    !pathname.includes(RoutePaths.GET_STARTED) &&
    !pathname.includes(RoutePaths.BEST_OF_YEAR) &&
    !pathname.includes(RoutePaths.PREMIUM_PRO) &&
    !pathname.startsWith(RoutePaths.APP) &&
    !pathname.startsWith(RoutePaths.MAP) &&
    !pathname.startsWith(RoutePaths.WATERS)
  );
};

export const NewsletterPopup = () => {
  const pathname = useSelector(getPath);
  const [complete, setComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const userClosed = Cookies.get(CookieKeys.newsletterClosed) === 'true';
  const pathRef = useRef('');
  const isLoggedIn = useSelector(hasAccessToken);
  const isWebview = useSelector(getIsWebview);

  useEffect(() => {
    pathRef.current = pathname;
    if (pathMatch(pathname)) {
      setTimeout(() => {
        if (pathname === pathRef.current && !isLoggedIn && !userClosed) {
          setOpen(true);
        }
      }, 5000);
    }
  }, [pathname]);

  if (isWebview) {
    return null;
  }

  const handleSubmit = () => {
    setComplete(true);
  };

  return (
    <div
      className={classNames(styles.popup, {
        [styles.popup__visible]: open,
      })}
    >
      <header className={styles.header}>
        <p className={styles.heading}>{complete ? 'THANK YOU' : 'WANT 10% OFF?'}</p>
        <CloseOutlined
          className={styles.close}
          onClick={() => {
            setOpen(false);
            Cookies.set(CookieKeys.newsletterClosed, 'true', getCookieConfig());
          }}
        />
      </header>

      <section className={styles.body}>
        {complete ? (
          <>
            <p>
              Your 10% Off code is: <span className={styles.code}>HELLO10</span>
            </p>
            <p className={styles.instructions}>
              To use, enter the discount code at checkout. Keep an eye on your inbox for more info,
              news, and offers from Omnia.
            </p>
          </>
        ) : (
          <>
            <p>Enter your email address to get 10% off your first order</p>
            {!userClosed && <NewsletterForm context="newsletter_popup" onSuccess={handleSubmit} />}
          </>
        )}
      </section>
    </div>
  );
};
