import * as React from 'react';

const SvgLakes = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="nonzero" stroke="currentColor">
      <path d="M12.25 14.9c-1.09 0-1.568-.626-2.046-1.112-.477-.556-.886-.973-1.704-.973s-1.227.417-1.705.973c-.477.486-1.022 1.112-2.045 1.112-1.09 0-1.568-.626-2.045-1.112-.478-.556-.887-.973-1.705-.973v-.556c1.023 0 1.568.625 2.045 1.112.478.486.887.973 1.705.973.818 0 1.227-.417 1.705-.973.477-.556 1.022-1.112 2.045-1.112 1.09 0 1.568.625 2.046 1.112.477.486.886.973 1.704.973s1.227-.417 1.704-.973c.478-.556 1.023-1.112 2.046-1.112v.556c-.818 0-1.227.417-1.704.973-.41.486-.955 1.112-2.046 1.112Zm0 4.1c-1.09 0-1.568-.625-2.046-1.112-.477-.487-.886-.973-1.704-.973s-1.227.417-1.705.973C6.318 18.444 5.773 19 4.75 19c-1.09 0-1.636-.625-2.114-1.112-.477-.487-.818-.973-1.636-.973v-.556c1.023 0 1.568.626 2.045 1.112.478.486.887.973 1.705.973.818 0 1.227-.417 1.705-.973.477-.556 1.022-1.112 2.045-1.112 1.09 0 1.568.626 2.046 1.112.477.486.886.973 1.704.973s1.227-.417 1.636-.973c.478-.556 1.023-1.112 2.046-1.112v.556c-.818 0-1.228.417-1.705.973-.34.486-.886 1.112-1.977 1.112Z" />
      <path d="m8.432 12.537-.205-.278c-.136-.209-4.022-5.421-4.022-7.576 0-.07.613-3.683 4.159-3.683h.068c3.545 0 4.159 3.614 4.159 3.614 0 .139.273 1.946-3.955 7.575l-.204.348ZM4.75 4.683c.068 1.53 2.523 5.282 3.682 6.88 3.818-5.212 3.614-6.88 3.614-6.88 0-.139-.478-3.197-3.614-3.197h-.068c-3 .07-3.546 2.92-3.614 3.197Z" />
    </g>
  </svg>
);

export default SvgLakes;
