import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import errorPlaceholderImage from '../../assets/placeholder.svg';

interface ImageLoaderProps {
  alt?: string;
  className?: string;
  isImageError: boolean;
  isImageLoading: boolean;
  lazyLoad?: boolean;
  loadingClassName?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => void;
  onError: () => void;
  onLoad: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  src: string;
  styles?: { [key: string]: string };
}

const ImageLoader = (props: ImageLoaderProps) => {
  const {
    alt,
    className,
    isImageError,
    isImageLoading,
    lazyLoad,
    loadingClassName,
    onClick,
    onError,
    onLoad,
    src,
  } = props;

  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.currentTarget.src !== errorPlaceholderImage) {
      onLoad(e);
    }
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.currentTarget.src !== errorPlaceholderImage) {
      onError();
    }
  };

  return (
    <>
      {isImageLoading && !isImageError && (
        <div className={loadingClassName}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      )}
      <img
        className={className}
        src={isImageError ? errorPlaceholderImage : src}
        onClick={onClick}
        onLoad={handleLoad}
        onError={handleError}
        alt={alt}
        loading={lazyLoad ? 'lazy' : 'eager'}
      />
    </>
  );
};

export default ImageLoader;
