import { FishingReport, Structure } from '@omniafishing/core';
import { Button, Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fishingReportContainsSeasonGroup,
  fishingReportContainsSku,
  fishingReportContainsSpecieName,
  fishingReportContainsStructure,
} from '../../../lib/fishing_reports';
import { getSeasonGroups, getStructures } from '../../../redux/reference_data';
import styles from './all_filters_drawer.less';

interface StructureCheckboxesProps {
  drawerStructures: CheckboxValueType[];
  fishingReports: FishingReport[];
  onChange: (techniques: CheckboxValueType[]) => void;
  drawerProductSku: string;
  drawerSeasonGroupName: string;
  drawerSpeciesName: string;
}
export const StructureCheckboxes = (props: StructureCheckboxesProps) => {
  const allStructures = useSelector(getStructures);
  const seasonGroups = useSelector(getSeasonGroups);
  const [showMore, setShowMore] = useState(false);

  const {
    drawerStructures,
    fishingReports,
    onChange,
    drawerProductSku,
    drawerSeasonGroupName,
    drawerSpeciesName,
  } = props;

  const structuresPresentInFishingReports = allStructures.filter((structure) => {
    const reportCount = fishingReports.filter((report) => {
      return fishingReportContainsStructure(report, structure.name);
    }).length;
    if (reportCount > 0) {
      return structure;
    }
  });

  const seasonGroupOfSeasonGroupName = seasonGroups.find(
    (season) => season.name === drawerSeasonGroupName
  );

  const countsOfStructuresMatchesFilteredByProductSeasonSpecies: number[] =
    structuresPresentInFishingReports.map((structure) => {
      if (drawerProductSku || drawerSeasonGroupName || drawerSpeciesName) {
        return fishingReports.filter((report) => {
          const reportContainsStructure = fishingReportContainsStructure(report, structure.name);
          const reportContainsProductSku =
            drawerProductSku && fishingReportContainsSku(report, drawerProductSku);
          const reportContainsSeasonGroup =
            drawerSeasonGroupName &&
            fishingReportContainsSeasonGroup(report, seasonGroupOfSeasonGroupName);
          const reportContainsSpeciesName = fishingReportContainsSpecieName(
            report,
            drawerSpeciesName
          );

          if (!!drawerSeasonGroupName && !!drawerProductSku && !!drawerSpeciesName) {
            return (
              reportContainsStructure &&
              reportContainsSeasonGroup &&
              reportContainsSpeciesName &&
              reportContainsProductSku
            );
          } else if (!!drawerProductSku && !!drawerSeasonGroupName) {
            return reportContainsStructure && reportContainsProductSku && reportContainsSeasonGroup;
          } else if (!!drawerProductSku && !!drawerSpeciesName) {
            return reportContainsStructure && reportContainsProductSku && reportContainsSpeciesName;
          } else if (!!drawerSeasonGroupName && !!drawerSpeciesName) {
            return (
              reportContainsStructure && reportContainsSeasonGroup && reportContainsSpeciesName
            );
          } else if (!!drawerProductSku) {
            return reportContainsStructure && reportContainsProductSku;
          } else if (!!drawerSeasonGroupName) {
            return reportContainsStructure && reportContainsSeasonGroup;
          } else if (!!drawerSpeciesName) {
            return reportContainsStructure && reportContainsSpeciesName;
          }
        }).length;
      }

      return fishingReports.filter((report) => {
        return fishingReportContainsStructure(report, structure.name);
      }).length;
    });

  const structuresList = (structures: Structure[]) => {
    return structures.map((structure, i) => {
      return (
        <Checkbox
          value={structure.name}
          className={styles.checkboxLabel}
          key={structure.name}
          disabled={countsOfStructuresMatchesFilteredByProductSeasonSpecies[i] === 0}
        >
          <span>{structure.display_name}</span>
          <span className={styles.checkboxLabelReportCount}>
            {countsOfStructuresMatchesFilteredByProductSeasonSpecies[i]}
          </span>
        </Checkbox>
      );
    });
  };

  return (
    <div className={styles.checkboxesContainer}>
      <Checkbox.Group
        onChange={(structureNames) => {
          onChange(structureNames);
        }}
        value={drawerStructures}
        className={styles.checkboxGroup}
      >
        {showMore
          ? structuresList(structuresPresentInFishingReports)
          : structuresList(structuresPresentInFishingReports.slice(0, 9))}
      </Checkbox.Group>
      {structuresPresentInFishingReports.length > 9 ? (
        <Button
          type="link"
          size="small"
          className={styles.showMoreLinkButton}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show All'}
        </Button>
      ) : null}
    </div>
  );
};
