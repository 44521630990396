import * as React from 'react';

const SvgWinterIcon = (props: React.SVGAttributes<SVGElement>) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#winter-icon_svg__a)">
        <path
          d="m15.55 6.442-.786-.786a.111.111 0 0 0-.158 0l-1.66 1.66H9.608l4.1-4.1a.111.111 0 0 0 0-.157l-.784-.785a.111.111 0 0 0-.158 0l-4.1 4.1V3.107L10.36 1.41a.111.111 0 0 0 0-.157L9.576.468a.111.111 0 0 0-.158 0L8 1.888 6.581.467a.111.111 0 0 0-.157 0l-.786.786a.111.111 0 0 0 0 .158l1.695 1.694v3.269l-4.1-4.101a.111.111 0 0 0-.158 0l-.785.786a.111.111 0 0 0 0 .157l4.1 4.1H3.054l-1.66-1.66a.111.111 0 0 0-.158 0l-.786.786a.111.111 0 0 0 0 .157L1.87 8.019.45 9.437a.111.111 0 0 0 0 .158l.786.785a.111.111 0 0 0 .157 0l1.73-1.728H6.39l-4.1 4.1a.111.111 0 0 0 0 .158l.785.786a.111.111 0 0 0 .157 0l4.101-4.1v3.335L5.64 14.626a.111.111 0 0 0 0 .157l.785.786a.111.111 0 0 0 .158 0L8 14.149l1.419 1.418a.111.111 0 0 0 .157 0l.786-.786a.11.11 0 0 0 0-.157L8.667 12.93V9.594l4.1 4.1a.11.11 0 0 0 .158 0l.786-.785a.111.111 0 0 0 0-.158l-4.101-4.1h3.269l1.729 1.728a.111.111 0 0 0 .157 0l.786-.786a.111.111 0 0 0 0-.157l-1.419-1.419L15.551 6.6a.112.112 0 0 0-.002-.157Z"
          fill={fill || '#657D7D'}
        />
      </g>
      <defs>
        <clipPath id="winter-icon_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgWinterIcon;
