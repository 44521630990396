import { Product } from '@omniafishing/core';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AlgoliaEvents } from '../lib/algolia_events';
import { FacebookEvents } from '../lib/facebook_events';
import { GoogleEvents } from '../lib/google_events';
import { AddToCartParams, WebAnalytics, WebAnalyticsEventAreas } from '../lib/web_analytics';
import {
  AddItemNotifications,
  addLineItems,
  getActiveDiscountCode,
  getCheckoutId,
  getLineItems,
  getSubtotalPrice,
  LineItemCustomAttribute,
  LineItemToAdd,
} from '../redux/cart';
import { getUser } from '../redux/user';
import { LocationTypes } from '../routes';

export type WebAnalyticsListViewedType = 'carousel' | 'store' | 'video' | 'search' | 'other';

export type ProductWithListUuid = Product & {
  list_uuid?: string;
};

export const useCart = () => {
  const activeDiscountCode = useSelector(getActiveDiscountCode);
  const checkoutId = useSelector(getCheckoutId);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const lineItems = useSelector(getLineItems);
  const subtotalPrice = useSelector(getSubtotalPrice);

  const addToCart = (params: {
    productsToAdd: {
      product: ProductWithListUuid;
      quantity: number;
    }[];
    position: number;
    addToCartArea?: WebAnalyticsEventAreas;
    customAttributes?: LineItemCustomAttribute[];
    locationType?: LocationTypes;
    notification?: AddItemNotifications;
    message?: string;
    list_uuid?: string;
  }) => {
    const {
      productsToAdd,
      addToCartArea,
      customAttributes = [],
      locationType,
      notification,
      message,
      position,
      list_uuid,
    } = params;

    // TODO: maybe add the pathname based getLineItemCustomAttributes(location.pathname) here
    // so all consumers don't need to do it

    const uniqCustomAttributes = _.uniqBy(customAttributes, 'key');
    const allProducts = productsToAdd.map(({ product }) => product);
    const lineItemsToAdd: LineItemToAdd[] = productsToAdd.map(({ product, quantity }) => ({
      variantId: product.shopify_graphql_id,
      quantity,
      customAttributes: uniqCustomAttributes,
    }));
    dispatch(addLineItems(checkoutId, lineItemsToAdd, notification, message));

    FacebookEvents.AddToCart(allProducts.map((p) => p.shopify_variant_id));
    GoogleEvents.AddToCart(allProducts);
    AlgoliaEvents.AddToCart(allProducts, user?.id?.toString());
    allProducts.forEach((product) => {
      const addToCartParams: AddToCartParams = {
        product,
        area: addToCartArea || WebAnalyticsEventAreas.MAIN_VIEW,
        locationType,
        position,
      };
      // different products can have different list uuids, so they are stored on the product
      if (product.list_uuid) {
        addToCartParams.list_uuid = product.list_uuid;
      } else if (list_uuid) {
        addToCartParams.list_uuid = list_uuid;
      }
      WebAnalytics.addToCart(addToCartParams);
    });
  };

  return {
    activeDiscountCode,
    addToCart,
    checkoutId,
    lineItems,
    subtotalPrice,
  };
};
