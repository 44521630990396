import { Waterbody } from '@omniafishing/core';
import _ from 'lodash';
import { WebAnalytics } from '../../lib/web_analytics';
import { BASE_LAYER, LAYER_TYPES, MapLayerState } from './map_types';
import { MapBounds } from './omnia_map';
import { SOURCES } from './omnia_map_sources';

export const determineWaterbodyActivity = (waterbodies: Waterbody[]) => {
  const waterbodiesSorted = _.sortBy(waterbodies, ['fishing_reports_count', 'favorites_count']);
  const waterbodiesWithFishingReports = waterbodiesSorted.filter(
    (w) => w.fishing_reports_count > 0
  );
  const lowActivityIndexCeiling = Math.round(waterbodiesWithFishingReports.length * 0.6) - 1;
  const highActivityWaterbodyIds = waterbodiesWithFishingReports
    .filter((_w, i) => i >= lowActivityIndexCeiling)
    .map((w) => w.id);

  return {
    waterbodiesSorted,
    highActivityWaterbodyIds,
  };
};

export const deriveStyleUrl = (mapLayerState: MapLayerState) => {
  if (mapLayerState[LAYER_TYPES.BASE] === BASE_LAYER.SATELLITE) {
    return SOURCES.mboxSatellite;
  }
  if (mapLayerState[LAYER_TYPES.BASE] === BASE_LAYER.STANDARD) {
    return SOURCES.mboxStandard;
  }
  return SOURCES.mboxStandard;
};

export const getActiveLayers = (mapLayerState: MapLayerState) => {
  const activeLayers = Object.values(mapLayerState)
    .flatMap((layer) => layer)
    .filter(Boolean);
  return activeLayers;
};

export const debouncedMapEventTracking = _.debounce(
  (mapBounds: MapBounds, mapLayerState: MapLayerState) => {
    const appliedLayers = getActiveLayers(mapLayerState);

    WebAnalytics.mapRendered({
      bounds: {
        north: mapBounds.n,
        south: mapBounds.s,
        east: mapBounds.e,
        west: mapBounds.w,
      },
      layers: appliedLayers,
    });
  },
  500
);
