import { FishingReport } from '@omniafishing/core';
import React, { useState } from 'react';
import base from '../../styles/base.less';
import styles from './fishing_report_card_comments.less';
import { OmniaButton } from '../omnia_button/omnia_button';
import { useDispatch } from 'react-redux';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { apiV1 } from '../../lib/api';

interface FishingReportCardCommentsProps {
  fishingReport: FishingReport;
  isLakePage: boolean;
  defaultExpanded: boolean;
  userShouldAddComments?: boolean;
}

export const FishingReportCardComments = (props: FishingReportCardCommentsProps) => {
  const { fishingReport, isLakePage, defaultExpanded, userShouldAddComments } = props;
  // leave as style until prismic starts using technique instead of style
  const { comments, season, species, structure, style, waterbody } = fishingReport;
  const [showFullComments, setShowFullComments] = useState(false);
  const dispatch = useDispatch();
  const commentsTooLong = comments && !showFullComments && comments.split(' ').length > 40;
  const commentsDisplay =
    commentsTooLong && !defaultExpanded
      ? comments.split(' ').splice(0, 40).join(' ').concat('... ')
      : comments;
  const reportHasComments = !!commentsDisplay?.length;

  return (
    <div className={styles.commentsSection}>
      {isLakePage && reportHasComments && (
        <h3 className={styles.seoTag}>
          Fishing Report for {species.display_name} using {style.display_name} in{' '}
          {season.display_name} ({structure.display_name})
        </h3>
      )}
      {comments != null ? (
        <div className={styles.commentsWrapper}>
          <q className={styles.comments}>{commentsDisplay}</q>{' '}
          {!defaultExpanded && commentsTooLong ? (
            <span
              className={base.link}
              onClick={() => {
                setShowFullComments(true);
              }}
            >
              Read More
            </span>
          ) : null}
        </div>
      ) : userShouldAddComments ? (
        <div className={styles.editWrapper}>
          <OmniaButton
            kind="tertiary"
            size="sm"
            onPress={async () => {
              const {
                data: { data: waterbodyDetail },
              } = await apiV1.waterbodyFetch(waterbody.url_slug);
              dispatch(
                FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(waterbodyDetail, fishingReport)
              );
            }}
          >
            Add Comments
          </OmniaButton>
          <span>Accurate descriptions tend to create conversions!</span>
        </div>
      ) : null}
    </div>
  );
};
