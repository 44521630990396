import { FastForwardOutlined, RightOutlined } from '@ant-design/icons';
import { OmniaVideoItemOmniaVideoContent } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { formatSecondsToMinutes } from '../../lib/videos';
import chatBubble from './chat_bubble.svg';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product.less';

export const VideoItemSidebarOmniaVideoContent = (
  props: VideoItemProps & {
    omnia_video_item: OmniaVideoItemOmniaVideoContent;
    selected: boolean;
    showJump: boolean;
  }
) => {
  const { onClick, omnia_video_item, onJumpClick, selected, showJump } = props;
  const { item, start_sec, end_sec, comments } = omnia_video_item;
  const { url } = item;
  const isLive = end_sec == null;

  return (
    <a href={url} target="_blank" className={styles.productSidebarLink} onClick={onClick}>
      <div
        className={classNames(styles.productSidebar, {
          [styles.productSidebar__selected]: selected,
          [styles.productSidebar__live]: isLive,
        })}
      >
        <div className={styles.content}>
          <div className={styles.productSidebarImg}>
            <img src={chatBubble} alt="" />
          </div>

          <div className={styles.productSidebarText}>
            {comments && (
              <div className={styles.productSidebarTitleContainer}>
                <p
                  className={classNames(
                    styles.productSidebarTitle,
                    styles.productSidebarTitle__omnia_video_content,
                    {
                      [styles.productSidebarTitle__omnia_video_content__link]: url,
                    }
                  )}
                >
                  “{comments}”
                </p>
                {url && <RightOutlined className={styles.rightArrowIcon} />}
              </div>
            )}
            {showJump && (
              <p
                className={styles.productSidebarJump}
                onClick={(e) => {
                  e.preventDefault();
                  onJumpClick?.();
                }}
              >
                <FastForwardOutlined className={styles.productSidebarJumpIcon} />
                Jump to {formatSecondsToMinutes(start_sec)}
              </p>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};
