import { FishingReport } from '@omniafishing/core';
import _ from 'lodash';
import { useEffect } from 'react';
import { WebAnalytics } from '../lib/web_analytics';
import { useAfterMountedEffect } from './use_after_mounted_effect';
import { usePrevious } from './use_previous';

export const mapFishingReportIdsAndPositions = (reports: FishingReport[]) => {
  return reports.map((r, i) => {
    return { report_id: r.id, position: i };
  });
};

export const useFishingReportListViewed = (
  fishingReports: FishingReport[],
  context: 'embedded' | 'standalone',
  totalFiltersApplied: number,
  numberOfReportsRendered: number
) => {
  const prevFishingReports = usePrevious(fishingReports);
  const previousFishingReports = prevFishingReports || [];

  useEffect(() => {
    if (!_.isEqual(previousFishingReports, fishingReports)) {
      const previousFishingReportIdsAndPosition =
        mapFishingReportIdsAndPositions(previousFishingReports);
      const currentFishingReportIdsAndPosition = mapFishingReportIdsAndPositions(fishingReports);

      const newFishingReportIdsAndPosition = currentFishingReportIdsAndPosition.filter(
        (report) =>
          !previousFishingReportIdsAndPosition.find((r) => {
            return r.report_id === report.report_id && r.position === report.position;
          })
      );
      WebAnalytics.fishingReportListViewed(context, newFishingReportIdsAndPosition);
    }
  }, [
    numberOfReportsRendered,
    fishingReports.map((r) => r.id).join(','),
    prevFishingReports.map((r) => r.id).join(','),
  ]);

  useAfterMountedEffect(() => {
    WebAnalytics.fishingReportListViewed(
      'embedded',
      mapFishingReportIdsAndPositions(fishingReports)
    );
  }, [totalFiltersApplied]);
};
