import { WaterbodyDetail } from '@omniafishing/core';
import ls from 'localstorage-slim';
import { useDispatch, useSelector } from 'react-redux';
import { StorageKeys } from '../lib/local_storage';
import { ONE_DAY_IN_SECONDS } from '../lib/time';
import {
  getUserPrefsBrands,
  getUserPrefsSpecies,
  getUserPrefsTechniques,
  getUserPrefsWaterbodies,
  UserPreferencesActions,
} from '../redux/user_preferences';

export const useUserPreferences = () => {
  const dispatch = useDispatch();
  const userPreferencesBrands = useSelector(getUserPrefsBrands);
  const userPreferencesSpecies = useSelector(getUserPrefsSpecies);
  const userPreferencesTechniques = useSelector(getUserPrefsTechniques);
  const userPreferencesWaterbodies = useSelector(getUserPrefsWaterbodies);

  const setUserPreferencesSpecies = (species_name: string) => {
    dispatch(UserPreferencesActions.USER_PREFERENCES_SPECIES_SAVE(species_name));
  };

  const setUserPreferencesBrand = (brand_name: string) => {
    dispatch(UserPreferencesActions.USER_PREFERENCES_BRAND_SAVE(brand_name));
  };

  const setUserPreferencesTechnique = (technique_name: string) => {
    dispatch(UserPreferencesActions.USER_PREFERENCES_TECHNIQUE_SAVE(technique_name));
  };

  const setUserPreferencesWaterbody = (waterbodyDetail: WaterbodyDetail) => {
    dispatch(UserPreferencesActions.USER_PREFERENCES_WATERBODY_SAVE(waterbodyDetail));
  };

  const getUserPreferencesSeasonGroup = () =>
    ls.get<string>(StorageKeys.userPreferencesSeasonGroup);

  const setUserPreferencesSeasonGroup = (season_group_name: string) => {
    if (!season_group_name) {
      return;
    }

    ls.set(StorageKeys.userPreferencesSeasonGroup, season_group_name, {
      ttl: ONE_DAY_IN_SECONDS,
    });
  };

  const userPreferencesSeasonGroup = getUserPreferencesSeasonGroup();

  return {
    userPreferencesBrands,
    userPreferencesSpecies,
    userPreferencesSeasonGroup,
    userPreferencesTechniques,
    userPreferencesWaterbodies,
    setUserPreferencesSpecies,
    setUserPreferencesBrand,
    setUserPreferencesSeasonGroup,
    setUserPreferencesTechnique,
    setUserPreferencesWaterbody,
  };
};
