import { CalendarFilled, EnvironmentFilled } from '@ant-design/icons';
import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { OmniaUrls } from '../../lib/urls';
import { isAmbassador, NAME_FALLBACK, roleAsText } from '../../lib/user';
import { WebAnalytics } from '../../lib/web_analytics';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import styles from './chip_details.less';

interface ChipDetailsProps {
  fishingReport: FishingReport;
  position: number;
  onUserClick?: () => void;
  onLakeClick?: () => void;
  className?: string;
  showLakeName?: boolean;
  showUserImage?: boolean;
  showUserName?: boolean;
  showUserStatus?: boolean;
  userImageSize?: number;
}
export const ChipDetails = (props: ChipDetailsProps) => {
  const {
    fishingReport,
    onUserClick,
    onLakeClick,
    position,
    className,
    showLakeName = true,
    showUserName = true,
    showUserImage = true,
    showUserStatus = true,
    userImageSize = 75,
  } = props;
  const { user, outing_date, accepted_at } = fishingReport;
  const userFullName = user.full_name || NAME_FALLBACK;
  const formattedDate = dayjs(outing_date || accepted_at).format('M/D/YY');

  const { isDesktop } = useResponsive();

  return (
    <div className={classNames(styles.chipContainer, className)}>
      {showUserImage && (
        <div className={styles.userImageContainer}>
          <AmbassadorLink
            ambassador={user}
            onClick={() => {
              onUserClick?.();
              WebAnalytics.fishingReportEmbeddedClick(
                '[reports].(user_link)',
                fishingReport,
                'embedded',
                position
              );
            }}
            target={isDesktop ? '_blank' : undefined}
          >
            <UserProfileImg image={user.image} imageSize={100} size={userImageSize} />
          </AmbassadorLink>
        </div>
      )}

      <div className={styles.chipDetails}>
        {showUserName && (
          <p className={styles.userName}>
            <AmbassadorLink
              ambassador={user}
              onClick={() => {
                onUserClick?.();
                WebAnalytics.fishingReportEmbeddedClick(
                  '[reports].(user_link)',
                  fishingReport,
                  'embedded',
                  position
                );
              }}
              target={isDesktop ? '_blank' : undefined}
            >
              {userFullName}
            </AmbassadorLink>
          </p>
        )}
        {isAmbassador(user) && showUserStatus && (
          <p className={styles.chipDetail}>
            <AmbassadorColorDot user={user} bordered />
            {roleAsText(user.role)} Ambassador
          </p>
        )}
        <p className={styles.chipDetail__date}>
          <CalendarFilled />
          <span>{formattedDate}</span>
        </p>
        {showLakeName && (
          <div>
            <Link
              to={OmniaUrls.waterbody(fishingReport.waterbody)}
              className={styles.reportedOnLake}
              onClick={() => onLakeClick?.()}
            >
              {/* <PinMidActivity favorite={false} width={16} height={20} fill={'#495f5f'} /> */}
              <EnvironmentFilled />
              <span className={styles.noBreak}>
                <em>{fishingReport.waterbody.primary_name}</em>
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
