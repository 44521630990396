import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getFullUrl } from '../../redux/router';

export interface SeoProps {
  canonicalUrl?: string;
  children?: React.ReactNode;
  description?: string;
  meta?: (
    | {
        name: string;
        content: string;
      }
    | {
        property: string;
        content: string;
      }
  )[];
  ogEventPlace?: string;
  ogEventStart?: string;
  ogImage?: string;
  ogType?: 'website' | 'article' | 'event' | 'place';
  schema?: any;
  title?: string;
  titleTemplate?: string;
}

export const SEO = (props: SeoProps) => {
  const {
    canonicalUrl,
    description,
    meta = [],
    ogEventPlace,
    ogEventStart,
    ogImage,
    ogType = `website`,
    schema,
    title,
    titleTemplate,
  } = props;

  const fullUrl = useSelector(getFullUrl);
  const canonical = canonicalUrl || fullUrl;

  let metas = [
    {
      property: `og:type`,
      content: ogType as string,
    },
    {
      property: `og:url`,
      content: canonical,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
  ];
  const links = [] as HelmetProps['link'];

  const helmetProps: HelmetProps = {
    meta: metas,
  };

  if (titleTemplate) {
    helmetProps.titleTemplate = titleTemplate;
  }

  if (title) {
    helmetProps.title = title;
    metas = [
      ...metas,
      {
        property: `og:title`,
        content: title,
      },
      {
        name: `twitter:title`,
        content: title,
      },
    ];
  }

  if (ogImage) {
    metas = [
      ...metas,
      {
        property: `og:image`,
        content: ogImage,
      },
    ];
  }

  if (ogEventStart) {
    metas = [
      ...metas,
      {
        property: `og:event:start_time`,
        content: ogEventStart,
      },
    ];
  }

  if (ogEventPlace) {
    metas = [
      ...metas,
      {
        property: `og:event:place`,
        content: ogEventPlace,
      },
    ];
  }

  if (description) {
    metas = [
      ...metas,
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ];
  }

  if (canonical) {
    links.push({
      rel: 'canonical',
      href: canonical,
    });
  }

  helmetProps.meta = [...metas, ...meta];
  helmetProps.link = links;

  return (
    <Helmet {...helmetProps}>
      {' '}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      {props.children}
    </Helmet>
  );
};
