import { Waterbody } from '@omniafishing/core';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getEnv } from '../../env';
import { fitBounds } from '../../lib/fit_bounds';
import { FILL_HIGH_ACTIVITY, FILL_LOW_ACTIVITY } from '../map/pin_low_activity';

export type WaterbodySubset = Pick<
  Waterbody,
  'bbox' | 'lat' | 'lng' | 'primary_name' | 'hot_scale'
>;

interface WaterbodyImgUrlOptions {
  imgSize: {
    width: number;
    height: number;
    padding?: number;
  };
  hires: boolean;
  zoom?: number;
  showPin?: boolean;
}

export const waterbodyImgUrl = (waterbody: WaterbodySubset, options: WaterbodyImgUrlOptions) => {
  const { imgSize, hires, zoom, showPin = true } = options;
  if (waterbody == null) {
    return null;
  }

  const color =
    waterbody.hot_scale > 0
      ? FILL_HIGH_ACTIVITY.replace('#', '')
      : FILL_LOW_ACTIVITY.replace('#', '');

  const res = hires ? '@2x' : '';

  const zoomLevel = zoom
    ? zoom
    : waterbody.bbox
    ? fitBounds(
        [waterbody.bbox[0], waterbody.bbox[2]],
        {
          width: imgSize.width,
          height: imgSize.height,
        },
        imgSize?.padding
      ).zoom
    : 12;

  const pin = showPin ? `pin-s+${color}(${waterbody.lng},${waterbody.lat})/` : '';

  const mapboxImgURL = `https://api.mapbox.com/styles/v1/omniafishing/cl8nijurz000u14o94ojffmkg/static/${pin}${
    waterbody.lng
  },${waterbody.lat},${zoomLevel},0.00,0.00/${imgSize.width}x${imgSize.height}${res}?access_token=${
    getEnv().MAPBOX_ACCESS_TOKEN
  }`;

  return mapboxImgURL;
};

interface WaterbodyImgProps {
  className?: string;
  height?: number;
  hires?: boolean;
  lazyLoad?: boolean;
  onLoad?: () => void;
  showPin?: boolean;
  size?: {
    width: number;
    height: number;
    padding?: number;
  };
  waterbody: WaterbodySubset;
  width?: number;
  zoom?: number;
}

export const WaterbodyImg = (props: WaterbodyImgProps) => {
  const {
    className,
    height,
    hires,
    lazyLoad = true,
    onLoad,
    showPin = true,
    size,
    waterbody,
    width,
    zoom,
  } = props;
  const imgSize = {
    width: 412,
    height: 300,
    ...size,
  };

  if (lazyLoad) {
    return (
      <LazyLoadImage
        src={waterbodyImgUrl(waterbody, { imgSize, hires, zoom, showPin })}
        alt={`${waterbody.primary_name} Map`}
        className={className}
        width={width}
        height={height}
        threshold={200} // px from viewport to load
        onLoad={() => onLoad?.()}
      />
    );
  }
  return (
    <img
      src={waterbodyImgUrl(waterbody, { imgSize, hires, zoom, showPin })}
      alt={`${waterbody.primary_name} Map`}
      className={className}
      width={width}
      height={height}
      onLoad={() => onLoad?.()}
    />
  );
};
