import classNames from 'classnames';
import React from 'react';
import styles from './loading.less';

export interface LoadingProps {
  text: string;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

export interface LogoProps {
  color?: string;
}

export const FILL_DEFAULT = '#042930';

export const Logo = (props: LogoProps) => {
  const fill = props.color || FILL_DEFAULT;
  return (
    <svg
      width="95"
      height="114"
      viewBox="0 0 95 114"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.logo}
    >
      <path
        d="M65.571.084H29.719C13.705.084.722 13.067.722 29.082v28.319l26.434-16.118V29.55a7.556 7.556 0 017.556-7.557H60.58a7.56 7.56 0 017.56 7.559v54.259a7.557 7.557 0 01-7.557 7.558H34.241c-11.04 0-9.133-11.467-5.579-16.369l7.598 3.93c-1.645-9.661 1.026-27.941 4.756-38.656C18.343 55.741.722 68.054.722 88.323c0 12.639 9.51 24.955 26.454 24.955h38.399c16.016 0 28.998-12.983 28.998-28.998V29.086C94.573 13.068 81.589.084 65.571.084"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Loading = (props: LoadingProps) => {
  const { className, color, text, style } = props;
  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <Logo color={color} />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default Loading;
