import { CalendarOutlined } from '@ant-design/icons';
import { Media } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import buSrc from '../../assets/bu.svg';
import iconPlay from '../../assets/icon-play.svg';
import iconArticle from '../../components/algolia_media_card/icon-article.svg';
import fallbackImg from '../../components/search_universal/fallback-image.png';
import { getImgixPath } from '../../lib/imgix';
import { articleCardDate } from '../../lib/prismic';
import { OmniaUrls } from '../../lib/urls';
import styles from './media_card.less';
import SvgOpenInNewWindow from '../svg/open_in_new_window';
interface MediaCardProps {
  className?: string;
  imgWidth?: number;
  linkToNewPageIcon?: boolean;
  media: Media;
  mediaTextClassName?: string;
  newBadge?: boolean;
  onClick?: () => void;
  onImageLoad?: () => void;
  showMediaInfo?: boolean;
  target?: '_blank';
}

export const MediaCard = (props: MediaCardProps) => {
  const {
    className,
    imgWidth,
    linkToNewPageIcon,
    media,
    mediaTextClassName,
    newBadge,
    onClick,
    onImageLoad,
    showMediaInfo = true,
    target,
  } = props;
  const { media_item, media_type } = media;
  const { title, thumbnail, tags } = media_item;

  const isBassU = media_type === 'BassUArticle';
  const w = imgWidth || 600;
  const h = Math.round(w * 0.666666667);

  const url = OmniaUrls.media(media);

  const published =
    media_type === 'OmniaVideo' ? media_item.publication_date : media_item.published_at;

  const isVideo =
    media_type === 'PrismicVideo' || media_type === 'OmniaVideo' || tags.includes('video');

  const isLinkToVideo = isBassU || media_type === 'PrismicVideo';

  const content = (
    <>
      <div className={styles.imgWrapper}>
        <img
          src={thumbnail ? getImgixPath(thumbnail, { w }) : fallbackImg}
          alt={title}
          className={styles.img}
          loading="lazy"
          width={w}
          height={h}
          onLoad={onImageLoad}
        />
        {newBadge && <span className={styles.newBadge}>New</span>}
        {isVideo ? (
          <>
            <span
              className={classNames(styles.badge, {
                [styles.badge__live]: media_type === 'OmniaVideo' && media_item.livestream,
                [styles.badge__topLeft]: !newBadge,
                [styles.badge__bottomLeft]: newBadge,
              })}
            >
              <img src={iconPlay} className={styles.badgeIcon} /> Video
            </span>

            {thumbnail && (
              <>
                {isLinkToVideo && linkToNewPageIcon ? (
                  <SvgOpenInNewWindow className={styles.iconPlay} />
                ) : (
                  <img src={iconPlay} className={styles.iconPlay} />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <span className={classNames(styles.badge, styles.badge__topLeft)}>
              <img src={iconArticle} className={styles.badgeIcon} /> Article
            </span>
          </>
        )}
      </div>
      {showMediaInfo && (
        <div className={classNames(styles.text, mediaTextClassName)}>
          <p className={styles.publicationDate}>
            <CalendarOutlined />
            <span>{articleCardDate(published)}</span>
          </p>
          <p className={styles.title}>{title}</p>
        </div>
      )}
    </>
  );

  if (isBassU) {
    return (
      <a
        href={url}
        className={classNames(styles.link, className)}
        onClick={() => {
          onClick?.();
        }}
        target={target}
      >
        {content}
        <span style={{ backgroundImage: `url(${buSrc})` }} className={styles.source}></span>
      </a>
    );
  }

  return (
    <Link
      to={url}
      className={classNames(styles.link, className)}
      onClick={() => {
        onClick?.();
      }}
      target={target}
    >
      {content}
    </Link>
  );
};
