import { CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useEventListener } from '../../hooks/use_event_listener';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './scroll_to_top_button.less';

export const ScrollToTopButton = () => {
  const [scroll, setScroll] = useState(0);
  const handleScroll = () => {
    setScroll(window.pageYOffset);
  };

  useEventListener('scroll', _.debounce(handleScroll, 300), null, { passive: true });

  return (
    <>
      <Button
        className={classNames(styles.button, {
          [styles.fadeIn]: scroll > 1500,
          [styles.fadeOut]: scroll < 1500,
        })}
        onClick={() => {
          window.scroll({ top: 0, behavior: 'smooth' });
          WebAnalytics.scrollToTopClick();
        }}
      >
        <CaretUpOutlined />
        <span className={styles.antText}>Top</span>
      </Button>
    </>
  );
};
