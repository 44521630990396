import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { FishingReport, ProductFamily } from '@omniafishing/core';
import { Button, Collapse, Drawer } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import _ from 'lodash';
import React, { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import {
  fishingReportContainsSku,
  getReportFacetOptions,
  sortFishingReportsByDistance,
  sortFishingReportsByCommentsProducts,
  sortReportsByOutingDate,
} from '../../lib/fishing_reports';
import { scrollToElementTop } from '../../lib/scroll';
import { WebAnalytics } from '../../lib/web_analytics';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import {
  getIpCurrentSeasonGroup,
  getIpLatitude,
  getIpLongitude,
  getIpZone,
} from '../../redux/geographic_location';
import { getSeasonGroups, getSpeciesBySpeciesName } from '../../redux/reference_data';
import { FilterTag } from '../filter_tag/filter_tag';
import { OmniaButton } from '../omnia_button/omnia_button';
import { SpanLink } from '../span_link/span_link';
import SvgReports from '../svg/reports';
import { MediaCheckboxes } from './all_filters_drawer/media_checkboxes';
import { ProductRadios } from './all_filters_drawer/product_radios';
import {
  convertSeasonGroupNameToSeasonGroup,
  SeasonGroupRadios,
} from './all_filters_drawer/season_group_radios';
import { SortingRadios } from './all_filters_drawer/sorting_radios';
import { SpeciesRadios } from './all_filters_drawer/species_radios';
import { StructureCheckboxes } from './all_filters_drawer/structure_checkboxes';
import { TechniqueCheckboxes } from './all_filters_drawer/technique_checkboxes';
import {
  filterByAllCategories,
  FilterByAllCategoriesArgs,
  getCountsOfProductsSeasonsSpecies,
} from './filters';
import styles from './product_detail_fishing_reports.less';
import { ProductDetailFishingReportCardList } from './product_detail_fishing_report_card_list';
import { ProductSelect } from './product_select';
import {
  defaultSeasonGroupValue,
  labelBuilderSeasonGroupSelect,
  removeIceSeasonPerUserZone,
  SeasonGroupSelect,
  SeasonGroupSelectValue,
} from './season_group_select';
import { SortingSelect } from './sorting_select';
import {
  defaultSpeciesValue,
  labelBuilderSpeciesSelect,
  SpeciesSelect,
  SpeciesSelectValue,
} from './species_select';

interface ProductDetailFishingReports {
  fishingReports: FishingReport[];
  fishingReportsHeaderRef: MutableRefObject<HTMLDivElement>;
  onTotalFishingReportsChange: (val: number) => void;
  onWidgetProductSkuChange: (val: string) => void;
  onWidgetSpeciesNameChange: (val: string) => void;
  onWidgetTechniqueNameChange: (val: string[]) => void;
  productFamily: ProductFamily;
  reportsWidgetProductSku: string;
  reportsWidgetSpeciesName: string;
  reportsWidgetTechniques: string[];
  totalFilteredFishingReports: number;
}

export enum ProductDetailFishingReportsQueryParams {
  fishing_reports_id = 'fishing_reports_id',
  fishing_reports_sort = 'fishing_reports_sort',
  fishing_reports_waterbody = 'fishing_reports_waterbody',
}

export const totalCountsOfMatchesInFishingReports = (countsOfMatches: number[]) => {
  return countsOfMatches.reduce((acc, num) => {
    return acc + num;
  }, 0);
};

export const ProductDetailFishingReports = (props: ProductDetailFishingReports) => {
  const ipLat = useSelector(getIpLatitude);
  const ipLong = useSelector(getIpLongitude);
  const seasonGroups = useSelector(getSeasonGroups);
  const ipZone = useSelector(getIpZone);
  const speciesBySpeciesName = useSelector(getSpeciesBySpeciesName);
  const ipCurrentSeasonGroup = useSelector(getIpCurrentSeasonGroup);
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const dispatch = useDispatch();
  const currentQuery = getCurrentQuery<{
    [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: boolean;
    [ProductDetailFishingReportsQueryParams.fishing_reports_waterbody]: string;
    [ProductDetailFishingReportsQueryParams.fishing_reports_id]: number;
  }>();
  const reportSort = currentQuery.fishing_reports_sort;
  const reportWaterbodyId = currentQuery.fishing_reports_waterbody;
  const reportId = currentQuery.fishing_reports_id;

  const [waterbodyName, setWaterbodyName] = useState<string>(null);

  // page state
  const [selectedProductSku, setSelectedProductSku] = useState<string>(null);
  const [selectedSeasonGroup, setSelectedSeasonGroup] = useState<SeasonGroupSelectValue>();
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesSelectValue>();
  const [isSortedByDistance, setIsSortedByDistance] = useState(reportSort ?? true);
  const [selectedTechniqueNames, setSelectedTechniqueNames] = useState<CheckboxValueType[]>();
  const [selectedStructureNames, setSelectedStructureNames] = useState<CheckboxValueType[]>();
  const [selectedMedia, setSelectedMedia] = useState<CheckboxValueType[]>();

  // drawer state
  const [drawerSortByDistance, setDrawerSortByDistance] = useState(true);
  const [drawerProductSku, setDrawerProductSku] = useState<string>();
  const [drawerSeasonGroupName, setDrawerSeasonGroupName] = useState<string>();
  const [drawerSpeciesName, setDrawerSpeciesName] = useState<string>();
  const [drawerStructures, setDrawerStructures] = useState<CheckboxValueType[]>();
  const [drawerTechniques, setDrawerTechniques] = useState<CheckboxValueType[]>();
  const [drawerMedia, setDrawerMedia] = useState<CheckboxValueType[]>();

  const [visible, setVisible] = useState(false);
  const [numberOfReportsRendered, setNumberOfReportsRendered] = useState(3);
  const {
    fishingReports,
    fishingReportsHeaderRef,
    onTotalFishingReportsChange,
    onWidgetProductSkuChange,
    onWidgetSpeciesNameChange,
    onWidgetTechniqueNameChange,
    productFamily,
    reportsWidgetProductSku,
    reportsWidgetSpeciesName,
    reportsWidgetTechniques,
    totalFilteredFishingReports,
  } = props;
  const productFamilyProducts = productFamily?.products || [];

  useEffect(() => {
    replaceQueryString({
      [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: true,
      [ProductDetailFishingReportsQueryParams.fishing_reports_waterbody]: null,
    });
  }, []);

  useEffect(() => {
    if (reportSort != null) {
      setIsSortedByDistance(reportSort);
    }
  }, [reportSort]);

  useEffect(() => {
    if (reportWaterbodyId != null) {
      const waterbody = fishingReports.find((r) => {
        return r.waterbody.id === reportWaterbodyId;
      })?.waterbody;
      setWaterbodyName(
        `${waterbody?.primary_name}, ${waterbody?.locales.map((locale) => `${locale.state.abbr}`)}`
      );
    }
  }, [reportWaterbodyId, fishingReports]);

  useEffect(() => {
    if (reportsWidgetSpeciesName) {
      resetAllValues();
      setSelectedSpecies({
        label: labelBuilderSpeciesSelect(
          speciesBySpeciesName(reportsWidgetSpeciesName),
          speciesCounts,
          speciesNameIndexValue(reportsWidgetSpeciesName)
        ),
        value: reportsWidgetSpeciesName,
      });
      onWidgetSpeciesNameChange(undefined);
    }
  }, [reportsWidgetSpeciesName]);

  useEffect(() => {
    if (reportsWidgetProductSku) {
      resetAllValues();
      setSelectedProductSku(reportsWidgetProductSku);
    }
    onWidgetProductSkuChange(null);
  }, [reportsWidgetProductSku]);

  useEffect(() => {
    if (reportsWidgetTechniques) {
      resetAllValues();
      setSelectedTechniqueNames(reportsWidgetTechniques);
    }
    onWidgetTechniqueNameChange(undefined);
  }, [reportsWidgetTechniques]);

  // drawer effects
  useEffect(() => {
    if (isSortedByDistance !== drawerSortByDistance) {
      setDrawerSortByDistance(isSortedByDistance);
    }
  }, [isSortedByDistance]);

  useEffect(() => {
    if (selectedProductSku) {
      setDrawerProductSku(selectedProductSku);
    } else if (selectedProductSku === null) {
      setDrawerProductSku(undefined);
    }
  }, [selectedProductSku]);

  useEffect(() => {
    const seasonGroup = selectedSeasonGroup?.label?.props['data-result'];
    if (selectedSeasonGroup) {
      setDrawerSeasonGroupName(seasonGroup?.name);
    }
  }, [selectedSeasonGroup]);

  useEffect(() => {
    const speciesName = selectedSpecies?.value;
    if (speciesName) {
      setDrawerSpeciesName(speciesName);
    } else if (speciesName === null) {
      setDrawerSpeciesName(undefined);
    }
  }, [selectedSpecies]);

  useEffect(() => {
    if (reportsWidgetTechniques?.length) {
      setDrawerTechniques(reportsWidgetTechniques);
    }
  }, [reportsWidgetTechniques?.join('')]);

  const filterArgs: FilterByAllCategoriesArgs = {
    fishingReports,
    selectedProductSku,
    reportWaterbodyId,
    reportId,
    selectedSeasonGroup,
    selectedSpecies,
    selectedStructureNames,
    selectedTechniqueNames,
    selectedMedia,
  };

  const fishingReportsFilteredByAllCategories = filterByAllCategories(filterArgs);

  useEffect(() => {
    onTotalFishingReportsChange(fishingReportsFilteredByAllCategories.length);
  }, [fishingReportsFilteredByAllCategories.length]);

  const showLoadMore = numberOfReportsRendered < totalFilteredFishingReports;

  const productsInFishingReports = productFamilyProducts.filter((product) => {
    const reportCount = fishingReports.filter((report) => {
      return fishingReportContainsSku(report, product.sku);
    }).length;
    if (reportCount > 0) {
      return product;
    }
  });

  const zoneSpecificSeasonGroups = removeIceSeasonPerUserZone(ipZone, seasonGroups);

  const speciesInFishingReports = getReportFacetOptions(fishingReports, 'species');

  // update this hot garbage to use getAllReportOptionsAndCounts
  const { productCounts, speciesCounts, seasonGroupCounts } = useMemo(
    () =>
      getCountsOfProductsSeasonsSpecies({
        fishingReports,
        productsInFishingReports,
        selectedProductSku,
        selectedSeasonGroup: selectedSeasonGroup?.label?.props['data-result'],
        selectedSpeciesName: selectedSpecies?.value,
        speciesInFishingReports,
        zoneSpecificSeasonGroups,
      }),
    [
      fishingReports.map((fr) => fr.id).join(''),
      productsInFishingReports.map((p) => p.id).join(''),
      selectedProductSku,
      selectedSeasonGroup?.value,
      selectedSpecies?.value,
      speciesInFishingReports.map((s) => s.id).join(''),
    ]
  );

  const reportsSortedByDistance = useMemo(() => {
    return sortFishingReportsByDistance(fishingReportsFilteredByAllCategories, ipLat, ipLong);
  }, [fishingReportsFilteredByAllCategories.map((fr) => fr.id).join(''), ipLat, ipLong]);

  /* sort reports by nearby / recent
   recent also sorts by if outing date, desc */
  const sortedFishingReports = isSortedByDistance
    ? reportsSortedByDistance
    : sortReportsByOutingDate(fishingReportsFilteredByAllCategories);

  // sort by userContent
  const fishingReportsSortedByContent = useMemo(
    () => sortFishingReportsByCommentsProducts(sortedFishingReports),
    [sortedFishingReports.map((fr) => fr.id).join('')]
  );

  const speciesNameIndexValue = (speciesName: string) => {
    return speciesInFishingReports.indexOf(speciesBySpeciesName(speciesName));
  };

  const productSelectFilterCount = selectedProductSku ? 1 : 0;
  const seasonGroupSelectFilterCount = selectedSeasonGroup?.value ? 1 : 0;
  const speciesSelectFilterCount = selectedSpecies?.value ? 1 : 0;
  const reportWaterbodyCount = reportWaterbodyId ? 1 : 0;
  const reportIdMatchCount = reportId ? 1 : 0;

  const structuresRenderedFilterTotalCount =
    selectedStructureNames?.length > 0 ? selectedStructureNames.length : 0;
  const techniquesRenderedFilterTotalCount =
    selectedTechniqueNames?.length > 0 ? selectedTechniqueNames.length : 0;
  const mediaRenderFilterTotalCount = selectedMedia?.length > 0 ? selectedMedia.length : 0;

  const totalRenderedFiltersApplied =
    productSelectFilterCount +
    reportWaterbodyCount +
    reportIdMatchCount +
    seasonGroupSelectFilterCount +
    speciesSelectFilterCount +
    structuresRenderedFilterTotalCount +
    techniquesRenderedFilterTotalCount +
    mediaRenderFilterTotalCount;

  const hasFiltersApplied = totalRenderedFiltersApplied > 0;
  const hasWaterbodyCount = reportWaterbodyCount > 0;

  const showMoreFishingReports = () => {
    if (numberOfReportsRendered > totalFilteredFishingReports) {
      setNumberOfReportsRendered(totalFilteredFishingReports);
    }
    setNumberOfReportsRendered((num) => num + 6);
  };

  /* Drawer constants and functions */
  const onClose = () => {
    setVisible(false);
  };

  const { Panel } = Collapse;

  const webAnalyticsCheckboxHelper = (
    eventType: 'technique' | 'structure' | 'media',
    previousValues: CheckboxValueType[],
    newValues: CheckboxValueType[]
  ) => {
    const filterValue: string[] = _.difference(newValues as string[], previousValues as string[]);

    if (filterValue.length > 0) {
      if (eventType === 'media') {
        WebAnalytics.contentFiltered(eventType, `${filterValue[0]}s_only`);
        return;
      }
      WebAnalytics.contentFiltered(eventType, filterValue[0] as string);
    }
  };

  const onFormSubmit = () => {
    const seasonGroup = convertSeasonGroupNameToSeasonGroup(drawerSeasonGroupName, seasonGroups);
    const seasonGroupIndex = zoneSpecificSeasonGroups.indexOf(seasonGroup);

    if (drawerSortByDistance !== undefined) {
      replaceQueryString({
        [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: false,
      });
    } else {
      replaceQueryString({
        [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: true,
      });
    }

    if (drawerProductSku) {
      setSelectedProductSku(drawerProductSku);
    } else if (drawerProductSku === undefined) {
      setSelectedProductSku(null);
    }
    if (drawerSeasonGroupName) {
      setSelectedSeasonGroup({
        label: labelBuilderSeasonGroupSelect(
          seasonGroup,
          ipCurrentSeasonGroup,
          seasonGroupCounts,
          seasonGroupIndex
        ),
        value: drawerSeasonGroupName,
      });
    } else if (drawerSeasonGroupName === undefined) {
      setSelectedSeasonGroup(defaultSeasonGroupValue);
    }
    if (drawerSpeciesName) {
      setSelectedSpecies({
        label: labelBuilderSpeciesSelect(
          speciesBySpeciesName(drawerSpeciesName),
          speciesCounts,
          speciesNameIndexValue(drawerSpeciesName)
        ),
        value: drawerSpeciesName,
      });
    } else if (drawerSpeciesName === undefined) {
      setSelectedSpecies(defaultSpeciesValue);
    }

    setSelectedStructureNames(drawerStructures);
    setSelectedTechniqueNames(drawerTechniques);
    setSelectedMedia(drawerMedia);
  };

  const resetAllValues = () => {
    setSelectedProductSku(null);
    setSelectedSeasonGroup(null);
    setSelectedTechniqueNames(undefined);
    setSelectedStructureNames(undefined);
    setSelectedMedia(undefined);
    setSelectedSpecies(null);

    setDrawerSortByDistance(true);
    setDrawerProductSku(undefined);
    setDrawerSpeciesName(undefined);
    setDrawerSeasonGroupName(undefined);
    setDrawerStructures(undefined);
    setDrawerTechniques(undefined);
    setDrawerMedia(undefined);
    // setting default to sort by distance
    replaceQueryString({
      [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: true,
      [ProductDetailFishingReportsQueryParams.fishing_reports_waterbody]: null,
      [ProductDetailFishingReportsQueryParams.fishing_reports_id]: null,
    });
  };

  const productFilterCount = drawerProductSku ? 1 : 0;
  const seasonGroupFilterCount = drawerSeasonGroupName ? 1 : 0;
  const speciesFilterCount = drawerSpeciesName ? 1 : 0;
  const structuresTotalCount = drawerStructures?.length > 0 ? drawerStructures.length : 0;
  const techniquesTotalCount = drawerTechniques?.length > 0 ? drawerTechniques.length : 0;
  const mediaTotalCount = drawerMedia?.length > 0 ? drawerMedia.length : 0;

  const totalDrawerFiltersApplied =
    productFilterCount +
    seasonGroupFilterCount +
    speciesFilterCount +
    structuresTotalCount +
    techniquesTotalCount +
    mediaTotalCount;

  const commonDrawerProps = {
    fishingReports,
    drawerProductSku,
    drawerSpeciesName,
    drawerSeasonGroupName,
  };

  return (
    <>
      <div ref={fishingReportsHeaderRef}>
        <div className={styles.fishingReportsFilters}>
          <div className={styles.filters}>
            <div className={styles.filters__desktop}>
              <ProductSelect
                productCounts={productCounts}
                selectedProductSku={selectedProductSku}
                onChange={(product) => {
                  setSelectedProductSku(product);
                  scrollToElementTop(fishingReportsHeaderRef.current, 20);
                }}
                productsInFishingReports={productsInFishingReports}
              />
              <SeasonGroupSelect
                seasonGroupCounts={seasonGroupCounts}
                selectedSeasonGroup={selectedSeasonGroup}
                onChange={(seasonGroup) => {
                  setSelectedSeasonGroup(seasonGroup);
                  scrollToElementTop(fishingReportsHeaderRef.current, 20);
                }}
              />
              <SpeciesSelect
                speciesCounts={speciesCounts}
                onChange={(specie) => {
                  setSelectedSpecies(specie);
                  scrollToElementTop(fishingReportsHeaderRef.current, 20);
                }}
                selectedSpecies={selectedSpecies}
                speciesInFishingReports={speciesInFishingReports}
              />
            </div>
            <div className={styles.allFiltersButton}>
              <Button
                onClick={() => {
                  setVisible(!visible);
                  WebAnalytics.productDetailPageClick('[report].(all_filters)');
                }}
              >
                <FilterOutlined />
                All Filters {hasFiltersApplied && `(${totalRenderedFiltersApplied})`}
              </Button>
            </div>
            {hasFiltersApplied && (
              <Button
                type="link"
                onClick={() => {
                  resetAllValues();
                  WebAnalytics.productDetailPageClick('[report].(clear_all_filters)');
                }}
              >
                Clear All
              </Button>
            )}
          </div>
          {ipLat && ipLong && (
            <SortingSelect
              isSortedByDistance={isSortedByDistance}
              onChange={(val) =>
                replaceQueryString({
                  [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: val,
                })
              }
              className={styles.sortingSelect}
            />
          )}
          {hasWaterbodyCount && (
            <div className={styles.waterbodyFilter}>
              <FilterTag onClose={resetAllValues}>{waterbodyName}</FilterTag>
            </div>
          )}
        </div>

        <ul className={styles.fishingReportList}>
          {totalFilteredFishingReports > 0 ? (
            <ProductDetailFishingReportCardList
              fishingReports={fishingReportsSortedByContent.slice(0, numberOfReportsRendered)}
              numberOfReportsRendered={numberOfReportsRendered}
              totalFiltersApplied={totalRenderedFiltersApplied + (isSortedByDistance ? 0 : 1)}
              showLoadMore={showLoadMore}
              onLoadMoreClick={showMoreFishingReports}
            />
          ) : (
            <div className={styles.emptyReportContainer}>
              <SvgReports width={35} />
              <p>This product has no fishing reports for these filters.</p>
              <p>
                Be the first to{' '}
                <span>
                  <SpanLink
                    onClick={() => {
                      dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN());
                    }}
                  >
                    file a report.
                  </SpanLink>
                </span>
              </p>
            </div>
          )}
        </ul>
      </div>
      <Drawer
        title="Sort & Filter"
        placement="right"
        onClose={onClose}
        open={visible}
        className={styles.drawerContainer}
      >
        <div>
          <Collapse
            bordered={false}
            defaultActiveKey={['1', '2', '3', '4', '5', '6', '7']}
            expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
          >
            <Panel header="Sort By" key="1">
              <SortingRadios
                onChange={(val) => {
                  setDrawerSortByDistance(val);
                  WebAnalytics.contentSorted(val ? 'nearby' : 'most_recent');
                }}
                drawerSortByDistance={drawerSortByDistance}
              />
            </Panel>
            <Panel
              header={`Variation ${productFilterCount ? `(${productFilterCount})` : ''}`}
              key="2"
            >
              <ProductRadios
                {...commonDrawerProps}
                productFamilyProducts={productFamilyProducts}
                onChange={(productSku) => {
                  setDrawerProductSku(productSku);
                  WebAnalytics.contentFiltered('product', productSku);
                }}
              />
            </Panel>
            <Panel
              header={`Seasons ${seasonGroupFilterCount ? `(${seasonGroupFilterCount})` : ''}`}
              key="3"
            >
              <SeasonGroupRadios
                {...commonDrawerProps}
                onChange={(seasonGroupName) => {
                  setDrawerSeasonGroupName(seasonGroupName);
                  WebAnalytics.contentFiltered('season', seasonGroupName);
                }}
              />
            </Panel>
            <Panel
              header={`Species ${speciesFilterCount ? `(${speciesFilterCount})` : ''}`}
              key="4"
            >
              <SpeciesRadios
                {...commonDrawerProps}
                onChange={(speciesName) => {
                  setDrawerSpeciesName(speciesName);
                  WebAnalytics.contentFiltered('species', speciesName);
                }}
              />
            </Panel>
            <Panel
              header={`Technique ${techniquesTotalCount ? `(${techniquesTotalCount})` : ''}`}
              key="5"
            >
              <TechniqueCheckboxes
                {...commonDrawerProps}
                drawerTechniques={drawerTechniques}
                onChange={(techniques) => {
                  setDrawerTechniques(techniques);
                  webAnalyticsCheckboxHelper('technique', drawerTechniques, techniques);
                }}
              />
            </Panel>
            <Panel
              header={`Structure ${structuresTotalCount ? `(${structuresTotalCount})` : ''}`}
              key="6"
            >
              <StructureCheckboxes
                {...commonDrawerProps}
                drawerStructures={drawerStructures}
                onChange={(structures) => {
                  setDrawerStructures(structures);
                  webAnalyticsCheckboxHelper('structure', drawerStructures, structures);
                }}
              />
            </Panel>
            <Panel header={`Images/Video ${mediaTotalCount ? `(${mediaTotalCount})` : ''}`} key="7">
              <MediaCheckboxes
                {...commonDrawerProps}
                drawerMedia={drawerMedia}
                onChange={(media) => {
                  setDrawerMedia(media);
                  webAnalyticsCheckboxHelper('media', drawerMedia, media);
                }}
              />
            </Panel>
          </Collapse>
        </div>

        <div className={styles.buttonGroup}>
          <OmniaButton
            onClick={() => {
              resetAllValues();
              onClose();
            }}
            isDisabled={totalDrawerFiltersApplied === 0}
            kind="tertiary"
            size="lg"
            block
          >
            Clear All {totalDrawerFiltersApplied > 0 && `(${totalDrawerFiltersApplied})`}
          </OmniaButton>
          <OmniaButton
            className={styles.submitButton}
            onClick={() => {
              onClose();
              onFormSubmit();
            }}
            kind="primary"
            size="lg"
            block
          >
            Apply
          </OmniaButton>
        </div>
      </Drawer>
    </>
  );
};
