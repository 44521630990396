import { EmblaCarouselType } from 'embla-carousel';
import { getSelectedCarouselIndex } from '../home_user_dashboard/home_promo_carousel';
import { imgSrcTransformed, ProductDetailMedia } from './product_detail_images';
import { LeftOutlined, RightOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { NextButton, PrevButton } from '../home_user_dashboard/carousel_buttons';
import classNames from 'classnames';
import iconPlay from '../../assets/icon-play.svg';
import img360 from './360.svg';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './product_detail_images.less';
import SvgOpenInNewWindow from '../svg/open_in_new_window';

export interface ProductDetailMediaCarouselProps {
  allMedia: ProductDetailMedia[];
  onMediaClick: (media: ProductDetailMedia, i: number) => void;
  selectedPanelIndex: number;
  showNewWindowIcon: boolean;
  emblaRef: any;
  emblaApi: EmblaCarouselType;
}

export const ProductDetailMediaCarousel = (props: ProductDetailMediaCarouselProps) => {
  const { emblaRef, emblaApi, onMediaClick, selectedPanelIndex, allMedia, showNewWindowIcon } =
    props;

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [showButtons, setShowButtons] = useState(false);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    const slideCountInView = emblaApi.slidesInView().length - 1;
    const currentIndex = getSelectedCarouselIndex(emblaApi);
    const nextIndex = currentIndex - slideCountInView;
    emblaApi.scrollTo(nextIndex);
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    const slideCountInView = emblaApi.slidesInView().length - 1;
    const currentIndex = getSelectedCarouselIndex(emblaApi);
    const nextIndex = currentIndex + slideCountInView;
    emblaApi.scrollTo(nextIndex);
  }, [emblaApi]);

  const onSelect = useCallback((carouselApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!carouselApi.canScrollPrev());
    setNextBtnDisabled(!carouselApi.canScrollNext());
  }, []);

  useEffect(
    function syncButtonState() {
      if (!emblaApi) {
        return;
      }

      onSelect(emblaApi);
      emblaApi.on('reInit', onSelect);
      emblaApi.on('select', onSelect);
    },
    [emblaApi, onSelect]
  );

  useEffect(
    function checkSlidesOutOfViewport() {
      if (!emblaApi) {
        return;
      }
      setShowButtons(emblaApi.canScrollNext());
    },
    [emblaApi]
  );

  if (!allMedia || allMedia.length === 0) {
    return null;
  }

  return (
    <div className={styles.carousel}>
      {showButtons && (
        <PrevButton
          onClick={onPrevButtonClick}
          className={classNames(styles.carouselButton, {
            [styles.carouselButton__disabled]: prevBtnDisabled,
          })}
          size={26}
        >
          <LeftOutlined />
        </PrevButton>
      )}
      <div ref={emblaRef} className={styles.carousel__viewport}>
        <div className={styles.carousel__container}>
          {allMedia.map((pdpMedia, i) => {
            const { type } = pdpMedia;
            const selected = i === selectedPanelIndex;
            if (type === 'product_image') {
              return (
                <li
                  className={classNames(styles.item, styles.image, {
                    [styles.item__selected]: selected,
                  })}
                  key={pdpMedia.src}
                >
                  <img
                    src={imgSrcTransformed(pdpMedia.src)}
                    alt={pdpMedia.alt_text}
                    onClick={() => onMediaClick(pdpMedia, i)}
                  />
                </li>
              );
            } else if (type === 'product_360_images') {
              return (
                <li
                  className={classNames(styles.item, styles.image, {
                    [styles.item__selected]: selected,
                  })}
                  key={pdpMedia.src[0]}
                >
                  <button onClick={() => onMediaClick(pdpMedia, i)} className={styles.button360}>
                    <img src={img360} alt="360 degree photos" />
                  </button>
                </li>
              );
            } else if (type === 'omnia_media') {
              const m = pdpMedia.media;
              const isLinkToNewPage =
                m.media_type === 'PrismicVideo' || m.media_type === 'BassUArticle';
              return (
                <li
                  className={classNames(styles.item, styles.mediaItem, {
                    [styles.item__selected]: selected,
                  })}
                  key={m.media_item.title}
                  onClick={() => onMediaClick(pdpMedia, i)}
                >
                  {showNewWindowIcon && isLinkToNewPage ? (
                    <SvgOpenInNewWindow className={styles.playIcon} />
                  ) : (
                    <img src={iconPlay} className={styles.playIcon} />
                  )}
                  <img
                    src={m.media_item.thumbnail}
                    alt={m.media_item.title}
                    className={styles.mediaItemImg}
                  />
                </li>
              );
            } else if (type === 'product_video') {
              return (
                <li
                  className={classNames(styles.item, styles.image, {
                    [styles.item__selected]: selected,
                  })}
                  key={pdpMedia.src}
                >
                  <button className={styles.buttonVideo} onClick={() => onMediaClick(pdpMedia, i)}>
                    <VideoCameraOutlined />
                    <span className={styles.buttonVideoText}>Video</span>
                  </button>
                </li>
              );
            }
          })}
        </div>
      </div>
      {showButtons && (
        <NextButton
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
          className={classNames(styles.carouselButton, {
            [styles.carouselButton__disabled]: nextBtnDisabled,
          })}
          size={26}
        >
          <RightOutlined />
        </NextButton>
      )}
    </div>
  );
};
