import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWaypointGreatSpotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    viewBox="0 0 26 25"
    fill="none"
    {...props}
  >
    <path
      fill="#E84B25"
      stroke="#fff"
      strokeWidth={2}
      d="M18.056 1.293 17.763 1H8.237l-.293.293-6.151 6.15-.293.294v9.526l.293.293 6.15 6.151.294.293h9.526l.293-.293 6.151-6.15.293-.294V7.737l-.293-.293z"
    />
    <g clipPath="url(#waypoint_great_spot_icon_svg__a)">
      <path
        fill="#fff"
        d="M12.29 10.687s-.78 2.257-.51 4.265l-.819-.443s-.971 1.696.575 1.813h2.88s.738-.04.789-.809v-5.93s.064-.814-.793-.892c-.05-.005-2.697-.017-2.76-.013-.832.052-.857.796-.857.796v1.33l-2.85 1.774v-3.16s.05-2.958 3.067-3.14h3.976s3.067.09 3.067 3.3v5.923s0 3.039-3.003 3.221h-3.853c-.584 0-1.191-.047-1.73-.3-1.242-.582-1.725-2.004-1.398-3.312.066-.265.164-.522.286-.765.36-.72.915-1.32 1.52-1.841.764-.657 1.6-1.227 2.413-1.817"
      />
    </g>
    <defs>
      <clipPath id="waypoint_great_spot_icon_svg__a">
        <path fill="#fff" d="M7.944 6.278h10.111v12.444H7.945z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWaypointGreatSpotIcon;
