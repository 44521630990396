import { FetchWaterbodyRecommendationsParams } from '@omniafishing/core';
import { apiV1 } from '../lib/api';

export const QueryKeys = {
  ME: {
    BASE: ['me'],
    WATERBODIES: ['me', 'waterbodies'],
    AMBASSADORS: ['me', 'ambassadors'],
  },
  WATERBODIES: {
    BASE: ['waterbodies'],
    BY_SLUG_OR_ID: (slug_or_id: string) => ['waterbodies', slug_or_id],
    LAKE_BREAKDOWN_VIDEO: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyLakeBreakdownVideoFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'lake_breakdown_video', params],
    RECOMMENDATIONS: (slug_or_id: string, params: FetchWaterbodyRecommendationsParams) => [
      ...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id),
      'recommendations',
      params,
    ],
    TOP_SPECIES: (
      slug_or_id: string,
      params: Parameters<typeof apiV1.waterbodyTopSpeciesFetch>[1]
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'top_species', params],
    WATERBODY_TECHNIQUES: (
      slug_or_id: string,
      params: {
        species: string;
        season_group: string;
      }
    ) => [...QueryKeys.WATERBODIES.BY_SLUG_OR_ID(slug_or_id), 'waterbody_techniques', params],
  },
  SALES_PRO_PRODUCT_FAMILIES: ['sales_pro_product_families'],
};
