import React from 'react';

const SvgSearch = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(1 1)" stroke="currentColor" fill="none" fillRule="evenodd">
      <circle cx={5.369} cy={5.369} r={5.369} />
      <circle cx={5.369} cy={5.369} r={5.369} />
      <path d="M13.919 14L9.097 9.178M.152 5.004c1.286 0 1.286 1.419 2.583 1.419 1.297 0 1.287-1.419 2.573-1.419 1.287 0 1.297 1.419 2.583 1.419 1.287 0 1.287-1.419 2.543-1.419" />
    </g>
  </svg>
);

export default SvgSearch;
