import React, { MouseEventHandler } from 'react';
import { statesData } from './states_data';
import USState from './us_state';
interface USMapProps {
  onClick?: MouseEventHandler;
  width?: number;
  height?: number;
  title?: string;
  defaultFill?: string;
  customize?: Customize;
}

type Customize = Record<string, { fill?: string; clickHandler?: MouseEventHandler }>;

export const USMap = (props: USMapProps) => {
  const { onClick, customize, width, height, defaultFill, title } = props;

  const clickHandler = (stateAbbreviation: any) => {
    onClick(stateAbbreviation);
  };

  const fillStateColor = (state: string) => {
    if (customize && customize[state] && customize[state].fill) {
      return customize[state].fill;
    }

    return defaultFill || '#D3D3D3';
  };

  const stateClickHandler = (state: keyof Customize) => {
    if (customize && customize[state] && customize[state].clickHandler) {
      return customize[state].clickHandler;
    }
    return clickHandler;
  };

  const buildPaths = () => {
    const paths = [];

    for (const stateKey in statesData) {
      if (statesData[stateKey]) {
        const path = (
          <USState
            key={stateKey}
            stateName={statesData[stateKey].name}
            dimensions={statesData[stateKey].dimensions}
            state={stateKey}
            fill={fillStateColor(stateKey)}
            onClickState={stateClickHandler(stateKey)}
          />
        );
        paths.push(path);
      }
    }

    return paths;
  };

  return (
    <svg
      className="us-state-map"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 959}
      height={height || 593}
      viewBox="0 0 959 593"
    >
      <title>{title}</title>
      <g className="outlines">
        {buildPaths()}
        <g className="DC state">
          <path
            className="DC1"
            fill={fillStateColor('DC1')}
            d="M801.8,253.8 l-1.1-1.6 -1-0.8 1.1-1.6 2.2,1.5z"
          />
          <circle
            className="DC2"
            onClick={clickHandler}
            data-name={'DC'}
            fill={fillStateColor('DC2')}
            stroke="#FFFFFF"
            strokeWidth="1.5"
            cx="801.3"
            cy="251.8"
            r="5"
            opacity="1"
          >
            <title>Washington D.C.</title>
          </circle>
        </g>
      </g>
    </svg>
  );
};
