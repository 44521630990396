import { FastForwardOutlined } from '@ant-design/icons';
import { OmniaVideoItemAmbassador } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { getImgixPath } from '../../lib/imgix';
import { OmniaUrls } from '../../lib/urls';
import { isUserPublic, NAME_FALLBACK } from '../../lib/user';
import { formatSecondsToMinutes } from '../../lib/videos';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product.less';

export const VideoItemSidebarAmbassador = (
  props: Omit<VideoItemProps, 'onClick'> & {
    omnia_video_item: OmniaVideoItemAmbassador;
    selected: boolean;
    showJump: boolean;
    onClick?: () => void;
  }
) => {
  const { omnia_video_item, onJumpClick, selected, showJump, onClick } = props;
  const { item, start_sec, end_sec, comments } = omnia_video_item;
  const { image, display_name, full_name, slug } = item;
  const isLive = end_sec == null;

  return (
    <div
      className={classNames(styles.productSidebar, {
        [styles.productSidebar__selected]: selected,
        [styles.productSidebar__live]: isLive,
      })}
    >
      <div className={styles.content}>
        <div className={styles.productSidebarImg}>
          <img src={getImgixPath(image, { w: 100 })} alt={display_name} />
        </div>

        <div className={styles.productSidebarText}>
          <p className={styles.productSidebarTitle}>{full_name || NAME_FALLBACK}</p>
          {showJump && (
            <p className={styles.productSidebarJump} onClick={onJumpClick}>
              <FastForwardOutlined className={styles.productSidebarJumpIcon} />
              Jump to {formatSecondsToMinutes(start_sec)}
            </p>
          )}
        </div>

        <div className={styles.productSidebarButtonPrice}>
          {isUserPublic(item) && (
            <OmniaLinkButton
              to={OmniaUrls.ambassador(slug)}
              kind="secondary"
              size="md"
              block
              fontSize={14}
              className={styles.productSidebarButton}
              target="_blank"
              onPress={onClick}
            >
              Learn More
            </OmniaLinkButton>
          )}
        </div>
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
