import algoliasearch from 'algoliasearch';
import { getEnv } from './env';

const env = getEnv();
export const searchClient = algoliasearch(env.ALGOLIA_APPLICATION_ID, env.ALGOLIA_SEARCH_KEY);

export enum AlgoliaSearchEvents {
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
}

export enum AlgoliaNonSearchEvents {
  viewedObjectIds = 'viewedObjectIds',
  clickedObjectIds = 'clickedObjectIds',
  convertedObjectIds = 'convertedObjectIDs',
}
