import { FastForwardOutlined } from '@ant-design/icons';
import { OmniaVideoItemCategory, OmniaVideoItemSubcategory } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { OmniaUrls } from '../../lib/urls';
import { formatSecondsToMinutes } from '../../lib/videos';
import { getCategoryBySubcategoryName } from '../../redux/reference_data';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product.less';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';

export const VideoItemSidebarCategory = (
  props: VideoItemProps & {
    omnia_video_item: OmniaVideoItemCategory | OmniaVideoItemSubcategory;
    selected: boolean;
    showJump: boolean;
  }
) => {
  const { onClick, omnia_video_item, onJumpClick, selected, showJump } = props;
  const { item, start_sec, end_sec, comments, item_type } = omnia_video_item;
  const { display_name, image, name } = item;
  const isLive = end_sec == null;
  const categoryBySubcatName = useSelector(getCategoryBySubcategoryName);
  const category = categoryBySubcatName(name);

  const url =
    item_type === 'Category' ? OmniaUrls.category(item) : OmniaUrls.subcategory(category, item);

  return (
    <div
      className={classNames(styles.productSidebar, {
        [styles.productSidebar__selected]: selected,
        [styles.productSidebar__live]: isLive,
      })}
    >
      <div className={styles.content}>
        <div onClick={onClick} className={styles.productSidebarImg}>
          <img src={image} alt="" />
        </div>

        <div className={styles.productSidebarText}>
          <p className={styles.productSidebarTitle} onClick={onClick}>
            {display_name}
          </p>
          {showJump && (
            <p className={styles.productSidebarJump} onClick={onJumpClick}>
              <FastForwardOutlined className={styles.productSidebarJumpIcon} />
              Jump to {formatSecondsToMinutes(start_sec)}
            </p>
          )}
        </div>

        <div className={styles.productSidebarButtonPrice}>
          <OmniaLinkButton
            kind="secondary"
            size="md"
            block
            fontSize={14}
            className={styles.productSidebarButton}
            to={url}
            onPress={onClick}
            target="_blank"
          >
            Learn More
          </OmniaLinkButton>
        </div>
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
