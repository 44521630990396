import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGeographicLocationByIp,
  getIp,
  getIpLatitude,
  getIpLongitude,
} from '../../redux/geographic_location';

export const GeographicLocationHandler = (): null => {
  const ip = useSelector(getIp);
  const dispatch = useDispatch();
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);

  useEffect(() => {
    if (ip && !(ipLatitude && ipLongitude)) {
      dispatch(fetchGeographicLocationByIp(ip));
    }
  }, [ip, ipLatitude, ipLongitude]);

  return null;
};
