import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { getCheckoutId, updateCheckoutAttributes } from '../../redux/cart';
import { getUtmTags } from '../../redux/utm';
import { getIsMobile } from '../../redux/window';

// most likely this will be delete soon after confirming its not used
export const UserCartAttributesHandler = (): null => {
  const getGoogleClientId = () => {
    return new Promise<string>((resolve) => {
      const w = window as any;
      // gtag only makes 'ga' function available after the library loads
      // so we have to stub it if undefined here
      w.ga =
        w.ga ||
        // tslint:disable-next-line
        function () {
          (w.ga.q = w.ga.q || []).push(arguments);
        };
      w.ga.l = +new Date();

      const fallback = w.setTimeout(() => {
        // after 4 seconds, assume the script is blocked
        resolve('');
      }, 4000);
      w.ga(() => {
        // this function is called after GA library initializes
        w.clearTimeout(fallback);
        const tracker = w.ga.getAll()[0];
        const clientId = tracker && (tracker.get('clientId') as string);

        return resolve(clientId);
      });
    });
  };

  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const utmTags = useSelector(getUtmTags);
  const checkoutId = useSelector(getCheckoutId);
  const { anonymousId } = useRudderstack();
  const [googleClientId, setGoogleClientId] = useState(null as string);

  const googleTimeout = () => {
    setTimeout(() => {
      getGoogleClientId().then((clientId) => {
        if (clientId && clientId !== googleClientId) {
          setGoogleClientId(clientId);
        } else {
          googleTimeout();
        }
      });
    }, 500);
  };

  useEffect(() => {
    googleTimeout();
  }, []);

  useEffect(() => {
    if (checkoutId && googleClientId && anonymousId) {
      dispatch(
        updateCheckoutAttributes(checkoutId, {
          ...utmTags,
          device: isMobile ? 'mobile' : 'desktop',
          'google-clientID': googleClientId,
          'rudderstack-clientID': anonymousId,
        })
      );
    }
  }, [checkoutId, googleClientId, anonymousId]);

  return null;
};
