import { OmniaResponse, StateDetail } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'states';

export enum StateKeys {
  states = 'states',
  stateDetails = 'stateDetails',
  detailLoadingState = 'detailLoadingState',
}

export const initialState = {
  [StateKeys.stateDetails]: {} as { [key: string]: StateDetail },
  [StateKeys.detailLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getStateDetailByAbbr = (state: ApplicationState, abbr: string) =>
  state[reducerName][StateKeys.stateDetails][abbr.toLowerCase()];
export const getDetailLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.detailLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function StatesReducer(state = initialState, action: StatesActions) {
  switch (action.type) {
    case ReduxActions.STATE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.detailLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.STATE_FETCH_SUCCESS: {
      const currentState = action.payload.data;
      const stateDetails = { ...state[StateKeys.stateDetails] };
      stateDetails[currentState.abbr.toLowerCase()] = currentState;
      return {
        ...state,
        [StateKeys.stateDetails]: stateDetails,
        [StateKeys.detailLoadingState]: LoadingState.DONE,
      };
    }

    case ReduxActions.STATE_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.detailLoadingState]: LoadingState.ERROR,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const StatesActions = {
  STATE_FETCH_PENDING: () => createAction(ReduxActions.STATE_FETCH_PENDING),
  STATE_FETCH_SUCCESS: (response: OmniaResponse<StateDetail>) =>
    createAction(ReduxActions.STATE_FETCH_SUCCESS, response),
  STATE_FETCH_ERROR: (err: any) => createAction(ReduxActions.STATE_FETCH_ERROR, err),
};
export type StatesActions = ActionsUnion<typeof StatesActions>;

export function fetchState(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(StatesActions.STATE_FETCH_PENDING());

    return apiV1
      .stateFetch(slug)
      .then((response) => {
        return dispatch(StatesActions.STATE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchState ${slug}`, error);
        return dispatch(StatesActions.STATE_FETCH_ERROR(error));
      });
  };
}
