import { applyMiddleware, combineReducers, createStore, Reducer, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ApplicationState } from '../helpers/app_state';
import ArticlesReducer, { reducerName as articlesReducerName } from './articles';
import AuthReducer, { reducerName as authReducerName } from './auth';
import BrandsReducer, { reducerName as brandsReducerName } from './brands';
import CartReducer, { reducerName as cartReducerName } from './cart';
import CategoryReducer, { reducerName as categoryReducerName } from './category';
import cookieStorageMiddleware from './cookie_storage_middleware';
import DealsReducer, { reducerName as dealsReducerName } from './deals';
import EventNotificationsReducer, {
  reducerName as eventNotificationsReducerName,
} from './event_notifications';
import FishingReportsReducer, { reducerName as fishingReportsReducerName } from './fishing_reports';
import FishingReportModalReducer, {
  reducerName as fishingReportModalReducerName,
} from './fishing_report_modal';
import FlashMessageReducer, { reducerName as flashMessageReducerName } from './flash_message';
import GeographicLocationReducer, {
  reducerName as geographicLocationReducerName,
} from './geographic_location';
import IntercomMiddleware from './intercom_middleware';
import InventoryModalReducer, { reducerName as inventoryModalReducerName } from './inventory_modal';
import LandingPageReducer, { reducerName as landingPageReducerName } from './landing_page';
import ListReducer, { reducerName as listReducerName } from './list';
import NewsReducer, { reducerName as newsReducerName } from './news';
import OrganizationsReducer, { reducerName as organizationsReducerName } from './organizations';
import PagesReducer, { reducerName as pagesReducerName } from './pages';
import ProductsTaggedReducer, { reducerName as productsTaggedReducerName } from './products_tagged';
import ProductDetailReducer, { reducerName as productDetailReducerName } from './product_detail';
import ProductFamilyQuickModalReducer, {
  reducerName as productFamilyQuickModalReducerName,
} from './product_family_quick_modal';
import ProUpsellModal, { reducerName as ProUpsellModalReducerName } from './pro_upsell_modal';
import RecommendationsReducer, {
  reducerName as recommendationsReducerName,
} from './recommendations';
import ReferenceDataReducer, { reducerName as referenceDataReducerName } from './reference_data';
import RouterReducer, { reducerName as routerReducerName } from './router';
import ShippingReducer, { reducerName as shippingReducerName } from './shipping';
import SignupLoginReducer, {
  reducerName as signupLoginModalReducerName,
} from './signup_login_modal';
import SpeciesReducer, { reducerName as speciesReducerName } from './species';
import StatesReducer, { reducerName as statesReducerName } from './states';
import StylesReducer, { reducerName as stylesReducerName } from './styles';
import TournamentsReducer, { reducerName as tournamentsReducerName } from './tournaments';
import trackjsMiddleware from './trackjs_middleware';
import UserReducer, { reducerName as userReducerName } from './user';
import { UserPreferencesReducer, userPreferencesReducerName } from './user_preferences';
import { UserPreferencesMiddleware } from './user_preferences_middleware';
import UserProfileReducer, { reducerName as userProfileReducerName } from './user_profile';
import { reducerName as utmReducerName, UtmReducer } from './utm';
import VideosReducer, { reducerName as videosReducerName } from './videos';
import WaterbodyReducer, { reducerName as waterbodyReducerName } from './waterbody';
import { WebViewMiddleware } from './webview_middleware';
import webAnalyticsMiddleware from './web_analytics_middleware';
import WindowReducer, { reducerName as windowReducerName } from './window';
import WishlistReducer, { reducerName as wishlistReducerName } from './wishlist';
export function configureStore(initialState: Partial<ApplicationState>): Store<ApplicationState> {
  const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    [articlesReducerName]: ArticlesReducer,
    [authReducerName]: AuthReducer,
    [brandsReducerName]: BrandsReducer,
    [cartReducerName]: CartReducer,
    [categoryReducerName]: CategoryReducer,
    [dealsReducerName]: DealsReducer,
    [eventNotificationsReducerName]: EventNotificationsReducer,
    [fishingReportModalReducerName]: FishingReportModalReducer,
    [fishingReportsReducerName]: FishingReportsReducer,
    [flashMessageReducerName]: FlashMessageReducer,
    [geographicLocationReducerName]: GeographicLocationReducer,
    [inventoryModalReducerName]: InventoryModalReducer,
    [landingPageReducerName]: LandingPageReducer,
    [listReducerName]: ListReducer,
    [newsReducerName]: NewsReducer,
    [organizationsReducerName]: OrganizationsReducer,
    [pagesReducerName]: PagesReducer,
    [productDetailReducerName]: ProductDetailReducer,
    [productFamilyQuickModalReducerName]: ProductFamilyQuickModalReducer,
    [productsTaggedReducerName]: ProductsTaggedReducer,
    [ProUpsellModalReducerName]: ProUpsellModal,
    [recommendationsReducerName]: RecommendationsReducer,
    [referenceDataReducerName]: ReferenceDataReducer,
    [routerReducerName]: RouterReducer,
    [signupLoginModalReducerName]: SignupLoginReducer,
    [shippingReducerName]: ShippingReducer,
    [speciesReducerName]: SpeciesReducer,
    [statesReducerName]: StatesReducer,
    [stylesReducerName]: StylesReducer,
    [tournamentsReducerName]: TournamentsReducer,
    [userPreferencesReducerName]: UserPreferencesReducer,
    [userProfileReducerName]: UserProfileReducer,
    [userReducerName]: UserReducer,
    [utmReducerName]: UtmReducer,
    [videosReducerName]: VideosReducer,
    [waterbodyReducerName]: WaterbodyReducer,
    [windowReducerName]: WindowReducer,
    [wishlistReducerName]: WishlistReducer,
  });

  const composedMiddleware = composeWithDevTools(
    applyMiddleware(
      thunk,
      cookieStorageMiddleware,
      webAnalyticsMiddleware,
      trackjsMiddleware,
      WebViewMiddleware,
      UserPreferencesMiddleware,
      IntercomMiddleware
    )
  );

  const store = createStore(rootReducer, initialState as ApplicationState, composedMiddleware);

  return store;
}
