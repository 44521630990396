import { DownOutlined } from '@ant-design/icons';
import { FishingReport, Product } from '@omniafishing/core';
import { PopoverProps } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import { WebAnalytics } from '../../lib/web_analytics';
import { FishingReportsStatsPopover } from '../fishing_reports_stats/fishing_reports_stats_popover';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgFishingReport from '../svg/fishing_report';
import styles from './fishing_reports_widget.less';

interface FishingReportsWidgetsProps {
  buttonClassName?: string;
  fishingReports: FishingReport[];
  getPopupContainer?: () => HTMLElement;
  hideTechniquesSection?: boolean;
  onProductSkuChange?: (val: string) => void;
  onSpeciesNameChange?: (val: string) => void;
  onTechniqueNameChange?: (val: string[]) => void;
  onViewAllReportsClick: () => void;
  placement?: PopoverProps['placement'];
  products: Product[];
}

export const FishingReportsWidget = React.memo((props: FishingReportsWidgetsProps) => {
  const {
    buttonClassName,
    fishingReports,
    getPopupContainer,
    hideTechniquesSection,
    onProductSkuChange,
    onSpeciesNameChange,
    onTechniqueNameChange,
    onViewAllReportsClick,
    placement,
    products,
  } = props;
  const [visible, setVisible] = useState(false);
  const { isDesktop } = useResponsive();

  return (
    <div className={styles.popoverContainer}>
      <OmniaButton
        onPress={() => {
          setVisible(!visible);
          WebAnalytics.productDetailPageClick('[stats].(count)');
        }}
        size="lg"
        kind="secondary"
        fontSize={14}
        className={classNames(styles.noPadding, buttonClassName)}
      >
        <FishingReportsStatsPopover
          className={styles.popoverButtonContent}
          fishingReports={fishingReports}
          getPopupContainer={getPopupContainer}
          hideTechniquesSection={hideTechniquesSection}
          onProductSkuChange={onProductSkuChange}
          onSpeciesNameChange={onSpeciesNameChange}
          onTechniqueNameChange={onTechniqueNameChange}
          onViewAllReportsClick={onViewAllReportsClick}
          onVisibleChange={(vis: boolean) => setVisible(vis)}
          placement={placement}
          products={products}
          visible={visible}
        >
          {isDesktop && (
            <span
              role="img"
              aria-label="fishing-reports-icon"
              className={styles.fishingReportsIcon}
            >
              <SvgFishingReport />
            </span>
          )}
          Fishing Reports ({fishingReports?.length})
          <DownOutlined
            className={classNames(styles.downIcon, { [styles.downIcon__rotate]: visible })}
          />
        </FishingReportsStatsPopover>
      </OmniaButton>
    </div>
  );
});
