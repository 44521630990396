import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerDepthContours = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36" {...props}>
    <path
      d="M22.895 16.557c-.568-.362-1.15-.635-1.713-.897a30.35 30.35 0 0 1-.682-.326c-.154-.077-.31-.15-.466-.224-.27-.127-.541-.25-.791-.407-1.148-.72-2.256-1.033-3.392-.955-.137 0-.274.02-.412.06-2.294.678-2.944 2.582-3.233 3.955-.323 1.533.156 2.871 1.35 3.768.708.533 1.595.839 2.712.937 1.035.091 1.887.197 2.636.661.852.526 1.606.688 2.21.688.298 0 .559-.039.776-.093.906-.223 1.668-.818 2.203-1.723 1.146-1.937.631-4.276-1.198-5.442zm-1.298 3.966c-.125.212-.271.352-.399.384-.172.045-.449-.048-.769-.246-1.288-.796-2.583-.967-3.908-1.083-.561-.049-.96-.168-1.222-.365-.198-.148-.366-.325-.255-.852.242-1.149.581-1.536 1.071-1.724.491-.016.987.146 1.587.522.374.234.759.415 1.099.574.135.063.27.127.401.193.248.124.499.241.751.359.5.233.973.455 1.382.716.622.397.53 1.067.262 1.521Z"
      className="layer_depth_contours_svg__cls-1"
    />
    <path
      d="M26.359 13.335c-2.327-1.475-4.966-3.145-7.612-4.664-1.318-.758-2.894-1.159-4.556-1.162-.012-.003-.021 0-.032 0-.1-.007-.216.005-.325.027l-.312.059a9.96 9.96 0 0 0-.873.186c-1.667.453-2.912 1.632-3.505 3.321a97.683 97.683 0 0 0-2.286 7.367c-.687 2.592.435 5.01 2.996 6.467 1.872 1.066 3.913 2.101 6.425 3.256 1.376.633 2.846.946 4.453.946.601 0 1.221-.044 1.862-.131 1.817-.247 3.26-.767 4.538-1.632 1.999-1.354 2.555-3.348 2.799-4.729.226-1.276.414-2.97-.124-4.74-.594-1.962-1.754-3.5-3.448-4.571m.717 8.808c-.249 1.409-.718 2.256-1.568 2.831-.896.607-1.946.976-3.305 1.161-1.795.243-3.337.056-4.712-.577-2.434-1.119-4.404-2.117-6.202-3.142-1.428-.812-1.976-1.89-1.628-3.204a95.31 95.31 0 0 1 2.22-7.149c.274-.783.789-1.282 1.528-1.482.207-.057.42-.093.632-.132l.209-.039c1.133.011 2.188.279 3.053.776 2.593 1.488 5.203 3.141 7.506 4.6 1.089.688 1.837 1.685 2.224 2.963.285.937.298 1.952.044 3.395Z"
      className="layer_depth_contours_svg__cls-1"
    />
    <path
      d="M35.722 20.521c-.202-3.668-1.758-6.716-4.497-8.814-3.037-2.327-6.233-4.521-9.325-6.643l-1.62-1.113c-2.682-1.845-5.732-2.004-7.815-1.966h-.012c-.1-.004-.191.003-.288.019-.216.036-.433.068-.648.101a18.38 18.38 0 0 0-1.592.284C6.424 3.22 4.52 5.608 4.418 9.295c-.059 2.092-.549 4.198-1.5 6.439C1.794 18.382.753 20.993.365 23.801c-.147 1.07-.422 3.062.909 4.686 1.255 1.531 3.144 1.567 3.95 1.583 2.15.04 4.259.585 6.446 1.665 2.761 1.361 5.193 2.034 7.656 2.115.437.015.874.046 1.312.076.644.046 1.305.093 1.98.093.19 0 .382-.004.574-.013 3.801-.165 6.971-1.152 9.688-3.019 1.546-1.062 2.492-2.707 2.663-4.633.152-1.722.292-3.761.178-5.835v.002ZM32.657 26.1c-.095 1.062-.584 1.927-1.416 2.498-2.257 1.55-4.931 2.371-8.174 2.513-.709.029-1.445-.021-2.224-.076-.473-.033-.946-.066-1.419-.082-2.037-.067-4.093-.645-6.47-1.818-2.578-1.271-5.089-1.914-7.675-1.964-.988-.019-1.467-.159-1.763-.521-.443-.542-.44-1.279-.278-2.451.339-2.46 1.265-4.775 2.35-7.331 1.079-2.545 1.661-5.065 1.729-7.492.066-2.39 1.047-3.635 3.278-4.164.442-.105.896-.171 1.348-.238.193-.028.388-.058.581-.089 2.663-.037 4.574.397 6.112 1.455l1.624 1.115c3.061 2.101 6.225 4.272 9.202 6.555 2.047 1.567 3.211 3.875 3.364 6.672.105 1.891-.027 3.801-.17 5.419Z"
      className="layer_depth_contours_svg__cls-1"
    />
  </svg>
);
export default SvgLayerDepthContours;
