import { mockFishingReport, mockProduct } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import OmniaCarousel from '../carousel/omnia_carousel';
import Loading from '../loading/loading';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './loading_card_carousel.less';

interface LoadingCardCarouselProps {
  blockButtons?: boolean;
  buttonActions?: string[];
  listItemClassName?: string;
  loadingText?: string;
  numberOfCards: number;
  placeholderHeight?: number;
  placeholderWidth?: number;
  sectionTitle?: string;
  showLines?: boolean;
  wrapperClassName?: string;
}

export const LoadingCardCarousel = (props: LoadingCardCarouselProps) => {
  const {
    blockButtons = true,
    buttonActions = [],
    listItemClassName,
    loadingText,
    numberOfCards,
    placeholderHeight,
    placeholderWidth,
    sectionTitle,
    showLines = true,
    wrapperClassName,
  } = props;

  const loadingCards = Array(numberOfCards)
    .fill('tempFillerString')
    .map((_, i) => {
      return {
        ...mockFishingReport({
          featured_product: {
            ...mockProduct({
              sku: `loading-${i}`,
            }),
          },
        }),
      };
    });

  return (
    <>
      <div className={classNames(styles.wrapper, wrapperClassName)}>
        <Loading text={loadingText || 'Loading Products'} className={styles.loading} />
        {sectionTitle && <p className={styles.sectionTitle}>{sectionTitle}</p>}
        <ol className={styles.list}>
          <OmniaCarousel key={String(numberOfCards)}>
            {loadingCards.map((fr) => (
              <li
                className={classNames(styles.item, listItemClassName)}
                key={fr.featured_product.sku}
              >
                <div className={classNames(styles.product, styles.skeleton)}>
                  <div
                    className={styles.productImg}
                    style={{
                      width: placeholderWidth || 250,
                      height: placeholderHeight || 250,
                    }}
                  />

                  {showLines && <p className={styles.productTitle}>Loading\nLoading</p>}

                  <div className={styles.bottom}>
                    {showLines && (
                      <p className={styles.price}>
                        <span>$0.00</span>
                      </p>
                    )}
                    <div className={styles.productButtons}>
                      {buttonActions.map((action, i) => (
                        <React.Fragment key={action + i}>
                          <OmniaButton
                            size="lg"
                            kind="tertiary"
                            className={styles.reportButton}
                            fontSize={14}
                            block={blockButtons}
                            isDisabled
                          >
                            {action}
                          </OmniaButton>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </OmniaCarousel>
        </ol>
      </div>
    </>
  );
};
