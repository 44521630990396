import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebAnalytics } from '../lib/web_analytics';
import { getRudderstackAnonymousId, UserActions } from '../redux/user';

export function useRudderstack() {
  const rudderstackAnonymousId = useSelector(getRudderstackAnonymousId);
  const [anonymousId, setAnonymousId] = useState<string>(rudderstackAnonymousId);
  const [loaded, setLoaded] = useState(false);
  const attempts = useRef(0);
  const dispatch = useDispatch();

  const checkForRudderstackAnonymousId = () => {
    setTimeout(() => {
      if (loaded) {
        return;
      }

      const anonymous_id = WebAnalytics.getRudderstackAnonymousId();
      const probablyBlocked = attempts.current > 3;
      if (anonymous_id || probablyBlocked) {
        setAnonymousId(anonymous_id);
        dispatch(UserActions.RUDDERSTACK_ANONYMOUS_ID_SET(anonymous_id));
        setLoaded(true);
      } else {
        attempts.current = attempts.current + 1;
        checkForRudderstackAnonymousId();
      }
    }, 500);
  };

  useEffect(() => {
    checkForRudderstackAnonymousId();
  }, []);

  return {
    anonymousId,
    loaded,
  };
}
