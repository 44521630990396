import * as React from 'react';
const SvgLayerWind = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.849 9.824c0-1.656 1.272-3.092 2.95-3.092 1.667 0 2.951 1.424 2.951 3.092 0 1.656-1.272 3.092-2.95 3.092h-4.345a.75.75 0 0 1 0-1.5H18.8c.76 0 1.45-.671 1.45-1.592 0-.925-.695-1.592-1.45-1.592-.76 0-1.451.672-1.451 1.592a.75.75 0 0 1-1.5 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.203 7.047c0-2.015 1.49-3.797 3.5-3.797 1.997 0 3.502 1.767 3.502 3.797 0 2.015-1.491 3.797-3.501 3.797H5.455a.75.75 0 0 1 0-1.5h6.249c1.038 0 2.001-.96 2.001-2.297 0-1.346-.97-2.297-2.001-2.297-1.038 0-2.001.959-2.001 2.297a.75.75 0 0 1-1.5 0M8.202 17.059c0 2.014 1.491 3.796 3.501 3.796 1.997 0 3.502-1.767 3.502-3.797 0-2.014-1.492-3.796-3.502-3.796H3a.75.75 0 0 0 0 1.5h8.703c1.038 0 2.002.959 2.002 2.296 0 1.346-.97 2.297-2.002 2.297-1.037 0-2-.959-2-2.297a.75.75 0 0 0-1.5 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLayerWind;
