import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import styles from './clarity_range.less';
import { Popover } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

interface ClarityRangeProps {
  clarities: number[];
  clarityScaleOnly?: boolean;
}

const allClarities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ClarityRange = (props: ClarityRangeProps) => {
  const { clarities, clarityScaleOnly } = props;
  const first = clarities[0];
  const last = clarities[clarities.length - 1];
  const singleClarityValue = clarities.length === 1;

  const content = (
    <div>
      <div
        className={classNames(styles.scaleLegend, {
          [styles.scaleLegend__widgetOnly]: clarityScaleOnly,
        })}
      >
        <div>CLEAR</div>
        <div>MURKY</div>
      </div>

      <div className={styles.scale}>
        {allClarities.map((clarity) => {
          const i = _.indexOf(clarities, clarity);
          const isActive = clarities.indexOf(clarity) > -1;
          const isPartOfRange =
            isActive && (clarities[i - 1] === clarity - 1 || clarities[i + 1] === clarity + 1);
          const isRangeEnd = isPartOfRange && clarities[i + 1] !== clarity + 1;
          const isRangeStart = isPartOfRange && clarities[i] - 1 !== clarities[i - 1];

          // this when it is a single value, not range
          // it removes left border of the number ahead of the clarity value
          const isActivePlusOne = clarities.indexOf(clarity - 1) > -1;

          return (
            <div
              className={classNames(styles.values, {
                [styles.active]: isActive,
                [styles.active__singleValue]: isActive && singleClarityValue,
                [styles.nextToActive__singleValue]: isActivePlusOne && singleClarityValue,
                [styles.range]: isPartOfRange,
                [styles.rangeEnd]: isRangeEnd,
                [styles.rangeStart]: isRangeStart,
              })}
              key={clarity}
            >
              {clarity}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <div
        className={classNames(styles.dotContainer, {
          [styles.dotContainer__widgetOnly]: clarityScaleOnly,
        })}
      >
        <div
          className={classNames(styles.dot, {
            [styles.zero]: first === 0,
            [styles.one]: first === 1,
            [styles.two]: first === 2,
            [styles.three]: first === 3,
            [styles.four]: first === 4,
            [styles.five]: first === 5,
            [styles.six]: first === 6,
            [styles.seven]: first === 7,
            [styles.eight]: first === 8,
            [styles.nine]: first === 9,
            [styles.ten]: first === 10,
          })}
        ></div>
        <div
          className={classNames(styles.dot, styles.last, {
            [styles.zero]: last === 0,
            [styles.one]: last === 1,
            [styles.two]: last === 2,
            [styles.three]: last === 3,
            [styles.four]: last === 4,
            [styles.five]: last === 5,
            [styles.six]: last === 6,
            [styles.seven]: last === 7,
            [styles.eight]: last === 8,
            [styles.nine]: last === 9,
            [styles.ten]: last === 10,
          })}
        ></div>
      </div>
      <div className={classNames({ [styles.firstAndLast__widgetOnly]: clarityScaleOnly })}>
        {first}–{last}
      </div>
      {clarityScaleOnly ? (
        content
      ) : (
        <Popover
          overlayClassName={styles.clarityPopover}
          content={content}
          trigger="click"
          arrowPointAtCenter
        >
          <InfoCircleOutlined className={styles.infoSvg} />
        </Popover>
      )}
    </>
  );
};
