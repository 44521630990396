import { OmniaVideo, OmniaVideoItem, ProductFamily } from '@omniafishing/core';
import { useDispatch } from 'react-redux';
import { WebAnalytics } from '../../lib/web_analytics';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { ProductFamilyQuickModalActions } from '../../redux/product_family_quick_modal';
import { webAnalyticsVideoItemClick } from './use_omnia_video_add_to_cart';

export const useOmniaVideoOpenProductFamilyModal = () => {
  const dispatch = useDispatch();

  const omniaVideoOpenProductFamilyModal =
    (
      product_family: ProductFamily,
      position: number,
      omnia_video_item: OmniaVideoItem,
      omnia_video: OmniaVideo,
      list_uuid: string
    ) =>
    () => {
      const lineItemCustomAttributes: LineItemCustomAttribute[] = [
        {
          key: LINE_ATTRIBUTION.OMNIA_VIDEO_ID,
          value: omnia_video.id.toString(),
        },
      ];

      if (omnia_video.affiliate?.campaign_source) {
        lineItemCustomAttributes.push({
          key: LINE_ATTRIBUTION.AFFILIATE,
          value: omnia_video.affiliate.campaign_source,
        });
      }

      dispatch(
        ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN({
          recommendedFamily: {
            product_family,
            products: product_family.products,
            contains_all_colors: true,
          },
          context: 'shopping',
          lineItemAttribution: lineItemCustomAttributes,
          list_uuid,
        })
      );
      WebAnalytics.productClick({
        productOrProductFamily: product_family,
        show_modal: true,
        position,
        list_uuid,
      });
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };
  return { omniaVideoOpenProductFamilyModal };
};
