import { Product, ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import { Select } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './rod_reel_select.less';
import { isItemNew } from './product_title';
import dayjs from 'dayjs';
import { NewBadge } from '../new_badge/new_badge';
export interface SelectOption {
  label: React.ReactNode;
  value: string;
}

interface RodAndReelSelectProps {
  shopifyProduct?: ShopifyProduct;
  products?: Product[];
  selectedVariant: ShopifyVariant | Product;
  onVariantSelected: (variant: ShopifyVariant | Product) => void;
  productFamily: ProductFamily;
}

export const RodAndReelSelect = (props: RodAndReelSelectProps) => {
  const { shopifyProduct, selectedVariant, onVariantSelected, productFamily, products } = props;

  const isProductFamilyNew = useMemo(() => {
    return isItemNew(dayjs(productFamily.published_at));
  }, [productFamily]);

  const selectOptionsFromShopifyProduct = useMemo(() => {
    if (!shopifyProduct) {
      return [];
    }
    return shopifyProduct.variants.edges.map((e) => {
      const shopifyVariant = e.node;
      const omniaProduct = productFamily.products.find((p) => p.sku === shopifyVariant.sku);
      const isNew =
        omniaProduct?.created_at &&
        isItemNew(dayjs(omniaProduct.created_at)) &&
        !isProductFamilyNew;
      return {
        label: (
          <span className={styles.selectLabel}>
            <span> {shopifyVariant.title} </span>{' '}
            {isNew && <NewBadge className={styles.newBadge} />}{' '}
          </span>
        ),
        value: shopifyVariant.sku,
      };
    });
  }, [shopifyProduct]);

  const selectOptionsFromProduct = useMemo(() => {
    if (!products) {
      return [];
    }
    return products.map((product) => {
      const isNew = isItemNew(dayjs(product.created_at)) && !isProductFamilyNew;
      return {
        label: (
          <span className={styles.selectLabel}>
            <span> {product.title} </span> {isNew && <NewBadge className={styles.newBadge} />}{' '}
          </span>
        ),
        value: product.sku,
      };
    });
  }, [products]);

  return (
    <>
      <span className={classNames(styles.optionLegend, styles.optionLegend__variantSelect)}>
        Choose model:
      </span>
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        className={styles.variantSelect}
        value={selectedVariant.sku}
        options={
          selectOptionsFromShopifyProduct.length
            ? selectOptionsFromShopifyProduct
            : selectOptionsFromProduct
        }
        dropdownMatchSelectWidth={false}
        popupClassName={styles.dropdown}
        onChange={(sku: string) => {
          let variant = null;
          if (shopifyProduct) {
            variant = shopifyProduct.variants.edges.find((n) => n.node.sku === sku)?.node;
          } else {
            variant = products.find((n) => n.sku === sku);
          }
          if (variant) {
            onVariantSelected(variant);
          }
        }}
      />
    </>
  );
};
