import { OmniaVideoItem } from '@omniafishing/core';

export const omniaVideoItemName = (ovi: OmniaVideoItem) => {
  switch (ovi.item_type) {
    case 'Brand':
    case 'Category':
    case 'Subcategory':
    case 'Species':
    case 'Technique':
      return ovi.item.display_name;
    case 'OmniaVideoContent':
      return ovi.comments;
    case 'Product':
      return ovi.item.title;
    case 'ProductFamily':
      return ovi.item.title;
    case 'User':
      return ovi.item.full_name;
    case 'Waterbody':
      return ovi.item.primary_name;
    default:
      break;
  }
};
