import { InfoCircleOutlined } from '@ant-design/icons';
import { Product } from '@omniafishing/core';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone } from '../../constants/loading_state';
import {
  cartContainsPremium,
  getLineItemsSubtotal,
  SHIPPING_EXPRESS_PRICE_DOLLARS,
  SHIPPING_FREE_CUTOFF,
  SHIPPING_GROUND_PRICE_DOLLARS,
} from '../../redux/cart';
import {
  fetchShipping,
  getLoadingState,
  getShipping,
  getShippingExpired,
} from '../../redux/shipping';
import { isUserPremium } from '../../redux/user';

interface ShippingEstimatorProps {
  product: Product;
  availableForSale: boolean;
  className?: string;
}

export const ShippingEstimator = (props: ShippingEstimatorProps) => {
  const { product, className, availableForSale } = props;

  const dispatch = useDispatch();
  const shipping = useSelector(getShipping);
  const isShippingExpired = useSelector(getShippingExpired);
  const loadingState = useSelector(getLoadingState);
  const cartHasPremium = useSelector(cartContainsPremium);
  const userIsPremium = useSelector(isUserPremium);
  const hasPremium = cartHasPremium || userIsPremium;
  const subtotalPrice = useSelector(getLineItemsSubtotal);

  useEffect(() => {
    if (isShippingExpired) {
      dispatch(fetchShipping());
    }
  }, [isShippingExpired]);

  if (!product || !isDone(loadingState) || !availableForSale) {
    return null;
  }

  const subtotalFreeShipping = parseFloat(subtotalPrice) >= SHIPPING_FREE_CUTOFF;
  const productOverShippingCutoff = parseFloat(product.price) >= SHIPPING_FREE_CUTOFF;
  let showExpress = true;
  const expressDate = dayjs(shipping.express).format('ddd, MMM D');
  const groundStartDate = dayjs(shipping.ground_start).format('MMM D');
  const groundEndDate = dayjs(shipping.ground_end).format('MMM D');
  const otherStartDate = dayjs(shipping.other_start).format('MMM D');
  const otherEndDate = dayjs(shipping.other_end).format('MMM D');
  let date = `${groundStartDate}—${groundEndDate}`;

  if (product.category_name === 'rods' || product.brand === 'plano') {
    showExpress = false;
  }
  if (!product.inventory_tracked) {
    date = `${otherStartDate}—${otherEndDate}`;
    showExpress = false;
  }

  let shippingCost = `$${SHIPPING_GROUND_PRICE_DOLLARS}`;
  if (hasPremium || subtotalFreeShipping || productOverShippingCutoff) {
    shippingCost = 'FREE';
  }
  return (
    <>
      <p className={className}>
        {shippingCost} Ground Delivery: <strong>{date}</strong>{' '}
        <Tooltip title="Standard shipping orders ship USPS First Class or FedEx Ground. The delivery estimate window given is our best estimate and delivery times may vary.">
          <InfoCircleOutlined />
        </Tooltip>
        {showExpress && (
          <>
            <br />
            {`$${SHIPPING_EXPRESS_PRICE_DOLLARS}`} Express Delivery: <strong>{expressDate}</strong>{' '}
            <Tooltip title="This estimated date is based on FedEx's 2-Day delivery schedule.">
              <InfoCircleOutlined />
            </Tooltip>
          </>
        )}
      </p>
    </>
  );
};
