import { EventHandler, SyntheticEvent, useEffect, useRef } from 'react';

/**
 * A custom React Hook that provides a declarative useEventListener.
 *
 * from both:
 * https://usehooks.com/useEventListener/
 * https://github.com/donavon/use-event-listener/blob/46471cd5fb77f9f06ce21995ca86205991b56b60/src/index.js
 */

export const useEventListener = <T extends SyntheticEvent>(
  eventName: string,
  handler: EventHandler<T>,
  element?: HTMLElement,
  options: { capture?: boolean; passive?: boolean; once?: boolean } = {}
) => {
  const savedHandler = useRef(null);
  const { capture, passive, once } = options;
  const isClient = typeof window !== 'undefined';
  const el = element || isClient ? window : null;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = el && el.addEventListener;
    if (!isSupported) {
      return;
    }

    const eventListener = (event: any) => savedHandler.current(event);
    const opts = { capture, passive, once };
    el.addEventListener(eventName, eventListener, opts);
    return () => {
      el.removeEventListener(eventName, eventListener, opts);
    };
  }, [eventName, el, capture, passive, once]);
};
