export function objectToFormData(values: Record<string, any>) {
  const formData = new FormData();

  for (const property in values) {
    if (values.hasOwnProperty(property)) {
      const val = values[property];

      if (Array.isArray(val)) {
        const propertyName = `${property}[]`;
        val.forEach((v) => {
          if (val != null) {
            formData.append(propertyName, v);
          }
        });
      } else {
        if (val != null) {
          formData.append(property, val);
        }
      }
    }
  }

  return formData;
}

export const removeAllWhitespace = (value: string) => {
  if (!value) {
    return value;
  }

  return value.replace(/\s/g, '');
};

export function trimObjectValues(values: Record<string, any>) {
  const trimmedValues = { ...values };

  for (const [key, value] of Object.entries(trimmedValues)) {
    if (typeof value !== 'string') {
      continue;
    }

    trimmedValues[key] = value.trim();
  }
  return trimmedValues;
}

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
