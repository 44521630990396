import { Media } from '@omniafishing/core';
import React, { useState } from 'react';
import styles from './dashboard_media_carousel.less';
import OmniaCarousel from '../carousel/omnia_carousel';
import { MediaCard } from '../media_card/media_card';

interface DashBoardMediaCarouselProps {
  media: Media[];
  isPanelOpen?: boolean;
  newMediaUids?: string[];
}
export const DashBoardMediaCarousel = (props: DashBoardMediaCarouselProps) => {
  const { media, isPanelOpen, newMediaUids } = props;
  const [panelRender, setPanelRender] = useState(false);

  return (
    <div className={styles.carouselContainer}>
      {isPanelOpen && (
        <OmniaCarousel
          key={media.map((m) => m.uid).join('') + panelRender}
          adaptive={false}
          resizeDebounce={900}
          resizeOnContentsReady
        >
          {media.map((m, i) => {
            return (
              <li key={m.uid} className={styles.mediaCard}>
                <MediaCard
                  media={m}
                  onImageLoad={() => setPanelRender(true)}
                  newBadge={newMediaUids.some((uid) => uid === m.uid)}
                />
              </li>
            );
          })}
        </OmniaCarousel>
      )}
    </div>
  );
};
