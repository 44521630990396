import * as React from 'react';
const SvgLongNextArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={'1em'}
    height={'1em'}
    viewBox={'0 0 5 11'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1 1 3 4.5L1 10"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLongNextArrow;
