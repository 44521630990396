import _ from 'lodash';

export interface TabData {
  title: string;
  hash: string;
  panel: React.ReactNode;
  noWrapper?: boolean;
}

export const getTabIndexByHash = (tabsContent: TabData[], hash: string) => {
  const index = _.findIndex(tabsContent, (tab) => tab.hash === hash);
  return index > 0 ? index : 0;
};

export const getTabHashByIndex = (tabsContent: TabData[], index: number) => {
  const hash = tabsContent[index].hash;
  return hash ? hash : null;
};
