import { ApolloQueryResult } from '@apollo/client';
import {
  CartPromo,
  CartUpsellProduct,
  Checkout,
  DiscountApplication,
  LineItem,
  OmniaResponse,
  ShopifyGiftCard,
} from '@omniafishing/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import { ReactNode } from 'react';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { ExecutionResultWorkaround, shopifyClientInstance } from '../apollo';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction, StringMap } from './actions_helper';
import { AuthActions } from './auth';
import { FlashMessageActions } from './flash_message';
import { CheckoutFragment } from './fragments';
import { RouterActions } from './router';

export const reducerName = 'cart';

export const PREMIUM_MEMBERSHIP_VARIANT_ID =
  process.env.PREMIUM_MEMBERSHIP_VARIANT_ID || 'gid://shopify/ProductVariant/18831480586297';
export const PREMIUM_MEMBERSHIP_VARIANT_ID_ENCODED =
  process.env.PREMIUM_MEMBERSHIP_VARIANT_ID_ENCODED ||
  'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xODgzMTQ4MDU4NjI5Nw==';
export const SHIPPING_FREE_CUTOFF = 50.0;
export const SHIPPING_GROUND_PRICE_CENTS = 500;
export const SHIPPING_GROUND_PRICE_DOLLARS = SHIPPING_GROUND_PRICE_CENTS / 100;
export const SHIPPING_EXPRESS_PRICE_DOLLARS = 6;

export type CartBannerType = 'kit_add' | 'kit_remove';

export interface CartBanner {
  type: CartBannerType;
  message: string | null;
  kitId?: string;
}

export enum StateKeys {
  cartUpsellProducts = 'cartUpsellProducts',
  cartHeaderBanners = 'cartHeaderBanners',
  checkoutId = 'checkoutId',
  completedAt = 'completedAt',
  discountErrorMessage = 'discountErrorCode',
  discounts = 'discounts',
  giftCards = 'giftCards',
  headerCheckoutId = 'headerCheckoutId',
  isOpen = 'isOpen',
  lineItems = 'lineItems',
  lineItemsSubtotal = 'lineItemsSubtotal',
  loadingState = 'loadingState',
  loadingStateAddToCart = 'loadingStateAddToCart',
  promos = 'promos',
  rebateBalance = 'rebateBalance',
  subtotalPrice = 'subtotalPrice',
  webUrl = 'webUrl',
}

export const initialState = {
  [StateKeys.cartUpsellProducts]: [] as CartUpsellProduct[],
  [StateKeys.checkoutId]: null as string,
  [StateKeys.completedAt]: null as string,
  [StateKeys.discountErrorMessage]: null as string,
  [StateKeys.discounts]: [] as DiscountApplication[],
  [StateKeys.giftCards]: [] as ShopifyGiftCard[],
  [StateKeys.headerCheckoutId]: null as string,
  [StateKeys.isOpen]: false,
  [StateKeys.lineItems]: [] as LineItem[],
  [StateKeys.lineItemsSubtotal]: null as string,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.loadingStateAddToCart]: LoadingState.NOT_STARTED,
  [StateKeys.promos]: [] as CartPromo[],
  [StateKeys.rebateBalance]: null as string,
  [StateKeys.cartHeaderBanners]: [] as CartBanner[],
  [StateKeys.subtotalPrice]: null as string,
  [StateKeys.webUrl]: null as string,
};

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState] as LoadingState;
export const getLoadingStateAddToCart = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingStateAddToCart] as LoadingState;
export const getLineItems = (state: ApplicationState) => state[reducerName][StateKeys.lineItems];
export const getLineItemsSubtotal = (state: ApplicationState) =>
  state[reducerName][StateKeys.lineItemsSubtotal];
export const getSubtotalPrice = (state: ApplicationState) =>
  state[reducerName][StateKeys.subtotalPrice];
export const getCheckoutId = (state: ApplicationState) => state[reducerName][StateKeys.checkoutId];
export const getHeaderCheckoutId = (state: ApplicationState) =>
  state[reducerName][StateKeys.headerCheckoutId];
export const getCompletedAt = (state: ApplicationState) =>
  state[reducerName][StateKeys.completedAt];
export const getWebUrl = (state: ApplicationState) => state[reducerName][StateKeys.webUrl];
export const getIsOpen = (state: ApplicationState) => state[reducerName][StateKeys.isOpen];
export const getCartPromos = (state: ApplicationState) => state[reducerName][StateKeys.promos];
export const getCartUpsellProducts = (state: ApplicationState) =>
  state[reducerName][StateKeys.cartUpsellProducts];
export const getCartBanner = (state: ApplicationState) =>
  state[reducerName][StateKeys.cartHeaderBanners];

export const cartContainsPremium = createSelector([getLineItems], (lineItems) => {
  return lineItems.some(
    (lineItem) =>
      lineItem?.variant?.id === PREMIUM_MEMBERSHIP_VARIANT_ID ||
      lineItem?.variant?.id === PREMIUM_MEMBERSHIP_VARIANT_ID_ENCODED
  );
});
export const getDiscounts = (state: ApplicationState) => state[reducerName][StateKeys.discounts];
export const getActiveDiscountCode = createSelector([getDiscounts], (discounts) => {
  const codeDiscounts = discounts.filter((discount) => discount.code != null);
  if (!codeDiscounts.length) {
    return null;
  }

  return codeDiscounts[0].code;
});
export const hasDiscounts = createSelector([getDiscounts], (discounts) => {
  return !!discounts.length;
});
export const getDiscountErrorMessage = (state: ApplicationState) =>
  state[reducerName][StateKeys.discountErrorMessage];
export const getGiftCards = (state: ApplicationState) => state[reducerName][StateKeys.giftCards];
export const hasGiftCards = createSelector([getGiftCards], (giftCards) => {
  return !!giftCards.length;
});
export const getRebateBalance = (state: ApplicationState) =>
  state[reducerName][StateKeys.rebateBalance];
export const hasRebateBalance = createSelector([getRebateBalance], (rebateBalance) => {
  return Number(rebateBalance) > 0;
});

export const sortLineItems = (lineItems: LineItem[]) =>
  _.sortBy(lineItems, (lineItem) =>
    Number(lineItem.id.replace('gid://shopify/CheckoutLineItem/', '').split('?')[0])
  );

export const filterLineItems = (lineItems: (LineItem | null)[]) =>
  lineItems.filter((l) => l != null && l.variant != null);

const prepareLineItems = (lineItems: LineItem[]) => filterLineItems(sortLineItems(lineItems));

export default function cartReducer(
  state = initialState,
  action: CartActions | AuthActions | RouterActions
) {
  switch (action.type) {
    case ReduxActions.CART_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.CART_FETCH_SUCCESS: {
      const checkout = action.payload.data.node;

      if (checkout == null) {
        return state;
      }

      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.giftCards]: checkout.appliedGiftCards,
        [StateKeys.webUrl]: checkout.webUrl,
        [StateKeys.completedAt]: checkout.completedAt,
      };
    }

    case ReduxActions.CART_FETCH_ERROR: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };
    }

    case ReduxActions.CART_ID_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.checkoutId]: action.payload.data.checkout_id,
      };
    }

    case ReduxActions.CART_ITEM_ADD_PENDING: {
      return {
        ...state,
        [StateKeys.loadingStateAddToCart]: LoadingState.PENDING,
      };
    }

    case ReduxActions.CART_ITEM_ADD_ERROR: {
      return {
        ...state,
        [StateKeys.loadingStateAddToCart]: LoadingState.ERROR,
      };
    }

    case ReduxActions.CART_ITEM_ADD_SUCCESS: {
      const { checkout } = action.payload.data.checkoutLineItemsAdd;

      if (checkout == null) {
        return state;
      }

      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));
      const isOpen = state[StateKeys.isOpen];
      return {
        ...state,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.giftCards]: checkout.appliedGiftCards,
        [StateKeys.isOpen]: isOpen ? isOpen : action.payload.notification === 'cart',
        [StateKeys.loadingStateAddToCart]: LoadingState.DONE,
      };
    }

    case ReduxActions.CART_ITEM_UPDATE_SUCCESS: {
      const { checkout } = action.payload.data.checkoutLineItemsUpdate;

      if (checkout == null) {
        return state;
      }

      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));
      return {
        ...state,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.giftCards]: checkout.appliedGiftCards,
      };
    }

    case ReduxActions.CART_ITEM_REMOVE_SUCCESS: {
      const { checkout } = action.payload.data.checkoutLineItemsRemove;

      if (checkout == null) {
        return state;
      }

      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));
      return {
        ...state,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.giftCards]: checkout.appliedGiftCards,
      };
    }

    case ReduxActions.CART_DISCOUNT_APPLY_SUCCESS: {
      const { checkout, checkoutUserErrors } = action.payload.data.checkoutDiscountCodeApplyV2;

      if (checkout == null) {
        return state;
      }

      const code = checkoutUserErrors.length ? checkoutUserErrors[0].code : null;
      const errorMessage = code != null ? INTERNALS.getErrorMessage(code) : null;
      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));

      return {
        ...state,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.discountErrorMessage]: errorMessage,
        [StateKeys.giftCards]: checkout.appliedGiftCards,
      };
    }

    case ReduxActions.CART_DISCOUNT_REMOVE_SUCCESS: {
      const { checkout } = action.payload.data.checkoutDiscountCodeRemove;

      if (checkout == null) {
        return state;
      }

      const lineItems = prepareLineItems(checkout.lineItems.edges.map((e) => e.node));

      return {
        ...state,
        [StateKeys.lineItems]: lineItems,
        [StateKeys.subtotalPrice]: checkout.subtotalPrice.amount,
        [StateKeys.lineItemsSubtotal]: checkout.lineItemsSubtotalPrice.amount,
        [StateKeys.discounts]: checkout.discountApplications.edges.map((edge) => edge.node),
        [StateKeys.discountErrorMessage]: null,
        [StateKeys.giftCards]: checkout.appliedGiftCards,
      };
    }

    case ReduxActions.CART_REBATE_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.rebateBalance]: action.payload.data,
      };
    }

    case ReduxActions.CART_REBATE_APPLY_SUCCESS: {
      const { checkout } = action.payload.data.checkoutGiftCardsAppend;

      return {
        ...state,
        [StateKeys.giftCards]: checkout.appliedGiftCards,
      };
    }

    case ReduxActions.CART_GIFT_CARD_REMOVE_SUCCESS: {
      return {
        ...state,
        [StateKeys.giftCards]: [],
      };
    }

    case ReduxActions.CART_OPEN: {
      return {
        ...state,
        [StateKeys.isOpen]: true,
      };
    }

    case ReduxActions.CART_CLOSE: {
      return {
        ...state,
        [StateKeys.isOpen]: false,
        [StateKeys.discountErrorMessage]: null,
        [StateKeys.cartHeaderBanners]: [],
      };
    }

    case ReduxActions.CART_PROMOS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.promos]: action.payload.data,
      };

    case ReduxActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.cartUpsellProducts]: action.payload.data,
      };
    }

    case ReduxActions.ROUTER_LOCATION_CHANGE: {
      return {
        ...state,
        [StateKeys.isOpen]: false,
        [StateKeys.discountErrorMessage]: null,
      };
    }

    case ReduxActions.AUTH_REFRESH_ERROR:
    case ReduxActions.AUTH_LOGOUT: {
      return {
        ...initialState,
      };
    }

    case ReduxActions.CART_HEADER_BANNER_APPEND: {
      return {
        ...state,
        [StateKeys.cartHeaderBanners]: [...state.cartHeaderBanners, action.payload],
      };
    }

    case ReduxActions.CART_HEADER_BANNER_CLEAR: {
      return {
        ...state,
        [StateKeys.cartHeaderBanners]: [] as CartBanner[],
      };
    }

    default:
      return state;
  }
}

export const errorMessages = {
  DISCOUNT_NOT_FOUND: 'Discount Code not found.',
} as StringMap<string>;

export const getErrorMessage = (code: string) => {
  let errorMessage = 'Unknown error. Please try again.';
  if (errorMessages[code]) {
    errorMessage = errorMessages[code];
  }
  return errorMessage;
};

export const INTERNALS = {
  getErrorMessage,
};

export const CartActions = {
  CART_CLOSE: () => createAction(ReduxActions.CART_CLOSE),
  CART_OPEN: () => createAction(ReduxActions.CART_OPEN),

  CART_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_FETCH_ERROR, err),
  CART_FETCH_PENDING: () => createAction(ReduxActions.CART_FETCH_PENDING),
  CART_FETCH_SUCCESS: (response: ApolloQueryResult<CartFetchResponse>) =>
    createAction(ReduxActions.CART_FETCH_SUCCESS, response),

  CART_ID_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_ID_FETCH_ERROR, err),
  CART_ID_FETCH_PENDING: () => createAction(ReduxActions.CART_ID_FETCH_PENDING),
  CART_ID_FETCH_SUCCESS: (
    response: OmniaResponse<{ checkout_id: string; was_checkout_complete: boolean }>
  ) => createAction(ReduxActions.CART_ID_FETCH_SUCCESS, response),

  CART_ITEM_ADD_ERROR: (err: any) => createAction(ReduxActions.CART_ITEM_ADD_ERROR, err),
  CART_ITEM_ADD_PENDING: () => createAction(ReduxActions.CART_ITEM_ADD_PENDING),
  CART_ITEM_ADD_SUCCESS: (
    response: ExecutionResultWorkaround<CartItemAddResponse>,
    notification: AddItemNotifications,
    message?: string | ReactNode
  ) => createAction(ReduxActions.CART_ITEM_ADD_SUCCESS, { ...response, notification, message }),

  CART_ITEM_REMOVE_ERROR: (err: any) => createAction(ReduxActions.CART_ITEM_REMOVE_ERROR, err),
  CART_ITEM_REMOVE_PENDING: () => createAction(ReduxActions.CART_ITEM_REMOVE_PENDING),
  CART_ITEM_REMOVE_SUCCESS: (response: ExecutionResultWorkaround<RemoveLineItemResponse>) =>
    createAction(ReduxActions.CART_ITEM_REMOVE_SUCCESS, response),

  CART_ITEM_UPDATE_ERROR: (err: any) => createAction(ReduxActions.CART_ITEM_UPDATE_ERROR, err),
  CART_ITEM_UPDATE_PENDING: () => createAction(ReduxActions.CART_ITEM_UPDATE_PENDING),
  CART_ITEM_UPDATE_SUCCESS: (response: ExecutionResultWorkaround<UpdateLineItemsResponse>) =>
    createAction(ReduxActions.CART_ITEM_UPDATE_SUCCESS, response),

  CART_DISCOUNT_APPLY_ERROR: (err: any) =>
    createAction(ReduxActions.CART_DISCOUNT_APPLY_ERROR, err),
  CART_DISCOUNT_APPLY_PENDING: () => createAction(ReduxActions.CART_DISCOUNT_APPLY_PENDING),
  CART_DISCOUNT_APPLY_SUCCESS: (response: ExecutionResultWorkaround<ApplyDiscountResponse>) =>
    createAction(ReduxActions.CART_DISCOUNT_APPLY_SUCCESS, response),

  CART_DISCOUNT_REMOVE_ERROR: (err: any) =>
    createAction(ReduxActions.CART_DISCOUNT_REMOVE_ERROR, err),
  CART_DISCOUNT_REMOVE_PENDING: () => createAction(ReduxActions.CART_DISCOUNT_REMOVE_PENDING),
  CART_DISCOUNT_REMOVE_SUCCESS: (response: ExecutionResultWorkaround<RemoveDiscountResponse>) =>
    createAction(ReduxActions.CART_DISCOUNT_REMOVE_SUCCESS, response),

  CART_REBATE_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_REBATE_FETCH_ERROR, err),
  CART_REBATE_FETCH_PENDING: () => createAction(ReduxActions.CART_REBATE_FETCH_PENDING),
  CART_REBATE_FETCH_SUCCESS: (response: OmniaResponse<string>) =>
    createAction(ReduxActions.CART_REBATE_FETCH_SUCCESS, response),

  CART_REBATE_APPLY_ERROR: (err: any) => createAction(ReduxActions.CART_REBATE_APPLY_ERROR, err),
  CART_REBATE_APPLY_PENDING: () => createAction(ReduxActions.CART_REBATE_APPLY_PENDING),
  CART_REBATE_APPLY_SUCCESS: (response: OmniaResponse<ApplyUserCartRebateResponse>) =>
    createAction(ReduxActions.CART_REBATE_APPLY_SUCCESS, response),

  CART_GIFT_CARD_REMOVE_ERROR: (err: any) =>
    createAction(ReduxActions.CART_GIFT_CARD_REMOVE_ERROR, err),
  CART_GIFT_CARD_REMOVE_PENDING: () => createAction(ReduxActions.CART_GIFT_CARD_REMOVE_PENDING),
  CART_GIFT_CARD_REMOVE_SUCCESS: () => createAction(ReduxActions.CART_GIFT_CARD_REMOVE_SUCCESS),

  CART_ATTRIBUTES_UPDATE_ERROR: (err: any) =>
    createAction(ReduxActions.CART_ATTRIBUTES_UPDATE_ERROR, err),
  CART_ATTRIBUTES_UPDATE_PENDING: () => createAction(ReduxActions.CART_ATTRIBUTES_UPDATE_PENDING),
  CART_ATTRIBUTES_UPDATE_SUCCESS: () => createAction(ReduxActions.CART_ATTRIBUTES_UPDATE_SUCCESS),

  CART_PROMOS_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_PROMOS_FETCH_ERROR, err),
  CART_PROMOS_FETCH_PENDING: () => createAction(ReduxActions.CART_PROMOS_FETCH_PENDING),
  CART_PROMOS_FETCH_SUCCESS: (response: OmniaResponse<CartPromo[]>) =>
    createAction(ReduxActions.CART_PROMOS_FETCH_SUCCESS, response),

  CART_UPSELL_PRODUCTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_ERROR, err),
  CART_UPSELL_PRODUCTS_FETCH_PENDING: () =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_PENDING),
  CART_UPSELL_PRODUCTS_FETCH_SUCCESS: (response: OmniaResponse<CartUpsellProduct[]>) =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS, response),

  CART_HEADER_BANNER_APPEND: (args: CartBanner) =>
    createAction(ReduxActions.CART_HEADER_BANNER_APPEND, args),
  CART_HEADER_BANNER_CLEAR: () => createAction(ReduxActions.CART_HEADER_BANNER_CLEAR),
};
export type CartActions = ActionsUnion<typeof CartActions>;

export function fetchCheckoutId({
  guest_checkout_id,
  merge_checkout_id,
}: {
  guest_checkout_id?: string;
  merge_checkout_id?: string;
}): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ID_FETCH_PENDING());

    return apiV1
      .checkoutIdFetch({
        guest_checkout_id,
        merge_checkout_id,
      })
      .then((response) => {
        return dispatch(CartActions.CART_ID_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`Error: fetchCheckoutId: ${guest_checkout_id}, ${merge_checkout_id}`, error);
        return dispatch(CartActions.CART_ID_FETCH_ERROR(error));
      });
  };
}

const checkoutAttributesUpdateV2 = gql`
  mutation checkoutAttributesUpdate($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export function updateCheckoutAttributes(
  checkoutId: string,
  attributes = {} as Record<string, string>
): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ATTRIBUTES_UPDATE_PENDING());

    const customAttributes = {
      campaign_source: attributes.source,
      campaign_medium: attributes.medium,
      campaign_name: attributes.name,
      campaign_term: attributes.term,
      campaign_content: attributes.content,
      device: attributes.device,
      'rudderstack-clientID': attributes['rudderstack-clientID'],
      'google-clientID': attributes['google-clientID'],
    } as Record<string, string>;

    const customAttributesAsKeyValue = Object.entries(customAttributes)
      .map((entry) => {
        const value = entry[1];
        if (value == null) {
          return null;
        }

        return {
          key: entry[0],
          value,
        };
      })
      .filter(Boolean);

    return shopifyClientInstance
      .mutate({
        mutation: checkoutAttributesUpdateV2,
        variables: {
          checkoutId,
          input: {
            customAttributes: customAttributesAsKeyValue,
          },
        },
      })
      .then((response) => {
        return dispatch(CartActions.CART_ATTRIBUTES_UPDATE_SUCCESS());
      })
      .catch((error) => {
        errorHandler(
          `Error: updateCheckoutAttributes: ${checkoutId}, ${JSON.stringify(attributes)}`,
          error
        );
        return dispatch(CartActions.CART_ATTRIBUTES_UPDATE_ERROR(error));
      });
  };
}

export interface CartItemAddResponse {
  checkoutLineItemsAdd: {
    checkout: Checkout;
  };
}

const checkoutLineItemsAddMutation = gql`
  mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export interface LineItemCustomAttribute {
  key: LINE_ATTRIBUTION;
  value: string;
}

export interface LineItemToAdd {
  variantId: string;
  quantity: number;
  customAttributes?: LineItemCustomAttribute[];
}

export enum LINE_ATTRIBUTION {
  AFFILIATE = '_affiliate', // used in affiliate video
  AMBASSADOR_CODE = '_ambassador_code', // @deprecated, currently only in lists which are phased out
  FISHING_REPORT_ID = '_fishing_report_id', // for fishing report adds
  OMNIA_VIDEO_ID = '_omnia_video_id', // for video cart adds
  PAGE_TYPE = '_page_type', // used in all cart adds
  URL = '_url', // used in all cart adds
  WATERBODY_ID = '_waterbody_id', // for waterbody page cart adds
  KIT = '_kit', // dynamic kit add to carts
  STATE_ABBR = '_state_abbr', // for state page cart adds
}

export type AddItemNotifications = 'cart' | 'flash' | 'none';

export function addLineItems(
  checkoutId: string,
  lineItems: LineItemToAdd[],
  notification: AddItemNotifications = 'cart',
  message?: string
): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ITEM_ADD_PENDING());

    return shopifyClientInstance
      .mutate({
        mutation: checkoutLineItemsAddMutation,
        variables: {
          checkoutId,
          lineItems,
        },
      })
      .then((response) => {
        return dispatch(CartActions.CART_ITEM_ADD_SUCCESS(response, notification, message));
      })
      .catch((error) => {
        errorHandler(`Error: addLineItems: ${checkoutId}, ${JSON.stringify(lineItems)}`, error);
        return dispatch(CartActions.CART_ITEM_ADD_ERROR(error));
      });
  };
}

export interface CartFetchResponse {
  node: Checkout;
}

const checkoutFetchQuery = gql`
  query checkoutFetch($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export function fetchCheckout(checkoutId: string): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_FETCH_PENDING());

    return shopifyClientInstance
      .query({
        query: checkoutFetchQuery,
        variables: {
          checkoutId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response: ApolloQueryResult<CartFetchResponse>) => {
        return dispatch(CartActions.CART_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCheckout: ${checkoutId}`, error);
        return dispatch(CartActions.CART_FETCH_ERROR(error));
      });
  };
}

const checkoutLineItemsUpdateMutation = gql`
  mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export interface LineItemToUpdate {
  id: string;
  quantity: number;
  customAttributes?: LineItemCustomAttribute[];
}
export interface UpdateLineItemsResponse {
  checkoutLineItemsUpdate: {
    checkout: Checkout;
  };
}

export function updateLineItems(checkoutId: string, lineItems: LineItemToUpdate[]): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ITEM_UPDATE_PENDING());

    return shopifyClientInstance
      .mutate({
        mutation: checkoutLineItemsUpdateMutation,
        variables: {
          checkoutId,
          lineItems,
        },
      })
      .then((response) => {
        return dispatch(CartActions.CART_ITEM_UPDATE_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`ERROR: updateLineItems: ${checkoutId} ${lineItems}`, error);
        return dispatch(CartActions.CART_ITEM_UPDATE_ERROR(error));
      });
  };
}

const checkoutLineItemsRemoveMutation = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export interface RemoveLineItemResponse {
  checkoutLineItemsRemove: {
    checkout: Checkout;
  };
}

export function removeLineItemInCart(checkoutId: string, lineItemIds: string[]): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ITEM_REMOVE_PENDING());

    return shopifyClientInstance
      .mutate({
        mutation: checkoutLineItemsRemoveMutation,
        variables: {
          checkoutId,
          lineItemIds,
        },
      })
      .then((response) => {
        return dispatch(CartActions.CART_ITEM_REMOVE_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`ERROR: removeLineItemInCart: ${checkoutId} ${lineItemIds}`, error);
        return dispatch(CartActions.CART_ITEM_REMOVE_ERROR(error));
      });
  };
}

const applyDiscountMutation = gql`
  mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export interface ApplyDiscountResponse {
  checkoutDiscountCodeApplyV2: {
    checkout: Checkout;
    checkoutUserErrors: { code: string; message: string }[];
  };
}

export function applyDiscount(checkoutId: string, discountCode: string): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_DISCOUNT_APPLY_PENDING());

    return shopifyClientInstance
      .mutate({
        mutation: applyDiscountMutation,
        variables: {
          checkoutId,
          discountCode,
        },
      })
      .then((response) => {
        dispatch(CartActions.CART_DISCOUNT_APPLY_SUCCESS(response));
        const { checkout, checkoutUserErrors } = response.data.checkoutDiscountCodeApplyV2;

        const lastAppliedDiscountNode = checkout.discountApplications.edges.slice(-1);
        const lastAppliedDiscount = lastAppliedDiscountNode[0]?.node;
        const appliedDiscountCode = lastAppliedDiscount?.code;
        const discountApplicable = lastAppliedDiscount?.applicable;

        const failedDiscount = checkoutUserErrors[0]?.code === 'DISCOUNT_NOT_FOUND';

        if (failedDiscount) {
          dispatch(
            FlashMessageActions.FLASH_MESSAGE_SET({
              header: 'Promo Code Was Not Applied',
              subheader: `We're sorry the promo code ${discountCode.toUpperCase()} didn't work with the items in your cart`,
              type: 'warning',
            })
          );
        } else if (discountCode === appliedDiscountCode && discountApplicable) {
          dispatch(
            FlashMessageActions.FLASH_MESSAGE_SET({
              header: 'Promo Code Applied',
              subheader: `Promo Code ${discountCode.toUpperCase()} has been applied to your cart!`,
            })
          );
        }
      })
      .catch((error) => {
        errorHandler(`ERROR: applyDiscount: ${checkoutId} ${discountCode}`, error);
        return dispatch(CartActions.CART_DISCOUNT_APPLY_ERROR(error));
      });
  };
}

const removeDiscountMutation = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export interface RemoveDiscountResponse {
  checkoutDiscountCodeRemove: {
    checkout: Checkout;
    checkoutUserErrors: { code: string; message: string }[];
  };
}

export function removeDiscount(checkoutId: string): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_DISCOUNT_REMOVE_PENDING());

    return shopifyClientInstance
      .mutate({
        mutation: removeDiscountMutation,
        variables: {
          checkoutId,
        },
      })
      .then((response) => {
        return dispatch(CartActions.CART_DISCOUNT_REMOVE_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`ERROR: removeDiscount: ${checkoutId}`, error);
        return dispatch(CartActions.CART_DISCOUNT_REMOVE_ERROR(error));
      });
  };
}

export interface ApplyUserCartRebateResponse {
  checkoutGiftCardsAppend: {
    checkout: Checkout;
    checkoutUserErrors: { code: string; message: string }[];
  };
}

export function applyUserCartRebate(): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_REBATE_APPLY_PENDING());

    return apiV1
      .userPremiumRewardsApply()
      .then((response) => {
        return dispatch(CartActions.CART_REBATE_APPLY_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: applyUserCartRebate`, error);
        return dispatch(CartActions.CART_REBATE_APPLY_ERROR(error));
      });
  };
}

const removeGiftCardMutation = gql`
  mutation checkoutGiftCardRemoveV2($appliedGiftCardId: ID!, $checkoutId: ID!) {
    checkoutGiftCardRemoveV2(appliedGiftCardId: $appliedGiftCardId, checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export interface RemoveCartGiftCardResponse {
  checkoutGiftCardRemoveV2: {
    checkout: Checkout;
    checkoutUserErrors: { code: string; message: string }[];
  };
}

export function removeCartGiftCard(checkoutId: string, appliedGiftCardIds: string[]): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_GIFT_CARD_REMOVE_PENDING());

    const promises = appliedGiftCardIds.map((appliedGiftCardId) => {
      return shopifyClientInstance.mutate({
        mutation: removeGiftCardMutation,
        variables: {
          checkoutId,
          appliedGiftCardId,
        },
      });
    });

    return Promise.all(promises)
      .then((responses: ExecutionResultWorkaround<RemoveCartGiftCardResponse>[]) => {
        // when looking at responses, you often get one each response showing only that one gift card removed,
        // not the latest, so we'll just assume since they all worked, gift cards should be an empty array
        return dispatch(CartActions.CART_GIFT_CARD_REMOVE_SUCCESS());
      })
      .catch((error) => {
        errorHandler(`ERROR: removeCartGiftCards: ${checkoutId}`, error);
        return dispatch(CartActions.CART_GIFT_CARD_REMOVE_ERROR(error));
      });
  };
}

export function fetchUserCartRebate(): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_REBATE_FETCH_PENDING());

    return apiV1
      .userPremiumRewardsFetch()
      .then((response) => {
        return dispatch(CartActions.CART_REBATE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserCartRebate`, error);
        return dispatch(CartActions.CART_REBATE_FETCH_ERROR(error));
      });
  };
}

export function fetchCartPromos(): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_PROMOS_FETCH_PENDING());

    return apiV1
      .cartPromosFetch()
      .then((response) => {
        return dispatch(CartActions.CART_PROMOS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCartPromos`, error);
        return dispatch(CartActions.CART_PROMOS_FETCH_ERROR(error));
      });
  };
}

export function fetchCartUpsellProducts(skus = [] as string[]): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_PENDING());

    return apiV1
      .cartUpsellProductsFetch(skus)
      .then((response) => {
        return dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCartUpsellProducts`, error);
        return dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_ERROR(error));
      });
  };
}
