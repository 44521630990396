import * as React from 'react';

const SvgSummerIcon = (props: React.SVGAttributes<SVGElement>) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#summer-icon_svg__a)" fill={fill || '#657D7D'}>
        <path d="M8 4.04A3.963 3.963 0 0 0 4.04 8 3.964 3.964 0 0 0 8 11.959a3.964 3.964 0 0 0 3.96-3.96A3.963 3.963 0 0 0 8 4.04Zm0 6.585a2.629 2.629 0 0 1-2.626-2.626A2.628 2.628 0 0 1 8 5.374a2.628 2.628 0 0 1 2.626 2.625A2.629 2.629 0 0 1 8 10.625ZM8.667.555a.111.111 0 0 0-.111-.11H7.444a.111.111 0 0 0-.11.11v2.329h1.333V.555ZM7.333 15.444c0 .061.05.111.111.111h1.112c.06 0 .11-.05.11-.111v-2.329H7.334v2.329ZM15.458 7.347H13.13V8.68h2.328c.062 0 .111-.05.111-.11V7.457a.111.111 0 0 0-.11-.111ZM2.87 7.347H.542a.111.111 0 0 0-.111.111v1.111c0 .061.05.111.11.111h2.33V7.347ZM12.099 11.17l-.943.942 1.647 1.647a.11.11 0 0 0 .157 0l.786-.786a.111.111 0 0 0 0-.157l-1.647-1.646ZM3.901 4.858l.943-.943-1.647-1.647a.111.111 0 0 0-.157 0l-.785.786a.111.111 0 0 0 0 .157L3.9 4.858ZM2.255 12.816a.111.111 0 0 0 0 .158l.785.785a.111.111 0 0 0 .158 0l1.646-1.646-.942-.943-1.647 1.646ZM13.745 3.211a.111.111 0 0 0 0-.157l-.785-.786a.111.111 0 0 0-.158 0l-1.646 1.647.943.943 1.646-1.647Z" />
      </g>
      <defs>
        <clipPath id="summer-icon_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSummerIcon;
