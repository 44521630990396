import { FishingReport } from '@omniafishing/core';
import React from 'react';
import { useFishingReportListViewed } from '../../hooks/use_fishing_report_list_viewed';
import { useResponsive } from '../../hooks/use_responsive';
import { WebAnalytics } from '../../lib/web_analytics';
import { FishingReportCard } from '../fishing_report_card/fishing_report_card';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './product_detail_fishing_report_card_list.less';

interface ProductDetailFishingReportCardListProps {
  fishingReports: FishingReport[];
  numberOfReportsRendered: number;
  onLoadMoreClick: () => void;
  showLoadMore: boolean;
  totalFiltersApplied: number;
}

export const ProductDetailFishingReportCardList = (
  props: ProductDetailFishingReportCardListProps
) => {
  const {
    fishingReports,
    numberOfReportsRendered,
    onLoadMoreClick,
    showLoadMore,
    totalFiltersApplied,
  } = props;

  useFishingReportListViewed(
    fishingReports,
    'embedded',
    totalFiltersApplied,
    numberOfReportsRendered
  );

  const { isSmallDesktop, isMobile } = useResponsive();

  let heroImageWidth: number;

  if (isSmallDesktop) {
    heroImageWidth = 435;
  } else if (!isSmallDesktop && !isMobile) {
    heroImageWidth = 478;
  }

  return (
    <div>
      {fishingReports.length > 0 && (
        <div className={styles.frRows}>
          {fishingReports.map((fishingReport, i) => {
            return (
              <li key={fishingReport.id} className={styles.card}>
                <FishingReportCard
                  fishingReport={fishingReport}
                  position={i}
                  onLakeClick={() => {
                    WebAnalytics.productDetailPageClick('[reports].(lake_link)');
                  }}
                  onMediaClick={() => {
                    WebAnalytics.productDetailPageClick('[reports].(media_link)');
                  }}
                  onProductClick={() => {
                    WebAnalytics.productDetailPageClick('[reports].(product_link)');
                  }}
                  onUserClick={() => {
                    WebAnalytics.productDetailPageClick('[reports].(user_link)');
                  }}
                  alternateHeroPixelWidth={heroImageWidth}
                />
              </li>
            );
          })}
        </div>
      )}
      {showLoadMore && (
        <div className={styles.loadMoreButton}>
          <OmniaButton kind="tertiary" size="lg" fontSize={14} onPress={onLoadMoreClick}>
            Load More Fishing Reports
          </OmniaButton>
        </div>
      )}
    </div>
  );
};
