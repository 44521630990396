import { Slider } from 'antd';
import React from 'react';
import ReactHammer from 'react-hammerjs';
import { getImgixPath } from '../../lib/imgix';

interface Props {
  images: string[];
  width: number;
}

export const RotateImages: React.FC<Props> = props => {
  const { images, width } = props;
  const [idx, setIdx] = React.useState(0);
  const [startIdx, setStartIdx] = React.useState(null);

  const onPanStart = (event: HammerInput) => {
    setStartIdx(idx);
  };

  const onPan = (event: HammerInput) => {
    const { deltaX } = event;
    const maxIdx = images.length - 1;
    const imagesLength = images.length;

    let newIdx: number;
    const steps = deltaX / (width / imagesLength);

    if (deltaX < 0) {
      newIdx = startIdx + Math.ceil(steps);
    } else {
      newIdx = startIdx + Math.floor(steps);
    }

    if (newIdx > maxIdx) {
      newIdx = newIdx % imagesLength;
    }
    if (newIdx < 0) {
      newIdx = newIdx % imagesLength;
      if (newIdx < 0) {
        newIdx = newIdx + imagesLength;
      }
    }

    setIdx(newIdx);
  };

  const imgSrc = getImgixPath(images[idx], { w: 1000 });

  return (
    <div>
      <ReactHammer onPanStart={onPanStart} onPan={onPan} direction="DIRECTION_HORIZONTAL">
        <img src={imgSrc} />
      </ReactHammer>
      <Slider
        max={images.length - 1}
        value={idx}
        tipFormatter={null}
        onChange={(val: number) => {
          setIdx(val);
        }}
      />
    </div>
  );
};
