import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes';

export const AboutOmniaBlurb = () => {
  return (
    <>
      <p>
        Omnia Fishing helps anglers find the right gear from our massive inventory of 20,000+ items.
        We provide personalized tackle recommendations based on the relationships between species,
        style, lake characteristics, season, community fishing reports, patterns, and products. We
        make sure you only see the products that make sense for you, on the lakes you fish, at the
        right time of year.
      </p>
      <p>
        Omnia is the most personalized tackle shopping on earth!{' '}
        <Link to={RoutePaths.HOME}>Get personalized recommendations</Link>
      </p>
    </>
  );
};
