import * as React from 'react';

const SvgSpringIcon = (props: React.SVGAttributes<SVGElement>) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#spring-icon_svg__a)">
        <path
          d="M13.644 8a2.881 2.881 0 0 0 1.247-1.527c.251-.746.156-1.561-.269-2.297-.418-.724-1.073-1.21-1.846-1.37a2.872 2.872 0 0 0-1.952.305C10.804 1.766 9.816.353 8 .353c-1.75 0-2.801 1.393-2.824 2.755C4 2.454 2.286 2.604 1.378 4.176.96 4.9.866 5.711 1.114 6.46A2.87 2.87 0 0 0 2.356 8a2.881 2.881 0 0 0-1.247 1.527c-.251.745-.156 1.56.269 2.296.418.724 1.073 1.211 1.846 1.37a2.88 2.88 0 0 0 1.952-.303c.02 1.345 1.008 2.758 2.824 2.758 1.75 0 2.802-1.394 2.824-2.757.42.233.908.367 1.404.367.894 0 1.811-.424 2.395-1.434.418-.724.511-1.535.263-2.284A2.874 2.874 0 0 0 13.644 8Zm-2.193-3.717c.311-.179.705-.243 1.056-.17.4.082.732.335.96.73.238.411.292.816.16 1.204a1.535 1.535 0 0 1-.683.823c-.922.532-1.88.505-3.294.177.425-1.39.879-2.231 1.801-2.764Zm-6.903 7.433c-.31.18-.705.244-1.056.171-.4-.082-.732-.335-.96-.73-.237-.411-.29-.816-.16-1.204.115-.338.364-.638.684-.823.923-.531 1.88-.505 3.294-.178-.425 1.39-.879 2.232-1.802 2.764ZM3.056 6.87c-.31-.18-.564-.49-.676-.829-.129-.387-.076-.801.152-1.197.34-.587.808-.768 1.23-.768.312 0 .6.1.786.208.923.533 1.377 1.375 1.802 2.764-1.415.327-2.371.354-3.294-.178ZM8 14.314c-1.18 0-1.492-.96-1.492-1.467 0-1.065.502-1.88 1.492-2.942.99 1.063 1.492 1.877 1.492 2.942 0 .567-.405 1.467-1.492 1.467ZM6.737 8a1.263 1.263 0 1 1 2.526 0 1.263 1.263 0 0 1-2.526 0ZM8 6.095c-.99-1.063-1.492-1.877-1.492-2.942 0-.567.405-1.467 1.492-1.467 1.18 0 1.492.96 1.492 1.467 0 1.065-.501 1.879-1.492 2.942Zm5.468 5.062c-.59 1.023-1.577.813-2.017.56-.922-.533-1.376-1.375-1.8-2.764 1.414-.327 2.37-.354 3.293.178.31.18.564.49.676.828.13.388.076.802-.152 1.198Z"
          fill={fill || '#657D7D'}
        />
      </g>
      <defs>
        <clipPath id="spring-icon_svg__a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSpringIcon;
