import { useSessionStorage } from '../../hooks/use_session_storage';
import { StorageKeys } from '../../lib/local_storage';

export const useUpsellProductsClosedSkus = () => {
  const [cartUpsellProductsClosedSkus, setCartUpsellProductsClosedSkus] = useSessionStorage(
    StorageKeys.cartUpsellProductsClosed,
    []
  );
  return {
    cartUpsellProductsClosedSkus,
    setCartUpsellProductsClosedSkus,
  };
};
