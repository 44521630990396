import queryString from 'query-string';

export enum GlobalQueryParams {
  add_to_cart = 'add_to_cart', // for auto adding variant ids to cart
  attribution = 'attribution',
  discount_code = 'discount_code',
  query = 'query',
  report_id = 'report_id', // auto shows a fishing report in a modal
  waypoint_hash = 'waypoint_hash',
}

export enum OmniaQueryParams {
  ambassadors = 'ambassadors',
  brands = 'brands',
  categories = 'categories',
  in_stock = 'in_stock',
  per_page = 'per_page',
  pg = 'pg',
  season = 'season',
  season_group = 'season_group',
  sort = 'sort',
  species = 'species',
  state = 'state',
  style = 'style',
  styles = 'styles',
  subcat_types = 'subcat_types',
  subcategories = 'subcategories',
  technique = 'technique',
  techniques = 'techniques',
  waterbody = 'waterbody',
  user_email = 'user_email',
}

export enum OmniaSortQueryParams {
  osort_latitude = 'osort_latitude',
  osort_longitude = 'osort_longitude',
  osort_anonymous_id = 'osort_anonymous_id',
  osort_treatment = 'osort_treatment',
}

export interface OmniaQueryStringData {
  pg?: string;
  pageSize?: string;
  species?: string[];
  styles?: string[];
  subcategories?: string[];
  subcat_types?: string[];
  brands?: string[];
  in_stock?: string;
}

export const getQueryStringItem = (query: string, property: string): string[] | undefined => {
  const item = queryString.parse(query)[property];
  if (!item) {
    return undefined;
  }
  switch (typeof item) {
    case 'string':
      return [item];
    default:
      return item;
  }
};
