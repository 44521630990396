import { SeasonGroupNames } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import styles from './season_group_icon.less';
import SvgSpringIcon from '../svg/spring_icon';
import SvgSummerIcon from '../svg/summer_icon';
import SvgFallIcon from '../svg/fall_icon';
import SvgWinterIcon from '../svg/winter_icon';
import SvgIceIcon from '../svg/ice_icon';
export interface SeasonGroupIconProps {
  seasonGroupName: SeasonGroupNames;
  className?: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  tag?: 'div' | 'span';
}

export const SeasonGroupIcon = ({
  seasonGroupName,
  className,
  size,
  color,
  style,
  tag,
}: SeasonGroupIconProps) => {
  let SeasonIcon = null;

  const props = {
    fill: color,
    width: size,
    height: size,
    style,
  };

  const ElementName = tag ?? 'div';

  switch (seasonGroupName) {
    case SeasonGroupNames.SPRING:
      SeasonIcon = <SvgSpringIcon {...props} />;
      break;
    case SeasonGroupNames.SUMMER:
      SeasonIcon = <SvgSummerIcon {...props} />;
      break;
    case SeasonGroupNames.FALL:
      SeasonIcon = <SvgFallIcon {...props} />;
      break;
    case SeasonGroupNames.WINTER:
      SeasonIcon = <SvgWinterIcon {...props} />;
      break;
    case SeasonGroupNames.ICE:
      SeasonIcon = <SvgIceIcon {...props} />;
      break;
  }

  return (
    <ElementName className={classNames(styles.seasonIcon, className)}>{SeasonIcon}</ElementName>
  );
};
