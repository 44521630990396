import * as React from 'react';

const SvgWishlist = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.255 7.286a6.086 6.086 0 0 0 1.018-3.357c0-2.143-1.819-3.929-4-3.929-1.455 0-2.91.786-3.637 1.929C6.91.786 5.455 0 4 0 1.818 0 0 1.714 0 3.929 0 7 2.473 10.214 7.418 13.5c.073.071.146.071.218.071.073 0 .146 0 .219-.071l.29-.214c.8 1 2.037 1.714 3.491 1.714 2.4 0 4.364-1.929 4.364-4.286a4.237 4.237 0 0 0-1.745-3.428Zm-6.619 5.5C3.055 9.643.727 6.714.727 3.929.727 2.143 2.182.714 4 .714c1.455 0 2.836.929 3.273 2.143.072.286.582.286.654 0C8.364 1.643 9.745.714 11.273.714c1.818 0 3.273 1.429 3.273 3.215a5.548 5.548 0 0 1-.873 3c-.582-.286-1.31-.5-2.037-.5-2.4 0-4.363 1.928-4.363 4.285 0 .715.145 1.357.509 2l-.146.072Zm4 1.5C9.6 14.286 8 12.714 8 10.714s1.6-3.571 3.636-3.571c2.037 0 3.637 1.571 3.637 3.571s-1.6 3.572-3.637 3.572Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={props?.strokeWidth || 0.3}
    />
    <path
      d="M14.182 10.357H12V8.214h-.727v2.143H9.09v.714h2.182v2.143H12v-2.143h2.182v-.714Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={props?.strokeWidth || 0.3}
    />
  </svg>
);

export default SvgWishlist;
