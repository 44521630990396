import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStaticRouterContext } from '../../types/static-context';
import { ApplicationState } from '../helpers/app_state';
import { getAccessToken, hasAccessToken } from '../redux/auth';
import { getCheckoutId } from '../redux/cart';
import {
  getBrands,
  getCategories,
  getSpecies,
  getSubcategories,
  getSubcatTypes,
  getTechniques,
} from '../redux/reference_data';
import { isUserAdmin } from '../redux/user';
import { getIsWebview } from '../redux/window';
import { Root } from './root';

function mapStateToProps(state: ApplicationState, ownProps = {}) {
  return {
    hasAccessToken: hasAccessToken(state),
    accessToken: getAccessToken(state),
    checkoutId: getCheckoutId(state),
    categories: getCategories(state),
    subcategories: getSubcategories(state),
    subcatTypes: getSubcatTypes(state),
    brands: getBrands(state),
    styles: getTechniques(state),
    species: getSpecies(state),
    isWebView: getIsWebview(state),
    isAdmin: isUserAdmin(state),
  };
}

const dispatchProps = {};

const RootConnected = connect(mapStateToProps, dispatchProps)(Root);
// This will probably need to keep using withRouter to have access to the staticContext
const RootContainer = withRouter(RootConnected);

export type RootProps = typeof dispatchProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<any, AppStaticRouterContext>;

export default RootContainer;
