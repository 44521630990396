import React from 'react';
import { useSelector } from 'react-redux';
import { hasAccessToken } from '../../redux/auth';
import DashboardNav from '../dashboard_nav/dashboard_nav';
import styles from './dashboard_header.less';

const DashboardHeader = ({ title, center }: { title: string; center?: boolean }) => {
  const isLoggedIn = useSelector(hasAccessToken);

  return (
    <header className={styles.header}>
      <h1
        className={styles.h1}
        style={{ marginInline: center ? 'auto' : 'unset', flex: center ? '0' : '1' }}
      >
        {title}
      </h1>
      <div className={styles.nav}>{isLoggedIn && <DashboardNav />}</div>
    </header>
  );
};

export default DashboardHeader;
