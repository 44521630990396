import { InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FlashMessageActions,
  FlashMessageType,
  getHeader,
  getSeconds,
  getSubheader,
  getType,
} from '../../redux/flash_message';
import SvgCheckMark from '../svg/checkmark';
import SvgCloseIcon from '../svg/closeIcon';
import styles from './flash_notification.less';

const FlashNotification = (): null => {
  const header = useSelector(getHeader);
  const subheader = useSelector(getSubheader);
  const seconds = useSelector(getSeconds);
  const messageType = useSelector(getType);
  const dispatch = useDispatch();

  useEffect(() => {
    const icons: Record<FlashMessageType, JSX.Element> = {
      success: <SvgCheckMark />,
      info: <InfoCircleFilled />,
      warning: <InfoCircleFilled />,
      error: <WarningFilled />,
    };

    const notificationProps: ArgsProps = {
      className: `${classNames(styles.flashNotification, {
        [styles.flashNotification__success]: messageType === 'success',
        [styles.flashNotification__info]: messageType === 'info',
        [styles.flashNotification__warning]: messageType === 'warning',
        [styles.flashNotification__error]: messageType === 'error',
      })}`,
      closeIcon: <SvgCloseIcon className={styles.closeSvg} />,
      btn: null,
      description: subheader,
      duration: seconds,
      icon: icons[messageType],
      message: <div className={styles.title}>{header}</div>,
      placement: 'top',
    };

    if (header || subheader) {
      if (messageType === 'error') {
        notification.error(notificationProps);
      } else {
        notification.success(notificationProps);
      }
      dispatch(FlashMessageActions.FLASH_MESSAGE_CLEAR());
    }
  }, [header, subheader, seconds, messageType]);
  return null;
};

export default FlashNotification;
