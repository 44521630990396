import {
  AuthLoginResponse,
  AuthTokens,
  AxiosOmniaError,
  OmniaErrorResponse,
  OmniaResponse,
  SignupParams,
  UserCampaignInfo,
  UserConfirmResponse,
  UserPrivate,
} from '@omniafishing/core';
import { Dispatch } from 'redux';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import getNow from '../lib/time';
import { WebAnalytics } from '../lib/web_analytics';
import { RoutePaths } from '../routes';
import { ActionsUnion, createAction } from './actions_helper';
import { RouterActions } from './router';

export const reducerName = 'auth';
export type reducerType = typeof initialState;

export enum StateKeys {
  loadingState = 'loadingState',
  loginLoadingState = 'loginLoadingState',
  error = 'error',
  accessToken = 'accessToken',
  accessTokenExpiration = 'accessTokenExpiration',
  refreshToken = 'refreshToken',
  isAuthExpired = 'isAuthExpired',
  confirmRedirect = 'confirmRedirect',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.loginLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.error]: null as string | null,
  [StateKeys.accessToken]: null as string | null,
  [StateKeys.accessTokenExpiration]: null as number | null,
  [StateKeys.isAuthExpired]: null as boolean | null,
  [StateKeys.refreshToken]: null as string | null,
  [StateKeys.confirmRedirect]: null as string | null,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

const MINUTE = 1000 * 60;
export const AUTH_TIME_THRESHOLD = 10 * MINUTE;

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getLoginLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loginLoadingState];
export const getError = (state: ApplicationState) => state[reducerName][StateKeys.error];
export const getAccessToken = (state: ApplicationState) =>
  state[reducerName][StateKeys.accessToken];
export const getAccessTokenExpiration = (state: ApplicationState) =>
  state[reducerName][StateKeys.accessTokenExpiration];
export const getRefreshToken = (state: ApplicationState) =>
  state[reducerName][StateKeys.refreshToken];
export const getIsAuthExpired = (state: ApplicationState) =>
  state[reducerName][StateKeys.isAuthExpired];
export const hasAccessToken = (state: ApplicationState) => {
  return getAccessToken(state) != null;
};
export const getConfirmRedirect = (state: ApplicationState) =>
  state[reducerName][StateKeys.confirmRedirect];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function authReducer(
  state = initialState,
  action: AuthActions | RouterActions
): reducerType {
  switch (action.type) {
    case ReduxActions.AUTH_SIGNUP_PENDING:
    case ReduxActions.AUTH_CONFIRM_PENDING:
    case ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_PENDING: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.error]: null,
      };
    }

    case ReduxActions.AUTH_LOGIN_PENDING: {
      return {
        ...state,
        [StateKeys.loginLoadingState]: LoadingState.PENDING,
        [StateKeys.error]: null,
      };
    }

    case ReduxActions.AUTH_LOGIN_SUCCESS:
    case ReduxActions.AUTH_SIGNUP_SUCCESS: {
      const { access_token_expires_in_sec, access_token, refresh_token } = action.payload.data;
      const expiresInMs = access_token_expires_in_sec * 1000;
      const accessTokenExpiration = getNow() + expiresInMs;

      const loadingStateKey =
        action.type === ReduxActions.AUTH_LOGIN_SUCCESS
          ? StateKeys.loginLoadingState
          : StateKeys.loadingState;

      return {
        ...state,
        [loadingStateKey]: LoadingState.DONE,
        [StateKeys.accessToken]: access_token,
        [StateKeys.accessTokenExpiration]: accessTokenExpiration,
        [StateKeys.refreshToken]: refresh_token,
      };
    }

    case ReduxActions.AUTH_LOGIN_ERROR: {
      return {
        ...state,
        [StateKeys.loginLoadingState]: LoadingState.ERROR,
        [StateKeys.error]: action.payload.data.error_code,
      };
    }

    case ReduxActions.AUTH_SIGNUP_ERROR:
    case ReduxActions.AUTH_CONFIRM_ERROR:
    case ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_ERROR: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
        [StateKeys.error]: action.payload.data.error_code,
      };
    }

    case ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
      };

    case ReduxActions.AUTH_CONFIRM_SUCCESS: {
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.confirmRedirect]: RoutePaths.DASHBOARD,
      };
    }

    case ReduxActions.AUTH_REFRESH_SUCCESS: {
      const expiresInMs = action.payload.access_token_expires_in_sec * 1000;
      const accessTokenExpiration = getNow() + expiresInMs;
      return {
        ...state,
        [StateKeys.accessToken]: action.payload.access_token,
        [StateKeys.accessTokenExpiration]: accessTokenExpiration,
      };
    }

    case ReduxActions.AUTH_EXPIRED_CHECK: {
      const isExpired = INTERNALS.isAuthExpiredCheck(
        state[StateKeys.accessTokenExpiration],
        getNow()
      );

      const existingValue = state[StateKeys.isAuthExpired];

      if (isExpired === existingValue) {
        return state;
      }

      return {
        ...state,
        [StateKeys.isAuthExpired]: isExpired,
      };
    }

    case ReduxActions.AUTH_REFRESH_ERROR:
    case ReduxActions.AUTH_LOGOUT:
      return initialState;

    case ReduxActions.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        [StateKeys.loadingState]: initialState[StateKeys.loadingState],
        [StateKeys.error]: initialState[StateKeys.error],
      };

    default:
      return state;
  }
}

export const isAuthExpiredCheck = (accessTokenExpiration: number | null, nowInMs: number) => {
  if (accessTokenExpiration == null) {
    return true;
  }
  const timeRemaining = accessTokenExpiration - nowInMs;
  return timeRemaining < AUTH_TIME_THRESHOLD;
};

export const INTERNALS = {
  isAuthExpiredCheck,
};

// ========================================================================== //
// Actions
// ========================================================================== //

export const AuthActions = {
  AUTH_CONFIRM_ERROR: (err: OmniaErrorResponse) =>
    createAction(ReduxActions.AUTH_CONFIRM_ERROR, err),
  AUTH_CONFIRM_PENDING: () => createAction(ReduxActions.AUTH_CONFIRM_PENDING),
  AUTH_CONFIRM_SUCCESS: (response: UserConfirmResponse) =>
    createAction(ReduxActions.AUTH_CONFIRM_SUCCESS, response),

  AUTH_LOGOUT: () => createAction(ReduxActions.AUTH_LOGOUT),

  AUTH_LOGIN_PENDING: () => createAction(ReduxActions.AUTH_LOGIN_PENDING),
  AUTH_LOGIN_SUCCESS: (response: OmniaResponse<AuthLoginResponse>) =>
    createAction(ReduxActions.AUTH_LOGIN_SUCCESS, response),
  AUTH_LOGIN_ERROR: (err: OmniaErrorResponse) => createAction(ReduxActions.AUTH_LOGIN_ERROR, err),

  AUTH_REFRESH_PENDING: () => createAction(ReduxActions.AUTH_REFRESH_PENDING),
  AUTH_REFRESH_SUCCESS: (response: AuthRefreshResponse) =>
    createAction(ReduxActions.AUTH_REFRESH_SUCCESS, response),
  AUTH_REFRESH_ERROR: (err: OmniaErrorResponse) =>
    createAction(ReduxActions.AUTH_REFRESH_ERROR, err),

  AUTH_SIGNUP_PENDING: () => createAction(ReduxActions.AUTH_SIGNUP_PENDING),
  AUTH_SIGNUP_SUCCESS: (response: OmniaResponse<AuthSignupResponse>) =>
    createAction(ReduxActions.AUTH_SIGNUP_SUCCESS, response),
  AUTH_SIGNUP_ERROR: (err: OmniaErrorResponse) => createAction(ReduxActions.AUTH_SIGNUP_ERROR, err),

  AUTH_PASSWORD_RESET_CONFIRM_PENDING: () =>
    createAction(ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_PENDING),
  AUTH_PASSWORD_RESET_CONFIRM_ERROR: (err: OmniaErrorResponse) =>
    createAction(ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_ERROR, err),
  AUTH_PASSWORD_RESET_CONFIRM_SUCCESS: (response: OmniaResponse<'SUCCESS'>) =>
    createAction(ReduxActions.AUTH_PASSWORD_RESET_CONFIRM_SUCCESS, response),

  AUTH_EXPIRED_CHECK: () => createAction(ReduxActions.AUTH_EXPIRED_CHECK),
};
export type AuthActions = ActionsUnion<typeof AuthActions>;

export interface AuthSignupResponse extends AuthTokens, UserCampaignInfo {
  user: UserPrivate;
}

export function loginUser(username: string, password: string, anonymous_id?: string): RequestThunk {
  return (dispatch: Dispatch) => {
    dispatch(AuthActions.AUTH_LOGIN_PENDING());

    return apiV1
      .userLogin({
        username,
        password,
        anonymous_id,
      })
      .then((response) => {
        return dispatch(AuthActions.AUTH_LOGIN_SUCCESS(response.data));
      })
      .catch((err: AxiosOmniaError) => {
        return dispatch(AuthActions.AUTH_LOGIN_ERROR(err.response));
      });
  };
}

export function signupUser(params: SignupParams): RequestThunk {
  return (dispatch) => {
    dispatch(AuthActions.AUTH_SIGNUP_PENDING());

    const {
      campaign_source,
      campaign_medium,
      campaign_name,
      campaign_term,
      campaign_content,
      username,
    } = params;

    return apiV1
      .userSignup({
        ...params,
        platform: 'web',
      })
      .then((response) => {
        WebAnalytics.followUpEnabled(username);
        return dispatch(
          AuthActions.AUTH_SIGNUP_SUCCESS({
            ...response.data,
            data: {
              ...response.data.data,
              campaign_source,
              campaign_medium,
              campaign_name,
              campaign_term,
              campaign_content,
            },
          })
        );
      })
      .catch((err: AxiosOmniaError) => {
        return dispatch(AuthActions.AUTH_SIGNUP_ERROR(err.response));
      });
  };
}

export function confirmUser(confirmToken: string, reset_password = false): RequestThunk {
  return (dispatch) => {
    dispatch(AuthActions.AUTH_CONFIRM_PENDING());

    return apiV1
      .userConfirm(confirmToken, reset_password)
      .then((response) => {
        return dispatch(AuthActions.AUTH_CONFIRM_SUCCESS(response.data));
      })
      .catch((err: AxiosOmniaError) => {
        return dispatch(AuthActions.AUTH_CONFIRM_ERROR(err.response));
      });
  };
}

export interface AuthRefreshResponse {
  access_token: string;
  access_token_expires_in_sec: number;
}

export function refreshAuth(refreshToken: string): RequestThunk {
  return (dispatch) => {
    dispatch(AuthActions.AUTH_REFRESH_PENDING());

    return apiV1
      .authRefresh(refreshToken)
      .then((response) => {
        return dispatch(AuthActions.AUTH_REFRESH_SUCCESS(response.data));
      })
      .catch((err: AxiosOmniaError) => {
        return dispatch(AuthActions.AUTH_REFRESH_ERROR(err.response));
      });
  };
}

export function passwordResetConfirm(
  username: string,
  code: string,
  password: string
): RequestThunk {
  return (dispatch) => {
    dispatch(AuthActions.AUTH_PASSWORD_RESET_CONFIRM_PENDING());

    return apiV1
      .userPasswordResetConfirm({
        username,
        code,
        password,
      })
      .then((response) => {
        return dispatch(AuthActions.AUTH_PASSWORD_RESET_CONFIRM_SUCCESS(response.data));
      })
      .catch((err: AxiosOmniaError) => {
        return dispatch(AuthActions.AUTH_PASSWORD_RESET_CONFIRM_ERROR(err.response));
      });
  };
}
