import {
  Brand,
  Category,
  Experiment,
  Forage,
  PromoBanner,
  Season,
  SeasonGroup,
  Species,
  State,
  Structure,
  Subcategory,
  SUBCATEGORY_UNLISTED,
  SubcatType,
  TechniqueFull,
} from '@omniafishing/core';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ReferenceData } from '../lib/api';
import { BrandsActions } from './brands';

export const SEASON_GROUP_NAME_SPRING = 'spring';
export const SEASON_GROUP_NAME_ICE = 'ice';

export const reducerName = 'reference_data';

export enum StateKeys {
  techniques = 'techniques',
  species = 'species',
  seasons = 'seasons',
  season_groups = 'season_groups',
  structures = 'structures',
  categories = 'categories',
  brands = 'brands',
  states = 'states',
  forages = 'forages',
  zone_current_seasons = 'zone_current_seasons',
  zone_current_season_groups = 'zone_current_season_groups',
  experiments = 'experiments',
  promo_banners = 'promo_banners',
}

export interface ZoneCurrentSeasons {
  1: Season;
  2: Season;
  3: Season;
}
export interface ZoneCurrentSeasonGroups {
  1: SeasonGroup;
  2: SeasonGroup;
  3: SeasonGroup;
}

export const initialState: ReferenceData = {
  [StateKeys.techniques]: [] as TechniqueFull[],
  [StateKeys.species]: [] as Species[],
  [StateKeys.seasons]: [] as Season[],
  [StateKeys.season_groups]: [] as SeasonGroup[],
  [StateKeys.categories]: [] as Category[],
  [StateKeys.brands]: [] as Brand[],
  [StateKeys.structures]: [] as Structure[],
  [StateKeys.states]: [] as State[],
  [StateKeys.forages]: [] as Forage[],
  [StateKeys.zone_current_seasons]: {
    1: null,
    2: null,
    3: null,
  } as ZoneCurrentSeasons,
  [StateKeys.zone_current_season_groups]: {
    1: null,
    2: null,
    3: null,
  } as ZoneCurrentSeasonGroups,
  [StateKeys.experiments]: [] as Experiment[],
  [StateKeys.promo_banners]: [] as PromoBanner[],
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getTechniques = (state: ApplicationState) => state[reducerName][StateKeys.techniques];
export const getSpecies = (state: ApplicationState) => state[reducerName][StateKeys.species];
export const getStructures = (state: ApplicationState) => state[reducerName][StateKeys.structures];
export const getSpeciesTop = createSelector([getSpecies], (species) => {
  return species.filter((s) => s.recommendations_order != null);
});
export const getSpeciesAlphabetical = createSelector([getSpecies], (species) => {
  return _.sortBy(species, 'display_name');
});

const _getSpeciesBySpeciesName = (species: Species[]) =>
  _.memoize(
    (species_name: string) => {
      if (!species_name) {
        return null;
      }
      return species.find((s) => s.name === species_name);
    },
    (species_name: string) => {
      return species_name;
    }
  );
export const getSpeciesBySpeciesName = createSelector([getSpecies], _getSpeciesBySpeciesName);

export const getSeasons = (state: ApplicationState) => state[reducerName][StateKeys.seasons];
export const getSeasonGroups = (state: ApplicationState) =>
  state[reducerName][StateKeys.season_groups];

export const _getSeasonGroupBySeasonName = (seasonGroups: SeasonGroup[]) =>
  _.memoize(
    (season_name: string) => {
      if (!season_name) {
        return null;
      }
      return seasonGroups.find((sg) => sg.seasons.some((s) => s.name === season_name));
    },
    (season_name: string) => {
      return season_name;
    }
  );
export const getSeasonGroupBySeasonName = createSelector(
  [getSeasonGroups],
  _getSeasonGroupBySeasonName
);

const _getTechniqueByTechniqueName = (techniques: TechniqueFull[]) =>
  _.memoize(
    (technique_name: string) => {
      if (!technique_name) {
        return null;
      }
      return techniques.find((t) => t.name === technique_name);
    },
    (technique_name: string) => {
      return technique_name;
    }
  );
export const getTechniqueByTechniqueName = createSelector(
  [getTechniques],
  _getTechniqueByTechniqueName
);

const _getStructureByStructureName = (structures: Structure[]) =>
  _.memoize(
    (structure_name: string) => {
      if (!structure_name) {
        return null;
      }
      return structures.find((s) => s.name === structure_name);
    },
    (structure_name: string) => {
      return structure_name;
    }
  );
export const getStructureByStructureName = createSelector(
  [getStructures],
  _getStructureByStructureName
);

const getCategoriesAll = (state: ApplicationState) => state[reducerName][StateKeys.categories];
export const getCategories = createSelector([getCategoriesAll], (categories) => {
  return categories.filter((c) => c.name !== 'unlisted');
});
export const getCategoriesAlphabetical = createSelector([getCategories], (categories) => {
  return _.orderBy(categories, 'display_name');
});
export const getSubcategories = createSelector([getCategories], (categories) => {
  return categories.reduce((acc, category) => {
    return acc.concat(category.subcategories.filter((s) => s.name !== SUBCATEGORY_UNLISTED));
  }, [] as Subcategory[]);
});
export const getSubcategoriesAlphabetical = createSelector([getSubcategories], (subcats) => {
  return _.orderBy(subcats, 'display_name');
});
export const getSubcatTypes = createSelector([getSubcategories], (subcategories) => {
  return subcategories.reduce((acc, subcategory) => {
    return acc.concat(subcategory.subcat_types);
  }, [] as SubcatType[]);
});

export const getBrands = (state: ApplicationState) => state[reducerName][StateKeys.brands];
export const getBrandsFeatured = createSelector([getBrands], (brands) => {
  return brands.filter((brand) => brand.featured);
});
export const getBrandsNotHidden = createSelector([getBrands], (brands) => {
  return brands.filter((brand) => !brand.hidden);
});
export const getBrandsAlphabetical = createSelector([getBrands], (brands) => {
  return _.orderBy(brands, 'display_name');
});

export const getStates = (state: ApplicationState) => state[reducerName][StateKeys.states];
export const getStatesFeatured = createSelector([getStates], (states) => {
  return states.filter((thisState) => thisState.position != null);
});
export const getStatesAlphabetical = createSelector([getStates], (states) => {
  return _.orderBy(states, 'name');
});

export const getForages = (state: ApplicationState) => state[reducerName][StateKeys.forages];
export const getZoneCurrentSeasons = (state: ApplicationState) =>
  state[reducerName][StateKeys.zone_current_seasons];
export const getZoneCurrentSeasonGroups = (state: ApplicationState) =>
  state[reducerName][StateKeys.zone_current_season_groups];
export const getExperiments = (state: ApplicationState) =>
  state[reducerName][StateKeys.experiments];
export const getPromoBanners = (state: ApplicationState) =>
  state[reducerName][StateKeys.promo_banners];

const _getTechniquesBySubcatTypes = (techniques: TechniqueFull[]) =>
  _.memoize(
    (subcat_type_names: string[]) => {
      return techniques.filter((t) => _.intersection(t.subcat_types, subcat_type_names).length > 0);
    },
    (subcat_type_names) => {
      return subcat_type_names.sort().join('');
    }
  );
export const getTechniquesBySubcatTypes = createSelector(
  [getTechniques],
  _getTechniquesBySubcatTypes
);

const _getTechniquesBySubcategories = (techniques: TechniqueFull[], subcategories: Subcategory[]) =>
  _.memoize(
    (subcategory_names: string[]) => {
      const subcategoriesSubcatTypes = _.flatten(
        subcategories
          .filter((s) => subcategory_names.includes(s.name))
          .map((s) => s.subcat_types.map((st) => st.name))
      );
      const techniquesBySubcatType =
        _getTechniquesBySubcatTypes(techniques)(subcategoriesSubcatTypes);
      const techniquesBySubcategory = techniques.filter(
        (t) => _.intersection(t.subcategories, subcategory_names).length > 0
      );
      return _.uniqBy(techniquesBySubcategory.concat(techniquesBySubcatType), 'name');
    },
    (subcategory_names) => {
      return subcategory_names.sort().join('');
    }
  );
export const getTechniquesBySubcategories = createSelector(
  [getTechniques, getSubcategories],
  _getTechniquesBySubcategories
);

const _getTechniquesByCategories = (
  techniques: TechniqueFull[],
  categories: Category[],
  subcategories: Subcategory[]
) =>
  _.memoize(
    (category_names: string[]) => {
      const categorySubcategories = _.flatten(
        categories
          .filter((c) => category_names.includes(c.name))
          .map((c) => c.subcategories.map((s) => s.name))
      );
      const techniquesBySubcategory = _getTechniquesBySubcategories(
        techniques,
        subcategories
      )(categorySubcategories);
      const techniquesByCategory = techniques.filter(
        (st) => _.intersection(st.categories, category_names).length > 0
      );
      return _.uniqBy(techniquesByCategory.concat(techniquesBySubcategory), 'name');
    },
    (category_names) => {
      return category_names.sort().join('');
    }
  );
export const getTechniquesByCategories = createSelector(
  [getTechniques, getCategories, getSubcategories],
  _getTechniquesByCategories
);

const _getSubcategoryBySubcatTypeName = (subcategories: Subcategory[]) =>
  _.memoize(
    (subcatTypeName: string) => {
      return subcategories.find((s) => s.subcat_types.some((st) => st.name === subcatTypeName));
    },
    (subcatTypeName) => {
      return subcatTypeName;
    }
  );
export const getSubcategoryBySubcatTypeName = createSelector(
  [getSubcategories],
  _getSubcategoryBySubcatTypeName
);

const _getCategoryBySubcategoryName = (categories: Category[]) =>
  _.memoize(
    (subcategory_name: string) => {
      return categories.find((c) => c.subcategories.some((s) => s.name === subcategory_name));
    },
    (subcategory_name) => {
      return subcategory_name;
    }
  );
export const getCategoryBySubcategoryName = createSelector(
  [getCategories],
  _getCategoryBySubcategoryName
);

// ========================================================================== //
// Reducer
// ========================================================================== //

//
// Reference data comes from initial state at the server request level
// so we have access to all the data on the first render for prefetching data
//

export default function ReferenceDataReducer(state = initialState, action: BrandsActions) {
  switch (action.type) {
    case ReduxActions.BRAND_FETCH_SUCCESS:
    case ReduxActions.BRAND_FAVORITE_SUCCESS:
    case ReduxActions.BRAND_UNFAVORITE_SUCCESS:
    case ReduxActions.BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: {
      const brand = action.payload.data;
      const newBrands = [...state[StateKeys.brands]];
      const index = _.findIndex(newBrands, { url_slug: brand.url_slug });
      newBrands.splice(index, 1, brand);

      return {
        ...state,
        [StateKeys.brands]: newBrands,
      };
    }

    default:
      return state;
  }
}
