import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { AmbassadorDetailMediaFishingReports } from '@omniafishing/core';
import { Collapse } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import { reportsWithinNumberOfDays } from '../../lib/fishing_reports';
import { RoutePaths } from '../../routes';
import { AmbassadorProfileMini } from '../ambassador_profile_mini/ambassador_profile_mini';
import { FishingReportsCarousel } from '../fishing_reports_carousel/fishing_reports_carousel';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import {
  mediaWithinNumberOfDays,
  RECENT_MEDIA_TIME_PERIOD,
  RECENT_REPORTS_TIME_PERIOD,
} from './dashboard';
import amboStyles from './dashboard_ambassadors.less';
import { DashBoardMediaCarousel } from './dashboard_media_carousel';
import styles from './dashboard_tab.less';
import { sortReportsByDateAndMedia } from './dashboard_waterbodies';

export const DashboardAmbassadors = ({
  ambassadorsRecentReportsAndMedia,
}: {
  ambassadorsRecentReportsAndMedia: AmbassadorDetailMediaFishingReports[];
}) => {
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (ambassadorsRecentReportsAndMedia.length) {
      const ambassadorsWithReportsOrMedia = ambassadorsRecentReportsAndMedia.filter(
        ({ fishing_reports, media }) => {
          return fishing_reports.length || media.length;
        }
      );
      setActivePanels(ambassadorsWithReportsOrMedia.map((item) => item.ambassador.slug));
    }
  }, [ambassadorsRecentReportsAndMedia]);

  const { Panel } = Collapse;

  const [ambassadorsWithRecentActivity, ambassadorsWithoutRecentActivity] = _.partition(
    ambassadorsRecentReportsAndMedia,
    ({ fishing_reports, media }) => fishing_reports.length || media.length
  );

  return (
    <>
      <div className={styles.topActionButtons}>
        <OmniaLinkButton
          to={RoutePaths.AMBASSADORS}
          kind="primary"
          size={isMobile ? 'md' : 'lg'}
          className={styles.addLakeButton}
        >
          <PlusCircleOutlined /> Find a New Ambassador
        </OmniaLinkButton>
        {isMobile && (
          <OmniaButton
            onPress={() => setActivePanels([])}
            size="md"
            kind="text"
            className={styles.collapseAllButton}
          >
            Collapse All
          </OmniaButton>
        )}
      </div>
      <div className={styles.collapseContainer}>
        <Collapse
          expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
          ghost
          expandIconPosition="end"
          onChange={(panels) => {
            setActivePanels(panels as string[]);
          }}
          activeKey={activePanels}
        >
          {ambassadorsWithRecentActivity.map(({ ambassador, fishing_reports, media }) => {
            const sortedAmbassadorReports = sortReportsByDateAndMedia(fishing_reports);
            const recentReports = reportsWithinNumberOfDays(
              fishing_reports,
              RECENT_REPORTS_TIME_PERIOD
            );
            const recentMedia = mediaWithinNumberOfDays(media, RECENT_MEDIA_TIME_PERIOD);
            const recentReportsCount = recentReports.length;
            const recentMediaCount = recentMedia.length;
            return (
              <Panel
                header={
                  <div className={amboStyles.amboProfileContainer}>
                    <AmbassadorProfileMini
                      ambassador={ambassador}
                      className={amboStyles.amboMini}
                      imageSize={80}
                      showLocation
                      showFollowers
                      showInfoIcon
                    />
                  </div>
                }
                key={ambassador.slug}
              >
                {sortedAmbassadorReports.length > 0 && (
                  <section className={classNames(styles.listWrapper, 'overflow-hidden')}>
                    <p className={styles.miniHeader}>
                      Recent Fishing Reports{' '}
                      {recentReportsCount > 0 && (
                        <span>
                          <span className={styles.redCircle}>
                            <span>{recentReportsCount}</span>
                          </span>
                        </span>
                      )}
                    </p>
                    <ul className={styles.list}>
                      {activePanels.includes(ambassador.slug) && (
                        <FishingReportsCarousel
                          fishingReports={sortedAmbassadorReports}
                          newReportIds={recentReports.map((r) => r.id)}
                          key={`${activePanels.includes(ambassador.slug)}carousel`}
                          className={styles.carousel}
                        />
                      )}
                    </ul>
                  </section>
                )}
                {media.length > 0 && (
                  <section className={classNames(styles.mediaWrapper, 'overflow-hidden')}>
                    <p className={styles.miniHeader}>
                      Recent Videos & Articles{' '}
                      {recentMediaCount > 0 && (
                        <span>
                          <span className={styles.redCircle}>
                            <span>{recentMediaCount}</span>
                          </span>
                        </span>
                      )}
                    </p>
                    <ul className={styles.list}>
                      <DashBoardMediaCarousel
                        media={media}
                        isPanelOpen={activePanels.includes(ambassador.slug)}
                        newMediaUids={recentMedia?.map((m) => m.uid)}
                        key={`${activePanels.includes(ambassador.slug)}carousel`}
                      />
                    </ul>
                  </section>
                )}
              </Panel>
            );
          })}
        </Collapse>
      </div>

      {ambassadorsWithoutRecentActivity.map(({ ambassador }) => {
        return (
          <div className={amboStyles.noActivityAmbos} key={ambassador.slug}>
            <div className={amboStyles.noMarginBlock}>
              <AmbassadorProfileMini
                ambassador={ambassador}
                imageSize={80}
                showLocation
                showFollowers
                className={amboStyles.amboMini}
                showInfoIcon
              />
            </div>
            <p className={amboStyles.noActivityCallOut}>No Recent Activity</p>
          </div>
        );
      })}
    </>
  );
};
