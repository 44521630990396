import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../helpers/app_state';
import { FlashMessageActions } from '../../redux/flash_message';
import {
  getArea,
  getLoadingState,
  getProduct,
  InventoryModalActions,
  isOpen,
  outOfStockSubscribe,
} from '../../redux/inventory_modal';
import { getUser } from '../../redux/user';
import InventoryModal from './inventory_modal';

function mapStateToProps(state: ApplicationState, ownProps = {}) {
  return {
    user: getUser(state),
    open: isOpen(state),
    loadingState: getLoadingState(state),
    product: getProduct(state),
    area: getArea(state),
  };
}

const dispatchProps = {
  setFlashMessage: FlashMessageActions.FLASH_MESSAGE_SET,
  close: InventoryModalActions.INVENTORY_MODAL_CLOSE,
  outOfStockSubscribe,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(dispatchProps, dispatch);

const InventoryModalContainer = connect(mapStateToProps, dispatchProps)(InventoryModal);

export type InventoryModalProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default InventoryModalContainer;
