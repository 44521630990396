import { LoadingOutlined } from '@ant-design/icons';
import { AriaButtonProps, useButton } from '@react-aria/button';
import classNames from 'classnames';
import React, { CSSProperties, useRef } from 'react';
import styles from './omnia_button.less';

export type ButtonSizes = 'sm' | 'md' | 'lg' | 'xl';
export type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'text' | 'link';

export interface OmniaButtonProps {
  block?: boolean;
  children: React.ReactNode;
  className?: string;
  fontSize?: number;
  htmlType?: 'submit';
  kind: ButtonTypes;
  loading?: boolean;
  onClick?: (e?: any) => void;
  shape?: 'rectangle' | 'square' | 'circle';
  size: ButtonSizes;
  style?: CSSProperties;
}

type OmniaButtonButtonProps = AriaButtonProps<'button'> & OmniaButtonProps;

export const omniaButtonStylesFromProps = () => {
  // TODO
};

export const OmniaButton = (props: OmniaButtonButtonProps) => {
  const { block, children, className, fontSize, kind, loading, onClick, shape, size, style } =
    props;
  const ref = useRef();
  const { buttonProps, isPressed } = useButton(props, ref);
  const optionalProps = loading ? { disabled: loading } : {};

  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, className, {
        [styles.primary]: kind === 'primary',
        [styles.secondary]: kind === 'secondary',
        [styles.tertiary]: kind === 'tertiary',
        [styles.text]: kind === 'text',
        [styles.link]: kind === 'link',
        [styles.small]: size === 'sm',
        [styles.medium]: size === 'md',
        [styles.large]: size === 'lg',
        [styles.xlarge]: size === 'xl',
        [styles.square]: shape === 'square',
        [styles.circle]: shape === 'circle',
        [styles.block]: block === true,
        [styles.pressed]: isPressed,
      })}
      {...buttonProps}
      {...optionalProps}
      style={{ fontSize, ...style }}
    >
      {loading && <LoadingOutlined style={{ fontSize: 24, width: '20px' }} spin />} {children}
    </button>
  );
};
