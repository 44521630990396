import { AlgoliaWaterbodyDetail, Waterbody } from '@omniafishing/core';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import styles from './waterbody_card.less';

interface WaterbodyCardProps {
  waterbody: Waterbody | AlgoliaWaterbodyDetail;
  onClick?: () => void;
  imgSize?: {
    width: number;
    height: number;
  };
  linkTo?: string;
}

export const WaterbodyCard = (props: WaterbodyCardProps) => {
  const { waterbody, onClick, imgSize, linkTo } = props;

  const states = waterbody.locales.map((l) => l.state.abbr);
  const counties = _.flatten(waterbody.locales.map((l) => l.counties));
  const linkProps = {
    to: linkTo || OmniaUrls.waterbody(waterbody),
    className: styles.tileLink,
    onClick: () => {
      onClick?.();
    },
  };
  return (
    <div className="flex flex-col">
      <Link {...linkProps}>
        <WaterbodyImg waterbody={waterbody} className={styles.img} size={imgSize} />
      </Link>
      <Link {...linkProps}>
        <span className={styles.name}>{waterbody.primary_name}</span>
      </Link>
      {(states.length > 0 || counties.length > 0) && (
        <span className={styles.locations}>
          {states.join(', ')}, {counties.join(', ')}
        </span>
      )}
    </div>
  );
};
