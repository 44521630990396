import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getUser } from '../../redux/user';
import { DashboardPaths, RoutePaths } from '../../routes';
import styles from './dashboard_nav.less';

const DashboardNav = () => {
  const user = useSelector(getUser);

  return (
    <div>
      <ul className={styles.list}>
        <li className={styles.item}>
          <NavLink
            exact={true}
            to={`${RoutePaths.DASHBOARD}`}
            className={styles.link}
            activeClassName={styles.link__active}
          >
            Dashboard
          </NavLink>
        </li>

        <li className={styles.item}>
          <NavLink
            exact={true}
            to={`${RoutePaths.DASHBOARD}/${DashboardPaths.ORDERS}`}
            className={styles.link}
            activeClassName={styles.link__active}
          >
            Order History
          </NavLink>
        </li>

        <li className={styles.item}>
          <NavLink
            exact={true}
            to={`${RoutePaths.DASHBOARD}/${DashboardPaths.EMAIL_PREFERENCES}`}
            className={styles.link}
            activeClassName={styles.link__active}
          >
            Email Alerts
          </NavLink>
        </li>

        {user?.wishlist_hash && (
          <li className={styles.item}>
            <NavLink
              exact={true}
              to={`${RoutePaths.WISHLIST}/${user?.wishlist_hash}`}
              className={styles.link}
              activeClassName={styles.link__active}
            >
              Wishlist
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DashboardNav;
