import { AriaButtonProps, useButton } from '@react-aria/button';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { OmniaButtonProps } from './omnia_button';
import styles from './omnia_button.less';

type OmniaLinkButtonProps = AriaButtonProps<'a'> & OmniaButtonProps & { to: LinkProps['to'] };

export const OmniaLinkButton = (props: OmniaLinkButtonProps) => {
  const { children, className, kind, size, block, to, shape, fontSize, style } = props;
  const ref = useRef();
  const { buttonProps, isPressed } = useButton({ ...props, elementType: 'a' }, ref);

  return (
    <Link
      className={classNames(styles.button, className, {
        [styles.primary]: kind === 'primary',
        [styles.secondary]: kind === 'secondary',
        [styles.tertiary]: kind === 'tertiary',
        [styles.text]: kind === 'text',
        [styles.link]: kind === 'link',
        [styles.small]: size === 'sm',
        [styles.medium]: size === 'md',
        [styles.large]: size === 'lg',
        [styles.xlarge]: size === 'xl',
        [styles.block]: block === true,
        [styles.square]: shape === 'square',
        [styles.circle]: shape === 'circle',
        [styles.pressed]: isPressed,
      })}
      to={to}
      {...buttonProps}
      style={{ fontSize, ...style }}
    >
      {children}
    </Link>
  );
};
