import { boot, shutdown, update } from '@intercom/messenger-js-sdk';
import { NextFunction } from 'connect';
import { Store } from 'redux';
import { ReduxActions } from '../constants/redux_actions';
import { getEnv } from '../env';
import { AuthActions } from './auth';
import { UserActions } from './user';

export const INTERNALS = {
  getIntercom: () => ({
    update,
    shutdown,
    boot,
  }),
};

const env = getEnv();

const createIntercomMiddleware =
  () => (store: Store) => (next: NextFunction) => (action: UserActions | AuthActions) => {
    if (action) {
      switch (action.type) {
        case ReduxActions.USER_FETCH_SUCCESS: {
          const user = action.payload.data;
          const intercom = INTERNALS.getIntercom();
          intercom.update({
            email: user.email,
            name: user.full_name,
            user_id: user.id.toString(),
          });
          break;
        }

        case ReduxActions.AUTH_LOGIN_SUCCESS:
        case ReduxActions.AUTH_SIGNUP_SUCCESS: {
          const { user } = action.payload.data;
          const intercom = INTERNALS.getIntercom();
          intercom.update({
            email: user.email,
            name: user.full_name,
            user_id: user.id.toString(),
          });
          break;
        }

        case ReduxActions.AUTH_LOGOUT: {
          const intercom = INTERNALS.getIntercom();
          intercom.shutdown();
          intercom.boot({
            app_id: env.INTERCOM_APP_ID,
          });
          break;
        }

        default:
          break;
      }
    }

    return next(action);
  };

const IntercomMiddleware = createIntercomMiddleware();

export default IntercomMiddleware;
