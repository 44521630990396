import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { FormattedExecutionResult, GraphQLError } from 'graphql';
import 'isomorphic-fetch';
import possibleTypes from '../../possibleTypes.json';

export type ExecutionResultWorkaround<T> = Omit<FormattedExecutionResult<T>, 'errors'> & {
  errors?: readonly GraphQLError[];
};

const cache = new InMemoryCache({ possibleTypes });

const httpLink = new HttpLink({
  uri:
    process.env.SHOPIFY_GRAPHQL_ENDPOINT ||
    'https://omnia-fishing.myshopify.com/api/2024-04/graphql.json',
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: any }) => ({
    headers: {
      'X-Shopify-Storefront-Access-Token':
        process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN || '422b2444dfe54529ddc6697d888017d0',
      ...headers,
    },
  }));
  return forward(operation);
});

export const shopifyClientInstance = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});
