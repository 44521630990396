interface CustomersSummary {
  summary: string;
  attributes: {
    name: string;
    summary: string;
    positive_count: number;
    negative_count: number;
    neutral_count: number;
    evidence: {
      excerpt: string;
      source_fishing_report_id: number;
    }[];
  }[];
}

export const summaries: Record<string, CustomersSummary> = {
  'outkast-tackle-chicken-jig': {
    summary:
      'The large hair jig has emerged as a premier tool for targeting quality bass, particularly excelling in deeper water scenarios around structure. Anglers consistently choose it for its versatility in mimicking larger forage and its effectiveness when fished around submerged vegetation, rock piles, and ledges. The product particularly shines during summer and fall patterns when bass are actively feeding on bluegill schools.',
    attributes: [
      {
        name: 'Deep Water Structure Performance',
        summary:
          'Anglers consistently report success using the hair jig around deeper structure, particularly weed edges, rock piles, and hard bottom areas.',
        positive_count: 11,
        negative_count: 0,
        neutral_count: 1,
        evidence: [
          {
            excerpt:
              'Found some fish chasing schools of bluegill on a point with hard bottom and submerged veg.. Started fishing the school by popping the hair off the bottom to catch larger fish.',
            source_fishing_report_id: 32463,
          },
          {
            excerpt:
              'Quality bass off deeper hard spots and bluegills beds on deeper weed edges. Chicken jig was best',
            source_fishing_report_id: 31770,
          },
          {
            excerpt:
              'Fish were still out deep chasing bluegill schools up and down weed points. Started with a chicken jig and never really took it out of my hand.',
            source_fishing_report_id: 24591,
          },
        ],
      },
      {
        name: 'Forage Imitation Capability',
        summary:
          'The hair jig proves especially effective when mimicking natural forage, particularly bluegill and cisco.',
        positive_count: 8,
        negative_count: 0,
        neutral_count: 0,
        evidence: [
          {
            excerpt: 'Launch a big hair jig around to cover water and imitate a big cisco',
            source_fishing_report_id: 4402,
          },
          {
            excerpt:
              'Throwing a big hair jig on rock spines and weed edges where I marked gills. Fun bite!',
            source_fishing_report_id: 15277,
          },
          {
            excerpt:
              'The fish are schooling off main lake points and deep grass edges... the bigger fish can be caught on large hair jigs fished along those same deeper grass edges.',
            source_fishing_report_id: 3377,
          },
        ],
      },
      {
        name: 'Technique Versatility',
        summary:
          'Anglers describe multiple successful retrieval methods, from slow rolling to stop-and-go techniques.',
        positive_count: 8,
        negative_count: 0,
        neutral_count: 1,
        evidence: [
          {
            excerpt:
              'Just use a sweeping motion with the rod, and follow the bait down. Because almost all of your bites will come as the bait is falling.',
            source_fishing_report_id: 16577,
          },
          {
            excerpt:
              'A stop and go retrieve was what he suggested, let it fall to the bottom then reel a couple turns, drop to the bottom and repeat as soon as it hits bottom - watch the line.',
            source_fishing_report_id: 4364,
          },
          {
            excerpt:
              'Slow rolling over the tops of the weeds. The new northland Minnesota mullet is pretty dang sweet!',
            source_fishing_report_id: 34008,
          },
        ],
      },
      {
        name: 'Quality Fish Production',
        summary: "Multiple reports emphasize the bait's effectiveness in catching larger bass.",
        positive_count: 7,
        negative_count: 0,
        neutral_count: 0,
        evidence: [
          {
            excerpt:
              'He was throwing an Outkast Chicken Jig, casting to submerged weeds, milfoil, coontail... the big heavy hair jig caught more of the quality bass.',
            source_fishing_report_id: 4364,
          },
          {
            excerpt: 'Fishing the chicken jig just off the edge produced the biggest fish.',
            source_fishing_report_id: 12074,
          },
          {
            excerpt:
              "If you don't have some large hair jigs in your arsenal, definitely give these a try.",
            source_fishing_report_id: 16577,
          },
        ],
      },
      {
        name: 'Gear Pairing Requirements',
        summary: 'Anglers note specific gear recommendations for optimal performance.',
        positive_count: 2,
        negative_count: 0,
        neutral_count: 0,
        evidence: [
          {
            excerpt:
              "Using a longer rod like a 7'6 Md Hvy is a great rod for this type of fishing. And match that with Sufix Advance Flouro in 17lb and you're set.",
            source_fishing_report_id: 16577,
          },
        ],
      },
      {
        name: 'Seasonal Effectiveness',
        summary:
          'Reports indicate strong performance across summer and fall patterns, particularly when fish are actively feeding.',
        positive_count: 10,
        negative_count: 0,
        neutral_count: 1,
        evidence: [
          {
            excerpt:
              'Foggy morning. Air temp was 45, water temp 68. Fall temps are starting... Fish were still out deep chasing bluegill schools',
            source_fishing_report_id: 24591,
          },
          {
            excerpt:
              'Finding good bright green climbs of coontail next to 15-20 ft of water was key. Fishing the chicken jig just off the edge produced the biggest fish.',
            source_fishing_report_id: 12074,
          },
        ],
      },
    ],
  },
};
