import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { getExperimentUuid, UserActions } from '../../redux/user';

// syncs experiment ids from rudderstack to redux
export const ExperimentHandler = (): null => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasAccessToken);
  const experimentUuid = useSelector(getExperimentUuid);
  const { anonymousId } = useRudderstack();

  useEffect(() => {
    if (anonymousId) {
      const userTraitsExperimentUuid = WebAnalytics.getRudderstackExperimentUuid();
      const experiment_uuid = userTraitsExperimentUuid || experimentUuid;
      if (isLoggedIn) {
        // for omnia users, identify with experiment ids happens on user fetch
        dispatch(UserActions.USER_EXPERIMENT_GROUP_ID_READY());
      } else if (experiment_uuid) {
        dispatch(
          UserActions.USER_EXPERIMENT_IDS_SET({
            uuid: experiment_uuid,
          })
        );
      }
    }
  }, [anonymousId]);

  return null;
};
