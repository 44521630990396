import { FastForwardOutlined } from '@ant-design/icons';
import { OmniaVideoItemSpecies } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { getImgixPath } from '../../lib/imgix';
import { formatSecondsToMinutes } from '../../lib/videos';
import { VideoItemProps } from './video_item';
import styles from './video_item_sidebar_product.less';
import { OmniaButton } from '../omnia_button/omnia_button';

export const VideoItemSidebarSpecies = (
  props: VideoItemProps & {
    omnia_video_item: OmniaVideoItemSpecies;
    selected: boolean;
    showJump: boolean;
  }
) => {
  const { onClick, omnia_video_item, onJumpClick, selected, showJump } = props;
  const { item, start_sec, end_sec, comments } = omnia_video_item;
  const { display_name, image } = item;
  const isLive = end_sec == null;

  return (
    <div
      className={classNames(styles.productSidebar, {
        [styles.productSidebar__selected]: selected,
        [styles.productSidebar__live]: isLive,
      })}
    >
      <div className={styles.content}>
        {image && (
          <div onClick={onClick} className={styles.productSidebarImg}>
            <img
              src={getImgixPath(image, { w: 200 })}
              alt={display_name}
              loading="lazy"
              width="200"
              height="200"
            />
          </div>
        )}

        <div className={styles.productSidebarText}>
          <p className={styles.productSidebarTitle} onClick={onClick}>
            Species: {display_name}
          </p>
          {showJump && (
            <p className={styles.productSidebarJump} onClick={onJumpClick}>
              <FastForwardOutlined className={styles.productSidebarJumpIcon} />
              Jump to {formatSecondsToMinutes(start_sec)}
            </p>
          )}
        </div>

        <div className={styles.productSidebarButtonPrice}>
          <OmniaButton
            kind="secondary"
            size="md"
            block
            fontSize={14}
            onClick={onClick}
            className={styles.productSidebarButton}
          >
            Learn More
          </OmniaButton>
        </div>
      </div>
      {comments && <div className={styles.comments}>{comments}</div>}
    </div>
  );
};
