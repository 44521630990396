import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { apiV1 } from '../../lib/api';
import { WebAnalytics } from '../../lib/web_analytics';
import { FlashMessageActions } from '../../redux/flash_message';
import { getIp } from '../../redux/geographic_location';
import { RoutePaths } from '../../routes';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './newsletter_form.less';

export interface NewsletterFormProps {
  context:
    | 'footer'
    | 'newsletter_page'
    | 'get_started_modal'
    | 'get_started_embed'
    | 'newsletter_popup'
    | 'best_of';
  onSuccess?: () => void;
}

export const NewsletterForm = (props: NewsletterFormProps) => {
  const { context, onSuccess } = props;

  const { isMobile } = useResponsive();
  const ipAddress = useSelector(getIp);
  const dispatch = useDispatch();
  const history = useHistory();
  const { anonymousId } = useRudderstack();
  const { stringify } = useQueryString();

  return (
    <section>
      <Form
        className={classNames(styles.newsletterForm, {
          [styles.newsletterForm__footer]: context === 'footer' || context === 'get_started_embed',
          [styles.newsletterForm__newsletterPage]: context === 'newsletter_page',
          [styles.newsletterForm__modal]: context === 'best_of',
          [styles.newsletterForm__newsletter_popup]: context === 'newsletter_popup',
        })}
        layout={isMobile || context === 'newsletter_popup' ? 'vertical' : 'inline'}
        onFinish={(values) => {
          const location: Record<NewsletterFormProps['context'], string> = {
            footer: 'Footer',
            get_started_modal: 'Get Started Modal',
            get_started_embed: 'Get Started Embed',
            newsletter_page: 'Subscribe',
            newsletter_popup: 'Widget',
            best_of: 'Best Of',
          };
          apiV1
            .newsletterSubscribe({
              email: values.email,
              anonymous_id: anonymousId,
              user_agent: window.navigator.userAgent,
              ip_address: ipAddress,
              location: location[context],
            })
            .then((res) => {
              dispatch(
                FlashMessageActions.FLASH_MESSAGE_SET({
                  header: "You're signed up!",
                  subheader: 'Thank you for joining the Omnia Fishing Newsletter',
                })
              );
              if (context === 'newsletter_page') {
                history.push(
                  `${RoutePaths.NEWSLETTER_SUCCESS}?${stringify({
                    email: values.email,
                  })}`
                );
              }
              if (context === 'get_started_modal') {
                apiV1.eventTrack('Get Started Newsletter Modal Subscribe', anonymousId, {
                  email: values.email,
                });
              }
              onSuccess?.();
              WebAnalytics.followUpEnabled(values.email);
            });
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input type="email" placeholder="Email Address" prefix={<MailOutlined />} />
        </Form.Item>
        <Form.Item className={styles.submitButton}>
          <OmniaButton size="lg" kind="primary" type="submit" className={styles.newsletterButton}>
            Sign Up Now
          </OmniaButton>
        </Form.Item>
      </Form>
    </section>
  );
};
