import { FishingReport, SeasonGroup } from '@omniafishing/core';
import { Radio } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useUserPreferences } from '../../../hooks/use_user_preferences';
import { isCurrentSeasonGroupText } from '../../../lib/seasons';
import { getIpCurrentSeasonGroup, getIpZone } from '../../../redux/geographic_location';
import { getSeasonGroups } from '../../../redux/reference_data';
import { getSeasonGroupCounts } from '../filters';
import { removeIceSeasonPerUserZone } from '../season_group_select';
import styles from './all_filters_drawer.less';

interface SeasonGroupRadiosProps {
  onChange: (val: string) => void;
  fishingReports: FishingReport[];
  drawerSeasonGroupName: string;
  drawerProductSku: string;
  drawerSpeciesName: string;
}

export const convertSeasonGroupNameToSeasonGroup = (
  seasonGroupName: string,
  seasonGroups: SeasonGroup[]
) => {
  return seasonGroups.find((season) => season.name === seasonGroupName);
};

export const SeasonGroupRadios = (props: SeasonGroupRadiosProps) => {
  const seasonGroups = useSelector(getSeasonGroups);
  const ipZone = useSelector(getIpZone);
  const ipCurrentSeasonGroup = useSelector(getIpCurrentSeasonGroup);
  const { setUserPreferencesSeasonGroup } = useUserPreferences();
  const { fishingReports, onChange, drawerSpeciesName, drawerProductSku, drawerSeasonGroupName } =
    props;

  const zoneSpecificSeasonGroups = removeIceSeasonPerUserZone(ipZone, seasonGroups);

  const seasonGroupCounts = getSeasonGroupCounts({
    zoneSpecificSeasonGroups,
    selectedProductSku: drawerProductSku,
    selectedSpeciesName: drawerSpeciesName,
    fishingReports,
  });

  return (
    <div className={styles.radios}>
      <Radio.Group
        onChange={(e) => {
          onChange(e.target.value);
          setUserPreferencesSeasonGroup(e.target.value);
        }}
        value={drawerSeasonGroupName}
      >
        <Radio value={undefined} className={styles.radioLabel}>
          <span>
            <span>All Seasons</span>
          </span>
        </Radio>
        {zoneSpecificSeasonGroups.map((seasonGroup, i) => {
          return (
            <Radio
              value={seasonGroup.name}
              className={styles.radioLabel}
              key={seasonGroup.name}
              disabled={seasonGroupCounts[i] === 0}
            >
              <span className={styles.selectText}>{`${
                seasonGroup.display_name
              }${isCurrentSeasonGroupText(seasonGroup, ipCurrentSeasonGroup)}`}</span>
              <span className={styles.selectLabelReportCount}>{seasonGroupCounts[i]}</span>
            </Radio>
          );
        })}
      </Radio.Group>
    </div>
  );
};
