import { FishingReport } from '@omniafishing/core';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  fishingReportContainsMedia,
  fishingReportContainsSeasonGroup,
  fishingReportContainsSku,
  fishingReportContainsSpecieName,
} from '../../../lib/fishing_reports';
import { getSeasonGroups } from '../../../redux/reference_data';
import styles from './all_filters_drawer.less';

interface MediaCheckboxesProps {
  drawerMedia: CheckboxValueType[];
  fishingReports: FishingReport[];
  onChange: (val: CheckboxValueType[]) => void;
  drawerProductSku: string;
  drawerSeasonGroupName: string;
  drawerSpeciesName: string;
}
export interface FilteringMediaType {
  name: 'video' | 'image';
  display_name: string;
}

export const FILTERING_MEDIA_TYPES: FilteringMediaType[] = [
  { name: 'image', display_name: 'Only Show Reports with Images' },
  { name: 'video', display_name: 'Only Show Reports with Videos' },
];

export const MediaCheckboxes = (props: MediaCheckboxesProps) => {
  const seasonGroups = useSelector(getSeasonGroups);

  const {
    drawerMedia,
    fishingReports,
    onChange,
    drawerProductSku,
    drawerSeasonGroupName,
    drawerSpeciesName,
  } = props;

  const SeasonGroupOfSeasonGroupName = seasonGroups.find(
    (season) => season.name === drawerSeasonGroupName
  );

  const countsOfMediaMatchesFilteredByProductSeasonSpecies: number[] = FILTERING_MEDIA_TYPES.map(
    (media) => {
      if (drawerProductSku || drawerSeasonGroupName || drawerSpeciesName) {
        return fishingReports.filter((report) => {
          const reportContainsMedia = fishingReportContainsMedia(report, media.name);
          const reportContainsProductSku =
            drawerProductSku && fishingReportContainsSku(report, drawerProductSku);
          const reportContainsSeasonGroup =
            drawerSeasonGroupName &&
            fishingReportContainsSeasonGroup(report, SeasonGroupOfSeasonGroupName);
          const reportContainsSpeciesName = fishingReportContainsSpecieName(
            report,
            drawerSpeciesName
          );

          if (!!drawerSeasonGroupName && !!drawerProductSku && !!drawerSpeciesName) {
            return (
              reportContainsMedia &&
              reportContainsSeasonGroup &&
              reportContainsSpeciesName &&
              reportContainsProductSku
            );
          } else if (!!drawerProductSku && !!drawerSeasonGroupName) {
            return reportContainsMedia && reportContainsProductSku && reportContainsSeasonGroup;
          } else if (!!drawerProductSku && !!drawerSpeciesName) {
            return reportContainsMedia && reportContainsProductSku && reportContainsSeasonGroup;
          } else if (!!drawerSeasonGroupName && !!drawerSpeciesName) {
            return reportContainsMedia && reportContainsSeasonGroup && reportContainsSpeciesName;
          } else if (!!drawerProductSku) {
            return reportContainsMedia && reportContainsProductSku;
          } else if (!!drawerSeasonGroupName) {
            return reportContainsMedia && reportContainsSeasonGroup;
          } else if (!!drawerSpeciesName) {
            return reportContainsMedia && reportContainsSpeciesName;
          }
        }).length;
      }

      return fishingReports.filter((report) => {
        return fishingReportContainsMedia(report, media.name);
      }).length;
    }
  );

  return (
    <div className={styles.mediaCheckboxes}>
      <Checkbox.Group
        onChange={(checkedValues: CheckboxValueType[]) => {
          onChange(checkedValues);
        }}
        value={drawerMedia}
        className={styles.checkboxGroup}
      >
        {FILTERING_MEDIA_TYPES.map((media, i) => {
          return (
            <Checkbox
              value={media.name}
              className={styles.checkboxLabel}
              key={media.name}
              disabled={countsOfMediaMatchesFilteredByProductSeasonSpecies[i] === 0}
            >
              <span>{media.display_name}</span>
              <span className={styles.checkboxLabelReportCount}>
                {countsOfMediaMatchesFilteredByProductSeasonSpecies[i]}
              </span>
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </div>
  );
};
