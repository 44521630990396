import _ from 'lodash';
import { useEffect } from 'react';
import { WindowListenerProps } from './window_listener_container';

export const WindowListener = (props: WindowListenerProps): null => {
  const { setSize, setLoaded } = props;

  useEffect(() => {
    setSize(window.innerWidth, window.innerHeight);
    window.addEventListener(
      'resize',
      _.debounce(() => {
        setSize(window.innerWidth, window.innerHeight);
      }, 100)
    );

    window.addEventListener('load', () => {
      setLoaded();
    });
  }, []);

  return null;
};
