import { FishingReport } from '@omniafishing/core';
import { getTechniqueByTechniqueName } from '../../redux/reference_data';
import { SeasonIcon } from '../season_icon/season_icon';
import { useSelector } from 'react-redux';
import React from 'react';
import styles from './report_list_details.less';
import SvgThermometerAir from '../svg/thermometer_air';

interface ReportListDetailsProps {
  report: FishingReport;
}
export const ReportListDetails = (props: ReportListDetailsProps) => {
  const { report } = props;
  const { species, season, technique, structure, forage, water_temperature } = report;

  const techniqueByTechniqueName = useSelector(getTechniqueByTechniqueName);

  return (
    <ul className={styles.listDetails}>
      <li className={styles.listDetail}>
        <p className={styles.reportProperty}>Species</p>
        <p className={styles.detail}>
          {species.image && <img src={species.image} />}
          {species.display_name}
        </p>
      </li>
      <li className={styles.listDetail}>
        <p className={styles.reportProperty}>Season</p>
        <p className={styles.detail}>
          <SeasonIcon seasonName={season.name} className={styles.seasonIcon} tag={'span'} />
          {season.display_name}
        </p>
      </li>
      <li className={styles.technique}>
        <p className={styles.reportProperty}>Technique</p>
        <p>{techniqueByTechniqueName(technique.name).display_name_alternate}</p>
      </li>
      <li>
        <p className={styles.reportProperty}>Structure</p>
        <p>{structure.display_name}</p>
      </li>
      {forage && (
        <li>
          <p className={styles.reportProperty}>Forage</p>
          <p>{forage.display_name}</p>
        </li>
      )}
      {water_temperature && (
        <li>
          <p className={styles.reportProperty}>Water Temperature</p>
          <p className={styles.waterTempMobile}>
            <span className={styles.thermIcon}>
              <SvgThermometerAir />
            </span>{' '}
            {water_temperature}°
          </p>
        </li>
      )}
    </ul>
  );
};
