import { Product } from '@omniafishing/core';
import { Select, Space } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './product_detail_fishing_reports.less';

interface ProductSelectProps {
  productCounts: number[];
  onChange: (val: string) => void;
  productsInFishingReports: Product[];
  selectedProductSku: string;
}

export const ProductSelect = (props: ProductSelectProps) => {
  const { productCounts, onChange, productsInFishingReports, selectedProductSku } = props;

  const defaultProductValue = {
    label: (
      <span className={styles.selectLabel__product}>
        <span>All Variations</span>
      </span>
    ),
    value: null as string,
  };

  const productSelectOptions = [defaultProductValue].concat(
    productsInFishingReports.map((product, i) => {
      return {
        label: (
          <span className={styles.selectLabel__product}>
            <span className={styles.selectLabelImg}>
              <img src={product.img_url} alt="" />
            </span>
            <span className={styles.selectText}>
              {product?.shopify_options.map((option) => option.value).join(' / ')}
            </span>
            <span className={styles.selectLabelReportCount__product}>{productCounts[i]}</span>
          </span>
        ),
        value: product?.sku,
        disabled: productCounts[i] === 0,
      };
    })
  );

  return (
    <div className={styles.selectContainer__product}>
      <Space>
        <Select
          options={productSelectOptions}
          value={selectedProductSku}
          onChange={(val) => {
            onChange(val);
            WebAnalytics.contentFiltered('product', val);
          }}
          className={classNames(styles.productSelect, styles.selects)}
          dropdownStyle={{ minWidth: '300px' }}
        />
      </Space>
    </div>
  );
};
