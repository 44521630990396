import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { EmblaCarouselType } from 'embla-carousel';
import { getImgixPath } from '../../lib/imgix';
import { getPage } from '../../redux/pages';
import { NextButton, PrevButton } from './carousel_buttons';
import { OmniaLink } from '../omnia_link/omnia_link';
import { parseMediaItemsFromPage } from '../home/parse_media_items_from_page';
import { useResponsive } from '../../hooks/use_responsive';
import { useSelector } from 'react-redux';
import Autoplay from 'embla-carousel-autoplay';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './home_promo_carousel.less';
import useEmblaCarousel from 'embla-carousel-react';

export const getSelectedCarouselIndex = (emblaApi: EmblaCarouselType) =>
  emblaApi.selectedScrollSnap();

export const HomePromoCarouselNew = () => {
  const page = useSelector(getPage);
  const mediaItems = parseMediaItemsFromPage(page);

  const { isMobile, isLargeTablet, isSmallDesktop, isLargeDesktop, isDesktop } = useResponsive();
  const isCarouselVertical = isLargeDesktop || isLargeTablet;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'start',
      loop: true,
      dragFree: true,
      axis: isCarouselVertical ? 'y' : 'x',
    },
    [Autoplay({ delay: 5000, stopOnMouseEnter: true })]
  );

  const clickHandler = useCallback((i: number) => {
    setSelectedIndex(i);
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.on('select', () => {
      setSelectedIndex(getSelectedCarouselIndex(emblaApi));
    });

    return () => emblaApi.destroy();
  }, [emblaApi]);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    setSelectedIndex(getSelectedCarouselIndex(emblaApi));
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setSelectedIndex(getSelectedCarouselIndex(emblaApi));
  }, [emblaApi]);

  const onSelect = useCallback((carouselApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!carouselApi.canScrollPrev());
    setNextBtnDisabled(!carouselApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  const showWideImage = isSmallDesktop || isMobile;

  if (!page || !mediaItems.length) {
    return null;
  }

  const imageByIndex = (index: number) => mediaItems[index % mediaItems.length];

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.heroContainer}>
        <OmniaLink to={imageByIndex(selectedIndex).linkUrl}>
          <img
            src={
              showWideImage
                ? getImgixPath(imageByIndex(selectedIndex).srcWide, { w: isMobile ? 600 : 1000 })
                : getImgixPath(imageByIndex(selectedIndex).src, { w: 700 })
            }
            alt=""
          />
        </OmniaLink>
      </div>

      <div className={styles.thumbs}>
        {isDesktop && (
          <PrevButton
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
            className={styles.carouselButton}
          >
            {isCarouselVertical ? <UpOutlined /> : <LeftOutlined />}
          </PrevButton>
        )}
        <div className={styles.thumbs__viewport} ref={emblaRef}>
          <div className={styles.thumbs__container}>
            {mediaItems.length > 0 &&
              mediaItems.map((item, i) => (
                <div
                  className={classNames(styles.thumbs__slide, {
                    [styles.thumbs__slide__selected]: i === selectedIndex,
                  })}
                  key={item.src + i}
                >
                  <button
                    onClick={() => clickHandler(i)}
                    className={styles.thumbs__slide__button}
                    type="button"
                  >
                    <img
                      className={styles.thumbs__slide__img}
                      src={getImgixPath(item.srcWide, { w: 335 })}
                      alt=""
                    />
                  </button>
                </div>
              ))}
          </div>
        </div>
        {isDesktop && (
          <NextButton
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
            className={styles.carouselButton}
          >
            {isCarouselVertical ? <DownOutlined /> : <RightOutlined />}
          </NextButton>
        )}
      </div>
    </div>
  );
};
