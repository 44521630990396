import { getZoneByState, Season, SeasonGroup } from '@omniafishing/core';
import Axios from 'axios';
import { ViewState } from 'react-map-gl';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { getEnv, getServerEnv, ServerEnvs } from '../env';
import { ApplicationState } from '../helpers/app_state';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';
import { ZoneCurrentSeasonGroups, ZoneCurrentSeasons } from './reference_data';

export const reducerName = 'geographic_location';
export type reducerType = typeof initialState;

export enum StateKeys {
  loadingState = 'loadingState',
  ip = 'ip',
  latitude = 'latitude',
  longitude = 'longitude',
  zoom = 'zoom',
  ipState = 'ipState',
  ipLatitude = 'ipLatitude',
  ipLongitude = 'ipLongitude',
  ipCurrentSeason = 'ipCurrentSeason',
  ipCurrentSeasonGroup = 'ipCurrentSeasonGroup',
  ipZone = 'ipZone',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.ip]: null as string,
  [StateKeys.latitude]: 44.9654934002428,
  [StateKeys.longitude]: -93.23362934311228,
  [StateKeys.zoom]: 9.5,
  [StateKeys.ipState]: null as string | null,
  [StateKeys.ipLatitude]: null as number,
  [StateKeys.ipLongitude]: null as number,
  [StateKeys.ipCurrentSeason]: null as Season,
  [StateKeys.ipCurrentSeasonGroup]: null as SeasonGroup,
  [StateKeys.ipZone]: null as 1 | 2 | 3,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getIp = (state: ApplicationState) => state[reducerName][StateKeys.ip];
export const getLatitude = (state: ApplicationState) => state[reducerName][StateKeys.latitude];
export const getLongitude = (state: ApplicationState) => state[reducerName][StateKeys.longitude];
export const getZoom = (state: ApplicationState) => state[reducerName][StateKeys.zoom];
export const getIpState = (state: ApplicationState) => state[reducerName][StateKeys.ipState];
export const getIpLatitude = (state: ApplicationState) => state[reducerName][StateKeys.ipLatitude];
export const getIpLongitude = (state: ApplicationState) =>
  state[reducerName][StateKeys.ipLongitude];
export const getIpZone = (state: ApplicationState) => state[reducerName][StateKeys.ipZone];
export const getIpCurrentSeason = (state: ApplicationState) =>
  state[reducerName][StateKeys.ipCurrentSeason];
export const getIpCurrentSeasonGroup = (state: ApplicationState) =>
  state[reducerName][StateKeys.ipCurrentSeasonGroup];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function geographicLocationReducer(
  state = initialState,
  action: LocationActions
): reducerType {
  switch (action.type) {
    case ReduxActions.LOCATION_FETCH_SUCCESS: {
      const zone = getZoneByState(action.payload.region_code);
      const zoneCurrentSeason = action.payload.zone_current_seasons[zone];
      const zoneCurrentSeasonGroup = action.payload.zone_current_season_groups[zone];

      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.latitude]: Number(action.payload.latitude),
        [StateKeys.longitude]: Number(action.payload.longitude),
        [StateKeys.ipState]: action.payload.region_code,
        [StateKeys.ipLatitude]: Number(action.payload.latitude),
        [StateKeys.ipLongitude]: Number(action.payload.longitude),
        [StateKeys.ipZone]: zone,
        [StateKeys.ipCurrentSeason]: zoneCurrentSeason,
        [StateKeys.ipCurrentSeasonGroup]: zoneCurrentSeasonGroup,
      };
    }

    case ReduxActions.LOCATION_UPDATE: {
      return {
        ...state,
        [StateKeys.latitude]: action.payload.latitude,
        [StateKeys.longitude]: action.payload.longitude,
        [StateKeys.zoom]: action.payload.zoom,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export interface IpStackResponse {
  longitude: string;
  latitude: string;
  region_code: string;
  // there's a lot more that we don't use
}
export interface FetchLocationAdditional {
  zone_current_seasons: ZoneCurrentSeasons;
  zone_current_season_groups: ZoneCurrentSeasonGroups;
}

export const LocationActions = {
  LOCATION_FETCH_PENDING: () => createAction(ReduxActions.LOCATION_FETCH_PENDING),
  LOCATION_FETCH_SUCCESS: (response: IpStackResponse & FetchLocationAdditional) =>
    createAction(ReduxActions.LOCATION_FETCH_SUCCESS, response),
  LOCATION_FETCH_ERROR: (err: any) => createAction(ReduxActions.LOCATION_FETCH_ERROR, err),

  LOCATION_UPDATE: (viewport: Partial<ViewState>) =>
    createAction(ReduxActions.LOCATION_UPDATE, viewport),
};
export type LocationActions = ActionsUnion<typeof LocationActions>;

export const ipStackUrl = (ipAddress: string) => {
  const isLocalhost = getServerEnv() === ServerEnvs.LOCAL;
  const protocol = isLocalhost ? 'http' : 'https';
  return `${protocol}://api.ipstack.com/${ipAddress}?access_key=${getEnv().IP_STACK_KEY}`;
};

// This is no longer used
export function fetchGeographicLocationByIp(ipAddress: string): RequestThunk {
  return (dispatch, getState) => {
    dispatch(LocationActions.LOCATION_FETCH_PENDING());

    const { reference_data } = getState();
    const { zone_current_seasons, zone_current_season_groups } = reference_data;

    return Axios.get<IpStackResponse>(ipStackUrl(ipAddress))
      .then((response) => {
        return dispatch(
          LocationActions.LOCATION_FETCH_SUCCESS({
            ...response.data,
            zone_current_seasons,
            zone_current_season_groups,
          })
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchGeographicLocationByIp: ${ipAddress}`, error);
        return dispatch(LocationActions.LOCATION_FETCH_ERROR(error));
      });
  };
}
