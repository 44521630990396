export function waitFor(condition: () => boolean, timeoutMs?: number) {
  return new Promise<void>((resolve, reject) => {
    const check = () => {
      if (!condition()) {
        return;
      }
      clearInterval(interval);
      resolve();
    };

    const interval = setInterval(check, 100);
    check();

    if (!timeoutMs) {
      return;
    }

    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, timeoutMs);
  });
}
