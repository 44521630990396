import { fatSwingOptions } from './keitech_fast_swing';
import { Link } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { Product } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import styles from './product_variant_selector.less';
import _ from 'lodash';

interface FatSwingOptionProps {
  selectedProduct: Product | null;
}
export const KeitechFatSwingAddOn = (props: FatSwingOptionProps) => {
  const { selectedProduct } = props;
  const selectedFatSwingOption = fatSwingOptions.find(
    (o) => o.handle === selectedProduct?.shopify_product_handle
  );
  const fatSwingOptionsOrdered = _.uniqBy([selectedFatSwingOption, ...fatSwingOptions], 'handle');

  return (
    <div className={styles.fieldset}>
      <legend className={styles.optionLegend}>
        Length: <span className={styles.optionName}>{selectedFatSwingOption?.text} </span>
      </legend>
      {fatSwingOptionsOrdered.map((option) => {
        if (selectedFatSwingOption.handle === option.handle) {
          return (
            <span className={classNames(styles.textLabel, styles.optionSelected)}>
              {option.text}
            </span>
          );
        }
        return (
          <Link
            className={styles.textLabel}
            style={{ color: 'currentColor' }}
            to={OmniaUrls.productFamily(option.handle)}
            target="_blank"
          >
            {option.text}
          </Link>
        );
      })}
    </div>
  );
};
