import { useSelector } from 'react-redux';
import { Breakpoints, getIsMobile, getWidth } from '../redux/window';

export function isTablet(width: number) {
  return Breakpoints.sm < width && width < Breakpoints.md;
}

export function isUnderTablet(width: number) {
  return width < Breakpoints.md;
}

export function isSmallDesktop(width: number) {
  return Breakpoints.lg < width;
}

export function isLargeTablet(width: number) {
  return Breakpoints.md < width && width < Breakpoints.lg;
}

export function isLargeDesktop(width: number) {
  return Breakpoints.xl < width;
}

export const useResponsive = () => {
  const isMobile = useSelector(getIsMobile);
  const width = useSelector(getWidth);

  return {
    isMobile,
    isDesktop: !isMobile,
    isTablet: isTablet(width),
    isUnderTablet: isUnderTablet(width),
    isOverTablet: !isUnderTablet(width),
    isLargeTablet: isLargeTablet(width),
    isSmallDesktop: isSmallDesktop(width),
    isLargeDesktop: isLargeDesktop(width),
  };
};
