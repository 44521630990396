import { PlayCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClickKey_TopNav, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { RoutePaths } from '../../routes';
import SvgMap from '../svg/map';
import SvgProducts from '../svg/products';
import SvgStates from '../svg/states';
import styles from './explore_menu.less';

export const ExploreMenu = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const webAnalyticsTopNavHelper = (click_key: ClickKey_TopNav, target_url?: string) => {
    WebAnalytics.topNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };
  return (
    <ul className={styles.list}>
      <li className={styles.listItem}>
        <Link
          to={RoutePaths.MAP}
          className={classNames(styles.link, styles.link__map)}
          onClick={() => {
            webAnalyticsTopNavHelper('[top_nav].(map)', RoutePaths.MAP);
          }}
        >
          <span role="img" aria-label="map-icon" className={styles.icon__map}>
            <SvgMap />
          </span>{' '}
          Map
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          to={RoutePaths.STATES}
          className={styles.link}
          onClick={() => {
            webAnalyticsTopNavHelper('[top_nav].(states)', RoutePaths.STATES);
          }}
        >
          <span role="img" aria-label="united-states-icon" className={styles.icon}>
            <SvgStates width={'1.2em'} height={'1.2em'} />
          </span>{' '}
          States
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          to={RoutePaths.BRANDS}
          className={styles.link}
          onClick={() => {
            webAnalyticsTopNavHelper('[top_nav].(brands)', RoutePaths.BRANDS);
          }}
        >
          <span role="img" aria-label="product-icon" className={styles.icon}>
            <SvgProducts className={styles.productIcon} strokeWidth={0.7} />
          </span>{' '}
          Brands
        </Link>
      </li>
      <li className={styles.listItem}>
        <Link
          to={RoutePaths.FISHING_ARTICLES}
          className={styles.link}
          onClick={() => {
            webAnalyticsTopNavHelper('[top_nav].(media)', RoutePaths.FISHING_ARTICLES);
          }}
        >
          <span className={classNames(styles.icon, styles.mediaIcon)}>
            <PlayCircleOutlined />
          </span>{' '}
          Media
        </Link>
      </li>
    </ul>
  );
};
