import { OmniaResponse, Product, ProductFamily } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'productsTagged';

export enum StateKeys {
  productFamilies = 'productFamilies',
}

export const initialState = {
  [StateKeys.productFamilies]: {} as { [key: string]: ProductFamily[] },
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getTagProductFamilies = (state: ApplicationState, tag: string) => {
  return state[reducerName][StateKeys.productFamilies][tag] || [];
};

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function productsTaggedReducer(state = initialState, action: ProductsTaggedActions) {
  switch (action.type) {
    case ReduxActions.PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS: {
      const productFamilies = { ...state[StateKeys.productFamilies] };
      productFamilies[action.payload.data.tag] = action.payload.data.product_families;

      return {
        ...state,
        [StateKeys.productFamilies]: productFamilies,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const ProductsTaggedActions = {
  PRODUCTS_TAGGED_FETCH_PENDING: () => createAction(ReduxActions.PRODUCTS_TAGGED_FETCH_PENDING),
  PRODUCTS_TAGGED_FETCH_SUCCESS: (
    response: OmniaResponse<{
      tag: string;
      products: Product[];
    }>
  ) => createAction(ReduxActions.PRODUCTS_TAGGED_FETCH_SUCCESS, response),
  PRODUCTS_TAGGED_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.PRODUCTS_TAGGED_FETCH_ERROR, err),

  PRODUCT_FAMILIES_TAGGED_FETCH_PENDING: () =>
    createAction(ReduxActions.PRODUCT_FAMILIES_TAGGED_FETCH_PENDING),
  PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS: (
    response: OmniaResponse<{
      tag: string;
      product_families: ProductFamily[];
    }>
  ) => createAction(ReduxActions.PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS, response),
  PRODUCT_FAMILIES_TAGGED_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.PRODUCT_FAMILIES_TAGGED_FETCH_ERROR, err),
};
export type ProductsTaggedActions = ActionsUnion<typeof ProductsTaggedActions>;

export function fetchProductFamiliesByTag(tag: string): RequestThunk {
  return (dispatch) => {
    dispatch(ProductsTaggedActions.PRODUCT_FAMILIES_TAGGED_FETCH_PENDING());

    return apiV1
      .tagProductFamiliesFetch(tag)
      .then((response) => {
        return dispatch(
          ProductsTaggedActions.PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS({
            ...response.data,
            data: {
              product_families: response.data.data,
              tag,
            },
          })
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchProductFamiliesByTag: ${tag}`, error);
        return dispatch(ProductsTaggedActions.PRODUCT_FAMILIES_TAGGED_FETCH_ERROR(error));
      });
  };
}
