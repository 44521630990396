import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'window';
export type reducerType = typeof initialState;

export enum Breakpoints {
  'sm' = 576,
  'md' = 768,
  'lg' = 992,
  'xl' = 1200,
}

const WIDTH_DEFAULT = 400;

export enum StateKeys {
  width = 'width',
  height = 'height',
  isMobile = 'isMobile',
  loaded = 'loaded',
  isWebview = 'isWebview',
}

export const initialState = {
  [StateKeys.width]: WIDTH_DEFAULT,
  [StateKeys.height]: 600,
  [StateKeys.isMobile]: true, // isMobile is set in the server render by UA string detection
  [StateKeys.loaded]: false,
  [StateKeys.isWebview]: false,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getWidth = (state: ApplicationState) => state[reducerName][StateKeys.width];
export const getHeight = (state: ApplicationState) => state[reducerName][StateKeys.height];
export const getIsMobile = (state: ApplicationState) => state[reducerName][StateKeys.isMobile];
export const getLoaded = (state: ApplicationState) => state[reducerName][StateKeys.loaded];
export const getIsWebview = (state: ApplicationState) => state[reducerName][StateKeys.isWebview];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function WindowReducer(state = initialState, action: WindowActions): reducerType {
  switch (action.type) {
    case ReduxActions.WINDOW_SET_SIZE:
      return {
        ...state,
        [StateKeys.width]: action.payload.width,
        [StateKeys.height]: action.payload.height,
        [StateKeys.isMobile]: isMobile(action.payload.width),
      };

    case ReduxActions.WINDOW_SET_LOADED:
      return {
        ...state,
        [StateKeys.loaded]: true,
      };

    default:
      return state;
  }
}

export function isMobile(width: number) {
  return width < Breakpoints.sm;
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const WindowActions = {
  WINDOW_SET_LOADED: () => createAction(ReduxActions.WINDOW_SET_LOADED),
  WINDOW_SET_SIZE: (width: number, height: number) =>
    createAction(ReduxActions.WINDOW_SET_SIZE, { width, height }),
};
export type WindowActions = ActionsUnion<typeof WindowActions>;
