import * as React from 'react';

const SvgLock = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      d="M27.7 14.6h-.6V9.2C27.1 4.1 23 0 18 0S8.9 4.1 8.9 9.2v5.4h-.6c-1.9 0-3.4 1.5-3.4 3.5v14.5c0 1.9 1.5 3.5 3.4 3.5h19.4c1.9 0 3.5-1.5 3.5-3.5V18c-.1-1.9-1.6-3.4-3.5-3.4zM11.8 9.2C11.8 5.8 14.6 3 18 3s6.2 2.8 6.2 6.2v5.4H11.8V9.2zm16.4 23.3c0 .3-.2.5-.5.5H8.3c-.3 0-.6-.2-.6-.5V18c0-.3.2-.5.6-.5h19.4c.3 0 .5.2.5.5v14.5z"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default SvgLock;
