import { FishingReport } from '@omniafishing/core';
import React from 'react';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { OmniaUrls } from '../../lib/urls';
import styles from './fishing_report_link.less';

export const FishingReportLink = ({ fishingReport }: { fishingReport: FishingReport }) => {
  return (
    <OmniaLinkButton
      to={{
        pathname: OmniaUrls.waterbody(fishingReport.waterbody),
        search: `?report_id=${fishingReport.id}`,
      }}
      size="sm"
      kind="text"
      className={styles.reportButton}
    >
      View Full Report
    </OmniaLinkButton>
  );
};
