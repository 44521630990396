import React from 'react';
import { useSelector } from 'react-redux';
import { getSeasonGroupBySeasonName } from '../../redux/reference_data';
import { SeasonGroupIcon, SeasonGroupIconProps } from './season_group_icon';

interface SeasonIconProps extends Omit<SeasonGroupIconProps, 'seasonGroupName'> {
  seasonName: string;
  color?: string;
  tag?: 'span' | 'div';
}

export const SeasonIcon = ({ seasonName, className, size, color, tag }: SeasonIconProps) => {
  const seasonGroupBySeasonName = useSelector(getSeasonGroupBySeasonName);
  const seasonGroup = seasonGroupBySeasonName(seasonName);

  return (
    <SeasonGroupIcon
      seasonGroupName={seasonGroup.name}
      size={size}
      className={className}
      color={color}
      tag={tag}
    />
  );
};
