import { FishingReport } from '@omniafishing/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { useFishingReportListViewed } from '../../hooks/use_fishing_report_list_viewed';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { omniaStringify } from '../../hooks/use_query_string';
import { GlobalQueryParams } from '../../lib/query_string';
import { OmniaUrls } from '../../lib/urls';
import { PDPMediaFishingReport } from '../../routes/product_detail_page/product_detail_page';
import OmniaCarousel from '../carousel/omnia_carousel';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import SvgFishingReport from '../svg/fishing_report';
import styles from './media_fishing_reports_list.less';
import { MediaFishingReportCard } from './media_fishing_report_card';

dayjs.extend(relativeTime);

interface RecentMediaFishingReportsListProps {
  allFishingReportsCount: number;
  fishingReports: FishingReport[] | PDPMediaFishingReport[];
  onSeeMoreClick?: () => void;
  preferProductTitle?: boolean;
  showFooterCTA?: boolean;
  showSeason?: boolean;
  showTechnique?: boolean;
}

export const MediaFishingReportsList = (props: RecentMediaFishingReportsListProps) => {
  const {
    allFishingReportsCount,
    fishingReports,
    onSeeMoreClick,
    preferProductTitle,
    showFooterCTA,
    showSeason,
    showTechnique,
  } = props;
  useFishingReportListViewed(fishingReports, 'embedded', 0, fishingReports.length);
  const list_uuid = useListAttributionUuid();
  const hasMoreReportsToView = allFishingReportsCount > fishingReports.length;

  return (
    <div className={styles.wrapper}>
      <ol className={styles.list}>
        <OmniaCarousel
          key={fishingReports.map((fr) => fr.id).join('')}
          className={styles.carousel}
          arrows
          newArrows
          fadedEdges
          adaptive={false}
        >
          {fishingReports.map((report, i) => {
            const { waterbody } = report;

            const reportIdQueryString = omniaStringify({
              [GlobalQueryParams.report_id]: report.id,
            });

            return (
              <li key={report.id} className={styles.fishingReportCardWrapper}>
                <MediaFishingReportCard
                  report={report}
                  position={i}
                  list_uuid={list_uuid}
                  showTechnique={showTechnique}
                  showSeason={showSeason}
                  preferProductTitle={preferProductTitle}
                />
                {showFooterCTA && (
                  <OmniaLinkButton
                    to={{
                      pathname: OmniaUrls.waterbody(report.waterbody),
                      search: reportIdQueryString,
                    }}
                    kind="tertiary"
                    size="lg"
                    fontSize={14}
                    className={styles.viewFullReportButton}
                  >
                    View {waterbody.primary_name}
                  </OmniaLinkButton>
                )}
              </li>
            );
          })}
          {hasMoreReportsToView && onSeeMoreClick && (
            <li className={styles.fishingReportCardWrapper}>
              <div className={styles.cardContainer__viewMore}>
                <div className={styles.cardContent__viewMore}>
                  <OmniaButton size="lg" kind="tertiary" fontSize={14} onPress={onSeeMoreClick}>
                    <span
                      role="img"
                      aria-label="fishing-reports-icon"
                      className={styles.fishingReportsIcon}
                    >
                      <SvgFishingReport />
                    </span>{' '}
                    View More Reports
                  </OmniaButton>
                </div>
              </div>
            </li>
          )}
        </OmniaCarousel>
      </ol>
    </div>
  );
};
