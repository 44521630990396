import {
  AlgoliaMedia,
  AlgoliaProduct,
  AmbassadorDetail,
  AmbassadorMini,
  Brand,
  Category,
  FishingReport,
  Media,
  OmniaVideo,
  Organization,
  SelectedOption,
  Species,
  State,
  Subcategory,
  SubcatType,
  TechniqueFull,
  TechniqueKeyword,
  Tournament,
  UserPrivate,
  Waypoint,
} from '@omniafishing/core';
import { MapQueryParams } from '../components/map_default/map_default';
import { OmniaVideoQueryParams } from '../components/omnia_video/omnia_video';
import { getBassULink } from '../prismic';
import { encodeVariantUrl, RoutePaths, WaterbodyPaths } from '../routes';
import { OmniaQueryParams } from './query_string';

class OmniaUrlsClass {
  algoliaMedia = (media: AlgoliaMedia) => {
    const { media_type } = media;
    if (media_type === 'PrismicVideo') {
      return this.video(media.slug);
    }
    if (media_type === 'PrismicArticle') {
      return this.article(media.slug);
    }
    if (media_type === 'BassUArticle') {
      return getBassULink(media.slug);
    }
    if (media_type === 'OmniaVideo') {
      return this.omniaVideo(media);
    }
  };
  algoliaProduct = (result: AlgoliaProduct) =>
    this.product(result.shopify_product_handle, result.shopify_options);
  ambassador = (slug: string) => `${RoutePaths.USERS}/${slug}`;
  article = (uid: string) => `${RoutePaths.ARTICLES}/${uid}`;
  brand = (brand: Brand) => `${RoutePaths.BRANDS}/${brand.url_slug}`;
  category = (category: Category) => `${RoutePaths.CATEGORIES}/${category.url_path}`;
  fishingReport = (report: FishingReport) => `${RoutePaths.FISHING_REPORTS}/${report.slug}`;
  media = (media: Media) => {
    const { media_item, media_type } = media;
    if (media_type === 'PrismicVideo') {
      return this.video(media_item.uid);
    }
    if (media_type === 'PrismicArticle') {
      return this.article(media_item.uid);
    }
    if (media_type === 'BassUArticle') {
      return getBassULink(media_item.uid);
    }
    if (media_type === 'OmniaVideo') {
      if (media_item.omnia_video_items.length) {
        const timeToSeek = media_item.omnia_video_items[0].start_sec;
        return this.omniaVideo(media_item) + `?${OmniaVideoQueryParams.time}=${timeToSeek}`;
      }
      return this.omniaVideo(media_item);
    }
  };
  map = (lat: number, lng: number, zoom?: number, waterbodySlug?: string) => {
    const zoomParams = zoom ? `&${MapQueryParams.zoom}=${zoom}` : '';
    const waterbodyParams = waterbodySlug
      ? `&${MapQueryParams.waterbody_slug}=${waterbodySlug}`
      : '';
    return `${RoutePaths.MAP}?${MapQueryParams.lat}=${lat}&${MapQueryParams.lng}=${lng}${zoomParams}${waterbodyParams}`;
  };
  news = (slug: string) => `${RoutePaths.NEWS}/${slug}`;
  omniaVideo = (omniaVideo: Pick<OmniaVideo, 'slug'>) => {
    return `${RoutePaths.OMNIA_VIDEOS}/${omniaVideo.slug}`;
  };

  organization = (org: Organization) => `${RoutePaths.ORGS}/${org.slug}`;
  product = (productHandle: string, selectedOptions: SelectedOption[]) =>
    `${RoutePaths.PRODUCTS}/${encodeVariantUrl(productHandle, selectedOptions)}`;
  productFamily = (productHandle: string) => `${RoutePaths.PRODUCTS}/${productHandle}`;
  subcategory = (category: Category, subcategory: Subcategory) => {
    // I don't know why this is needed
    if (!category || !subcategory) {
      return '#';
    }

    return `${this.category(category)}/${subcategory.url_path}`;
  };
  subcatType = (category: Category, subcategory: Subcategory, subcatType: SubcatType) =>
    `${this.subcategory(category, subcategory)}/${subcatType.slug}`;
  state = (state: State) => `${RoutePaths.STATES}/${state.slug}`;
  species = (specie: Species) => `${RoutePaths.SPECIES}/${specie.url_path}`;
  technique = (technique: TechniqueFull) => `${RoutePaths.TECHNIQUES}/${technique.url_path}`;
  techniqueKeyword = (technique: TechniqueFull, technique_keyword: TechniqueKeyword) =>
    `${this.technique(technique)}/${technique_keyword.slug}`;
  tournament = (tournament: Tournament) => `${RoutePaths.TOURNAMENTS}/${tournament.slug}`;
  user = (user: AmbassadorMini | AmbassadorDetail | UserPrivate) =>
    `${RoutePaths.USERS}/${user.slug}`;
  video = (uid: string) => `${RoutePaths.VIDEOS}/${uid}`;
  waterbody = (waterbody: { url_slug: string }, subRoute?: WaterbodyPaths) => {
    let path = `${RoutePaths.WATERS}/${waterbody.url_slug}`;
    if (subRoute) {
      path += `${subRoute}`;
    }
    return path;
  };
  waypoint = (waypoint: Waypoint) => `${RoutePaths.MAP}?waypoint_hash=${waypoint.id_hash}`;
}

export const OmniaUrls = new OmniaUrlsClass();

export const splitGetStartedUrl = (fullUrl: string) => {
  // examples:
  // /best-way-to-fish-for-largemouth-bass-on-lake-minnetonka
  // /best-way-to-fish-for-largemouth-bass
  // /best-way-to-fish-on-lake-minnetonka

  let url = fullUrl.split('/best-way-to-fish')[1];
  let speciesName = null as string | null;
  let waterbodySlug = null as string | null;
  if (url.includes('-on-')) {
    [url, waterbodySlug] = url.split('-on-');
  }
  if (url.includes('-for-')) {
    [url, speciesName] = url.split('-for-');
  }
  return {
    speciesName,
    waterbodySlug,
  };
};

export const buildGetStartedUrl = (
  species?: Species,
  waterbody_slug?: string,
  season_group_name?: string
) => {
  let url = RoutePaths.GET_STARTED as string;
  if (species) {
    url += `-for-${species.display_name.toLowerCase().replace(' ', '-')}`;
  }
  if (waterbody_slug) {
    url += `-on-${waterbody_slug}`;
  }
  if (season_group_name) {
    url += `?${OmniaQueryParams.season_group}=${season_group_name}`;
  }

  return url;
};

export const youtubeUrlRegex =
  /^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/|@)?[\w-]+)$/;
