import queryString from 'query-string';

export const IMGIX_URL = 'omnia-fishing.imgix.net';
export const PRISMIC_IMGIX_URL = 'images.prismic.io';

// from https://docs.imgix.com/apis/url
interface ImgixFormatting {
  [key: string]: unknown; // to "fix" a TS3.5.1 error
  w?: number;
  h?: number;
  auto?: 'format';
  q?: number;
  ar?: string;
  fit?: 'crop';
  crop?: 'faces';
}

export const getImgixPath = (path: string | null, options: ImgixFormatting = {}) => {
  if (!path || (!path.includes(IMGIX_URL) && !path.includes(PRISMIC_IMGIX_URL))) {
    return path;
  }

  const basePath = path.split('?')[0];
  const query = path.split('?')[1];

  const defaultParams: ImgixFormatting = {
    auto: 'format',
  };
  const existingParams = queryString.parse(query);
  const newParams = existingParams
    ? {
        ...defaultParams,
        ...existingParams,
        ...options,
      }
    : options;

  const queryParams = queryString.stringify(newParams);

  return `${basePath}?${queryParams}`;
};
