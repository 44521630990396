import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface LinkButtonProps extends LinkProps {
  size?: SizeType;
  type?: ButtonType | 'orange';
  block?: boolean;
  children: React.ReactNode;
}

export const LinkButton = (props: LinkButtonProps) => {
  const { children, to, rel, className, type, size, block, onClick, target } = props;

  return (
    <Link
      to={to}
      className={classNames('ant-btn', className, {
        'ant-btn-primary': type === 'primary',
        'ant-btn-dashed': type === 'dashed',
        'btn--shop': type === 'orange',
        'ant-btn-link': type === 'link',
        'ant-btn-lg': size === 'large',
        'ant-btn-sm': size === 'small',
        'ant-btn-block': block === true,
      })}
      rel={rel}
      onClick={onClick}
      target={target}
    >
      {children}
    </Link>
  );
};
