import { useCallback, useMemo, useState } from 'react';
import {
  BASE_LAYER,
  CORE_LAYER,
  LAYER_TYPES,
  MapLayerState,
  OVERLAY_LAYER,
  POINTS_OF_INTEREST_LAYER,
  WEATHER_LAYER,
} from '../map/map_types';

export const DEFAULT_MAP_STATE: MapLayerState = {
  [LAYER_TYPES.BASE]: BASE_LAYER.STANDARD,
  [LAYER_TYPES.OVERLAY]: [OVERLAY_LAYER.PINS],
  [LAYER_TYPES.CORE]: undefined,
  [LAYER_TYPES.WEATHER]: [] as WEATHER_LAYER[],
  [LAYER_TYPES.POINTS_OF_INTEREST]: [] as POINTS_OF_INTEREST_LAYER[],
};

export const useMapLayerState = (defaultStateOverrides?: Partial<MapLayerState>) => {
  const [baseLayer, setBaseLayer] = useState<BASE_LAYER>(
    defaultStateOverrides?.[LAYER_TYPES.BASE] || DEFAULT_MAP_STATE[LAYER_TYPES.BASE]
  );
  const [coreLayer, setCoreLayer] = useState<CORE_LAYER | undefined>(
    defaultStateOverrides?.[LAYER_TYPES.CORE] || DEFAULT_MAP_STATE[LAYER_TYPES.CORE]
  );
  const [overlayLayers, setOverlayLayers] = useState<OVERLAY_LAYER[]>(
    defaultStateOverrides?.[LAYER_TYPES.OVERLAY] || DEFAULT_MAP_STATE[LAYER_TYPES.OVERLAY]
  );
  const [weatherLayers, setWeatherLayers] = useState<WEATHER_LAYER[]>(
    defaultStateOverrides?.[LAYER_TYPES.WEATHER] || DEFAULT_MAP_STATE[LAYER_TYPES.WEATHER]
  );
  const [pointsOfInterestLayer, setPointsOfInterestLayer] = useState<POINTS_OF_INTEREST_LAYER[]>(
    defaultStateOverrides?.[LAYER_TYPES?.POINTS_OF_INTEREST] ||
      DEFAULT_MAP_STATE[LAYER_TYPES.POINTS_OF_INTEREST]
  );

  const mapLayerState: MapLayerState = useMemo(
    () => ({
      [LAYER_TYPES.BASE]: baseLayer,
      [LAYER_TYPES.CORE]: coreLayer,
      [LAYER_TYPES.OVERLAY]: overlayLayers,
      [LAYER_TYPES.WEATHER]: weatherLayers,
      [LAYER_TYPES.POINTS_OF_INTEREST]: pointsOfInterestLayer,
    }),
    [
      baseLayer,
      coreLayer,
      overlayLayers.join(','),
      pointsOfInterestLayer.join(','),
      weatherLayers.join(','),
    ]
  );

  const onSelectCoreLayer = useCallback(
    (l: CORE_LAYER) => {
      if (l === coreLayer) {
        setCoreLayer(undefined);
      } else {
        setCoreLayer(l);
      }
    },
    [coreLayer]
  );

  const onSelectOverlayLayer = useCallback(
    (l: OVERLAY_LAYER) => {
      if (overlayLayers.includes(l)) {
        setOverlayLayers(overlayLayers.filter((layer) => layer !== l));
      } else {
        setOverlayLayers([...overlayLayers, l]);
      }
    },
    [overlayLayers]
  );

  const onSelectWeatherLayer = useCallback(
    (weather: WEATHER_LAYER) => {
      if (weatherLayers.includes(weather)) {
        setWeatherLayers(weatherLayers.filter((w) => w !== weather));
      } else {
        setWeatherLayers([...weatherLayers, weather]);
      }
    },
    [weatherLayers]
  );

  const onSelectPointsOfInterestLayer = useCallback(
    (l: POINTS_OF_INTEREST_LAYER) => {
      if (pointsOfInterestLayer.includes(l)) {
        setPointsOfInterestLayer(pointsOfInterestLayer.filter((layer) => layer !== l));
      } else {
        setPointsOfInterestLayer([...pointsOfInterestLayer, l]);
      }
    },
    [pointsOfInterestLayer]
  );

  const onResetMapState = useCallback(() => {
    setBaseLayer(DEFAULT_MAP_STATE[LAYER_TYPES.BASE]);
    setCoreLayer(DEFAULT_MAP_STATE[LAYER_TYPES.CORE]);
    setOverlayLayers(DEFAULT_MAP_STATE[LAYER_TYPES.OVERLAY]);
    setPointsOfInterestLayer(DEFAULT_MAP_STATE[LAYER_TYPES.POINTS_OF_INTEREST]);
    setWeatherLayers(DEFAULT_MAP_STATE[LAYER_TYPES.WEATHER]);
  }, []);

  return {
    mapLayerState,
    onResetMapState,
    onSelectCoreLayer,
    onSelectOverlayLayer,
    onSelectPointsOfInterestLayer,
    onSelectWeatherLayer,
    setBaseLayer,
  };
};
