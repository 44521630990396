import { SeasonGroup } from '@omniafishing/core';

export const isCurrentSeasonGroupText = (
  seasonGroup: SeasonGroup | null,
  currentSeasonGroup: SeasonGroup | null
) => {
  if (!seasonGroup || !currentSeasonGroup) {
    return '';
  }
  return currentSeasonGroup.name === seasonGroup?.name ? ' (Current)' : '';
};
