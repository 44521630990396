import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './omnia_switch.less';

interface OmniaSwitchProps {
  onChange: SwitchChangeEventHandler;
  checked: boolean;
  children: React.ReactNode;
  switchSide: 'right' | 'left';
}

export const OmniaSwitch = (props: OmniaSwitchProps) => {
  const { onChange, checked, children, switchSide } = props;
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className={classNames(styles.switch, {
        [styles.flipped]: switchSide === 'left',
      })}
      onClick={(e) => {
        onChange(!isChecked, null);
      }}
    >
      <span className={styles.text}>{children}</span>
      <Switch checked={isChecked} onChange={onChange} />
    </div>
  );
};

OmniaSwitch.defaultProps = {
  switchSide: 'right',
};
