import { useEffect } from 'react';
import { useQueryString } from '../../hooks/use_query_string';
import { WebAnalytics } from '../../lib/web_analytics';

export const ShortUrlChecker = (): null => {
  const { getQueryStringProperty } = useQueryString();
  const shortUrl = getQueryStringProperty('short_url')?.[0] || null;

  useEffect(() => {
    if (shortUrl) {
      WebAnalytics.shortUrlClicked(shortUrl);
    }
  }, [shortUrl]);

  return null;
};
