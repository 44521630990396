import * as React from 'react';
const SvgThermometerAir = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.123 15.554a3.66 3.66 0 0 0 3.663-3.663c0-1.038-.422-1.949-1.22-2.686-.14-.127-.173-.207-.173-.395L9.4 2.944c0-1.5-.918-2.498-2.277-2.498-1.373 0-2.304.998-2.304 2.498l.014 5.866c0 .188-.034.268-.175.395-.803.73-1.218 1.648-1.218 2.686a3.664 3.664 0 0 0 3.683 3.663Zm0-1.166a2.508 2.508 0 0 1-2.511-2.51c0-.851.401-1.588 1.104-2.063.208-.134.288-.261.288-.53V2.992c0-.837.45-1.373 1.119-1.373.65 0 1.098.536 1.098 1.373v6.295c0 .268.08.395.288.529a2.44 2.44 0 0 1 1.105 2.062 2.504 2.504 0 0 1-2.491 2.511Zm3.555-11.323h1.467a.4.4 0 0 0 .415-.409.4.4 0 0 0-.415-.408H10.68a.405.405 0 0 0-.422.408c0 .221.167.409.421.409Zm0 1.855h1.467a.4.4 0 0 0 .415-.409.4.4 0 0 0-.415-.408H10.68a.405.405 0 0 0-.422.408c0 .221.167.409.421.409Zm0 1.868h1.467a.4.4 0 0 0 .415-.409.4.4 0 0 0-.415-.408H10.68a.405.405 0 0 0-.422.408c0 .221.167.409.421.409ZM7.11 13.484c.891 0 1.607-.716 1.607-1.614 0-.615-.348-1.131-.863-1.406-.215-.114-.282-.194-.282-.515V6.527c0-.342-.2-.556-.462-.556-.254 0-.455.214-.455.556v3.422c0 .321-.067.401-.281.515-.516.275-.864.79-.864 1.406 0 .898.716 1.614 1.6 1.614Zm3.57-4.834h1.466a.4.4 0 0 0 .415-.409.4.4 0 0 0-.415-.408H10.68a.405.405 0 0 0-.422.408c0 .221.167.409.421.409Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgThermometerAir;
