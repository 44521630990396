import { connect } from 'react-redux';
import { ApplicationState } from '../../helpers/app_state';
import { WindowActions } from '../../redux/window';
import { WindowListener } from './window_listener';

function mapStateToProps(state: ApplicationState, ownProps = {}) {
  return {};
}

const dispatchProps = {
  setSize: WindowActions.WINDOW_SET_SIZE,
  setLoaded: WindowActions.WINDOW_SET_LOADED,
};

const WindowListenerContainer = connect(mapStateToProps, dispatchProps)(WindowListener);

export type WindowListenerProps = typeof dispatchProps & ReturnType<typeof mapStateToProps>;

export default WindowListenerContainer;
