import * as React from 'react';

const SvgIceIcon = (props: React.SVGAttributes<SVGElement>) => {
  const { width, height, fill, ...rest } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.357 4.056 8.333.579a.667.667 0 0 0-.666 0L1.643 4.056a.667.667 0 0 0-.333.577v6.956c0 .238.127.458.333.577l6.024 3.477a.667.667 0 0 0 .666 0l6.024-3.477a.667.667 0 0 0 .333-.577V4.633a.667.667 0 0 0-.333-.577Zm-1 7.148L8 14.296l-5.357-3.092V5.018L8 1.926l5.357 3.092v6.186Z"
        fill={fill || '#657D7D'}
      />
      <path
        d="M3.986 5.024a.112.112 0 0 0-.152.04l-.555.963a.11.11 0 0 0 .04.151l4.014 2.317v4.533c0 .062.05.111.111.111h1.112c.06 0 .11-.05.11-.11V8.495l4.014-2.317a.11.11 0 0 0 .041-.152l-.555-.962a.11.11 0 0 0-.152-.04L8 7.34 3.986 5.024Z"
        fill={fill || '#657D7D'}
      />
    </svg>
  );
};

export default SvgIceIcon;
