import { Product, ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { getImgixPath } from '../../lib/imgix';
import { OmniaUrls } from '../../lib/urls';
import { getIpState } from '../../redux/geographic_location';
import { getBrandsNotHidden } from '../../redux/reference_data';
import { NewBadge } from '../new_badge/new_badge';
import styles from './product_title.less';
interface ProductTitleProps {
  productFamily: ProductFamily;
  selectedProduct: Product;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
  showOnlyTitle: boolean;
}

export const isItemNew = (createdAtDate: dayjs.Dayjs, daysCount: number = 30) => {
  const now = dayjs();
  return now.diff(createdAtDate, 'days') <= daysCount;
};

export const ProductTitle = React.memo((props: ProductTitleProps) => {
  const { productFamily, selectedProduct, selectedVariant, shopifyProduct, showOnlyTitle } = props;
  const { isDesktop } = useResponsive();
  const ipState = useSelector(getIpState);
  const brands = useSelector(getBrandsNotHidden);
  const brand = brands.find((b) => b.name === productFamily?.brand);

  const isBestSellerInIpState = selectedProduct?.best_seller_states.includes(ipState);

  const createdAtDate = selectedProduct?.created_at && dayjs(selectedProduct?.created_at);
  const isVariantNew = createdAtDate && isItemNew(createdAtDate);
  const isProductFamilyNew = isItemNew(dayjs(productFamily?.published_at));

  return (
    <div className={styles.headingWrapper}>
      <div>
        <h1 className={styles.heading} id={selectedVariant.sku}>
          {shopifyProduct.title}
        </h1>
        {!showOnlyTitle && (
          <div className={styles.subTitleSection}>
            <p className={styles.variantTitle}>
              <span>{selectedVariant.title}</span>
              {!isProductFamilyNew && isVariantNew && (
                <NewBadge className={styles.variantNewBadge} />
              )}
            </p>
            {isBestSellerInIpState && (
              <span className={styles.pillsWrapper}>
                {isBestSellerInIpState && (
                  <span className={styles.pill}>Best Seller in {ipState}</span>
                )}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.newBadgeContainer}>{isProductFamilyNew && <NewBadge />}</div>
      <>
        {brand && brand.image && isDesktop && (
          <div
            className={classNames(styles.brandImg, {
              [styles.brandImg__small]: showOnlyTitle,
            })}
          >
            <Link
              to={OmniaUrls.brand(brand)}
              className={styles.brandImgLink}
              style={{ backgroundImage: `url(${getImgixPath(brand.image, { w: 225 })})` }}
            ></Link>
          </div>
        )}
      </>
    </div>
  );
});
