import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'proUpsellModal';

export enum StateKeys {
  open = 'open',
}

export const initialState = {
  [StateKeys.open]: false,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const isOpen = (state: ApplicationState) => state[reducerName][StateKeys.open];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function ProUpsellModal(state = initialState, action: ProUpsellModalActions) {
  switch (action.type) {
    case ReduxActions.PRO_UPSELL_MODAL_OPEN:
      return {
        ...state,
        [StateKeys.open]: true,
      };

    case ReduxActions.PRO_UPSELL_MODAL_CLOSE:
      return {
        ...state,
        [StateKeys.open]: false,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const ProUpsellModalActions = {
  PRO_UPSELL_MODAL_OPEN: () => createAction(ReduxActions.PRO_UPSELL_MODAL_OPEN),
  PRO_UPSELL_MODAL_CLOSE: () => createAction(ReduxActions.PRO_UPSELL_MODAL_CLOSE),
};
export type ProUpsellModalActions = ActionsUnion<typeof ProUpsellModalActions>;
