import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

interface WistiaEmbedProps {
  embedUrl: string;
  autoplay?: boolean;
  className?: string;
  videoRef?: (instance: any) => void;
}

export const WistiaEmbed = (props: WistiaEmbedProps) => {
  const { embedUrl, autoplay, className, videoRef } = props;

  const mediaId = embedUrl.split('?')[0].split('/').pop();

  useEffect(() => {
    if (videoRef) {
      const w = window as any;
      w._wq = w._wq || [];
      w._wq.push({
        id: mediaId,
        onReady(video: any) {
          videoRef(video);
        },
      });
    }
  }, []);

  return (
    <div className={className}>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${mediaId}.jsonp`} async />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>

      <div
        className="wistia_responsive_padding"
        style={{
          padding: '56.25% 0 0 0',
          position: 'relative',
        }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <div
            className={classNames(
              'wistia_embed',
              `wistia_embed`,
              `wistia_async_${mediaId}`,
              `videoFoam=true`,
              `autoPlay=${!!autoplay}`
            )}
            style={{
              height: '100%',
              position: 'relative',
              width: '100%',
            }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: 0,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                transition: 'opacity 200ms',
                width: '100%',
              }}
            >
              <img
                alt=""
                aria-hidden="true"
                src={`https://fast.wistia.com/embed/medias/${mediaId}/swatch`}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: '100%',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
