import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsOpen } from '../../redux/cart';

export const BODY_OVERFLOW_CLASS_NAME = 'overflow-hidden';

export const BodyOverflowHandler = (): null => {
  const isOpen = useSelector(getIsOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(BODY_OVERFLOW_CLASS_NAME);
    } else {
      document.body.classList.remove(BODY_OVERFLOW_CLASS_NAME);
    }
  }, [isOpen]);

  return null;
};
