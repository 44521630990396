import React from 'react';
import styles from './products_nav.less';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { ShopMenu } from './shop_menu';
import { ExploreMenu } from './explore_menu';
import { useLocation } from 'react-router';
import { getPageTypeByPathname, PageTypes } from '../../routes';
import { Affix } from 'antd';

export const PRODUCTS_NAV_HEIGHT_APPROX = 88;

export const ProductsNav = () => {
  const location = useLocation();
  const pageType = getPageTypeByPathname(location.pathname);

  if (pageType === PageTypes.MAP) {
    return null;
  }
  const content = (
    <section className={styles.ProductsNav}>
      <ContentWrapper mobileNoPadding>
        <nav className={styles.nav}>
          <div className={styles.section__products}>
            <span className={styles.sectionHeading}>Shop</span>
            <ShopMenu />
          </div>
          <div>
            <span className={styles.sectionHeading}>Explore</span>
            <ExploreMenu />
          </div>
        </nav>
      </ContentWrapper>
    </section>
  );

  if (
    ([PageTypes.PRODUCT_DETAIL, PageTypes.LAKE_PAGE, PageTypes.STATE] as PageTypes[]).includes(
      pageType
    )
  ) {
    return content;
  }

  return <Affix>{content}</Affix>;
};
