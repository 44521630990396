import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone } from '../../constants/loading_state';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { apiV1 } from '../../lib/api';
import {
  fetchAffiliate,
  getAffiliateCampaign,
  getAffiliateLoadingState,
  getAffiliateSource,
  getReferer,
  getUtmTags,
  UTM_MEDIUM_AFFILIATE,
} from '../../redux/utm';

export const AffiliateChecker = (): null => {
  const utmTags = useSelector(getUtmTags);
  const dispatch = useDispatch();
  const referer = useSelector(getReferer);
  const loadingState = useSelector(getAffiliateLoadingState);
  const source = useSelector(getAffiliateSource);
  const campaign = useSelector(getAffiliateCampaign);
  const { anonymousId, loaded } = useRudderstack();

  useEffect(() => {
    if (utmTags?.medium === UTM_MEDIUM_AFFILIATE) {
      dispatch(fetchAffiliate(utmTags.source, utmTags.name, referer));
    }
  }, [utmTags?.medium]);

  useEffect(() => {
    if (isDone(loadingState) && loaded) {
      apiV1.eventTrack('Affiliate Link Tracked', anonymousId, {
        affiliate: source,
        campaign,
        referrer: referer,
      });
      apiV1.eventIdentify(anonymousId, {
        affiliate: source,
      });
    }
  }, [anonymousId, loaded, loadingState]);

  return null;
};
