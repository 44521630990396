import React, { useState } from 'react';
import { Button, Radio } from 'antd';
import { fishingReportContainsSpecieName } from '../../../lib/fishing_reports';
import { FishingReport, Species } from '@omniafishing/core';
import { useSelector } from 'react-redux';
import { getSeasonGroups, getSpecies } from '../../../redux/reference_data';
import { convertSeasonGroupNameToSeasonGroup } from './season_group_radios';
import styles from './all_filters_drawer.less';
import { getSpeciesCounts } from '../filters';

interface SpeciesRadiosProps {
  onChange: (val: string) => void;
  fishingReports: FishingReport[];
  drawerProductSku: string;
  drawerSeasonGroupName: string;
  drawerSpeciesName: string;
}

export const SpeciesRadios = (props: SpeciesRadiosProps) => {
  const allSpecies = useSelector(getSpecies);
  const seasonGroups = useSelector(getSeasonGroups);
  const [showMore, setShowMore] = useState(false);

  const { onChange, fishingReports, drawerProductSku, drawerSeasonGroupName, drawerSpeciesName } =
    props;

  const speciesInFishingReports = allSpecies.filter((species) => {
    const initialReportMatchesCount = fishingReports.filter((report) => {
      return fishingReportContainsSpecieName(report, species.name);
    }).length;

    if (initialReportMatchesCount > 0) {
      return species;
    }
  });
  const selectedSeasonGroup = convertSeasonGroupNameToSeasonGroup(
    drawerSeasonGroupName,
    seasonGroups
  );
  const speciesCounts = getSpeciesCounts({
    speciesInFishingReports,
    selectedProductSku: drawerProductSku,
    selectedSeasonGroup,
    fishingReports,
  });

  const speciesList = (species: Species[]) => {
    return species.map((s, i) => {
      return (
        <Radio
          value={s.name}
          className={styles.radioLabel}
          key={s.name}
          disabled={speciesCounts[i] === 0}
        >
          <span>{s.display_name}</span>
          <span className={styles.selectLabelReportCount}>{speciesCounts[i]}</span>
        </Radio>
      );
    });
  };

  return (
    <div className={styles.radios}>
      <Radio.Group
        onChange={(e) => {
          const speciesName = e.target.value as string;
          onChange(speciesName);
        }}
        value={drawerSpeciesName}
      >
        <Radio value={undefined} className={styles.radioLabel}>
          <span>
            <span>All Species</span>
          </span>
        </Radio>
        {showMore
          ? speciesList(speciesInFishingReports)
          : speciesList(speciesInFishingReports.slice(0, 10))}
      </Radio.Group>
      {speciesInFishingReports.length > 9 ? (
        <Button
          type="link"
          size="small"
          className={styles.showMoreLinkButton}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show All'}
        </Button>
      ) : null}
    </div>
  );
};
