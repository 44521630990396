import React, { MouseEventHandler } from 'react';

interface USStateProps {
  dimensions: string;
  fill: string;
  state: string;
  onClickState: MouseEventHandler<SVGPathElement>;
  stateName: string;
}

const USAState = (props: USStateProps) => {
  const { dimensions, fill, state, onClickState, stateName } = props;

  return (
    <path
      d={dimensions}
      fill={fill}
      data-name={state}
      className={`${state} state`}
      onClick={onClickState}
    >
      <title>{stateName}</title>
    </path>
  );
};
export default USAState;
