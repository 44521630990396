import { OmniaVideo, OmniaVideoItem } from '@omniafishing/core';
import React, { useEffect, useState } from 'react';
import { OmniaUrls } from '../../lib/urls';
import dayjs from 'dayjs';
import { OmniaVideoQueryParams } from '../omnia_video/omnia_video';
import Plyr from 'plyr';
import { omniaVideoItemName } from '../../lib/omnia_video';

interface GetVideoSchemaProps {
  omnia_video: OmniaVideo;
  omnia_video_items: OmniaVideoItem[];
  plyr: Plyr;
}
export const GetVideoSchema = (props: GetVideoSchemaProps) => {
  const { omnia_video, plyr, omnia_video_items } = props;
  const { title, mux_playback_id, publication_date, thumbnail, summary, source, updated_at } =
    omnia_video;
  const [videoDuration, setVideoDuration] = useState<number>();

  useEffect(() => {
    if (plyr) {
      plyr.on('ready', () => {
        setVideoDuration(plyr.duration);
      });
    }
  }, [plyr]);

  const getSchema = () => {
    const schema: any = {
      '@context': 'https://schema.org/',
      '@type': 'VideoObject',
      name: title,
      description: summary,
      datePublished: dayjs(publication_date).format('YYYY-MM-DD'),
      dateModified: updated_at,
      thumbnailUrl: thumbnail ? [thumbnail] : [],
      image: thumbnail,
      uploadDate: dayjs(publication_date).format('YYYY-MM-DD'),
      contentUrl: source ? source : `https://stream.mux.com/${mux_playback_id}.m3u8`,
      hasPart: omnia_video_items.map((ovi) => {
        return {
          '@type': 'Clip',
          startOffset: ovi.start_sec,
          endOffset: ovi.end_sec,
          name: omniaVideoItemName(ovi),
          url:
            OmniaUrls.omniaVideo(omnia_video) + `?${OmniaVideoQueryParams.time}=${ovi.start_sec}`,
        };
      }),
    };

    if (videoDuration) {
      schema.duration = dayjs.duration(videoDuration, 's').toISOString();
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    );
  };
  return <>{getSchema()}</>;
};
