import * as React from 'react';

const SvgCheckMark = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm5.563-14.402a.999.999 0 1 0-1.428-1.4l-5.418 5.525-2.353-2.276a1 1 0 1 0-1.391 1.437l3.067 2.967a1 1 0 0 0 1.409-.019l6.114-6.234Z"
      style={{
        fill: 'currentcolor',
        fillRule: 'evenodd',
      }}
    />
  </svg>
);

export default SvgCheckMark;
