export enum LoadingState {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export function isNotStarted(loadingState: LoadingState) {
  return loadingState === LoadingState.NOT_STARTED;
}
export function isPending(loadingState: LoadingState) {
  return loadingState === LoadingState.PENDING;
}
export function isDone(loadingState: LoadingState) {
  return loadingState === LoadingState.DONE;
}
export function isError(loadingState: LoadingState) {
  return loadingState === LoadingState.ERROR;
}
export function isPendingOrNotStarted(loadingState: LoadingState) {
  return isPending(loadingState) || isNotStarted(loadingState);
}
