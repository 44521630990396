import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import styles from './carousel_buttons.less';

interface UsePrevNextButtonsType {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  onButtonClick?: (emblaApi: EmblaCarouselType) => void
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    if (onButtonClick) {
      onButtonClick(emblaApi);
    }
  }, [emblaApi, onButtonClick]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    if (onButtonClick) {
      onButtonClick(emblaApi);
    }
  }, [emblaApi, onButtonClick]);

  const onSelect = useCallback((_emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!_emblaApi.canScrollPrev());
    setNextBtnDisabled(!_emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

interface Button extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  size?: number;
}

export const PrevButton = (props: Button) => {
  const { children, size, ...restProps } = props;
  let style: CSSProperties = {};
  if (size) {
    style = {
      width: `${size}px`,
      height: `${size}px`,
      minHeight: `${size}px`,
      minWidth: `${size}px`,
    };
  }
  return (
    <button style={style} className={styles.button} type="button" {...restProps}>
      {children}
    </button>
  );
};

export const NextButton = (props: Button) => {
  const { children, size, ...restProps } = props;

  let style: CSSProperties = {};
  if (size) {
    style = {
      width: `${size}px`,
      height: `${size}px`,
      minHeight: `${size}px`,
      minWidth: `${size}px`,
    };
  }
  return (
    <button style={style} className={styles.button} type="button" {...restProps}>
      {children}
    </button>
  );
};
