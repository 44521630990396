import React from 'react';
import { useResponsive } from '../../../hooks/use_responsive';
import { getImgixPath } from '../../../lib/imgix';
import SvgExpandIcon from '../../svg/expand_icon';
import styles from './fishing_report_card_media.less';

interface FishingReportCardImageProps {
  onImageClick: (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => void;
  image: string;
}
export const FishingReportCardImage = (props: FishingReportCardImageProps) => {
  const { onImageClick, image } = props;
  const { isMobile } = useResponsive();

  const getbackgroundImageStyle = (formattedImage: string) => {
    return {
      backgroundImage: `url('${formattedImage}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'none',
      filter: 'blur(4px)',
    };
  };

  const formattedBackgroundImage =
    image &&
    getImgixPath(image, {
      w: isMobile ? 300 : 170,
      ar: '1.5:1',
      fit: 'crop',
      crop: 'faces',
    });

  return (
    <>
      {isMobile && <div className={styles.overlayFade}></div>}
      <div
        style={getbackgroundImageStyle(formattedBackgroundImage)}
        className={styles.imgBackground}
      />
      <img
        className={styles.image}
        src={getImgixPath(image, {
          w: isMobile ? 300 : 174,
          h: isMobile ? 210 : 117,
          crop: 'faces',
        })}
        onClick={onImageClick}
      />
      <button className={styles.expandIcon} onClick={onImageClick}>
        <SvgExpandIcon />
      </button>
    </>
  );
};
