import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLayerLightning = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 36 36" {...props}>
    <path
      d="M9.063 35.451c-.265 0-.53-.043-.792-.13-.629-.249-1.049-.67-1.266-1.215-.246-.617-.208-1.331.105-2.011l4.936-12.031H6.628c-1.426 0-2.098-.702-2.371-1.121-.272-.419-.646-1.315-.069-2.625l5.951-13.573c.659-1.536 1.672-2.196 3.384-2.196h10.106c.95 0 1.742.417 2.173 1.143.454.766.423 1.739-.087 2.672l-2.954 5.692h6.692c1.133 0 2.081.589 2.474 1.538.418 1.01.126 2.189-.764 3.079l-20.09 19.875c-.591.592-1.295.903-2.01.903m-2.08-18.287h9.388l-5.569 13.574 17.974-17.782H17.988l4.935-9.506h-9.399c-.401 0-.512.061-.514.061-.003.004-.073.07-.209.388L6.984 17.165Z"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default SvgLayerLightning;
