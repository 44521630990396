import {
  AmbassadorMini,
  List,
  OmniaPrismicArticle,
  OmniaResponse,
  State,
  Waterbody,
} from '@omniafishing/core';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import {
  PrismicArticle,
  PrismicDocument,
  PrismicDocumentTypes,
  prismicOmniaInstance,
} from '../prismic';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'articles';

export enum StateKeys {
  articles = 'articles',
  articleData = 'articleData',
  relatedContent = 'relatedContent',
  articlesLoadingState = 'articlesLoadingState',
}

export const initialState = {
  [StateKeys.articles]: {} as Record<string, PrismicDocument<PrismicArticle>>,
  [StateKeys.articleData]: {} as Record<string, ArticleDataResponse>,
  [StateKeys.articlesLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.relatedContent]: {
    articles: [] as OmniaPrismicArticle[],
    species: [] as string[],
    styles: [] as string[],
    brands: [] as string[],
    ambassadors: [] as AmbassadorMini[],
    waterbodies: [] as Waterbody[],
  },
};

// ========================================================================== //
// Selectors
// ========================================================================== //

const getArticlesAll = (state: ApplicationState) => state[reducerName][StateKeys.articles];

export const getArticles = createSelector([getArticlesAll], (articles) => {
  const articlesArray = _.values(articles);
  return _.orderBy(
    articlesArray,
    (article) => new Date(article.data.publication_date).getTime(),
    'desc'
  );
});

const _getArticlesByTags = (articles: PrismicDocument<PrismicArticle>[]) =>
  _.memoize(
    (tags: string[], limit = null as number) => {
      const taggedArticles = articles.filter((article) =>
        article.tags.some((articleTag) => tags.includes(articleTag))
      );
      if (limit) {
        return taggedArticles.slice(0, limit);
      }
      return taggedArticles;
    },
    (tags, limit = null as number) => {
      return `${tags.sort().join('_')}_${limit}`;
    }
  );
export const getArticlesByTags = createSelector([getArticles], _getArticlesByTags);
export const getArticleByUid = (state: ApplicationState, uid: string) =>
  state[reducerName][StateKeys.articles][uid];
export const getArticleData = (state: ApplicationState, uid: string) =>
  state[reducerName][StateKeys.articleData][uid];
export const getRelatedContent = (state: ApplicationState) =>
  state[reducerName][StateKeys.relatedContent];
export const getArticlesLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.articlesLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function ArticlesReducer(state = initialState, action: ArticlesActions) {
  switch (action.type) {
    case ReduxActions.ARTICLE_FETCH_SUCCESS: {
      const document = action.payload;
      const articles = { ...state[StateKeys.articles] };
      articles[document.uid] = document;
      return {
        ...state,
        [StateKeys.articles]: articles,
      };
    }

    case ReduxActions.ARTICLE_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.articleData]: {
          ...state[StateKeys.articleData],
          [action.payload.uid]: action.payload.data,
        },
      };
    }

    case ReduxActions.ARTICLE_RELATED_CONTENT_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.relatedContent]: initialState.relatedContent,
      };
    }

    case ReduxActions.ARTICLE_RELATED_CONTENT_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.relatedContent]: action.payload.data,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const ArticlesActions = {
  ARTICLE_FETCH_PENDING: () => createAction(ReduxActions.ARTICLE_FETCH_PENDING),
  ARTICLE_FETCH_SUCCESS: (response: PrismicDocument<PrismicArticle>) =>
    createAction(ReduxActions.ARTICLE_FETCH_SUCCESS, response),
  ARTICLE_FETCH_ERROR: (err: any) => createAction(ReduxActions.ARTICLE_FETCH_ERROR, err),

  ARTICLE_DATA_FETCH_PENDING: () => createAction(ReduxActions.ARTICLE_DATA_FETCH_PENDING),
  ARTICLE_DATA_FETCH_SUCCESS: (uid: string, response: OmniaResponse<ArticleDataResponse>) =>
    createAction(ReduxActions.ARTICLE_DATA_FETCH_SUCCESS, { ...response, uid }),
  ARTICLE_DATA_FETCH_ERROR: (err: any) => createAction(ReduxActions.ARTICLE_DATA_FETCH_ERROR, err),

  ARTICLE_RELATED_CONTENT_FETCH_PENDING: () =>
    createAction(ReduxActions.ARTICLE_RELATED_CONTENT_FETCH_PENDING),
  ARTICLE_RELATED_CONTENT_FETCH_SUCCESS: (response: OmniaResponse<ArticleRelatedContentResponse>) =>
    createAction(ReduxActions.ARTICLE_RELATED_CONTENT_FETCH_SUCCESS, response),
  ARTICLE_RELATED_CONTENT_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.ARTICLE_RELATED_CONTENT_FETCH_ERROR, err),
};
export type ArticlesActions = ActionsUnion<typeof ArticlesActions>;

export function fetchArticle(uid: string): RequestThunk {
  return (dispatch) => {
    dispatch(ArticlesActions.ARTICLE_FETCH_PENDING());

    return prismicOmniaInstance()
      .then((api) => {
        return api.getByUID(PrismicDocumentTypes.ARTICLE, uid);
      })
      .then((response) => {
        return dispatch(ArticlesActions.ARTICLE_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`Error: fetchArticle: ${uid}`, error);
        return dispatch(ArticlesActions.ARTICLE_FETCH_ERROR(error));
      });
  };
}

interface ArticleDataParams {
  states?: string[];
  waterbodies?: string[];
  lists?: string[];
  ambassador?: string;
}

export interface ArticleDataResponse {
  states: State[];
  waterbodies: Waterbody[];
  lists: List[];
  ambassador: AmbassadorMini | null;
}

export function fetchArticleData(uid: string, data: ArticleDataParams): RequestThunk {
  return (dispatch) => {
    dispatch(ArticlesActions.ARTICLE_DATA_FETCH_PENDING());

    return apiV1
      .articleDataFetch(data)
      .then((response) => {
        return dispatch(ArticlesActions.ARTICLE_DATA_FETCH_SUCCESS(uid, response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchArticleData', error);
        return dispatch(ArticlesActions.ARTICLE_DATA_FETCH_ERROR(error));
      });
  };
}

export interface ArticleRelatedContentResponse {
  articles: OmniaPrismicArticle[];
  species: string[];
  styles: string[];
  techniques: string[];
  brands: string[];
  waterbodies: Waterbody[];
  ambassadors: AmbassadorMini[];
}

export function fetchArticleRelatedContent(uid: string): RequestThunk {
  return (dispatch) => {
    dispatch(ArticlesActions.ARTICLE_RELATED_CONTENT_FETCH_PENDING());

    return apiV1
      .articleRelatedContentFetch(uid)
      .then((response) => {
        return dispatch(ArticlesActions.ARTICLE_RELATED_CONTENT_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchArticleRelatedContent', error);
        return dispatch(ArticlesActions.ARTICLE_RELATED_CONTENT_FETCH_ERROR(error));
      });
  };
}
