import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import Flicking, { MoveEndEvent, ViewportSlot } from '@egjs/react-flicking';
import { Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { WebAnalytics } from '../../lib/web_analytics';
import { getPromoBanners } from '../../redux/reference_data';
import { RoutePaths } from '../../routes';
import '../../styles/flicking.less';
import styles from './promo_bar.less';

const SLIDE_DURATION_MS = 8000;

export const PromoBar = () => {
  const flicking = useRef<Flicking>();
  const promoBanners = useSelector(getPromoBanners);
  const location = useLocation();
  const pathIsMap = location?.pathname?.startsWith(RoutePaths.MAP);
  const [complete, setComplete] = useState(false);

  if (pathIsMap) {
    return null;
  }
  const manySlides = promoBanners.length > 1;
  const plugins = [];
  if (manySlides) {
    plugins.push(new Arrow());
    if (!complete) {
      plugins.push(
        new AutoPlay({ duration: SLIDE_DURATION_MS, direction: 'NEXT', stopOnHover: true })
      );
    }
  }

  return (
    <>
      {promoBanners.length > 0 && (
        <div className={styles.promoBar}>
          <div
            className={styles.promoBarContent}
            onClick={(e: any) => {
              const element = e.target as HTMLElement;
              if (element.tagName === 'A') {
                WebAnalytics.promoBarClick(element.parentElement.innerHTML);
              }
            }}
          >
            <Flicking
              align="prev"
              bound={true}
              renderOnlyVisible={true}
              plugins={plugins}
              circular={false}
              ref={flicking}
              onMoveEnd={(e: MoveEndEvent) => {
                const status = flicking.current.getStatus();
                if (status.position.panel === promoBanners.length - 1) {
                  setTimeout(() => {
                    setComplete(true);
                    flicking.current?.moveTo(0);
                  }, SLIDE_DURATION_MS);
                }
              }}
            >
              {promoBanners.map((banner) => {
                const content = <div dangerouslySetInnerHTML={{ __html: banner.content }} />;
                const panel = banner.tooltip ? (
                  <Tooltip title={banner.tooltip} placement="bottom">
                    {content}
                  </Tooltip>
                ) : (
                  content
                );
                return (
                  <div className={styles.promoBarPanel} key={banner.id}>
                    {panel}
                  </div>
                );
              })}
              {manySlides && (
                <ViewportSlot>
                  <span className="flicking-arrow-prev"></span>
                  <span className="flicking-arrow-next"></span>
                </ViewportSlot>
              )}
            </Flicking>
          </div>
        </div>
      )}
    </>
  );
};
