import { CalendarOutlined } from '@ant-design/icons';
import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useResponsive } from '../../hooks/use_responsive';
import {
  getSeasonGroupBySeasonName,
  getTechniqueByTechniqueName,
} from '../../redux/reference_data';
import { DesktopOnly } from '../responsive_elements/responsive_elements';
import SvgThermometerAir from '../svg/thermometer_air';
import styles from './fishing_report_card_details.less';
import { UserDetails } from './user_details';
import { ShareReportButton } from '../fishing_report_card/share_report_button';
interface FishingReportCardDetailsProps {
  cardSize: 'small' | 'large';
  fishingReport: FishingReport;
  isLakePage: boolean;
  onLakeClick?: () => void;
  onShareClick?: () => void;
  onUserClick?: () => void;
  position: number;
  showLakeName: boolean;
}

export const FishingReportCardDetails = (props: FishingReportCardDetailsProps) => {
  const {
    cardSize,
    fishingReport,
    isLakePage,
    onLakeClick,
    onShareClick,
    onUserClick,
    position,
    showLakeName,
  } = props;
  const {
    forage,
    image,
    outing_date,
    season,
    species,
    structure,
    technique,
    video,
    water_temperature,
  } = fishingReport;
  const { isMobile } = useResponsive();
  const seasonGroupBySeasonName = useSelector(getSeasonGroupBySeasonName);
  const techniqueByTechniqueName = useSelector(getTechniqueByTechniqueName);

  const hasMedia = !!image || !!video;

  const formattedOutingDate = dayjs(outing_date).format('M/D/YY');
  const isSmallCard = cardSize === 'small';

  return (
    <section className={styles.reportDetails}>
      <div className={styles.reportDetailsColumnOne}>
        <DesktopOnly tag="section">
          <UserDetails
            fishingReport={fishingReport}
            position={position}
            onLakeClick={onLakeClick}
            onUserClick={onUserClick}
            isLakePage={isLakePage}
            className={styles.userDetails}
            showLakeName={showLakeName}
          />
          <div className={styles.subDetails}>
            <div className={styles.shareButtonContainer}>
              <ShareReportButton fishingReport={fishingReport} onShareClick={onShareClick} />
            </div>
            {outing_date && (
              <div className={styles.outingDate}>
                <p>
                  <CalendarOutlined /> {formattedOutingDate}
                </p>
              </div>
            )}
            {water_temperature && (
              <div className={styles.waterTemp}>
                <p>
                  <span className={styles.thermIcon}>
                    <SvgThermometerAir />
                  </span>{' '}
                  {water_temperature}° (Water)
                </p>
              </div>
            )}
          </div>
        </DesktopOnly>
      </div>
      <div
        className={classNames(styles.reportDetailsColumnTwo, {
          [styles.reportDetailsColumnTwo__small]: isSmallCard,
        })}
      >
        <ul className={classNames(styles.details, { [styles.details__small]: isSmallCard })}>
          {isMobile && outing_date && (
            <li
              className={classNames(styles.outingDateMobile, {
                [styles.outingDateMobile__noMedia]: !hasMedia && isLakePage,
              })}
            >
              <p className={styles.detailsCategory}>Outing Date</p>
              <p className={styles.detailsDetail}>
                <CalendarOutlined /> {formattedOutingDate}
              </p>
            </li>
          )}
          <li
            className={classNames({ [styles.seasonLabel__noOutingDate]: !outing_date && isMobile })}
          >
            <p className={styles.detailsCategory}>Season</p>
            <p className={styles.detailsDetail}>
              <span
                className={classNames(
                  styles.seasonIcon,
                  styles[seasonGroupBySeasonName(season.name).name]
                )}
              ></span>
              {season.display_name}
            </p>
          </li>
          <li className={styles.species}>
            <p className={styles.detailsCategory}>Species</p>
            <p className={styles.detailsDetail}>
              {species.image && <img src={species.image} />}
              {species.display_name}
            </p>
          </li>
          <li className={styles.technique}>
            <p className={styles.detailsCategory}>Technique</p>
            <p className={styles.detailsDetail}>
              {techniqueByTechniqueName(technique.name).display_name_alternate}
            </p>
          </li>
          <li>
            <p className={styles.detailsCategory}>Structure</p>
            <p className={styles.detailsDetail}>{structure.display_name}</p>
          </li>
          {forage && (
            <li>
              <p className={styles.detailsCategory}>Forage</p>
              <p className={styles.detailsDetail}>{forage.display_name}</p>
            </li>
          )}
          {isMobile && water_temperature && (
            <li>
              <p className={styles.detailsCategory}>Water Temperature</p>
              <p className={classNames(styles.detailsDetail, styles.waterTempMobile)}>
                <span className={styles.thermIcon}>
                  <SvgThermometerAir />
                </span>{' '}
                {water_temperature}° (Water)
              </p>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};
