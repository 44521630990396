import { AmbassadorDetail } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { roleAsText } from '../../lib/user';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import styles from './ambassador_profile_mini.less';
import { EnvironmentOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useResponsive } from '../../hooks/use_responsive';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes';

interface AmbassadorProfileMiniProps {
  ambassador: AmbassadorDetail;
  className?: string;
  imageSize?: number;
  showLocation?: boolean;
  showFollowers?: boolean;
  showInfoIcon?: boolean;
}

export const AmbassadorProfileMini = (props: AmbassadorProfileMiniProps) => {
  const { ambassador, className, imageSize, showLocation, showFollowers, showInfoIcon } = props;
  const { image, role, full_name, location, followers_count } = ambassador;
  const { isMobile, isDesktop } = useResponsive();

  const showFollowersDesktop = showFollowers && isDesktop;
  const showFollowersMobile = showFollowers && isMobile;
  const FollowersBlock = (
    <span
      className={classNames(styles.followers, {
        [styles.followers__singleDigit]: followers_count < 10,
        [styles.followers__mobile]: isMobile,
      })}
    >
      <strong>{followers_count}</strong> {pluralize('Follower', followers_count)}
    </span>
  );
  return (
    <div className={styles.ambassador}>
      <AmbassadorLink ambassador={ambassador} target="_blank">
        <UserProfileImg image={image} imageSize={imageSize || 48} />
      </AmbassadorLink>
      <div className={classNames(styles.details, className)}>
        <p className={styles.name}>
          <AmbassadorLink ambassador={ambassador} target="_blank">
            {full_name}
          </AmbassadorLink>
        </p>
        <p className={styles.role}>
          <AmbassadorColorDot user={ambassador} />
          {roleAsText(role)} Ambassador
          {showInfoIcon && (
            <Link to={RoutePaths.AMBASSADOR_APPLICATION} target="_blank">
              <InfoCircleOutlined />
            </Link>
          )}
        </p>
        {showLocation && location && (
          <p className={styles.locationLine}>
            <span className={styles.location}>
              <EnvironmentOutlined /> {location}
            </span>
            {showFollowersDesktop && FollowersBlock}
          </p>
        )}
        {showFollowersMobile && FollowersBlock}
      </div>
    </div>
  );
};
