import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { getEnv } from './env';
import { omniaStringify } from './hooks/use_query_string';
import { CookieKeys } from './redux/cookie_storage_middleware';

export const INTERNALS = {
  getApiBaseUrl,
  getApiBaseUrlV1,
};

const V1 = 'v1/';

export const axiosOmniaV1 = axios.create({
  baseURL: `${INTERNALS.getApiBaseUrl()}${V1}`,
  paramsSerializer: (params) => {
    return omniaStringify(params);
  },
});

export function getApiBaseUrl() {
  return getEnv().API_BASE_URL;
}

export function getApiBaseUrlV1() {
  return `${getEnv().API_BASE_URL}${V1}`;
}

export function shouldIncludeAuthHeaderV1(config: AxiosRequestConfig) {
  const apiBaseUrl = INTERNALS.getApiBaseUrlV1();
  return (
    config.baseURL === apiBaseUrl &&
    config.url.split('/')[0] !== 'auth' &&
    config.url.split('/')[0].indexOf('http') === -1 &&
    !config.headers.Authorization
  );
}

axiosOmniaV1.interceptors.request.use((config) => {
  if (shouldIncludeAuthHeaderV1(config)) {
    const accessToken = Cookies.get(CookieKeys.accessToken);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});
