import { OmniaResponse, OmniaVideo, OmniaVideoItem, OmniaVideoOptions } from '@omniafishing/core';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import {
  PrismicDocument,
  PrismicDocumentTypes,
  prismicOmniaInstance,
  PrismicVideo,
} from '../prismic';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'videos';

export enum StateKeys {
  videos = 'videos',
  omnia_video = 'omnia_video',
  omnia_videos = 'omnia_videos',
  omnia_video_items = 'omnia_video_items',
  videos_loading_state = 'videos_loading_state',
  omnia_videos_loading_state = 'omnia_videos_loading_state',
}

export const initialState = {
  [StateKeys.videos]: {} as Record<string, PrismicDocument<PrismicVideo>>,
  [StateKeys.omnia_video]: null as OmniaVideo,
  [StateKeys.omnia_videos]: [] as OmniaVideo[],
  [StateKeys.omnia_video_items]: [] as OmniaVideoItem[],
  [StateKeys.videos_loading_state]: LoadingState.NOT_STARTED,
  [StateKeys.omnia_videos_loading_state]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

const getVideosAll = (state: ApplicationState) => state[reducerName][StateKeys.videos];
export const getVideos = createSelector([getVideosAll], (videos) => {
  const videosArray = _.values(videos);
  return _.orderBy(videosArray, (video) => new Date(video.data.publication_date).getTime(), 'desc');
});
const _getVideosByTags = (videos: PrismicDocument<PrismicVideo>[]) =>
  _.memoize(
    (tags: string[], limit = null as number) => {
      const taggedVideos = videos.filter((video) =>
        video.tags.some((videoTag) => tags.includes(videoTag))
      );
      if (limit) {
        return taggedVideos.slice(0, limit);
      }
      return taggedVideos;
    },
    (tags, limit = null as number) => {
      return `${tags.sort().join('_')}_${limit}`;
    }
  );
export const getVideosByTags = createSelector([getVideos], _getVideosByTags);
export const getVideoByUid = (state: ApplicationState, uid: string) =>
  state[reducerName][StateKeys.videos][uid];
export const getOmniaVideo = (state: ApplicationState) => state[reducerName][StateKeys.omnia_video];
export const getOmniaVideos = (state: ApplicationState) =>
  state[reducerName][StateKeys.omnia_videos];
export const getOmniaVideoItems = (state: ApplicationState) =>
  state[reducerName][StateKeys.omnia_video_items];
export const getOmniaVideosLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.omnia_videos_loading_state];
export const getVideosLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.videos_loading_state];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function VideosReducer(state = initialState, action: VideosActions) {
  switch (action.type) {
    case ReduxActions.VIDEO_FETCH_SUCCESS: {
      const document = action.payload;
      const videos = { ...state[StateKeys.videos] };
      videos[document.uid] = document;
      return {
        ...state,
        [StateKeys.videos]: videos,
      };
    }

    case ReduxActions.OMNIA_VIDEO_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.omnia_video]: null,
        [StateKeys.omnia_video_items]: [],
      };
    }

    case ReduxActions.OMNIA_VIDEO_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.omnia_video]: action.payload.data.omnia_video,
        [StateKeys.omnia_video_items]: action.payload.data.omnia_video_items,
      };
    }

    case ReduxActions.OMNIA_VIDEOS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.omnia_videos]: [],
        [StateKeys.omnia_videos_loading_state]: LoadingState.PENDING,
      };
    }

    case ReduxActions.OMNIA_VIDEOS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.omnia_videos]: action.payload.data,
        [StateKeys.omnia_videos_loading_state]: LoadingState.DONE,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const VideosActions = {
  VIDEO_FETCH_PENDING: () => createAction(ReduxActions.VIDEO_FETCH_PENDING),
  VIDEO_FETCH_SUCCESS: (response: PrismicDocument<PrismicVideo>) =>
    createAction(ReduxActions.VIDEO_FETCH_SUCCESS, response),
  VIDEO_FETCH_ERROR: (err: any) => createAction(ReduxActions.VIDEO_FETCH_ERROR, err),

  OMNIA_VIDEOS_FETCH_PENDING: () => createAction(ReduxActions.OMNIA_VIDEOS_FETCH_PENDING),
  OMNIA_VIDEOS_FETCH_SUCCESS: (response: OmniaResponse<OmniaVideo[]>) =>
    createAction(ReduxActions.OMNIA_VIDEOS_FETCH_SUCCESS, response),
  OMNIA_VIDEOS_FETCH_ERROR: (err: any) => createAction(ReduxActions.OMNIA_VIDEOS_FETCH_ERROR, err),

  OMNIA_VIDEO_FETCH_PENDING: () => createAction(ReduxActions.OMNIA_VIDEO_FETCH_PENDING),
  OMNIA_VIDEO_FETCH_SUCCESS: (
    response: OmniaResponse<{ omnia_video: OmniaVideo; omnia_video_items: OmniaVideoItem[] }>
  ) => createAction(ReduxActions.OMNIA_VIDEO_FETCH_SUCCESS, response),
  OMNIA_VIDEO_FETCH_ERROR: (err: any) => createAction(ReduxActions.OMNIA_VIDEO_FETCH_ERROR, err),
};
export type VideosActions = ActionsUnion<typeof VideosActions>;

export function fetchVideo(uid: string): RequestThunk {
  return (dispatch) => {
    dispatch(VideosActions.VIDEO_FETCH_PENDING());

    return prismicOmniaInstance()
      .then((api) => {
        return api.getByUID(PrismicDocumentTypes.VIDEO, uid);
      })
      .then((response) => {
        return dispatch(VideosActions.VIDEO_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`Error: fetchVideo: ${uid}`, error);
        return dispatch(VideosActions.VIDEO_FETCH_ERROR(error));
      });
  };
}

export function fetchOmniaVideo(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(VideosActions.OMNIA_VIDEO_FETCH_PENDING());

    return apiV1
      .omniaVideoFetch(slug)
      .then((response) => {
        return dispatch(VideosActions.OMNIA_VIDEO_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchOmniaVideo', error);
        return dispatch(VideosActions.OMNIA_VIDEO_FETCH_ERROR(error));
      });
  };
}

export function fetchOmniaVideos(options: OmniaVideoOptions = {}): RequestThunk {
  return (dispatch) => {
    dispatch(VideosActions.OMNIA_VIDEOS_FETCH_PENDING());

    return apiV1
      .omniaVideosFetch(options)
      .then((response) => {
        return dispatch(VideosActions.OMNIA_VIDEOS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchOmniaVideos', error);
        return dispatch(VideosActions.OMNIA_VIDEOS_FETCH_ERROR(error));
      });
  };
}
