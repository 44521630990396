import * as React from 'react';
import { SVGProps } from 'react';

export const SvgStarRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.082 1.124C8.43.32 9.57.32 9.918 1.124l1.557 3.603a1 1 0 0 0 .824.599l3.908.367c.872.082 1.225 1.167.567 1.746l-2.945 2.594a1 1 0 0 0-.315.97l.858 3.83c.191.854-.731 1.525-1.485 1.078l-3.378-2a1 1 0 0 0-1.018 0l-3.378 2c-.754.447-1.677-.224-1.485-1.079l.858-3.83a1 1 0 0 0-.315-.969L1.226 7.44c-.658-.579-.305-1.664.567-1.746l3.908-.367a1 1 0 0 0 .824-.6l1.557-3.602Z"
      fill="currentColor"
    />
  </svg>
);
