import { Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { ScrollToTop } from '../../components/scroll_to_top/scroll_to_top';
import { SEO } from '../../components/seo/seo';
import { Spacer } from '../../components/spacer/spacer';
import { USMap } from '../../components/us_map/us_map';
import { WaterbodyCard } from '../../components/waterbody_card/waterbody_card';
import { getStatesAlphabetical } from '../../redux/reference_data';
import { BestOfPaths, RoutePaths } from '../../routes';
import { AboutOmniaBlurb } from './about_omnia_blurb';
import { bestOfLakes } from './best_of_year_data';
import styles from './best_of_year_page.less';

const currentDate = dayjs();
export const previousYearPlusOneMonth = currentDate
  .subtract(1, 'year')
  .add(1, 'month')
  .format('YYYY');

// this is a "parent page to best of year lake and state pages"
const BestOfYearPage = () => {
  const states = useSelector(getStatesAlphabetical);
  const history = useHistory();

  const goToState = (abbr: string) => {
    const state = states.find((s) => s.abbr === abbr);
    if (state) {
      history.push(`${RoutePaths.BEST_OF_YEAR}${BestOfPaths.STATES}/${state.abbr.toLowerCase()}`);
    }
  };
  const mapHandler = (event: React.MouseEvent) => {
    goToState((event.target as HTMLElement).dataset.name);
  };

  return (
    <ScrollToTop>
      <SEO
        title={`Best Baits of ${previousYearPlusOneMonth}`}
        description={`A list of the best baits and lures for fishing reported by local anglers in ${previousYearPlusOneMonth}.`}
      />
      <div className={styles.page}>
        <header className={styles.header}>
          <ContentWrapper>
            <h1 className={styles.h1}>Best Baits of {previousYearPlusOneMonth}</h1>
            <p>
              When it comes to identifying the best baits for a successful fishing outing, local
              knowledge is key. What works for one region may not work at all in another. That's why
              we collect thousands of detailed fishing reports from anglers across the country to
              power our local recommendations. This report leverages all that data in order to
              present the baits of the year for {previousYearPlusOneMonth}.
            </p>
          </ContentWrapper>
        </header>

        <ContentWrapper className={styles.bestState}>
          <Spacer size={50} />

          <h2>Best Baits for Your State</h2>
          <p>Select a state to view the angler-reported hot baits in your region.</p>

          <div className={styles.map}>
            <Select
              className={styles.mapSelect}
              placeholder="Select your State"
              onChange={(abbr: string) => {
                goToState(abbr);
              }}
              showSearch
              options={states.map((s) => {
                return {
                  label: s.name,
                  value: s.abbr,
                };
              })}
              filterOption={(input, option) =>
                String(option.label).toLowerCase().includes(input.toLowerCase())
              }
            />
            <USMap onClick={mapHandler} width={null} height={null} />
          </div>
        </ContentWrapper>

        <section className={styles.lakesSection}>
          <ContentWrapper>
            <h2>Best Baits for Most Popular Lakes</h2>
            <p>
              Get a hot bait report recapping {previousYearPlusOneMonth} technique and gear trends
              for popular lakes.
            </p>
            <ul className={styles.lakes}>
              {bestOfLakes.map((w) => {
                return (
                  <li className={styles.lake} key={w.id}>
                    <WaterbodyCard
                      waterbody={w}
                      linkTo={`${RoutePaths.BEST_OF_YEAR}${BestOfPaths.LAKES}/${w.url_slug}`}
                    />
                  </li>
                );
              })}
            </ul>
          </ContentWrapper>
        </section>

        <Spacer size={50} />

        <ContentWrapper>
          {/* <section>
            <h2>{previousYearPlusOneMonth} Tackle Industry Trends</h2>
            <p>
              Some fishing trends were significant enough to show up across the nation. Get the
              scoop on the biggest topics and emerging trends for the year.
            </p>
            <Link to={`${RoutePaths.OMNIA_VIDEOS}/omnia-best-of-2022`}>
              <img
                src={thumbnail}
                alt={`Omnia's Best of ${previousYearPlusOneMonth}`}
                className={styles.video}
              />
            </Link>

            <Spacer size={50} />
          </section> */}

          <section className={styles.aboutOmnia}>
            <h3 className={styles.h2}>About Omnia Fishing</h3>
            <AboutOmniaBlurb />
          </section>
        </ContentWrapper>

        <Spacer size={100} />
      </div>
    </ScrollToTop>
  );
};

export default BestOfYearPage;
