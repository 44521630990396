import classNames from 'classnames';
import React from 'react';
import base from '../../styles/base.less';
import styles from './span_link.less';

export const SpanLink = (props: {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  className?: string;
  underline?: boolean;
  currentColor?: boolean;
}) => {
  const { children, onClick, className, underline, currentColor } = props;
  return (
    <span
      className={classNames(base.link, className, {
        [styles.underline]: underline,
        [styles.currentColor]: currentColor,
      })}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
