import React from 'react';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../redux/auth';
import RootContainer from './root_container';

export const RootWrapper = () => {
  const accessToken = useSelector(getAccessToken);

  return <RootContainer key={accessToken} />;
};
