import _ from 'lodash';
import Prismic from 'prismic-javascript';
import { QueryOptions } from 'prismic-javascript/types/ResolvedApi';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { errorHandler } from '../lib/error_handler';
import {
  PrismicDocument,
  PrismicNewsArticle,
  PrismicNewsDocumentTypes,
  prismicNewsInstance,
} from '../prismic';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'news';

export enum StateKeys {
  news = 'news',
}

export const initialState = {
  [StateKeys.news]: {} as Record<string, PrismicDocument<PrismicNewsArticle>>,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

const getArticles = (state: ApplicationState) => state[reducerName][StateKeys.news];
export const getNewsArticles = createSelector([getArticles], (articles) => {
  const articlesArray = _.values(articles);
  return _.orderBy(
    articlesArray,
    (article) => new Date(article.data.publication_date).getTime(),
    'desc'
  );
});

export const getNewsArticleByUid = (state: ApplicationState, uid: string) =>
  state[reducerName][StateKeys.news][uid];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function NewsReducer(state = initialState, action: NewsActions) {
  switch (action.type) {
    case ReduxActions.NEWS_FETCH_SUCCESS: {
      const document = action.payload;
      const news = { ...state[StateKeys.news] };
      news[document.uid] = document;
      return {
        ...state,
        [StateKeys.news]: news,
      };
    }

    case ReduxActions.NEWS_ALL_FETCH_SUCCESS: {
      const news = { ...state[StateKeys.news] };
      action.payload.forEach((document) => {
        news[document.uid] = document;
      });

      return {
        ...state,
        [StateKeys.news]: news,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const NewsActions = {
  NEWS_FETCH_PENDING: () => createAction(ReduxActions.NEWS_FETCH_PENDING),
  NEWS_FETCH_SUCCESS: (response: PrismicDocument<PrismicNewsArticle>) =>
    createAction(ReduxActions.NEWS_FETCH_SUCCESS, response),
  NEWS_FETCH_ERROR: (err: any) => createAction(ReduxActions.NEWS_FETCH_ERROR, err),

  NEWS_ALL_FETCH_PENDING: () => createAction(ReduxActions.NEWS_ALL_FETCH_PENDING),
  NEWS_ALL_FETCH_SUCCESS: (response: PrismicDocument<PrismicNewsArticle>[]) =>
    createAction(ReduxActions.NEWS_ALL_FETCH_SUCCESS, response),
  NEWS_ALL_FETCH_ERROR: (err: any) => createAction(ReduxActions.NEWS_ALL_FETCH_ERROR, err),
};
export type NewsActions = ActionsUnion<typeof NewsActions>;

export function fetchNews(uid: string): RequestThunk {
  return (dispatch) => {
    dispatch(NewsActions.NEWS_FETCH_PENDING());

    return prismicNewsInstance()
      .then((api) => {
        return api.getByUID(PrismicNewsDocumentTypes.ARTICLE, uid);
      })
      .then((response) => {
        return dispatch(NewsActions.NEWS_FETCH_SUCCESS(response));
      })
      .catch((error) => {
        errorHandler(`Error: fetchNews: ${uid}`, error);
        return dispatch(NewsActions.NEWS_FETCH_ERROR(error));
      });
  };
}

export function fetchAllNews(): RequestThunk {
  return (dispatch) => {
    dispatch(NewsActions.NEWS_FETCH_PENDING());

    const predicates: any = [
      Prismic.Predicates.at('document.type', PrismicNewsDocumentTypes.ARTICLE),
    ];

    const options: QueryOptions = {
      orderings: `[my.${PrismicNewsDocumentTypes.ARTICLE}.publication_date desc]`,
      pageSize: 50,
    };

    return prismicNewsInstance()
      .then((api) => {
        return api.query(predicates, options);
      })
      .then((response) => {
        return dispatch(NewsActions.NEWS_ALL_FETCH_SUCCESS(response.results));
      })
      .catch((error) => {
        errorHandler(`Error: fetchAllNews`, error);
        return dispatch(NewsActions.NEWS_FETCH_ERROR(error));
      });
  };
}
