import { FishingReport } from '@omniafishing/core';
import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import { formatAttributesAsStrings } from '../../lib/line_item_attribution';
import { GlobalQueryParams } from '../../lib/query_string';
import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';
import { getPath } from '../../redux/router';
import { PageTypes, RoutePaths, getPageTypeByPathname } from '../../routes';
import styles from './fishing_report_display_modal.less';
import { FishingReportModalCard } from './fishing_report_modal_card';
import { useLocation } from 'react-router';
import { usePrevious } from '../../hooks/use_previous';
import { useFlickingRenderRef } from '../../hooks/use_flicking_render_ref';
interface FishingReportDisplayModalProps {
  open: boolean;
  fishingReport: FishingReport;
  onClose: () => void;
}

export const FishingReportDisplayModal = (props: FishingReportDisplayModalProps) => {
  const { open, fishingReport, onClose } = props;
  const path = useSelector(getPath);
  const isPDP = path.startsWith(RoutePaths.PRODUCTS + '/');
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.attribution]: string[];
  }>();

  const location = useLocation();
  const pathIsLakePage = getPageTypeByPathname(location.pathname) === PageTypes.LAKE_PAGE;
  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    if (previousPath !== location.pathname && open) {
      onClose();
    }
  }, [location.pathname]);

  const { isRendered, setIsRendered, renderCallbackRef } = useFlickingRenderRef();

  if (!fishingReport) {
    return null;
  }

  const lineItemCustomAttributes: LineItemCustomAttribute[] = [
    {
      key: LINE_ATTRIBUTION.FISHING_REPORT_ID,
      value: fishingReport.id.toString(),
    },
  ];
  const fishingReportCustomAttributes = formatAttributesAsStrings(lineItemCustomAttributes);

  // as implemented this replaces existing attribution
  const addFishingReportLineItemAttribution = () => {
    if (isPDP) {
      const currentAttributionStrings = currentQuery.attribution;

      if (!currentAttributionStrings?.includes(fishingReportCustomAttributes[0])) {
        replaceQueryString({
          [GlobalQueryParams.attribution]: fishingReportCustomAttributes,
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        setIsRendered(false);
      }}
      footer={null}
      className={styles.modal}
      width={null}
      destroyOnClose
      centered
      wrapClassName={styles.modalWrapper}
    >
      <div ref={renderCallbackRef}>
        <FishingReportModalCard
          fishingReport={fishingReport}
          position={0}
          onLakeClick={addFishingReportLineItemAttribution}
          onMediaClick={addFishingReportLineItemAttribution}
          onUserClick={addFishingReportLineItemAttribution}
          onShareClick={addFishingReportLineItemAttribution}
          adaptiveProductCarouselHeight={false}
          key={`${fishingReport.id}${isRendered}`}
          className={styles.fishingReportCard}
          onClose={onClose}
          showLakeCTA={!pathIsLakePage}
        />
      </div>
    </Modal>
  );
};
