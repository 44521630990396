import { Product } from '@omniafishing/core';
import { getServerEnv, ServerEnvs } from '../env';

const productAsGaItem = ({ product, quantity }: { product: Product; quantity: number }) => {
  return {
    item_id: product.shopify_variant_id,
    item_name: product.title,
    price: product.price,
    currency: 'USD',
    quantity,
  };
};

export const INTERNALS = {
  getGtag: () => {
    const log = (...args: any[]) => {
      console.log('Google Track:', args);
    };
    if (getServerEnv() === ServerEnvs.PRODUCTION) {
      return (window as any).gtag as typeof log;
    }
    return log;
  },
};

class GoogleEventsClass {
  AddToCart(products: Product[]) {
    const gtag = INTERNALS.getGtag();

    const items = products.map((product) => {
      return productAsGaItem({ product, quantity: 1 });
    });
    const value = items
      .reduce((acc, item) => {
        return acc + Number(item.price);
      }, 0)
      .toString();

    gtag('event', 'add_to_cart', {
      currency: 'USD',
      items,
      value,
    });
    gtag('event', 'conversion', {
      send_to: 'AW-797380647/ZxHTCIzN1y8CEKegnPwC',
      value: 1.0,
      currency: 'USD',
    });
  }

  ViewItem(product: Product) {
    const gtag = INTERNALS.getGtag();
    const value = product.price;
    gtag('event', 'view_item', {
      currency: 'USD',
      items: [productAsGaItem({ product, quantity: 1 })],
      value,
    });
  }
}

export const GoogleEvents = new GoogleEventsClass();
