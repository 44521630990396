import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import { isBase64 } from '../../lib/base64';
import { fetchCartPromos, fetchCheckoutId, getHeaderCheckoutId } from '../../redux/cart';
import { CookieKeys } from '../../redux/cookie_storage_middleware';

export const UserCartHandler = (): null => {
  const dispatch = useDispatch();
  const headerCheckoutId = useSelector(getHeaderCheckoutId);
  const checkoutId = headerCheckoutId || Cookies.get(CookieKeys.checkoutId);
  const { currentQuery } = useQueryString();

  let mergeCheckoutId = currentQuery.checkoutToAdd as string;
  // checkoutToAdd should be base64 encoded because checkouts have a key param baked in like:
  // gid://shopify/Checkout/31lk4j2l5j23hqkjnajf3jhkl2j3?key=abc12345
  // which would break the url param
  if (isBase64(mergeCheckoutId)) {
    mergeCheckoutId = atob(mergeCheckoutId);
  }

  useEffect(() => {
    dispatch(
      fetchCheckoutId({
        guest_checkout_id: checkoutId,
        merge_checkout_id: mergeCheckoutId,
      })
    );
    dispatch(fetchCartPromos());
  }, []);

  return null;
};
