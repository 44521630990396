import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsMobile, getWidth } from '../../redux/window';
import { LoadableVideoPlayer } from '../video_player/loadable_video_player';
import { HTMLPlyrVideoElement } from '../video_player/video_player';
import styles from './media_modal.less';
export interface ActiveMedia {
  src: string;
  type: 'img' | 'video';
}

interface MediaModalProps {
  activeMedia: ActiveMedia;
  onCancel: (e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>) => void;
  showButtons?: boolean;
}

export const MediaModal = (props: MediaModalProps) => {
  const { activeMedia, onCancel } = props;

  const isMobile = useSelector(getIsMobile);
  const pageWidth = useSelector(getWidth);
  const [loaded, setLoaded] = useState(false);
  const [videoRef, setVideoRef] = useState(null as HTMLPlyrVideoElement);
  const plyr = videoRef?.plyr;

  useEffect(() => {
    // video player loaded
    if (plyr) {
      plyr.once('canplay', () => {
        setLoaded(true);
      });
    }
  }, [plyr]);

  return (
    <Modal
      open={activeMedia != null}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      width={isMobile ? pageWidth * 0.9 : pageWidth * 0.8}
      className={styles.mediaModal}
      centered
    >
      {!loaded && (
        <div className={styles.loading}>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      )}
      {activeMedia?.type === 'img' && (
        <img
          src={activeMedia.src}
          style={{
            maxHeight: '80dvh',
            marginInline: 'auto',
            height: loaded ? 'auto' : 0,
          }}
          onLoad={() => setLoaded(true)}
        />
      )}
      {activeMedia?.type === 'video' && (
        <div style={{ height: loaded ? 'auto' : 0 }}>
          <LoadableVideoPlayer
            source={{
              sources: [
                {
                  src: activeMedia.src,
                  type: 'video/mp4',
                },
              ],
              type: 'video',
            }}
            options={{
              autoplay: true,
            }}
            ref={(video) => {
              setVideoRef(video);
            }}
          />
        </div>
      )}
    </Modal>
  );
};
