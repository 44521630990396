import { FacebookFilled, InstagramFilled, YoutubeFilled } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { ClickKey_Footer } from '../../lib/web_analytics';
import SvgAppleAppStore from '../svg/apple_app_store';
import SvgGooglePlayStore from '../svg/google_play_store';
import styles from './footer.less';
import omnia_logo from './omnia_logo.svg';

enum IconLinks {
  FACEBOOK = 'https://www.facebook.com/OmniaFishing/',
  INSTAGRAM = 'https://www.instagram.com/omniafishing/',
  YOUTUBE = 'https://www.youtube.com/@omniafishing',
  APPLESTORE = 'https://omnia.fish/apple',
  GOOGLEPLAY = 'https://omnia.fish/google',
}

interface FooterIconsProps {
  webAnalyticsFooterHelper: (
    click_key: ClickKey_Footer,
    target_url: string,
    isExternal_url?: boolean
  ) => void;
}

export const FooterIcons = (props: FooterIconsProps) => {
  const { webAnalyticsFooterHelper } = props;
  return (
    <ul className={styles.links}>
      <div className={styles.icons}>
        <li>
          <Link to="/" onClick={() => webAnalyticsFooterHelper('[footer].(logo)', '/')}>
            <img src={omnia_logo} alt="Omnia_Logo" width={190} height={40} />
          </Link>
        </li>
        <div className={styles.socials}>
          <li>
            <a
              target="_blank"
              href={IconLinks.FACEBOOK}
              onClick={() =>
                webAnalyticsFooterHelper('[footer].(social)', IconLinks.FACEBOOK, true)
              }
            >
              <FacebookFilled style={{ color: '#323233' }} />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={IconLinks.INSTAGRAM}
              onClick={() =>
                webAnalyticsFooterHelper('[footer].(social)', IconLinks.INSTAGRAM, true)
              }
            >
              <InstagramFilled style={{ color: '#323233' }} />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href={IconLinks.YOUTUBE}
              onClick={() => webAnalyticsFooterHelper('[footer].(social)', IconLinks.YOUTUBE, true)}
            >
              <YoutubeFilled style={{ color: '#323233' }} />
            </a>
          </li>
        </div>

        <div className={styles.appstores}>
          <li className={styles.appleStoreIcon}>
            <a
              target="_blank"
              href={IconLinks.APPLESTORE}
              onClick={() =>
                webAnalyticsFooterHelper('[footer].(app_store_apple)', IconLinks.APPLESTORE, true)
              }
            >
              <SvgAppleAppStore />
            </a>
          </li>
          <li className={styles.googleplayIcon}>
            <a
              target="_blank"
              href={IconLinks.GOOGLEPLAY}
              onClick={() =>
                webAnalyticsFooterHelper('[footer].(app_store_google)', IconLinks.GOOGLEPLAY, true)
              }
            >
              <SvgGooglePlayStore />
            </a>
          </li>
        </div>
      </div>
    </ul>
  );
};
