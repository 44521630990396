import { AmbassadorMini } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { isUserPublic } from '../../lib/user';

interface AmbassadorLinkProps {
  ambassador: AmbassadorMini;
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler;
  target?: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >['target'];
}

export const AmbassadorLink = (props: AmbassadorLinkProps) => {
  const { ambassador, children, className, onClick, target } = props;
  const { slug } = ambassador;

  if (isUserPublic(ambassador) && slug) {
    return (
      <Link to={OmniaUrls.ambassador(slug)} className={className} onClick={onClick} target={target}>
        {children}
      </Link>
    );
  }

  return (
    <span className={className} onClick={onClick}>
      {children}
    </span>
  );
};
