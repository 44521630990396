import { Product } from '@omniafishing/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { FlashMessageActions } from '../../redux/flash_message';
import { createUserProductActions } from '../../redux/user';
import { OmniaButton } from '../omnia_button/omnia_button';
import SignupPopover from '../signup_popover/signup_popover';

interface AddToWishlistProps {
  children?: React.ReactNode;
  className?: string;
  products: Product[];
}

export const AddToWishlist = (props: AddToWishlistProps) => {
  const { products, children, className } = props;
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasAccessToken);
  const [open, setOpen] = useState(false);
  const skus = products.map((p) => p.sku);

  const onClick = () => {
    if (isLoggedIn) {
      dispatch(createUserProductActions(skus, 'wishlist'));
      dispatch(
        FlashMessageActions.FLASH_MESSAGE_SET({
          header: 'Product Added',
          subheader: 'This product was added to your wishlist',
        })
      );
      products.forEach((p) => {
        WebAnalytics.productAddedToWishlist(p);
      });
    } else {
      setOpen(true);
    }
  };

  const content = (
    <OmniaButton size="lg" kind="tertiary" fontSize={14} onPress={onClick} className={className}>
      {children}
    </OmniaButton>
  );

  if (!isLoggedIn) {
    return (
      <SignupPopover actionText="use Wishlist" initialOpen={open}>
        {content}
      </SignupPopover>
    );
  }

  return content;
};
