import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { trackJs } from '../../lib/trackjs';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import ErrorPage from '../error_page/error_page';

class ErrorBoundary extends React.Component<RouteComponentProps> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  state = {
    hasError: false,
    error: null as string,
  };

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
          error: null,
        });
      }
    });
  }

  componentDidCatch(error: any, info: any) {
    trackJs(error);
    console.log(error.message);
    this.setState({ error: error.message.toLowerCase() });
  }

  render() {
    const isLoadingError =
      this.state.error != null &&
      this.state.error.includes('loading') &&
      this.state.error.includes('chunk');

    if (isLoadingError) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    if (this.state.hasError) {
      return (
        <>
          {isLoadingError ? (
            <ContentWrapper
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <h1>Omnia Fishing has been updated, reloading now</h1>
            </ContentWrapper>
          ) : (
            <ErrorPage context="error" />
          )}
        </>
      ); // end return
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
