import * as React from 'react';

const SvgFishingReport = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.0002 0H9.0002C5.3002 0 2.2002 3 2.2002 6.8V29.3C2.2002 33 5.3002 36 9.0002 36H27.0002C30.7002 36 33.8002 33 33.8002 29.2V6.8C33.8002 3 30.7002 0 27.0002 0ZM30.9002 29.2C30.9002 31.3 29.2002 33 27.0002 33H9.0002C6.9002 33 5.2002 31.3 5.2002 29.1V6.8C5.2002 4.7 6.9002 3 9.0002 3H27.0002C29.1002 3 30.9002 4.7 30.9002 6.8V29.2Z"
      fill="currentColor"
    />
    <path
      d="M9.5002 12.4H26.5002C26.7002 12.4 26.8002 12.5 26.8002 12.7V14.6C26.8002 14.8 26.7002 14.9 26.5002 14.9H9.5002C9.3002 14.9 9.2002 14.8 9.2002 14.6V12.7C9.2002 12.6 9.3002 12.4 9.5002 12.4Z"
      fill="currentColor"
    />
    <path
      d="M9.5002 6.80005H26.5002C26.7002 6.80005 26.8002 6.90005 26.8002 7.10005V9.00005C26.8002 9.20005 26.7002 9.30005 26.5002 9.30005H9.5002C9.3002 9.30005 9.2002 9.10005 9.2002 9.00005V7.10005C9.2002 6.90005 9.3002 6.80005 9.5002 6.80005Z"
      fill="currentColor"
    />
    <path
      d="M24.5 18H11.5C10.1 18 9 19.1 9 20.5V26.7C9 28.1 10.1 29.2 11.5 29.2H24.5C25.9 29.2 27 28.1 27 26.7V20.5C27 19.1 25.9 18 24.5 18ZM24.5 26.7H11.5V20.5H24.5V26.7Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFishingReport;
