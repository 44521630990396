import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../helpers/app_state';
import {
  AuthActions,
  getAccessTokenExpiration,
  getIsAuthExpired,
  getRefreshToken,
  hasAccessToken,
  refreshAuth,
} from '../../redux/auth';
import { fetchUser, getLoadingState as getUserLoadingState, getUser } from '../../redux/user';
import UserAuthHandler from './user_auth_handler';

function mapStateToProps(state: ApplicationState, ownProps = {}) {
  return {
    hasAccessToken: hasAccessToken(state),
    accessTokenExpiration: getAccessTokenExpiration(state),
    isAuthExpired: getIsAuthExpired(state),
    refreshToken: getRefreshToken(state),
    user: getUser(state),
    userLoadingState: getUserLoadingState(state),
  };
}

const dispatchProps = {
  refreshAuth,
  fetchUser,
  checkAuthExpired: AuthActions.AUTH_EXPIRED_CHECK,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(dispatchProps, dispatch);

const UserAuthHandlerContainer = connect(mapStateToProps, dispatchProps)(UserAuthHandler);

export type UserAuthHandlerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default UserAuthHandlerContainer;
